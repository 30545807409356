
export default {
  name: 'CommonPhrasePicker',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    // 点击管理按钮
    handleClickManageBtn() {
      this.$emit('open-dialog');
    },
    // 点击某行常用语
    handleClickPhrase(phrase) {
      if (!phrase) return;
      this.$emit('click-phrase', phrase);
    },
    // 点击弹窗之外关闭
    handleClose() {
      this.$emit('close');
    },
  },
};
