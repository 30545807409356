
import { getCountries, getStates, getCity } from '@/api/client-api/api.js';
export default {
  name: 'SearchAddress',
  props: {
    length: {
      type: Number,
      default: 100,
    },
    overall: {
      type: Number,
      default: 1,
    },
    regionarr: {
      type: Array,
      required: true,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    is_request: {
      type: String,
      default: 'flag',
    },
  },
  data() {
    return {
      countrylist: [],
      countryvalue: '',
      statelist: [],
      statevalue: '',
      cityvalue: '',
      citylist: [],
      valuelist: this.regionarr, // 地址id数组
      labellist: [], // 地址名称数组
      has_state: '0', // 是否有国家
    };
  },
  async mounted() {
    this.is_request == 'flag' && (await this.getCountries());
    if (this.valuelist.length > 0) {
      this.countryvalue = this.valuelist[0];
      if (this.valuelist.length == 3) {
        // 国家，省，市
        this.statevalue = this.valuelist[1];
        await this.getstate(this.countryvalue);
        this.cityvalue = this.valuelist[2];
        await this.getCity(this.statevalue);
      }
      if (this.valuelist.length == 2) {
        // 国家，市
        await this.getCity(this.countryvalue, 'country');
        this.cityvalue = this.valuelist[1];
      }
    }
  },
  watch: {
    is_request(newValue) {
      this.countryvalue = '';
      this.statevalue = '';
      this.cityvalue = '';
      this.statelist = [];
      this.citylist = [];
      this.valuelist = [];
      this.labellist = [];
      newValue ? this.getCountries(newValue) : '';
    },
  },
  methods: {
    getCountries(id = '') {
      return new Promise((resolve, reject) => {
        getCountries({ id })
          .then((res) => {
            if (res?.errcode == 0) {
              this.countrylist = res?.data;
              resolve();
            } else {
              reject();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },
    getstate(id) {
      let option = {
        id: id,
      };
      return new Promise((resolve, reject) => {
        getStates(option)
          .then((res) => {
            if (res?.errcode == 0) {
              this.statelist = res?.data;
              resolve();
            } else {
              reject();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },
    getCity(id, flag) {
      let option = {
        id: id,
        type: flag,
      };
      return new Promise((resolve, reject) => {
        getCity(option)
          .then((res) => {
            if (res?.errcode == 0) {
              this.citylist = res?.data;
              resolve();
            } else {
              reject();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },
    countrychange(val) {
      // 国家change
      // 清空省市数据
      this.statevalue = '';
      this.cityvalue = '';
      this.statelist = [];
      this.citylist = [];
      this.valuelist = [];
      this.labellist = [];
      if (val != '') {
        let countryobj = this.countrylist.filter((item) => {
          return item.id == val;
        });
        this.valuelist.push(val);
        this.labellist.push(countryobj[0].cname);
        this.has_state = countryobj[0].has_state;
        if (countryobj[0].has_state == 1) {
          this.getstate(val);
        } else {
          // 没有省
          this.getCity(val, 'country');
        }
      }
      this.$emit('getaddressvalue', this.valuelist, this.labellist);
    },
    statechange(val) {
      // 省chang
      // 将市的数据清空
      this.cityvalue = '';
      this.citylist = [];
      let countryvalue = this.valuelist[0];
      let countrylabel = this.labellist[0];
      this.valuelist = [];
      this.labellist = [];
      // 国家数据
      this.valuelist.push(countryvalue);
      this.labellist.push(countrylabel);
      // 省数据
      if (val != '') {
        let stateobj = this.statelist.filter((item) => {
          return item.id == val;
        });
        this.valuelist.push(val);
        this.labellist.push(stateobj[0].cname);
        this.getCity(val);
      }
      this.$emit('getaddressvalue', this.valuelist, this.labellist);
    },
    citychange(val) {
      // console.log(val + '--------val');
      // console.log(this.valuelist, '----------this.valueList');
      // 市change
      if (val != '') {
        let cityobj = this.citylist.filter((item) => {
          return item.id == val;
        });
        let flag =
          this.countrylist.filter((item) => item.id == this.valuelist[0])[0]
            .has_state == 1
            ? true
            : false; //判断国家是否有省
        if (
          this.valuelist.length == 3 ||
          (this.valuelist.length == 2 && !flag)
        ) {
          if (this.valuelist.length == 3) {
            this.valuelist.splice(2, 1);
            this.labellist.splice(2, 1);
          } else if (this.valuelist.length == 2) {
            this.valuelist.splice(1, 1);
            this.labellist.splice(1, 1);
          }
        }
        this.valuelist.push(val);
        this.labellist.push(cityobj[0].cname);
      } else {
        let valuelength = this.valuelist.length;
        let labellength = this.labellist.length;
        this.valuelist.splice(valuelength - 1, 1);
        this.labellist.splice(labellength - 1, 1);
      }
      this.$emit('getaddressvalue', this.valuelist, this.labellist);
    },
  },
};
