
export default {
  data() {
    return {};
  },
  props: {
    // 语言配置
    langConfig: {
      type: Object,
      default: () => {},
    },
    // 精选商品列表
    goodsSelectedL: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'selected-products',
    },
  },
  computed: {},
  methods: {
    // 页面触发
    //跳转到详情页面
    comDetails(item) {
      this.toCachedShopdetail(
        item.item_id,
        item.image_default_id,
        item.sku_lowest_price_img
      );
    },
  },
};
