import request from '@/utils/request.js';
// 平台公告
// 获取公告
export function getBulletinList(data) {
  return request({
    url: '/Notice/noticeDisplay',
    apiModule: 'notify',
    method: 'get',
    params: data,
    controller: {
      openLoading: data?.openLoading || false,
    },
  });
}

