
import { getCustomerManagerInfo } from '@/api/client-api/goods/shopindex.js';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  name: 'accountManager',
  data() {
    return {
      phoneNumber: '',
      managerName: '',
      isAccount: true,
      hover: false,
      arrowLeft: false,
      arrowRight: false,
      isClick: '-1', //记录是否被点击
      token: '',
    };
  },
  computed: {
    showContactSticker() {
      return this.token && !this.$route?.meta?.hideContactSticker;
    },
  },
  methods: {
    handleEmptyData(content) {
      return content ? content : '--';
    },
    // 鼠标点击效果
    controlArrow() {
      if (this.isClick === '1') {
        this.hover = false;
        // console.log("哟呼~被点击了");
        this.arrowRight = false;
        this.arrowLeft = true;
        this.isClick = '-1';
        return;
      }
      if (this.isClick === '-1') {
        this.hover = true;
        // console.log("哟呼~被点击了");
        this.arrowRight = true;
        this.arrowLeft = false;
        this.isClick = '1';
      }
    },
    mouseEnter() {
      if (this.isClick === '-1') {
        this.arrowLeft = true;
        this.isAccount = false;
      }
    },
    mouseEnter1() {
      if (this.isClick === '') {
      }
      this.arrowRight = true;
      this.isAccount = false;
    },
    mouseLeave() {
      if (this.isClick === '-1') {
        this.arrowLeft = false;
        this.arrowRight = false;
        this.isAccount = true;
      }
      console.log(this.isClick, '1344');
    },
    // 获取客户经理信息
    getCustomerManagerData() {
      if (this.token) {
        getCustomerManagerInfo({ MUTE_WARNING: 1 })
          .then((res) => {
            if (res?.errcode == 0) {
              this.phoneNumber = res?.data?.phone;
              this.managerName = res?.data?.name;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
  },
  mounted() {
    this.token = this.$cookies.get(CUR_TOKEN_NAME);
    this.getCustomerManagerData();
  },
};
