import JSZip from 'jszip';
import axios from 'axios';

/**
 * 压缩文件为zip压缩包
 * @author hukeyi
 * @description 使用jszip库压缩多个文件，并下载zip
 */
const zipTool = new JSZip();

/**
 * 下载文件，获取blob文件流
 * @param {*} url 文件路径
 * @returns url对应的文件blob
 */
function downloadFile(url) {
  return new Promise((resolve, reject) => {
    if (!url) {
      return resolve();
    }
    axios({
      url: url, //下载的url
      method: 'get',
      responseType: 'blob',
    })
      .then((res) => {
        const filestream = res.data; // 返回的文件流
        const blob = new Blob([filestream], {
          type: res.headers['content-type'],
        });
        return resolve(blob);
      })
      .catch((err) => {
        console.log('下载失败：', err);
        return reject(err);
      });
  });
}
/**
 * 下载并压缩文件，生成zip文件并下载
 * @param {*} list 文件路径列表
 */
async function zip(list, zipName) {
  try {
    if (!process.client) {
      return;
    }
    for (let file of list) {
      let url = file.fileUrl,
        name = file.fileName;
      const fileBlob = await downloadFile(url);
      zipTool.file(name, fileBlob);
    }
    const zipBase64 = await zipTool.generateAsync({ type: 'base64' });
    let href = 'data:application/zip;base64,' + zipBase64;
    const a = document.createElement('a');
    a.href = href;
    a.download = decodeURI(zipName);
    document.body.appendChild(a);
    a.click();
    // 下载完移除元素
    document.body.removeChild(a);
    window.URL.revokeObjectURL(href);
  } catch (err) {
    console.error('生成压缩包失败：', err);
  }
}

export { zip };
