
import OrderList from '@/pages/back-stage/trading-module/components/orderlist.vue';
export default {
    name:'no-pay',
    components:{
        OrderList
    },
    data() {
        return{

        };
    },

    filters: {

    },
    activated(){
    },
    deactivated() {
    },
    mounted() {

    },
    methods: {

    }
};
