
export default {
  data() {
    return {};
  },

  methods: {
    handleClickClose() {
      this.$emit('close-dialog');
    },
    // 点击同意并使用按钮
    handleClickAgree() {
      this.$emit('agree');
    },
    // 跳转协议内容页面
    goProtocolDetail() {
      window.open('/protocol?id=9', '_blank');
    },
  },
  mounted() {},
};
