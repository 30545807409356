
import PreviewImgsBlock from '../components/PreviewImgsBlock';
import CopyItem from '../components/CopyItem';
import { complaintDetails } from '@/api/client-api/complaints/complaints.js';

import AppealForm from '../components/AppealForm';

export default {
  name: 'ReviewReportDetail',
  components: { PreviewImgsBlock, CopyItem, AppealForm },
  data() {
    return {
      complainId: '', // 投诉id
      detailInfo: [], // 投诉详情
    };
  },
  filters: {
    // 判断是否有值
    emptyValFilter(val) {
      if (val == 0) {
        return '0';
      }
      return !val ? '--' : val;
    },
  },
  computed: {
    // 是否“我收到的投诉”
    isReceive() {
      return this.detailInfo?.[0]?.identity === 'accused';
    },
    // 是否“我发起的投诉”
    isLaunch() {
      return this.detailInfo?.[0]?.identity === 'complainant';
    },
    // 是否违规
    isViolated() {
      return this.detailInfo?.[0]?.is_violate;
    },
    // 举报结果文本
    resultTitle() {
      let str = '';
      if (this.isViolated == 1) {
        str = '本次举报成立';
      } else if (this.isViolated == 2) {
        str = '本次举报不成立';
      } else if (this.isViolated == 3) {
        str = '违规但未扣分';
      }
      return str;
    },
    hasResult() {
      return this.detailInfo?.[0]?.deal_status == 2;
    },
  },
  methods: {
    // 点击查看订单详情
    handleClickOrderId(id) {
      if (!id) {
        return;
      }
      let routeData = this.$router.resolve({
        path: `/orderDetails?orderNo=${id}`,
      });
      window.open(routeData.href, '_blank');
    },
    // 点击返回按钮
    goBack() {
      // this.$router.go(-1);
      this.$router.replace('/complaints/complaintslist');
    },
    // 获取投诉详情
    getDetailInfo() {
      this.detailInfo = [];
      const options = {
        complaint_id: this.complainId,
      };
      complaintDetails(options)
        .then((res) => {
          if (res) {
            if (res?.errcode == 0) {
              let obj = JSON.parse(JSON.stringify(res?.data));
              if (obj.img && obj.img != '') {
                obj.img = obj?.img?.split(',') || [];
              } else {
                obj.img = [];
              }
              if (obj.attachment && obj.attachment != '') {
                obj.attachment = obj?.attachment?.split(',') || [];
              } else {
                obj.attachment = [];
              }
              obj.img.push(obj.video);
              this.detailInfo.push(obj);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // 按钮的显示隐藏
  },

  mounted() {
    if (this.$route.params?.id) {
      this.complainId = this.$route.params?.id?.toString();
      this.getDetailInfo(); // 获取投诉详情
    } else {
      this.$router.push('/error');
    }
  },
};
