
export default {
  data() {
    return {};
  },
  props: {
    goolist: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    goGoodDetail(id) {
      this.$router.push("/" + id + ".html");
    },
    // 关闭弹框
    closeGoodIdag() {
      this.$emit("closeGoodIdag");
    },
  },
};
