// 你是否正面临以下问题？
export const PROBLEM_LIST = [
  {
    title: '资源少',
    text: '找不到低价\n优质、稳定、蓝海货源',
    icon: require('@/assets/landing-page/problem1.svg'),
  },
  {
    title: '选品难',
    text: '不知道什么品好卖\n选不对品',
    icon: require('@/assets/landing-page/problem2.svg'),
  },
  {
    title: '运营难',
    text: '曝光少，转化低\n广告投流打水漂',
    icon: require('@/assets/landing-page/problem3.svg'),
  },
  {
    title: '资金压力大',
    text: '仓储囤货成本高\n钱压在货上',
    icon: require('@/assets/landing-page/problem4.svg'),
  },
];

// 来细目 你能得到(PC)
export const GET_PC_LIST = [
  {
    text: '全品类 | 一手低价货源',
    icon: require('@/assets/landing-page/pc/get1.png'),
  },
  {
    text: '1对1 | 专业选品指导',
    icon: require('@/assets/landing-page/pc/get2.png'),
  },
  {
    text: '免费&专业 | 运营培训',
    icon: require('@/assets/landing-page/pc/get3.png'),
  },
  {
    text: '不囤货 | 无资金压力',
    icon: require('@/assets/landing-page/pc/get4.png'),
  },
];

// 来细目 你能得到(mobile)
export const GET_MOBILE_LIST = [
  {
    text: '全品类 | 一手低价货源',
    icon: require('@/assets/landing-page/mobile/get1.png'),
  },
  {
    text: '1对1 | 专业选品指导',
    icon: require('@/assets/landing-page/mobile/get2.png'),
  },
  {
    text: '免费&专业 | 运营培训',
    icon: require('@/assets/landing-page/mobile/get3.png'),
  },
  {
    text: '不囤货 | 无资金压力',
    icon: require('@/assets/landing-page/mobile/get4.png'),
  },
];

// 一手低价货源 品类齐全(PC)
export const CATE_PC_LIST = [
  { text: '家电', icon: require('@/assets/landing-page/pc/cate1.png') },
  { text: '3C数码', icon: require('@/assets/landing-page/pc/cate2.png') },
  {
    text: '美妆个护',
    icon: require('@/assets/landing-page/pc/cate3.png'),
  },
  {
    text: '家居百货',
    icon: require('@/assets/landing-page/pc/cate4.png'),
  },
  {
    text: '户外运动',
    icon: require('@/assets/landing-page/pc/cate5.png'),
  },
  {
    text: '鞋服箱包',
    icon: require('@/assets/landing-page/pc/cate6.png'),
  },
  {
    text: '母婴用品',
    icon: require('@/assets/landing-page/pc/cate7.png'),
  },
  {
    text: '宠物用品',
    icon: require('@/assets/landing-page/pc/cate8.png'),
  },
];

// 一手低价货源 品类齐全(mobile)
export const CATE_MOBILE_LIST = [
  {
    text: '家电',
    icon: require('@/assets/landing-page/mobile/cate1.png'),
  },
  {
    text: '3C数码',
    icon: require('@/assets/landing-page/mobile/cate2.png'),
  },
  {
    text: '美妆个护',
    icon: require('@/assets/landing-page/mobile/cate3.png'),
  },
  {
    text: '家居百货',
    icon: require('@/assets/landing-page/mobile/cate4.png'),
  },
  {
    text: '户外运动',
    icon: require('@/assets/landing-page/mobile/cate5.png'),
  },
  {
    text: '鞋服箱包',
    icon: require('@/assets/landing-page/mobile/cate6.png'),
  },
  {
    text: '母婴用品',
    icon: require('@/assets/landing-page/mobile/cate7.png'),
  },
  {
    text: '宠物用品',
    icon: require('@/assets/landing-page/mobile/cate8.png'),
  },
];

// 做电商 上细目商城
export const COME_LIST = [
  {
    num: '1万',
    unit: '+',
    text: '源头厂家',
    icon: require('@/assets/landing-page/come1.png'),
  },
  {
    num: '30万',
    unit: '+',
    text: '热销商品',
    icon: require('@/assets/landing-page/come2.png'),
  },
  {
    num: '百亿',
    text: '平台补贴',
    icon: require('@/assets/landing-page/come3.png'),
  },
];
