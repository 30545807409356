export default ({ $axios }, inject) => {

  // 获取商城补贴商品列表
  inject("getMallSubsidyItemList", (data) =>
    $axios({
      url: "/item/getMallSubsidyItemList",
      method: "get",
      params: data,
    })
  );
};
