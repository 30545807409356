
export default {
  name: 'WareTable',
  data() {
    return {
      tableDataList: this.tableData,
      ConfigurationList: this.Configuration,
      tableFiledCtrl: [], //更多字段选中的
      moreFieldList: this.moreField,
      // selectedRows: [], //存储选中的数据
      row: {},
    };
  },
  props: {
    // 表格配置列表
    Configuration: {
      type: Array,
      default: () => {},
    },
    // 表格信息列表
    tableData: {
      type: Array,
      default: () => {},
    },
    // 是否有商品列表或更多字段
    isTitle: {
      type: Boolean,
      default: false,
    },
    // 更多字段列表
    moreField: {
      type: Array,
      default: () => {},
    },
    // 是否开启表格复选
    tableCheck: {
      type: Boolean,
      default: true,
    },
    // 需要拿来对比的字段(全选，单选等事件不放心可以用此字段)
    // 如果存在表格数据更新的情况，请用这个字段
    compareFields: {
      type: [String, Number, Object],
      default: '',
    },
    // 决定是否固定列
    isFixed: {
      type: Boolean,
      default: true,
    },
    // 选择的列表数据
    choseRows: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    // 表格配置
    Configuration(n) {
      if (n) {
        this.ConfigurationList = n;
      }
    },
    // 更多字段
    moreField(n) {
      if (n) {
        this.moreFieldList = n;
      }
    },
    // 表格数据
    tableData(n) {
      if (n) {
        this.recoveryCheck(this.tableData);
      }
    },
  },
  computed: {
    // tableDataList() {
    //   return this.tableData;
    // },
    // ConfigurationList() {
    //   // this.selectedRows = [];
    //   return this.Configuration;
    // },
    // moreFieldList() {
    //   return this.moreField;
    // },
    // 选择的列表数据
    selectedRows: {
      get() {
        if (this.choseRows) {
          return JSON.parse(JSON.stringify(this.choseRows));
        } else return [];
      },
      set() {
        if (this.choseRows) {
          this.recoveryCheck(this.tableData);
        }
      },
    },
  },
  mounted() {},
  methods: {
    // 单选
    handleSelect(selection, row) {
      let checkBox = '';
      let idx = '';
      // if (this.compareFields && this.compareFields !== '') {
      //   // 判断是否选中
      //   checkBox = selection.find(
      //     (item) => item[this.compareFields] === row[this.compareFields]
      //   );
      //   /**
      //    * 使用这个方式比较有个注意点，对象中的属性，序列要一致
      //    */
      //   idx = this.selectedRows.findIndex(
      //     (item) => item[this.compareFields] === row[this.compareFields]
      //   );
      // }
      // if (this.compareFields === '') {
      //   // 判断是否选中
      //   checkBox = selection.find(
      //     (item) => JSON.stringify(item) === JSON.stringify(row)
      //   );
      //   /**
      //    * 使用这个方式比较有个注意点，对象中的属性，序列要一致
      //    */
      //   idx = this.selectedRows.findIndex(
      //     (item) => JSON.stringify(row) === JSON.stringify(item)
      //   );
      // }
      // // 未选中
      // if (!checkBox) {
      //   if (idx > -1) {
      //     this.selectedRows.splice(idx, 1);
      //   }
      // } else {
      //   if (idx === -1) {
      //     this.selectedRows.push(row);
      //   }
      // }

      // this.$emit('select', this.selectedRows);
      this.$emit('select', row);
    },
    // 全选
    handleSelectAll(selection) {
      // console.log(selection, '点击全选');
      // 全选或取消全选时更新选中行的数据
      // 全选的情况
      if (selection) {
        // selection.forEach((item) => {
        //   this.handleSelect(selection, item);
        // });
        const filteredSelection = selection.filter((item) => {
          return !this.choseRows.some((row) => {
            if (this.compareFields && this.compareFields !== null) {
              return row[this.compareFields] === item[this.compareFields];
            } else {
              return JSON.stringify(row) === JSON.stringify(item);
            }
          });
        });
        filteredSelection.forEach((item) => {
          this.handleSelect(selection, item);
        });
      }
      // 取消全选的情况
      if (selection.length === 0) {
        this.tableData.forEach((item) => {
          this.handleSelect(selection, item);
        });
      }
    },
    // 根据数据恢复选中状态
    recoveryCheck(arr) {
      if (arr && arr.length > 0) {
        // 有特定字段的情况
        if (this.compareFields && this.compareFields !== null) {
          arr.forEach((item) => {
            if (
              this.selectedRows.findIndex(
                (item2) =>
                  item2[this.compareFields] === item[this.compareFields]
              ) > -1
            ) {
              this.$nextTick(() => {
                this.$refs.allTable.toggleRowSelection(item, true);
              });
            }
          });
        } else {
          console.log(this.selectedRows, arr);
          arr.forEach((item) => {
            if (
              this.selectedRows.findIndex(
                (item2) => JSON.stringify(item2) === JSON.stringify(item)
              ) > -1
            ) {
              this.$nextTick(() => {
                this.$refs.allTable.toggleRowSelection(item, true);
              });
            }
          });
        }
      }
    },

    // 更多字段的选择规则
    checkboxChange() {
      // 拷贝
      let arr = JSON.parse(JSON.stringify(this.Configuration));
      // 初始化
      this.ConfigurationList = arr;
      // 选中进入
      if (this.tableFiledCtrl && this.tableFiledCtrl.length > 0) {
        this.tableFiledCtrl.forEach((item) => {
          this.ConfigurationList.splice(
            this.ConfigurationList.length - 1,
            0,
            item.fieladList
          );
        });
      }
      if (this.$refs.allTable) {
        this.$refs.allTable.doLayout();
      }
    },

    // 重置表格选中的数据
    reSelectData() {
      this.tableFiledCtrl = [];
      this.selectedRows = [];
      if (this.$refs.allTable) {
        this.$refs.allTable.doLayout();
      }
    },
  },
};
