
import {
  getBasicInformation,
  editUserBasicInfo,
} from '@/api/client-api/account/accountmanage.js';
export default {
  name: 'BasicInformation',
  data() {
    return {
      showPreviewimg: false, //预览弹窗标识
      imgUrl: '', //预览图片

      cannotDialog: false, // 不可修改基础信息组件状态
      cannotEditTipContent: '', // 不可修改基础信息提示语

      actionStatus: false, // 操作状态 false查看状态不可修改 true编辑状态可修改
      title: '查看基础信息',
      lastEditTimes: '-1', // 当前剩余修改次数
      nextEditDays: '-1', // 下次可修改天数
      // 分销商基础信息
      userBasicMsg: {
        id: '', // 用户id
        username: '', // 用户名
        nickname: '', // 昵称
        avatar: '', // 头像
      },
      showFileArray: [], // 需要回显的头像

      usernameTip: false, // 用户名是否有错
      nicknameTip: false, // 昵称是否有错
      avatarTip: false, // 头像是否有错
    };
  },
  filters: {
    // 判断是否有值
    emptyVal(val) {
      if (val && val != '') {
        return val;
      } else {
        return '--';
      }
    },
  },
  watch: {
    userBasicMsg: {
      deep: true,
      handler(newVal) {
        if (
          newVal.username.trim().length > 0 &&
          newVal.username.trim().length <= 20
        ) {
          this.usernameTip = false;
        }

        if (
          newVal.nickname.trim().length > 0 &&
          newVal.nickname.trim().length <= 20
        ) {
          this.nicknameTip = false;
        }
      },
    },
  },
  computed: {},
  mounted() {
    this.judgePageStatus(); // 判断当前页面是查看还是编辑
    this.getBasicData(); // 获取基础数据
  },
  methods: {
    //预览图片回调
    showPreviewimgFunc(url, isImg) {
      this.imgUrl = this.$options.filters.imgbaseurl(url);
      if (isImg) {
        this.showPreviewimg = true;
      } else {
        this.showPreviewdoc = true;
      }
    },
    //关闭预览回调
    previewCloseFunc() {
      this.showPreviewimg = this.showPreviewdoc = false;
    },
    // 判断当前页面是查看还是编辑
    judgePageStatus() {
      if (!this.$route.params.type) {
        this.actionStatus = false;
        this.title = '查看基础信息';
        return;
      }
      if (this.$route.params.type && this.$route.params.type == 'edit') {
        this.actionStatus = true;
        this.title = '编辑基础信息';

        return;
      }
      if (this.$route.params.type && this.$route.params.type !== 'edit') {
        this.actionStatus = false;
        this.$router.push('/error');
        return;
      }
    },
    // 获取基础数据
    getBasicData() {
      getBasicInformation()
        .then((res) => {
          if (res?.errcode == 0) {
            // 获取
            this.userBasicMsg = {
              username: res?.data?.username, // 用户名
              nickname: res?.data?.nickname, // 昵称
            };

            // 从localStorage拿id和头像
            let userDetail = JSON.parse(localStorage.getItem('userdetail'));
            this.userBasicMsg.id = userDetail?.user_id;
            this.userBasicMsg.avatar = userDetail?.portrait;

            if (userDetail?.portrait == '') {
              this.showFileArray = [];
            } else {
              this.showFileArray = userDetail?.portrait?.split(',');
            }

            /**
             * 修改次数相关
             */
            this.lastEditTimes = res?.data?.basic_modify_num?.toString();
            this.nextEditDays = res?.data?.modify_residue_day?.toString();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 头像变动
    changeAvatar(avatar) {
      this.userBasicMsg.avatar = avatar?.join();

      if (this.userBasicMsg.avatar != '') {
        this.avatarTip = false;
      }
    },
    // 点击保存按钮
    save() {
      if (this.lastEditTimes == '0') {
        this.cannotDialog = true;
        this.cannotEditTipContent =
          '基础信息每30天修改一次，您的修改次数已达上限，距下次可修改还剩' +
          this.nextEditDays +
          '天。';
        return;
      }

      if (this.userBasicMsg?.username?.includes('细目')) {
        this.$message.warning('用户名中不可含有“细目”字样，请重新编辑用户名');
        return;
      }
      if (this.userBasicMsg?.nickname?.includes('细目')) {
        this.$message.warning('昵称中不可含有“细目”字样，请重新编辑昵称');
        return;
      }

      this.saveBasicInfoTip();

      this.$nextTick(() => {
        if (document.getElementsByClassName('err-color').length !== 0) {
          return;
        }

        let options = {
          username: this.userBasicMsg?.username,
          nickname: this.userBasicMsg?.nickname,
          portrait: this.userBasicMsg?.avatar,
        };
        editUserBasicInfo(options)
          .then((res) => {
            if (res?.errcode == 0) {
              this.$message.success('保存成功');
              // 替换头像和昵称
              let userdetail = JSON.parse(
                this.$store.state.login.loginInfo.userdetail
              );
              userdetail.login_account = this.userBasicMsg?.username;
              userdetail.nickname = this.userBasicMsg?.nickname;
              userdetail.portrait = this.userBasicMsg?.avatar;
              this.$cookies.set('userdetail', JSON.stringify(userdetail), {
                path: '/',
                maxAge: 60 * 60 * 24 * 30,
              });
              this.$store.commit('login/setUserInfo', {
                key: 'userdetail',
                value: JSON.stringify(userdetail),
              });
              localStorage.setItem('userdetail', JSON.stringify(userdetail));

              this.$router.push('/account/accountset');
            }
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },
    // 点击返回按钮
    back() {
      this.$router.go(-1);
    },
    // 点击确认/取消不可修改提示
    sureDialogTip() {
      this.cannotDialog = false;
      this.$router.push('/account/accountset');
    },
    // 保存字段验证
    saveBasicInfoTip() {
      if (
        this.userBasicMsg?.username?.trim()?.length == 0 ||
        this.userBasicMsg?.username?.trim()?.length > 20
      ) {
        this.usernameTip = true;
      }
      if (
        this.userBasicMsg?.nickname?.trim()?.length == 0 ||
        this.userBasicMsg?.nickname?.trim()?.length > 20
      ) {
        this.nicknameTip = true;
      }
      if (this.userBasicMsg?.avatar == '') {
        this.avatarTip = true;
      } else {
        this.avatarTip = false;
      }
    },
  },
};
