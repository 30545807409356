
export default {
  props: {
    tipTitle: {
      type: String,
      default: "温馨提示",
    },
  },
  data() {
    return {
      activeName: 1, // 默认待开票
    };
  },
};
