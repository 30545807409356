
import GoodFormLine from './components/GoodFormLine';
import FoldTable from './components/FoldTable';
import SpecificationItem from './components/SpecificationItem';
import StarRateItem from './components/StarRateItem.vue';
import GridBlock from './components/GridBlock.vue';
import ProvinceSelect from './components/ProvinceSelect.vue';
import WarehouseNotice from './components/WarehouseNotice.vue';
import CouponDrawer from './components/CouponDrawer.vue';
import ServicePopover from './components/ServicePopover.vue';
// import {
//   getItemWarehouses,
//   getChangeLog,
//   getItemFreight,
// } from '@/api/client-api/goods/shopindex';

import { getCoupons } from '@/api/goods/good_detail.js';

import { getSupAddrList } from '@/api/client-api/mine/mineapi';

import { SpecAdjoinMatrix } from '@/utils/sku_algorithm';
import { formatEvaluationCountLabel } from './js/utils';
import { getOperationFee } from '@/utils/price_calculator.js';

import {
  ORDER_TYPE_OPTIONS,
  FORM_MAIN_LABELS,
  WAREHOUSE_TABLE_CONFIG,
  LOG_TABLE_CONFIG,
  INFO_CONFIG,
} from './js/label_config_form_main';

import { UNIT_LABELS } from './js/label_config_common';
import { mapGetters } from 'vuex';
// 变量
import {
  curDefaultLanguage,
  CUR_TOKEN_NAME,
} from '@/utils/cur_default_config.js'; //默认语言
export default {
  name: 'DetailFormMain',
  components: {
    GoodFormLine,
    FoldTable,
    SpecificationItem,
    StarRateItem,
    GridBlock,
    ProvinceSelect,
    WarehouseNotice,
    CouponDrawer,
    ServicePopover,
  },
  props: {
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
    currency: {
      type: String,
      default: '¥',
    },
    itemId: {
      type: String,
      default: '',
    },
    // 商品信息（与sku无关的）
    goodInfo: {
      type: Object,
      default: () => {},
    },
    // 当前选中的 sku id
    skuId: {
      type: String,
      default: '',
    },
    // sku 信息
    skuInfo: {
      type: Object,
      default: () => {},
    },
    // 商品的所有 sku 列表数据
    skuList: {
      type: Array,
      default: () => [],
    },
    // 当前选中的仓库id
    warehouseId: {
      type: String,
      default: '',
    },
    // 商品评价数
    evaluationCount: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      changeNotices: [], // 变更通知
      waitCollectCoupon: [], // 待领取优惠券
      usableCoupon: [], // 可用优惠券

      /**
       * 基础信息
       * 商品id和sku相关
       */
      currSkuId: this.skuId, // 当前用户选择的规格对应的 skuid
      currSkuInfo: this.skuInfo,
      goodInputNum: 1, // 当前商品数量
      reachStockUp: false, // 是否达到备货标准
      stockUpPolicy: [], // 备货政策
      stockUpPrice: 0, // 备货折扣
      stockUpDiscount: '', // 备货折扣文本

      /**
       * 下单类型
       */
      currOrderType: '2', // 1: 样品单；2：普通单

      /**
       * 操作费
       */
      currOperationFee: 0,

      /**
       * 规格
       */
      selectedSpecItems: new Set(), // 被选中的商品规格种类和规格值字符串（"种类@值"）集合
      selectedSpecMap: new Map(), // 被选中的商品规格种类和规格值映射（种类=>值）集合
      stockedSpecItems: new Set(), // 当前情况（有哪些规格被选中）下，仍有库存（=不置灰）的规格值集合
      /**
       * 仓库
       */
      currWarehouseId: '', // 当前仓库ID
      currStockNum: 0, // 当前仓库的库存数量

      isWarehouseTableFold: true, // 仓库表格是否折叠
      warehouseFullList: [], // 仓库完整列表
      // -------------------------------------------
      // 供应商发布公告相关需求
      selectedWarehouseNotice: [], //被选中仓库的公告信息
      // -------------------------------------------
      // 仓库地址id数组
      warehouseAreaObj: {
        country_id: '',
        province_id: '',
      },

      /**
       * 物流
       */
      logTemplateList: [], // 物流模版列表
      logisticsId: '', // 物流模板ID
      logisticsPrice: '', // 物流费
      insurancePrice: '', // 保价费
      /**
       * 物流地址信息
       */

      // 发货地地区数据
      startAreaObj: {
        country: '',
        province: '',
        city: '',
        country_id: '',
        province_id: '',
        city_id: '',
      },
      // 收货地地区数据
      endAreaObj: {
        country: '',
        province: '',
        country_id: '',
        province_id: '',
      },
      // 分销商的默认收货地址
      defaultEndAreaObj: {
        country: '',
        province: '',
        country_id: '',
        province_id: '',
      },
      // 当前网站顶部的地址
      // 当默认收货地址不在地址列表中时，用作备用的收货地址数据
      currAreaObj: {
        country: '',
        province: '',
        country_id: '',
        province_id: '',
      },

      // 倒计时相关
      curCountdownTime: null, //计时器
      curDataTime: '', //当前时间
      token: '',
      wareLogistics: '', //当前到仓物流费
    };
  },
  computed: {
    ...mapGetters('common', ['currAreaData', 'currLang']),
    // 标签的多语言配置
    labelConfig() {
      return FORM_MAIN_LABELS[this.lang || curDefaultLanguage];
    },
    // 单位的多语言配置
    unitLabelConfig() {
      return UNIT_LABELS[this.lang || curDefaultLanguage];
    },
    // 仓库表格配置
    warehouseTableFormat() {
      return WAREHOUSE_TABLE_CONFIG[this.lang || curDefaultLanguage];
    },
    // 物流表格配置
    logisticsTableFormat() {
      return LOG_TABLE_CONFIG[this.lang || curDefaultLanguage];
    },
    // 发货地国省市地址字符串
    startAddrStr() {
      return this.getWarehouseAddr(this.startAreaObj);
    },
    // 下单类型的选项配置
    orderTypeOptions() {
      return ORDER_TYPE_OPTIONS[this.lang || curDefaultLanguage];
    },
    // 评价总数
    evaluationSum() {
      let label = formatEvaluationCountLabel(this.evaluationCount);
      return label;
    },
    // 评价评分
    evaluateScore() {
      let score = this.goodInfo?.evaluate_score || 0;
      return parseFloat(score);
    },
    // 月销量标签
    mouthlySalesLabel() {
      let sales = this.goodInfo?.month_sale_count;
      return sales;
    },
    // 产品信息数据
    goodInfoList() {
      const goodFeatures = this.normalizeEmptyValue(
        this.goodInfo?.goods_feature
      );
      const packingSize = this.normalizeEmptyValue(
        this.currSkuInfo?.packing_size
      );
      const packingWeightLabel = this.normalizeEmptyValue(
        this.currSkuInfo?.packing_weight
      );

      return [
        {
          label: INFO_CONFIG[this.lang || curDefaultLanguage]?.['size'],
          type: 'text',
          data: packingSize,
          isNoWrap: true,
        },
        {
          label: INFO_CONFIG[this.lang || curDefaultLanguage]?.['weight'],
          type: 'text',
          data: packingWeightLabel,
        },
        {
          label: INFO_CONFIG[this.lang || curDefaultLanguage]?.['features'],
          type: 'text',
          data: goodFeatures,
        },
      ];
    },
    // skuList 中的仓库信息
    warehouseInfoBySku() {
      let warehouseInfo = this.skuInfo?.ware_info_list?.find(
        (item) => item.ware_id == this.currWarehouseId
      );
      return warehouseInfo;
    },
    // 是否接受样品政策
    isSampleAccepted() {
      return this.goodInfo?.risk_rate == '1';
    },
    // 样品政策
    samplePolicy() {
      const policy =
        this.goodInfo?.risk_rate == '1'
          ? this.labelConfig?.['sample-policy-content'](
              this.goodInfo.sample_policy_rate
            )
          : '--';
      return policy;
    },
    // 默认库存单位
    defaultUnit() {
      return this.unitLabelConfig['piece']; // 件
    },
    // 商品的单位
    currUnit() {
      if (!this.goodInfo || !this.goodInfo.sale_id) {
        return this.defaultUnit;
      } else if (this.goodInfo.sale_id == '2') {
        return this.unitLabelConfig['packet']; // 包
      } else if (this.goodInfo.sale_id == '1') {
        return this.goodInfo.measurement_unit;
      } else {
        return this.defaultUnit;
      }
    },
    // 可作为样品购买的数量
    // 取样品数和当前库存数的最小值
    sampleAmount() {
      return Math.min(
        Number(this.warehouseInfoBySku?.sample_available_amount) || 0,
        this.currStockNum || 0
      );
    },
    // 可下的样品单数量
    sampleOrderAmount() {
      return (
        Number(this.warehouseInfoBySku?.sample_order_available_amount) || 0
      );
    },
    // 是否展示“下单类型”字段
    showOrderType() {
      // 1）当前商品“接受样品政策”；2）可作为样品购买的数量大于0；3）可下的样品单的数量大于0
      return (
        this.isSampleAccepted &&
        this.sampleAmount > 0 &&
        this.sampleOrderAmount > 0
      );
    },
    // 当前的下单数量限制
    currOrderNumMaxLimit() {
      return this.currOrderType == 1
        ? Math.min(this.currStockNum, this.sampleAmount)
        : this.currStockNum;
    },

    /**
     * 价格相关
     */

    // 价格
    originalPrice() {
      let info = this.currSkuInfo;
      return info?.underlinedPrice ? parseFloat(info.underlinedPrice) : 0;
    },
    // 价格标签
    priceLabel() {
      let price = this.originalPrice;
      return this.currency + price;
    },
    // 优惠后价格
    favorablePrice() {
      let info = this.currSkuInfo;
      return info?.price ? parseFloat(info.price) : 0;
    },
    // 建议零售价标签
    suggestPriceLabel() {
      let price = this.skuInfo?.suggest_price;
      if (!price || price === '--') {
        return '--';
      }
      return this.currency + price;
    },
    // 是否有折扣
    hasDiscount() {
      return this.favorablePrice != this.originalPrice;
    },
    // 总价
    totalPrice() {
      if (!this.currWarehouseId || !this.goodInputNum) {
        return '';
      }
      let firstItemPrice = this.favorablePrice; // 首件商品价格
      let itemPrice = this.originalPrice; // 非首件商品价格
      if (this.reachStockUp) {
        // 首件商品价格，达到批发标准，则取【批发政策价格】，不补贴
        firstItemPrice = this.stockUpPrice;
        itemPrice = this.stockUpPrice;
      }
      if (!firstItemPrice) {
        firstItemPrice = 0;
      }
      if (!itemPrice) {
        itemPrice = 0;
      }
      // 物流费（含佣金)
      let logPrice = this.logisticsPrice;
      if (!logPrice) {
        logPrice = 0;
      }
      // 保价费
      let insurPrice = this.insurancePrice;
      if (!insurPrice) {
        insurPrice = 0;
      }
      // 操作费（含佣金）
      let opPrice = this.currOperationFee;
      if (!opPrice) {
        opPrice = 0;
      }
      // 到仓物流费
      let wareLogistics = 0;
      if (this.wareLogistics) {
        wareLogistics = Number(this.wareLogistics);
      }
      // 价格 = 商品价格 + 物流费（含佣金） + 操作费（含佣金）+ 到仓物流费 + 保价费
      return (
        parseFloat(firstItemPrice) +
        parseFloat(itemPrice) * Math.max(this.goodInputNum - 1, 0) +
        parseFloat(logPrice) +
        parseFloat(insurPrice) +
        parseFloat(opPrice) +
        parseFloat(wareLogistics) * this.goodInputNum
      ).toFixed(2);
    },
    // 总价标签文本
    totalPriceLabel() {
      if (this.totalPrice !== 0 && !this.totalPrice) {
        return '--';
      }
      return `${this.currency}${this.totalPrice}`;
    },
    // ----------------------
    // 商品补贴相关

    /**
     * 规格
     */

    // 规格的全部数组
    specItems() {
      if (!this.goodInfo?.specifications) return [];
      return JSON.parse(this.goodInfo?.specifications);
    },
    // 规格矩阵
    specMatrix() {
      if (!this.specItems || !this.skuList || this.skuList.length === 0) {
        return;
      }
      // 有库存的规格种类数组
      const stockedArr = this.getStockedSpecItems(this.skuList);
      // 如果无有库存的规格种类，则返回
      if (stockedArr.length === 0) {
        return;
      }

      return new SpecAdjoinMatrix(this.specItems, stockedArr);
    },
  },
  watch: {
    skuInfo: {
      handler: function (newVal, oldVal) {
        this.currSkuInfo = newVal;
        this.getTime();
      },
    },
    // 用户选择的商品数量改变
    goodInputNum() {
      this.updateStockUpPrice();
      this.updateWarehouseTableOpfee();
      this.fetchLogTemplates();
    },
    warehouseId(newVal) {
      if (newVal) {
        this.currWarehouseId = newVal;
      }
    },
    // 数字输入框的最大限制改变
    currOrderNumMaxLimit(newVal) {
      this.goodInputNum = this.normalizeOrderNumRange(this.goodInputNum);
    },
  },
  methods: {
    /**
     * 数据处理
     */

    /**
     * 统一处理空值，空值返回`--`
     * @param { String } val 要处理的值
     * @return { String } 处理后的值
     */
    normalizeEmptyValue(val) {
      return val ? val : '--';
    },

    /**
     * 限制只能输入正整数
     * @param e keyup事件对象
     */
    normalizeInputNumber(e) {
      let val = e.target.value;
      this.goodInputNum = val.replace(/[^\d]/g, '');
    },
    // 限制订单数量的大小
    normalizeOrderNumRange(value) {
      let normalizedValue = value;
      if (value > this.currOrderNumMaxLimit) {
        normalizedValue = this.currOrderNumMaxLimit;
      }
      return Number(normalizedValue) || 1;
    },
    /**
     * 是否展示税率
     * @param rate 税率
     */
    handleTaxRate(rate) {
      let taxRate = Number(rate) || 0;
      return this.lang != 'en' && taxRate > 0;
    },
    // 获取当前用户在 <topheader> 选择的地址数据
    getCurrLocation() {
      let areaData = this.currAreaData;
      let cPropName = 'country' + (this.lang === 'en' ? '_en' : '');
      let pPropName = 'province' + (this.lang === 'en' ? '_en' : '');
      return {
        country_id: areaData?.country_id || '',
        province_id: areaData?.province_id || '',
        country: areaData?.[cPropName] || '',
        province: areaData?.[pPropName] || '',
      };
    },

    /**
     * 更新备货政策
     */
    updateStockUpPolicy() {
      let policy = this.warehouseInfoBySku?.stock_detail || [];
      let arr = [];
      // 获取备货政策的优惠区间
      for (let info of policy) {
        let [min, max] = info.stock_quantity.split('-');
        let obj = {
          price: parseFloat(info.discount_price),
          discount: info.discount,
          min: Number(min) || 0,
          max: Number(max) || 0,
        };
        arr.push(obj);
      }
      // 防止后端返回数据错误，这里排下序
      arr.sort((a, b) => a.min - b.min > 0);
      this.stockUpPolicy = arr;
    },
    /**
     * 检查备货政策，是否能用备货折扣；若能，则
     * 根据当前商品数量，检查能够匹配的最大备货折扣
     */
    updateStockUpPrice() {
      let num = this.goodInputNum;
      let sPrice = 0,
        sDiscount = '',
        isReach = false;
      for (let policy of this.stockUpPolicy) {
        let min = policy?.min || Infinity,
          price = policy?.price || 0;
        if (num >= min) {
          // 只要商品数量大于等于优惠区间的最小值，就代表商品数量满足优惠区间
          // 比如，num=1000, min=100, max=200,
          // 1000 满足 [100, 200] 的优惠区间
          sPrice = price;
          sDiscount = policy?.discount;
          isReach = true;
        }
      }
      // 最后获取到的备货折扣价格，是数量满足优惠区间且备货折扣最大的
      this.reachStockUp = isReach;
      if (this.reachStockUp) {
        this.stockUpPrice = sPrice;
        this.stockUpDiscount = sDiscount;
      }
    },
    /**
     * 获取仓库/物流信息的地址
     * @param info 仓库数据对象/物流信息发货地对象
     * @return { String } 仓库地址字符串，中文逗号分隔
     */
    getWarehouseAddr(info) {
      let { country, province, city } = info;
      let addr = [country, province, city].filter(Boolean);
      if (addr.length > 2) {
        addr.splice(1, 1);
      }
      return addr.join('，');
    },
    /**
     * 重置收货地址
     */
    resetEndAddr() {
      if (this.currWarehouseId) {
        // 如果当前有选中仓库，则重置为默认地址
        this.endAreaObj.country = this.defaultEndAreaObj?.country || '';
        this.endAreaObj.country_id = this.defaultEndAreaObj?.country_id || '';
        this.endAreaObj.province = this.defaultEndAreaObj?.province || '';
        this.endAreaObj.province_id = this.defaultEndAreaObj?.province_id || '';
      } else {
        // 如果当前无选中仓库，则重置为空
        this.endAreaObj.country = '';
        this.endAreaObj.country_id = '';
        this.endAreaObj.province = '';
        this.endAreaObj.province_id = '';
      }
      // 重新获取物流信息表格数据
      this.fetchLogTemplates();
    },
    /**
     * 更新仓库列表数据的操作费字段
     */
    updateWarehouseTableOpfee() {
      const list = this.warehouseFullList;
      let id = this.currWarehouseId;
      for (let i = 0; i < list.length; i++) {
        const x = list[i];
        let opRule = x.operation_fee_rule;
        if (!opRule) {
          this.$set(list[i], 'operation_fee_price', 0);
          continue;
        }
        let type = opRule.type,
          rule = opRule.rule,
          commission = opRule.commission;
        let num = this.goodInputNum;
        let price = getOperationFee(type, rule, num, commission);
        this.$set(list[i], 'operation_fee_price', price);
        // 更新当前的操作费
        if (id === x.ware_id) {
          this.currOperationFee = price;
        }
      }
    },

    /**
     * 根据skuId，更新页面相关数据
     * （skuid，规格，轮播图，仓库表格【物流表格】）
     * @param skuId，新的skuId
     */
    updateDataBySku(skuId, noUpdateCarousel) {
      if (!skuId) {
        console.log('无SKU');
      }
      this.currSkuId = skuId;

      // 更新规格选项显示的置灰与否
      this.updateStockedSpecOptions();

      // skuId 更新
      this.currSkuInfo = this.skuList.find((x) => x.sku_id == skuId);
      // 更新sku对应的轮播主图
      if (!noUpdateCarousel) {
        this.$emit('update-carousel', this.currSkuInfo?.sku_image);
      }
      this.$nextTick(() => {
        // 如果调用了clear函数，将会自动 fetch 仓库列表
        // 但，包含clear函数的仓库地址选择器组件可能还未挂载，此时就需要手动fetch仓库
        if (this.$refs?.shipStartAddress) {
          this.$refs?.shipStartAddress?.clearAddressFunc();
        } else {
          this.fetchWarehouses();
        }
      });

      // 触发父组件更新skuId
      this.$emit('update-sku', skuId);
    },

    /**
     * 根据更新的仓库，更新其他页面数据
     * （id，库存，发货地，收货地，操作费，备货政策，物流表格）
     * @param id 仓库id
     */
    updateDataByWarehouse(id) {
      if (!id) {
        // id为空，清空仓库列表
        this.warehouseFullList = [];
      }

      this.currWarehouseId = id;
      const currObj = this.warehouseFullList.find((x) => x.ware_id == id);
      // -------------------------------------------
      // 供应商发布公告相关需求,获取选中仓库的公告信息
      this.selectedWarehouseNotice = [];
      if (currObj?.announcement_content?.length > 0) {
        this.selectedWarehouseNotice =
          JSON.parse(JSON.stringify(currObj?.announcement_content || [])) || [];
      }

      this.wareLogistics = currObj?.ware_logistics;
      // -------------------------------------------
      // 更新库存
      this.currStockNum = Number(currObj?.store) || 0;
      // 根据仓库更换发货地
      this.startAreaObj = {
        country: currObj?.country || '',
        province: currObj?.province || '',
        city: currObj?.city || '',
        country_id: currObj?.country_id || '',
        province_id: currObj?.province_id || '',
        city_id: currObj?.city_id || '',
      };
      // 更新操作费
      this.currOperationFee = currObj?.operation_fee_price
        ? parseFloat(currObj.operation_fee_price)
        : 0;

      // 更新备货政策
      this.updateStockUpPolicy();
      // 更新备货折扣
      this.updateStockUpPrice();
      if (!id) {
        // id 为空，清空物流信息收货地址
        this.resetEndAddr();
      }
      // #memo 仓库id -> 物流地址选择器数据更新逻辑
      // 1. 如果仓库id改变，那么物流信息的地址选择器应该重新初始化地址数据
      // 因此不需要特地再次重置物流信息
      // 2. 如果仓库id不改变，那么物流信息地址数据不需要重置
      // （但运费模版列表更不更新另说，运费模版列表与仓库id变化无关，与sku变化有关）
      // 3. 如果需要请求新的运费模版，那不是因为收货地址改变，而是因为 sku_id 改变

      // 更新父组件的仓库 id 和 仓库类型
      this.$emit('update-warehouse', id, currObj?.ware_type);
    },

    /**
     * 根据物流模版id更新页面相关数据
     * （物流模版id，物流费）
     * @param { String } 物流模版id cha_id
     */
    updateDataByLog(id) {
      const item = this.logTemplateList.find((x) => x.cha_id == id);

      this.logisticsId = item?.cha_id || '';
      this.logisticsPrice = item?.price || '';
      this.insurancePrice = item?.insurance_charge || '';

      if (!this.logisticsId) {
        this.logTemplateList = [];
      }
    },

    /**
     * 获取有库存的规格种类（哪些规格值有对应的有库存sku）
     * @param skuList 当前商品的sku列表
     * @return stockedSpecList 有库存的规格列表，用于初始化规格矩阵
     */
    getStockedSpecItems(skuList) {
      const stockedSpecList = [];
      for (let i = 0; i < skuList.length; i++) {
        let specInfo = JSON.parse(skuList[i]?.spec_info || '[]');
        const stockedSpecItem = specInfo?.map((x) => x?.value)?.filter(Boolean);
        if (stockedSpecItem.length > 0) {
          stockedSpecList.push({ id: i, specs: stockedSpecItem });
        }
      }
      return stockedSpecList;
    },

    /**
     * 根据当前选中的规格选项，更新不置灰的规格选项（stockedSpecItems）
     */
    updateStockedSpecOptions() {
      if (!this.selectedSpecItems) {
        return;
      }
      if (this.specMatrix) {
        // 筛选出当前选中的规格值
        const currSelectedSpecValues = [...this.selectedSpecItems].map(
          (x) => x.split('@')[1]
        );
        const stockedArr = this.specMatrix.getSpecscOptions(
          currSelectedSpecValues
        );
        // 更新不置灰选项
        this.stockedSpecItems = new Set(stockedArr);
      }
    },

    /**
     * 根据当前选中的规格选项，更新当前的skuid
     * 检查用户是否已经选完所有的规格值（每种规格种类是否都选中某个规格值）
     */
    updateSelectedSkuId() {
      let skuId;
      // 用户已选完所有规格 => 有了被选中的 sku
      if (this.selectedSpecItems.size == this.specItems.length) {
        const specMap = this.selectedSpecMap;
        let targetHash = '';
        for (let i = 0; i < this.specItems.length; i++) {
          const key = this.specItems[i].name;
          targetHash += `${key}@${specMap.get(key)}`;
          if (i < this.specItems.length - 1) {
            targetHash += '|';
          }
        }
        // 查找用户选择的规格对应的 skuid
        const skuList = this.skuList;
        const skuIndex = skuList.findIndex((x) => x.spec_desc == targetHash);
        if (skuIndex >= 0) {
          skuId = skuList[skuIndex].sku_id;
        }
      }
      this.updateDataBySku(skuId);
    },

    /**
     * 进入页面时默认选中一个规格
     * @description 默认选中价格最低的 sku 的规格
     */
    selectDefaultSpecOption(noUpdateCarousel) {
      const initialSpecItem = this.currSkuInfo;
      if (!this.currSkuInfo) {
        return;
      }
      const skuId = initialSpecItem.sku_id;
      let specInfo = JSON.parse(initialSpecItem.spec_info || '[]');
      const specItemArray = specInfo?.map((x) => `${x?.name}@${x?.value}`);
      specItemArray.forEach((x) => this.selectedSpecItems.add(x));

      let skuValid = true;
      // skuValid true 表示当前 sku 的规格值合格
      // 什么叫“合格”？
      // 即，当前 sku 的规格值 spec_desc 与 itemInfo.specifications 是匹配的
      // 比如，当前规格 specifications 为 { name: '内存', value: ['1G'] }
      // 当前第一个 sku 规格 spec_desc 为 '1@1G'，它的规格名是 1，与'内存'不匹配
      // 可能是发布商品或补货配置时规格填写错误
      // 此时不应去请求仓库信息，也无需更新规格高亮
      for (const item of this.selectedSpecItems) {
        const [label, value] = item.split('@');
        const labelIndex = this.specItems.findIndex((x) => x.name == label);
        if (labelIndex < 0) {
          skuValid = false;
          break;
        }
        const valueIndex = this.specItems[labelIndex].value.findIndex(
          (x) => x == value
        );
        if (valueIndex < 0) {
          skuValid = false;
          break;
        }
        this.selectedSpecMap.set(label, value);
      }
      // 根据sku变化更新相关数据
      this.updateDataBySku(skuValid ? skuId : '', !!noUpdateCarousel);
    },
    // 设置默认收货地址
    setDefaultPOR(addr) {
      // 设置当前网站顶部显示的地址
      this.currAreaObj = Object.assign({}, this.getCurrLocation());
      // 设置当前的默认收货地址（分销商默认收货地址）
      this.defaultEndAreaObj.country = addr?.countries_name || '';
      this.defaultEndAreaObj.country_id = addr?.country_id || '';
      this.defaultEndAreaObj.province = addr?.province_name || '';
      this.defaultEndAreaObj.province_id = addr?.province_id || '';
      // 最终初始化收货地址由 province-select 组件决定
      // 设置收货地址为默认收货地址
      this.resetEndAddr();
    },

    /**
     * 页面事件触发
     */
    // 倒计时相关
    // 倒计时相关
    getTime() {
      if (process.client) {
        if (this.curCountdownTime) {
          clearInterval(this.curCountdownTime);
          this.curCountdownTime = null;
          this.curDataTime = '';
        }
        // 有补贴的时候才有倒计时
        // if (this.hasSubsidy) {
        this.curCountdownTime = setInterval(() => {
          this.curDataTime = new Date();
        }, 1000);
        // }
      }
    },
    // 倒计时相关
    countdownToMonthEnd(date) {
      // 如果没有提供日期，则使用当前日期
      date = date || new Date();

      // 当前月份的最后一天
      var monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      monthEnd.setHours(23, 59, 59);
      // 计算剩余时间（单位：秒）
      var secondsLeft = (monthEnd - date) / 1000;

      const days = Math.floor(secondsLeft / 86400); //天
      const hours = Math.floor((secondsLeft % 86400) / 3600); //时
      const minutes = Math.floor((secondsLeft % 3600) / 60); //分
      const seconds = Math.floor(secondsLeft % 60); //秒

      // 返回 对应倒计时
      let timeStr =
        days.toString().padStart(2, '0') +
        '天' +
        hours.toString().padStart(2, '0') +
        '时' +
        minutes.toString().padStart(2, '0') +
        '分' +
        seconds.toString().padStart(2, '0') +
        '秒';
      return timeStr;
    },

    // 点击“复制下单信息”
    handleClickCopyOrderInfo() {
      let logInfo = this.logTemplateList?.find(
        (x) => x.cha_id == this.logisticsId
      );
      let logText = logInfo
        ? `${logInfo.fre_id}-${logInfo.cha_id}-${logInfo.pro_id}`
        : '';
      let text = [
        this.currSkuId,
        this.goodInputNum,
        this.currWarehouseId,
        logText,
      ].join('\t');
      this.copyText(text);
    },
    // 点击复制仓库信息
    handleClickCopyWarehouseCol(row) {
      this.copyText(row['ware_id']);
    },

    // 点击优惠券字段的“查看更多”
    // 跳转优惠券管理
    handleClickMoreCoupons() {
      let routeData = this.$router.resolve({
        path: '/fund/coupon',
      });
      window.open(routeData.href, '_blank');
    },

    /**
     * 视口移动到指定的标签页
     * @param tabKey 指定标签页的 id
     */
    handleFocusInfoTabs(tabKey) {
      this.$emit('focus-tab', tabKey);
    },

    /**
     * 点击某个规格选项的事件
     * @param label 规格种类
     * @param value 规格值
     */
    handleClickSpecOptions(label, value) {
      if (!this.stockedSpecItems.has(value)) {
        // 用户点击的规格选项没有库存，直接return
        return;
      }
      const specItem = `${label}@${value}`;
      if (this.selectedSpecItems.has(specItem)) {
        // 用户点击已被选中的某个规格值
        this.selectedSpecItems.delete(specItem);
        this.selectedSpecMap.delete(label);
      } else if (this.selectedSpecMap.has(label)) {
        // 用户点击已选中某一值的规格的另一个值
        const preValue = this.selectedSpecMap.get(label);
        const preSpecItem = `${label}@${preValue}`;
        this.selectedSpecItems.delete(preSpecItem);

        this.selectedSpecItems.add(specItem);
        this.selectedSpecMap.set(label, value);
      } else {
        // 用户点击未被选中的规格值且当前规格无值被选中
        this.selectedSpecItems.add(specItem);
        this.selectedSpecMap.set(label, value);
      }

      // 尝试更新当前选中的 skuid
      this.updateSelectedSkuId();
      this.updateStockedSpecOptions();
    },

    /**
     * 仓库表格触发事件
     */
    /**
     * 选中仓库表格中某一行
     * @param obj 被选中的仓库信息对象
     */
    handleSelectWarehouse(id) {
      this.updateDataByWarehouse(id);
    },

    /**
     * 仓库表格的选择器地址变化
     * @param areaData 更新后的仓库地址数据
     */
    handleSelectWarehouseAddr(areaData) {
      this.warehouseAreaObj = {
        country_id: areaData?.country_id || '',
        province_id: areaData?.province_id || '',
      };
      // 获取仓库表格数据
      this.fetchWarehouses();
    },

    /**
     * 物流表格触发事件
     */

    /**
     * 物流表格的收货地地址数据变化
     * @param areaData 更新后的收货地地址数据
     */
    handleSelectEndAddr(areaData) {
      this.endAreaObj = {
        country_id: areaData?.country_id || '',
        province_id: areaData?.province_id || '',
        country: areaData?.country || '',
        province: areaData?.province || '',
      };
      this.fetchLogTemplates();
    },

    /**
     * 选择物流信息表格的某一行
     * @param id 表格行物流id cha_id
     */
    handleSelectLogTemplate(id) {
      this.updateDataByLog(id);
    },
    /**
     * 点击立即购买按钮，跳转下单支付页
     */
    handleClickPurchase() {
      if (!this.token) {
        this.$router.push('/');
      }
      if (!this.currSkuId) {
        this.$message.warning('请选择规格');
        return;
      }
      // 当出现这个弹窗时，大概率是后端传回数据错误
      // 因为有sku被选中，说明sku必然有库存，则应有仓库数据
      // 仓库列表不能为空
      if (this.warehouseFullList.length === 0) {
        // this.$message.warning('No data: 无仓库数据');
        return;
      }
      if (!this.currWarehouseId) {
        this.$message.warning('请选择仓库');
        return;
      }

      const options = {
        num: this.goodInputNum, // 商品数量
        item_id: this.itemId, // 商品ID
        sku_id: this.currSkuId, // sku ID
        log_id: this.logisticsId, // 物流模板ID
        war_id: this.currWarehouseId, // 仓库ID
        sco_id: this.startAreaObj.country_id, // 发货地国家ID
        sp_id: this.startAreaObj.province_id, // 发货地省份ID
        sc_id: this.startAreaObj.city_id, // 发货地城市ID
        order_type: this.currOrderType, // 下单类型
        /**
         * 下单页的物流收货地只会有两种来源：
         * 1）用户的默认地址；2）用户收货地址列表中的某一个地址
         * 也就是说，下单页的物流收货地与商品详情的物流信息收货地无直接关系
         * 可以不用传收货地数据
         */
        // eco_id: this.endAreaObj.country_id, // 收货地国家ID
        // ep_id: this.endAreaObj.province_id, // 收货地省份ID
        // eco: this.endAreaObj.country, // 收货地国家名称
        // ep: this.endAreaObj.province, // 收货地省份名称
      };

      // const routeData = this.$router.resolve({
      //   path: '/pay',
      //   query: {
      //     ...options,
      //   },
      // });
      this.$router.push({
        path: '/pay',
        query: {
          ...options,
        },
      });
      // window.open(routeData.href);
    },

    /**
     * 有补贴的情况下，跳转到补贴活动页面
     */
    goGoodsSubsidy() {
      window.open('/activity/goods');
    },

    /**
     * 请求后端接口
     */

    // 获取分销商默认地址
    async fetchDefaultPOR() {
      try {
        // #todo 后续优化：传一个参数直接拿到默认地址
        const params = {
          pages: 1,
          pageSize: 100000,
          API_LANG_TYPE: this.lang || this.currLang,
          MUTE_WARNING: 1,
        };
        const res = await getSupAddrList(params);
        if (res.errcode == 0) {
          // 首先取用户默认收货地址
          let defaultAddr = res.data?.data?.find((x) => x.status == '1');
          this.setDefaultPOR(defaultAddr);
        }
      } catch (err) {
        console.error(err);
      }
    },

    /**
     * 获取优惠券列表
     * @param itemId 商品id
     */
    async fetchCoupons(itemId) {
      let lang = this.lang || this.currLang;
      // 国内分销才展示优惠券
      if (lang !== 'zh-cn') return;
      try {
        const options = {
          item_id: itemId,
          API_LANG_TYPE: lang,
          MUTE_WARNING: 1,
        };
        let res = await getCoupons(options);
        if (res?.errcode == 0) {
          // this.coupons = res.data?.list;
          this.waitCollectCoupon = res?.data?.unGetCoupon || []; // 待领取优惠券
          this.usableCoupon = res?.data?.unUseCoupon || []; // 可用优惠券
        }
      } catch (err) {
        console.error(err);
      }
    },

    // 点击领券按钮,打开右边优惠券抽屉
    couponClick() {
      this.$refs.couponDrawer.openDrawer(); // 打开抽屉
    },
    /**
     * 成功领券,处理数据
     * @param {*} id 成功领取优惠券的id
     */
    handleSuccessCoupon(id) {
      // 在待领取优惠券中查找对应优惠券id的对象
      const item = this.waitCollectCoupon?.find((obj) => obj.coupon_id == id);

      if (item) {
        item.rest_num = (item.rest_num - 1)?.toString(); // 剩余总数
        item.num = (item.num + 1)?.toString(); // 个人领取数量
        // 无限制领取的优惠券不用减掉
        if (item.get_limit_num != '-1') {
          item.get_limit_num = (item.get_limit_num - 1)?.toString(); // 个人剩余最多还可以领取的数量
        }
      }
    },
    // 更新优惠券列表
    handleRefreshCoupon() {
      this.fetchCoupons(this.itemId);
    },

    /**
     * 获取变更通知数据
     * @param itemId 商品id
     */
    async fetchChangeNotices(itemId) {
      try {
        const options = {
          item_id: itemId,
          API_LANG_TYPE: this.lang,
          MUTE_WARNING: 1,
        };
        let res = await this.$getChangeLog(options);
        if (res?.errcode == 0) {
          this.changeNotices = res.data.list;
        }
      } catch (err) {
        console.error(err);
      }
    },
    /**
     * 获取仓库信息列表数据
     */
    async fetchWarehouses(newParams) {
      try {
        if (!this.currSkuId) {
          // 无skuId，清空仓库数据
          this.updateDataByWarehouse();
          return;
        }
        // 获取仓库详情
        let params = {
          item_id: this.itemId,
          sku_id: this.currSkuId,
          API_LANG_TYPE: this.lang,
        };

        if (this.warehouseAreaObj?.country_id) {
          params.country_id = this.warehouseAreaObj.country_id;
        }
        if (this.warehouseAreaObj?.province_id) {
          params.province_id = this.warehouseAreaObj.province_id;
        }
        params = Object.assign(params, newParams);
        const res = await this.$getItemWarehouses(params);

        if (res.errcode == 0) {
          // 过滤库存为0 的数组，为0不显示
          this.warehouseFullList = res.data?.filter(
            (x) => x.store && parseInt(x.store, 10) > 0
          );
          // 更新仓库表格的操作费字段
          this.updateWarehouseTableOpfee();

          if (this.warehouseFullList.length > 0) {
            let index = this.warehouseFullList.findIndex(
              (x) => x.ware_id == this.currWarehouseId
            );
            index = Math.max(0, index); // 无则默认选中第一项
            const id = this.warehouseFullList[index].ware_id; // 当前仓库ID
            // 仓库信息表格选中id
            this.$refs.warehouseFoldTable?.setSelectedRowId(id);
            // 更新会随着仓库变化的页面数据
            this.updateDataByWarehouse(id);

            this.updateStockedSpecOptions(); // 更新规格数组 #fixme 需要更新这个吗
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    /**
     * 获取物流信息列表数据
     */
    async fetchLogTemplates() {
      try {
        const noEndAreaData =
          !this.endAreaObj.country_id && !this.endAreaObj.province_id;
        // 如果当前未选目的地，则不查询运费模版
        if (noEndAreaData) {
          // 清空运费模版列表
          this.updateDataByLog();
          return;
        }
        if (!this.currSkuInfo) {
          return;
        }
        const params = {
          start: this.startAreaObj.country_id,
          start_state: this.startAreaObj.province_id,
          start_city: this.startAreaObj.city_id,
          end: this.endAreaObj.country_id,
          end_state: this.endAreaObj.province_id,
          supplier_id: this.goodInfo?.supplier_id,
          sku_id: this.currSkuInfo.sku_id,
          sku_num: this.goodInputNum,
          API_LANG_TYPE: this.lang,
          MUTE_WARNING: 1,
        };
        // 如果当前选中一个仓库，则传 war_id
        if (this.currWarehouseId) {
          params.war_id = this.currWarehouseId;
        }
        const res = await this.$getItemFreight(params);
        if (res.errcode == 0) {
          const tempArr = res.data;
          this.logTemplateList = tempArr?.length > 0 ? tempArr : [];
          let arr = this.logTemplateList.map((item) => {
            return item.full_name + ':' + item.fre_id;
          });
          console.log(arr, '当前商品，物流模板数据');
          if (tempArr?.length > 0) {
            // 保价费从物流费中拆出来后，后端做了按照 物流费+保价费，从低到高的排序
            // 第一个物流模板就是最便宜的模板，默认选中第一个就行。
            const id = tempArr[0].cha_id;
            this.$refs.logFoldTable?.setSelectedRowId(id);
            this.updateDataByLog(id);
          } else {
            // 清空运费模版列表
            this.updateDataByLog();
            console.log('后端传回运费模版为空');
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  mounted() {
    if (process.client) {
      this.token = this.$cookies.get(CUR_TOKEN_NAME);
      if (this.token) {
        this.fetchDefaultPOR(); // 获取分销商默认地址
      } else {
        this.setDefaultPOR();
      }
      this.fetchCoupons(this.itemId);
      this.getTime();
    }
  },
  destroyed() {
    if (this.curCountdownTime) {
      clearInterval(this.curCountdownTime);
      this.curCountdownTime = null;
    }
  },
};
