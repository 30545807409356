
export default {
  name: 'IconButton',
  props: {
    showHover: {
      type: Boolean,
      default: false,
    },
    // 按钮前缀的样式
    prefixStyle: {
      type: Object,
      default: () => {},
    },
    // 按钮的样式
    btnStyle: {
      type: Object,
      default: () => {},
    },
    // 按钮文本标签的样式
    labelStyle: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
