import createPersistedState from 'vuex-persistedstate';

export default ({ store }) => {
  createPersistedState({
    key: 'my-app-state', // 设置一个自定义的键名
    storage: window.localStorage,
    paths: [
      'category',
      'common',
      'good',
      'loading',
      'login',
      'old',
      'pay',
      'home',
    ],
  })(store);
  createPersistedState({
    key: 'my-app-state-session', // 设置一个自定义的键名
    storage: window.sessionStorage,
    paths: ['chat', 'page_filter_criteria'],
  })(store);
};
