
import { delReply } from '@/api/client-api/trade/orderlist.js';
import EvaluateDetailDialog from './EvaluateDetailDialog.vue';
import Report from '@/pages/back-stage/complaints-module/complaints-report/components/Report.vue'; //举报
import { COMPLAINT_SCENARIO } from '@/pages/back-stage/complaints-module/complaints-report/js/complaint_type.js'; //发起场景
export default {
  name: 'myPublishTable',
  components: {
    Report,
    EvaluateDetailDialog,
  },
  data() {
    return {
      COMPLAINT_SCENARIO,
      showEvaluateDetail: false,
      currentTid: '',
      colors: ['#FF7802', '#FF7802', '#FF7802'],

      // 评价举报需要的
      reportObj: {},
      showeReportDialog: false, // 举报
      delEvaVisible: false, // 删除二次确认框
    };
  },

  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  filters: {
    // 判断是否有值
    emptyValFilter(val) {
      return !val ? '--' : val;
    },
  },
  methods: {
    // 点击跳转 商品详情
    handleClickToGoodsDetail(item) {
      let currency =
        item?.currency == '￥' || item?.currency == '¥' ? '2' : '3';
      this.toCachedShopdetail(
        item.item_id,
        item.image_default_id,
        item.sku_lowest_price_img,
        '_blank',
        currency
      );
    },
    // 点击跳转订单详情
    handleClickToOrderDetail(tid) {
      let pathHerf = this.$router.resolve({
        path: '/trade/orderdetails',
        query: {
          item_id: tid,
        },
      });
      window.open(pathHerf.href, '_blank');
    },
    handleClickDetail(tid) {
      this.currentTid = tid;
      this.showEvaluateDetail = true;
    },
    // 关闭查看详情弹窗
    closeDetailDialog() {
      this.showEvaluateDetail = false;
    },
    // 打开评价举报
    handleReport(item) {
      this.reportObj = item;
      this.showeReportDialog = true;
    },
    // 关闭评价举报
    handleCloseeReportForm() {
      this.reportObj = {};
      this.showeReportDialog = false;
    },
    // 评价举报确认按钮
    handleConfimeReport() {
      this.reportObj = {};
      this.showeReportDialog = false;
    },
    // 关闭确认是否删除评论弹窗
    closeDelEvaDialog() {
      this.delEvaVisible = false;
    },
    // 点击删除评论
    handleClickDelEvaluate(tid) {
      this.currentTid = tid;
      this.delEvaVisible = true;
    },
    // 确定删除评论
    configDelEvaluate() {
      let options = {
        tid: this.currentTid,
      };
      delReply(options)
        .then((res) => {
          if (res.errcode == 0) {
            this.delEvaVisible = false;
            this.$message.success('删除成功');
            this.$emit('update');
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
