
// api
import { weiPayStatus } from '@/api/client-api/goods/pay.js';
export default {
  name: 'WeChatPayDialog',
  props: {
    weChatUrl: {
      type: String,
      default: '',
    },
    outTradeNo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      codeUrl: '', // 二维码图片
      weChatTimer: null, // 微信支付倒计时
      stateTimer: null, // 获取微信支付状态
      payTime: 1800, // 30分钟
      version: 6, // 二维码生成的版本，数字越大，二维码越复杂
    };
  },
  mounted() {
    this.countdownStart();
  },
  beforeDestroy() {
    // 离开页面清除计时器
    if (this.stateTimer) {
      clearInterval(this.stateTimer);
    }
    if (this.weChatTimer) {
      clearInterval(this.weChatTimer);
    }
  },
  watch: {
    weChatUrl(val) {
      this.countdownStart();
    },
  },
  methods: {
    // 倒计时开始
    countdownStart() {
      this.payTime = 1800; // 倒计时30分钟
      this.handleClickCreateQRcode();
      // 获取支付状态
      if (this.stateTimer) {
        clearInterval(this.stateTimer);
      }
      this.stateTimer = setInterval(this.weiPayStatus, 1000);
      // 支付倒计时
      if (this.weChatTimer) {
        clearInterval(this.weChatTimer);
      }
      this.weChatTimer = setInterval(this.payTimeNum, 1000);
    },
    // 30分钟倒计时
    payTimeNum() {
      if (this.payTime > 0) this.payTime--;
      if (this.payTime == 0 && this.weChatTimer) {
        clearInterval(this.weChatTimer);
      }
    },
    // 获取微信支付状态
    async weiPayStatus() {
      try {
        let option = {
          out_trade_no: this.outTradeNo,
          MUTE_WARNING: 1,
        };
        const res = await weiPayStatus(option);
        if (res?.errcode == 0 && res?.data?.status == 'SUCCESS') {
          if (this.stateTimer) {
            clearInterval(this.stateTimer);
          }

          this.$message.success('支付成功');
          this.$emit('pay-success');
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 点击生成二维码
    async handleClickCreateQRcode() {
      try {
        const options = {
          text: this.weChatUrl,
          version: this.version,
          margin: 0,
          MUTE_WARNING: 1,
        };
        let that = this; // 必须要，否则 this 指向会错误
        function callbackFn(err, url) {
          if (err) {
            console.error(err);
            return;
          }
          that.codeUrl = url;
        }
        await this.createQRcode(options, callbackFn);
      } catch (err) {
        console.error(err);
      }
    },
    // 计算微信二维码失效倒计时
    getHour(time) {
      let mine = time % 60; // 分钟
      let hour = parseInt(time / 60);
      return [hour, mine];
    },
    // 关闭微信支付弹窗
    closeDialog() {
      if (this.stateTimer) {
        clearInterval(this.stateTimer);
      }
      if (this.weChatTimer) {
        clearInterval(this.weChatTimer);
      }
      this.$emit('close-dialog');
    },
    // 点击重新获取二维码
    weChatPay() {
      this.$emit('get-again');
    },
  },
};
