
import { EVALUATION_BLOCK_LABELS } from '../js/label_config_form_main';
import { formatEvaluationTime } from '@/utils/time';

import StarRateItem from './StarRateItem';
import EvaluationCarousel from './EvaluationCarousel';
// 变量
import { curDefaultLanguage } from '@/utils/cur_default_config.js'; //默认语言

export default {
  name: 'EvaluationItem',
  components: {
    StarRateItem,
    EvaluationCarousel,
  },
  props: {
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
    // 评价类型：客户评价(user) or 供应商回复(supplier)
    type: {
      type: String,
      default: 'user',
    },
    // 评论人的昵称
    username: {
      type: String,
      default: '',
    },
    // 评论人头像
    avatar: {
      type: String,
      default: '',
    },
    // 评论打分
    rate: {
      type: Number,
      default: 5,
    },
    // 评论内容
    content: {
      type: String,
      default: '',
    },
    // 评论带图，逗号分隔的图片名字符串
    imgs: {
      type: String,
      default: '',
    },
    // 评论带视频
    video: {
      type: String,
      default: '',
    },
    // 商品标题
    title: {
      type: String,
      default: '',
    },
    // 评论时间
    createTime: {
      type: String,
      default: '',
    },
    // 评论的id，用于视频的唯一id标识
    id: {
      type: [String, Number],
      default: '',
    },
    // 评论的分销商的id
    userId: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    // 是否为供应商回复
    isSupplier() {
      return this.type === 'supplier';
    },
    // 标签多语言配置
    labelConfig() {
      return EVALUATION_BLOCK_LABELS[this.lang || curDefaultLanguage];
    },
    // 评论图片列表
    evaluationImgList() {
      let str = this.imgs;
      return !str ? [] : str.split(',');
    },
    // 评论视频列表
    evaluationVideoList() {
      return [this.video].filter(Boolean);
    },
    // 是否可以举报（不能举报自己）
    reportAllowed() {
      let userdetail = JSON.parse(
        this.$store.state.login.loginInfo?.userdetail || '{}'
      );
      return this.userId == userdetail.user_id;
    },
  },
  methods: {
    // 是否是有效评分
    isValidRate(rate) {
      return rate >= 0 && rate <= 5;
    },
    formatTime(time) {
      if (isNaN(Number(time))) {
        // 非数字型，直接返回
        return time;
      }
      return formatEvaluationTime(time);
    },
    handleReport() {
      this.$emit('handle-report');
    },
  },
};
