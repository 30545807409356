
import searchaddress from '@/components/searchaddress.vue';
import EditOrderAddress from './editOrderAddress.vue';
import OrderImportDialog from './OrderImportDialog.vue';
import OrderProgressDialog from './OrderProgressDialog.vue';
import ExtendReceipt from './ExtendReceipt.vue';
import {
  getUserOrderList,
  addOrderMemo,
  closeOrder,
  confirmReceipt,
  getOrderTrajectory,
  getOrderPanelData,
  checkOrderPay,
  exportOrderList,
  getOrderSourceList,
} from '@/api/client-api/trade/orderlist.js';
import { getContinents, getItemFreight } from '@/api/client-api/api.js';
import { getFundInfo } from '@/api/client-api/fund/fundCenter.js';
import { mapMutations } from 'vuex';
export default {
  name: 'order-list',
  components: {
    searchaddress,
    EditOrderAddress,
    OrderImportDialog,
    OrderProgressDialog,
    ExtendReceipt,
  },
  data() {
    return {
      activeName: '1',
      statusOptions: [
        // 搜索状态
        { label: '全部', value: '' },
        { label: '待付款', value: 'WAIT_BUYER_PAY' },
        { label: '支付处理中', value: 'PAYMENT_PROCESSING' },
        { label: '待发货', value: 'WAIT_SELLER_SEND_GOODS' },
        { label: '待收货', value: 'WAIT_BUYER_CONFIRM_GOODS' },
        { label: '待评价', value: 'WAIT_COMMENT' },
        { label: '取消订单', value: 'TRANSACTION_CANCEL' },
        { label: '纠纷中', value: 'REFUND_IN_PROGRESS' },
        { label: '已退款', value: 'REFUNDED' },
        { label: '交易成功', value: 'SUCCESSFUL_TRADE' },
        // { label: '交易关闭', value: 'TRANSACTION_CLOSURE' },
        // { label: '交易失败', value: 'TRANSACTION_FAILED' },

        { label: '支付失败', value: 'PAYMENT_FAILED' },
        { label: '超时未支付', value: 'OVERTIME_UNPAID' },
        { label: '超时未发货', value: 'FAILURE_TO_SHIP_DUE_TO_TIMEOUT' },
        // {
        //   label:'已申请退款',
        //   value:'REFUND_APPLIED',
        // },
        // {
        //   label:'退货待发货',
        //   value:'RETURN_TO_BE_SHIPPED',
        // },
        // {
        //   label:'退货待收货',
        //   value:'RETURNED_GOODS_TO_BE_RECEIVED',
        // },
        // {
        //   label: '退货/退款中',
        //   value: 'REFUND_IN_PROGRESS',
        // },
        // {
        //   label:'投诉中',
        //   value:'COMPLAINT_IN_PROGRESS',
        // },
        // {
        //   label:'超时',
        //   value:'OVER_TIME',
        // },
      ],
      seach: {
        status: '', // 状态
        orderTime: [],
        seachType: '',
        trade_type: '',
        memo_type: '',
        warehouses_type: '',
        currency: '',
        order_source: '',
      },
      seachInput: '',
      orderOptions: [
        { label: '全部', value: '' },
        { label: '样品单', value: '1' },
        { label: '普通单', value: '2' },
      ],
      selectOptions: [
        { label: '全部', value: '' },
        { label: '有备注', value: '1' },
        { label: '无备注', value: '2' },
      ],
      warehouseOptions: [
        { label: '全部', value: '' },
        { label: '供应商仓', value: '1' },
        { label: '平台仓', value: '2' },
      ],
      currencyOptions: [
        { label: '全部', value: '' },
        { label: '人民币', value: '1' },
        { label: '美元', value: '2' },
      ],
      orderSourceOptions: [], // 订单来源选项
      // 订单类型映射表
      codeToTradeTypeMap: {
        1: '样品单',
        2: '普通单',
      },
      // activeName 与 tag_type 的映射表
      activeNameToTagTypeMap: {
        1: '', // 全部订单
        2: '8', // 待支付
        3: '4', // 支付处理中
        4: '3', // 待发货
        5: '5', // 待收货
        6: '7', // 待评价
        7: '6', // 退货/纠纷
      },
      seachOptions: [
        { label: '订单号', value: 'tid' },
        // {label:"供应商ID",value:"supplier_id"},
        { label: '仓库ID', value: 'war_id' },
        { label: '商品标题', value: 'title' },
        { label: '商品ID', value: 'item_id' },
        { label: '导入ID', value: 'import_id' },
        { label: '物流单号', value: 'logi_no' },
      ],
      isOrderSleectAll: false, //全选
      isIndeterminate: false,
      orderList: [],
      info: {}, //看板
      totalnum: 0, // 数据总条数
      selectOrderCheckbox: [], // 选中的数据
      logisticshow: false, // 更换物流弹框
      currentorderobj: {}, // 当前信息
      Continents: [], // 大州信息
      currentChau: '', // 当前大洲
      provinceCityArea: [], //根据洲获取国家
      tempAddressLabel: [],
      tempAddressValue: [],
      logisticstemplate: [], // 物流信息
      tabletype: true,
      tablenumber: 2, // 物流信息展示数量
      // currentlogistics:{} , // 当前物流信息
      remarkshow: false, // 编辑备注
      currentitemid: '',
      remarkcontent: '',
      closeordershow: true, // 关闭
      configshow: false,
      closeid: '',
      logshow: false, // 物流详情
      logdetaillist: [], // 物流信息
      logisticName: '', //物流公司
      logi_no: '', //物流单号
      timer: null, // 定时器
      // pay_deadline:0, // 付款截至时间
      // receipt_time:0, // 收货截至时间
      // deliver_deadline:0 , // 签收倒计时
      title: '',
      pageNum: 1,
      pageSize: 10,
      form: {
        changeText: '',
      }, //修改备注
      bulkPayTipDialog: false, // 批量支付弹窗状态
      alreadyExistOrders: '', // 已存在的批量支付订单ID
      alreadyExistOrdersShow: '', // 展示到页面
      showRejectReson: false, // 驳回原因弹窗
      currentRejectReson: '', // 当前驳回原因
      addressShow: false,
      showRefundTipDialog: false, // 点击申请退款或请售后 提示弹窗
      refundTid: '', // 当前点击申请退款或申请售后的tip
      refundSupplierId: '', // 当前点击申请退款或申请售后的信息supplier_id

      showOrderImportDialog: false, // 是否展示订单导入弹窗
      targetImportId: '', // 导入id
      targetImportStatus: '', // 导入状态
      showOrderProgressDialog: false, // 是否展示查看下单进度弹窗
      showExportedHistoryDialog: false, // 是否展示导出历史弹窗
      exportType: 23, // 导出任务类型（分销商订单）

      arrearsLength: 0, // 欠费列表总数
      payArrearsTipDialog: false, // 欠费支付提示弹窗状态
      currencyFont: '', // 欠费账户文字 人民币/美元
      currencyNum: '', // 欠费账户 1人民币/2美元
      extendShow: false, // 延长收货弹框
      timeStr: '', // 延迟弹框，时间文案
      extendableDays: '', // 最长可延长收货天数
    };
  },
  props: {
    orderStatus: {
      type: String,
      default: '',
    },
    titlele: {
      type: String,
      default: '',
    },
  },
  mounted() {
    if (process.client) {
      this.activeName = this.orderStatus;
      this.title = this.titlele;
      if (this.$route.query?.start && this.$route.query?.end) {
        this.seach.orderTime = [
          this.$route.query?.start,
          this.$route.query?.end,
        ];
        this.getUserOrderlist();
        return;
      }
      // this.$nextTick(() => {
      // 获取vuex中的数据
      let info =
        JSON.parse(JSON.stringify(this.getPageFilterData() || null)) || null;
      if (info) {
        this.echoSearchData(info);
      }
      this.getUserOrderlist(false);
      // });

      this.getOrderPanelData();
      this.fetchOrderSourceList();
      // this.getOrderTrajectory('JT3006769667867');
    }
  },
  computed: {
    checkedCount() {
      // 去重后再计算数量
      const count = new Set(this.selectOrderCheckbox).size;
      return count;
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    ...mapMutations('pay', ['addBulkPaymentId']),
    // 获取待支付订单的更多操作包含的菜单
    getWaitPayOpConfig(item) {
      if (item.status != 'WAIT_BUYER_PAY') return [];
      const ops = [
        {
          label: '取消订单',
          eventName: 'cancel-order',
        },
      ];
      // 用户在待支付状态时是否修改订单地址（-1否，1是）
      if (item.edit_addr_wait_pay == -1) {
        ops.push({
          label: '修改订单',
          eventName: 'edit-order',
        });
      }
      return ops;
    },
    statusClass(status) {
      switch (status) {
        case 'WAIT_BUYER_PAY': // 待支付
          return 'waithrpaymodel';
        case 'PAYMENT_PROCESSING': // 支付处理中
          return 'payhanding';
        case 'WAIT_SELLER_SEND_GOODS': // 待发货
          return 'waitsend';
        case 'WAIT_BUYER_CONFIRM_GOODS': // 待收货
          return 'waitreceive';
        case 'WAIT_COMMENT': // 待评价
          return 'waitcomment';
        case 'SUCCESSFUL_TRADE': // 交易成功
          return 'ordersucceed';
        case 'TRANSACTION_CLOSURE': // 交易关闭
          return 'orderfail';
        case 'TRANSACTION_CANCEL': // 取消订单
          return 'cancelorder';
        case 'REFUNDED': // 已退款
          return 'orderfail';
        case 'OVERTIME_UNPAID': // 超时未支付
          return 'orderfail';
        case 'PAYMENT_FAILED': // 支付失败
          return 'orderfail';
        case 'FAILURE_TO_SHIP_DUE_TO_TIMEOUT': // 超时未发货
          return 'orderfail';
        case 'REFUND_IN_PROGRESS': // 纠纷中
          return 'cancelorder';
        default:
          break;
      }
    },
    //返回状态描述文字
    getStatus(flag) {
      let box = [
        { name: 'WAIT_BUYER_PAY', value: '待支付' },
        { name: 'PAYMENT_PROCESSING', value: '支付处理中' },
        { name: 'WAIT_SELLER_SEND_GOODS', value: '待发货' },
        { name: 'WAIT_BUYER_CONFIRM_GOODS', value: '待收货' },
        { name: 'WAIT_COMMENT', value: '待评价' },
        { name: 'REFUND_IN_PROGRESS', value: '纠纷中' },
        { name: 'SUCCESSFUL_TRADE', value: '交易成功' },
        { name: 'TRANSACTION_CLOSURE', value: '交易关闭' },
        { name: 'TRANSACTION_CANCEL', value: '取消订单' },
        { name: 'REFUNDED', value: '已退款' },
        { name: 'OVERTIME_UNPAID', value: '超时未支付' },
        { name: 'FAILURE_TO_SHIP_DUE_TO_TIMEOUT', value: '超时未发货' },
        { name: 'PAYMENT_FAILED', value: '支付失败' },
      ];
      let status = box.filter((item) => item.name == flag);
      if (status.length != 0) {
        return status[0].value;
      } else {
        return '--';
      }
    },
    // 处理空数据
    handleNullData(content) {
      return content ? content : '--';
    },
    // 跳转商品详情
    comDetails(item) {
      if ('1' == item.currency_code) {
        // 人民币
        item.publish_language_id = '2';
      }
      if ('2' == item.currency_code) {
        // 美元
        item.publish_language_id = '3';
      }
      this.toCachedShopdetail(
        item.item_id,
        item.image_default_id,
        item.sku_lowest_price_img,
        '_blank',
        item.publish_language_id
      );
    },
    // 返回收货人信息
    getTitle(row) {
      if (!row) return;
      let str = '';
      if (row.receiver_country) {
        str += row.receiver_country;
      }
      if (row.receiver_state) {
        str += '.' + row.receiver_state;
      }
      if (row.receiver_city) {
        str += '.' + row.receiver_city;
      }
      if (row.receiver_district) {
        str += '.' + row.receiver_district;
      }
      if (row.receiver_address) {
        str += '.' + row.receiver_address;
      }

      return str;
    },
    //时间戳转为天数
    getDayByHours(time) {
      let days = parseInt(time / 86400);
      return days;
    },

    // 下拉输入组合框清空事件
    clearSeachType() {
      this.changeSeachType();
      this.onChange();
    },
    changeSeachType() {
      this.seachInput = '';
    },
    // 状态筛选
    changeStatus() {
      this.seach.orderTime = [];
      this.onChange();
    },
    //筛选自动搜索
    onChange() {
      this.pageNum = 1;
      this.getUserOrderlist();
    },
    // 获取面板数据
    getOrderPanelData() {
      getOrderPanelData({ MUTE_WARNING: 1 })
        .then((res) => {
          if (res.errcode == 0) {
            this.info = res.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 筛选条件回显
     * @param {Object} info 当前页面缓存的搜索条件
     */
    echoSearchData(info) {
      this.seach = { ...info, orderTime: [] };
      this.pageNum = info?.pageNum || 1;
      // 时间范围筛选
      if (info?.creat_start_time && info?.creat_end_time) {
        this.seach.orderTime = [info?.creat_start_time, info?.creat_end_time];
      }
      // 下拉加输入筛选
      if (info?.searchType) {
        this.seach.seachType = info?.searchType;
        this.seachInput = info?.[info?.searchType] || '';
      }
      this.$forceUpdate();
    },
    /**
     * 获取订单列表
     * @param {Boolean} isCache 控制是否对筛选条件进行缓存
     */
    async getUserOrderlist(isCache = true) {
      try {
        const options = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          // tag_type:this.activeName,
          trade_type: this.seach.trade_type,
          memo_type: this.seach.memo_type,
          warehouses_type: this.seach.warehouses_type,
          currency: this.seach.currency,
          status: this.seach.status,
          order_source: this.seach.order_source,
          MUTE_WARNING: 1,
        };
        if (this.activeName == 1) {
          // 全部订单
          options.tag_type = '';
        } else if (this.activeName == 2) {
          // 待支付
          options.tag_type = '8';
        } else if (this.activeName == 3) {
          // 支付处理中
          options.tag_type = '4';
        } else if (this.activeName == 4) {
          // 待发货
          options.tag_type = '3';
        } else if (this.activeName == 5) {
          // 待收货
          options.tag_type = '5';
        } else if (this.activeName == 6) {
          // 待评价
          options.tag_type = '7';
        } else if (this.activeName == 7) {
          // 退货/纠纷
          options.tag_type = '6';
        }
        if (this.seach?.orderTime?.length > 0) {
          options.creat_start_time = this.seach.orderTime[0];
          options.creat_end_time = this.seach.orderTime[1];
        }
        if (this.seachInput) {
          if (!this.seach.seachType) this.seach.seachType = 'tid';
          let searchType = this.seach.seachType || 'tid';
          options.searchType = searchType;
          options[searchType] = this.seachInput;
        }
        // this.selectOrderCheckbox = [];
        // console.log('所选数据==>', this.selectOrderCheckbox);
        // this.isOrderSleectAll = false;
        // this.isIndeterminate = false;
        if (isCache) {
          this.setPageFilter(options);
        }
        const res = await getUserOrderList(options);
        if (res.errcode == 0) {
          // this.info = res.data.panel_date;
          // res.data.list.forEach((item) => {
          //   item.btnpolicy =
          //     '供应商问题' +
          //     item.return_policy.supplier_problem.toString() +
          //     ';分销商问题' +
          //     item.return_policy.user_problem.toString();
          // });
          this.totalnum = Number(res.data.total);
          // 处理倒计时
          console.log(this.timer, 'this.timer');

          if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
          }
          res.data.list.forEach((item) => {
            // 付款倒计时
            const t1 = Date.parse(new Date());
            item.pay_deadline_difference =
              item.pay_deadline * 1000 - t1 > 0
                ? item.pay_deadline * 1000 - t1
                : 0;
            // 收货倒计时
            item.deliver_deadline_difference =
              item.deliver_deadline * 1000 - t1 > 0
                ? item.deliver_deadline * 1000 - t1
                : 0;
            // 签收倒计时
            // item.deliver_deadline_difference = item.deliver_deadline*1000 -t1 > 0 ? item.deliver_deadline*1000 -t1 : 0 ;
            item.receipt_time_difference =
              item.receipt_time * 1000 - t1 > 0
                ? item.receipt_time * 1000 - t1
                : 0;
            // 支付处理中 审核倒计时
            item.examine_deadline_difference =
              item.examine_deadline * 1000 - t1 > 0
                ? item.examine_deadline * 1000 - t1
                : 0;
          });

          this.timer = setInterval(() => {
            res.data.list.forEach((item, index) => {
              // 付款倒计时
              const t1 = Date.parse(new Date());
              item.pay_deadline_difference =
                item.pay_deadline * 1000 - t1 > 0
                  ? item.pay_deadline * 1000 - t1
                  : 0;
              // 收货倒计时
              item.deliver_deadline_difference =
                item.deliver_deadline * 1000 - t1 > 0
                  ? item.deliver_deadline * 1000 - t1
                  : 0;
              // 签收倒计时
              // item.deliver_deadline_difference = item.deliver_deadline*1000 -t1 > 0 ? item.deliver_deadline*1000 -t1 : 0 ;
              item.receipt_time_difference =
                item.receipt_time * 1000 - t1 > 0
                  ? item.receipt_time * 1000 - t1
                  : 0;
              // 支付处理中 审核倒计时
              item.examine_deadline_difference =
                item.examine_deadline * 1000 - t1 > 0
                  ? item.examine_deadline * 1000 - t1
                  : 0;
              this.$forceUpdate();
            });
            // 是否清timer定时器
            this.hasClearInterval(res.data.list);
          }, 1000);

          this.orderList = res.data.list;
          // console.log('当前列表页数据==》', this.orderList);
          this.handleSelectedAll();
          // 如果页数不是第一页，并且数据为空的情况
          if (this.pageNum > 1 && this.orderList?.length == 0) {
            this.goPage(1);
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 判断是否清空当前页面的定时器
    hasClearInterval(list) {
      // 是否清空倒计时 timer
      let hasStopTimer = list?.every(
        (item) =>
          item?.pay_deadline_difference === 0 &&
          item?.receipt_time_difference === 0 &&
          item?.deliver_deadline_difference === 0 &&
          item?.examine_deadline_difference === 0
      );
      if (hasStopTimer) {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
      }
    },
    goPage(currentpagr, pageSize) {
      // 当前页
      this.pageNum = Number(currentpagr);
      if (pageSize) {
        this.pageSize = pageSize;
      }
      this.getUserOrderlist();
    },
    // 头部切换路由
    toptitlecheckurl(index) {
      if (index == this.orderStatus) {
        return;
      }
      let url = '';
      if (index == 1) {
        //全部订单
        url = '/trade/allorder';
      } else if (index == 2) {
        //待支付
        url = '/trade/nopay';
      } else if (index == 3) {
        //支付处理中
        url = '/trade/paymentprocessing';
      } else if (index == 4) {
        //待发货
        url = '/trade/nodelivergoods';
      } else if (index == 5) {
        //待收货
        url = '/trade/noreceived';
      } else if (index == 6) {
        //待评价
        url = '/trade/noevaluate';
      } else if (index == 7) {
        // 退款/纠纷
        url = '/trade/disputeslist';
      }
      this.$router.push(url);
    },
    // 点击“查看已导出列表”按钮，展示导出历史弹窗
    handleClickViewExportedBtn() {
      this.showExportedHistoryDialog = true;
    },
    // 导出订单
    async exportOrders(options) {
      const params = this.filterEmptyKey(
        Object.assign({ export_type: this.exportType }, options)
      );
      console.log('【导出订单】', params);

      try {
        const res = await exportOrderList(params);
        if (res?.errcode == 0) {
          // 订单导出
          this.$message.success(res.msg || '操作成功');
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 导出全部信息
    handleExportAll() {
      const options = {
        tag_type: this.activeNameToTagTypeMap[this.activeName] || '',
        trade_type: this.seach.trade_type,
        memo_type: this.seach.memo_type,
        warehouses_type: this.seach.warehouses_type,
        currency: this.seach.currency,
        status: this.seach.status,
        MUTE_WARNING: 1,
      };
      if (this.seach?.orderTime?.length > 0) {
        options.creat_start_time = this.seach.orderTime[0];
        options.creat_end_time = this.seach.orderTime[1];
      }
      if (this.seachInput) {
        if (!this.seach.seachType) this.seach.seachType = 'tid';
        options[this.seach.seachType] = this.seachInput;
      }
      this.exportOrders(options);
    },
    // 导出选中信息
    handleExportPart() {
      if (!this.selectOrderCheckbox?.length) {
        this.$message.warning('请选择您要导出的订单');
        return;
      }
      const tids = this.selectOrderCheckbox.toString();
      this.exportOrders({ tid: tids });
    },
    // 刷新列表
    handleRefresh() {
      this.onChange();
      this.getOrderPanelData();
    },
    // 点击订单导入按钮
    handleClickImportOrderBtn() {
      this.showOrderImportDialog = true;
    },
    // 更换物流
    changelogistic(item) {
      this.currentorderobj = item;
      this.logisticshow = true;
      // 清空数据
      this.currentChau = '';
      this.provinceCityArea = [];
      this.tempAddressLabel = [];
      this.tempAddressValue = [];
    },

    async dataChange(id) {
      this.tempAddressLabel = [];
      this.tempAddressValue = [];
      this.provinceCityArea.length ? this.provinceCityArea.splice(0, 1) : '';
      this.provinceCityArea.push(id);
    },
    getAddressData(value, label) {
      this.tempAddressLabel = label;
      this.tempAddressValue = value;
      // 获取物流信息
      if (
        (this.$refs.searchaddress.has_state == 1 &&
          this.tempAddressValue.length == 3) ||
        (this.$refs.searchaddress.has_state == 0 &&
          this.tempAddressValue.length == 2)
      ) {
        this.getItemFreight(this.tempAddressValue[0]);
      }
    },

    getItemFreight(id) {
      let option = {
        start: this.currentorderobj.war_country_id,
        end: id,
        supplier_id: this.currentorderobj.supplier_id,
        sku_id: this.currentorderobj.sku_id,
        sku_num: this.currentorderobj.itemnum,
        MUTE_WARNING: 1,
      };
      getItemFreight(option)
        .then((res) => {
          if (res.errcode == 0) {
            res.data.forEach((item) => {
              item.checked = false;
            });
            this.logisticstemplate = res.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    tempalgechange(item) {
      // 操作物流模板
      let id = item.checked ? item.fre_id : '';
      this.logisticsId = id;
      this.logisticstemplate.forEach((res) => {
        if (res.fre_id != id) {
          res.checked = false;
        }
      });
      this.$forceUpdate();
    },

    ontablemore() {
      this.tabletype = !this.tabletype;
      if (this.tabletype) {
        this.tablenumber = 2;
      } else {
        this.tablenumber = 9999999999;
      }
    },
    // 切换物流
    configlog() {
      this.logisticshow = false;
    },
    // 取消
    cancellog() {
      this.logisticshow = false;
    },

    remarkdigshow(item) {
      this.remarkshow = true;
      this.currentitemid = item.tid;
      this.remarkcontent = item.user_remarks;
    },
    subBtn() {
      const options = {
        tid: this.currentitemid,
        user_remarks: this.remarkcontent,
      };
      addOrderMemo(options)
        .then((res) => {
          if (res.errcode == 0) {
            this.$message.success('编辑成功');
            this.remarkshow = false;
            this.getUserOrderlist();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    delsubBtn() {
      this.remarkshow = false;
      this.currentitemid = '';
    },
    // 跳转支付方式选择页面
    async payjupm(item) {
      await this.judgeArrearsInfo(item.currency_code);
      if (this.arrearsLength) {
        this.payArrearsTipDialog = true;
        return;
      }
      window.open(`/paychose?tid=${item.tid}`);
      this.addBulkPaymentId(item.tid);
    },
    // 判断是否有欠费信息
    async judgeArrearsInfo(currency) {
      try {
        this.currencyNum = currency;
        this.currencyFont = currency == '2' ? '美元' : '人民币';
        let options = {
          currency,
          MUTE_WARNING: 1,
        };
        const res = await getFundInfo(options);
        if (res?.errcode == 0) {
          this.arrearsLength = res?.data?.arrears_num || 0;
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 跳转去支付
    goPayArrears() {
      this.$router.push('/fund/fundcenter/' + this.currencyNum);
    },
    // 关闭弹窗
    closeDialog() {
      this.payArrearsTipDialog = false; // 关闭欠费信息提示弹窗
      this.arrearsLength = 0; // 重置欠费支付数据

      this.extendShow = false; // 关闭延迟收货弹框
      this.timeStr = '';
      this.extendableDays = '';
      this.currentitemid = '';
    },
    // 查看驳回原因
    checkRejectReson(rejectContent) {
      this.showRejectReson = true;
      this.currentRejectReson = rejectContent;
    },
    closeRejectDialog() {
      this.showRejectReson = false;
    },
    //全选按钮
    onSelectOrderAll(val) {
      let currentAllCheck = this.orderList.map((item) => item.tid);
      if (val) {
        this.selectOrderCheckbox =
          this.selectOrderCheckbox.concat(currentAllCheck);
      } else {
        let orderTids = new Set(currentAllCheck);
        const newArr = this.selectOrderCheckbox.filter(
          (x) => !orderTids.has(x)
        );
        this.selectOrderCheckbox = newArr.slice();
      }
      this.selectOrderCheckbox = [...new Set(this.selectOrderCheckbox)];

      this.isIndeterminate = false;
    },
    // 点击选择某一个订单
    handleCheckOrder(val) {
      this.handleSelectedAll();
    },
    // 判断全选、半选、未选
    handleSelectedAll() {
      // 当前页所有订单的tid数组
      let orderListTids = this.orderList?.map((x) => x.tid);
      let intersection = this.selectOrderCheckbox.filter(
        (x) => orderListTids.indexOf(x) > -1
      );
      let intersectLength = intersection?.length;
      this.isOrderSleectAll = intersectLength === this.orderList?.length; // 全选
      this.isIndeterminate =
        intersectLength > 0 && intersectLength < this.orderList?.length; // 半选
    },

    // 跳转
    loadPageReplaceOrPush(id) {
      window.open(`/trade/orderdetails?item_id=${id}`);
    },
    //跳转到纠纷详情
    loadPageReplaceOrPushReturn(id) {
      window.open(`/trade/disputes?item_id=${id}`);
    },
    //获取当前时间并计算时间差
    getNowTime(time) {
      if (process.client) {
        var date3 = time;
        if (date3 == 0) {
          ('');
          return '超时';
        } else {
          date3 = Math.abs(date3);
          //计算出相差天数
          var days = Math.floor(date3 / (24 * 3600 * 1000));
          //计算出小时数
          var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
          var hours = Math.floor(leave1 / (3600 * 1000));
          //计算相差分钟数
          var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
          var minutes = Math.floor(leave2 / (60 * 1000));
          //计算相差秒数
          var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
          var seconds = Math.round(leave3 / 1000);
          let surplusTime =
            days + '天' + hours + '时' + minutes + '分' + seconds + '秒';
          return surplusTime;
        }
      }
    },
    getNowHourTime(time) {
      if (process.client) {
        let date3 = time;
        if (date3 == 0) {
          ('');
          return '超时';
        } else {
          date3 = Math.abs(date3);
          //计算出相差天数
          let days = Math.floor(date3 / (24 * 3600 * 1000));
          //计算出小时数
          let leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
          let hours = Math.floor(leave1 / (3600 * 1000));
          let surplusTime = days + '天' + hours + '小时';
          return surplusTime;
        }
      }
    },
    // 点击申请退款或申请售后
    handleClickApplyRefund(tid, supplierId) {
      this.refundTid = tid;
      this.refundSupplierId = supplierId;
      this.showRefundTipDialog = true;
    },
    // 点击延长收货
    handleExtend(item) {
      this.currentitemid = item.tid;
      this.timeStr = this.getNowHourTime(item?.receipt_time_difference);
      this.extendableDays = item?.extend_receipt_total_time;
      this.extendShow = true;
    },
    // 关闭延长收货弹框
    closeExtend() {
      this.extendShow = false;
      this.extendableDays = '';
      // 更新列表
      this.getUserOrderlist();
    },
    // 关闭申请退款弹窗提示
    closeRefundTipDialog() {
      this.showRefundTipDialog = false;
      this.refundTid = '';
      this.refundSupplierId = '';
    },
    // 确认申请退款或申请售后 跳转申请申请退货/款页面
    goRefundInfo() {
      this.showRefundTipDialog = false;
      if (this.refundTid && this.refundSupplierId) {
        setTimeout(() => {
          window.open(
            `/trade/applyforrefund?item_id=${this.refundTid}&supplier_id=${this.refundSupplierId}`
          );
          this.refundTid = '';
          this.refundSupplierId = '';
        }, 150);
      }
    },
    // 查看发票
    viewInvoice(tid) {
      window.open(`/fund/invoice/2/${tid}`);
    },
    // 修改订单地址
    editAddress(obj) {
      this.item_tid = obj.tid;
      this.orderAddress = {
        country_id: obj.receive_country_id, // 收货地址，国家
        country: obj.receiver_country,
        province_id: obj.receive_state_id, // 收货地，省
        province: obj.receiver_state,
        city_id: obj.receive_city_id, // 收货地，市
        city: obj.receiver_city,
        area: obj.receiver_district,
        area_id: obj.receive_areas_id,
        deatilAddress: obj.receiver_address, // 退货的详细地址
        zip: obj.receiver_zip, // 邮政编码
        username: obj.receiver_user, // 收获人姓名
        phone: obj.receiver_phone, // 电话
        dialing_code: obj.dialing_code, // 区号
        chaType: obj.cha_type, // 运费模板类型
        freightTempType: obj.type, // 运费模板类型，省到省或市到市
        countryHaveAreas: obj.have_areas, // 收货地国家是否有区
      };
      this.addressShow = true;
    },
    // 关闭修改地址弹框
    closeAddress() {
      this.addressShow = false;
      this.getUserOrderlist();
    },
    // 复制操作
    _copy(context) {
      // 创建输入框元素
      let oInput = document.createElement('input');
      // 将想要复制的值
      oInput.value = context;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand('Copy');
      // 弹出复制成功信息
      this.$message.success('复制成功');
      // 复制后移除输入框
      oInput.remove();
    },

    // 取消订单
    deleOrder(tid) {
      this.closeid = tid;
      this.configshow = true;
    },
    closeoder() {
      closeOrder({ tid: this.closeid })
        .then((res) => {
          if (res.errcode == 0) {
            this.$message.success(res.msg);
            this.getUserOrderlist();
            this.getOrderPanelData();
            this.configshow = false;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    canceldorer() {
      this.configshow = false;
    },
    resizedata() {
      this.seach.status = '';
      this.seach.orderTime = [];
      this.seach.trade_type = '';
      this.seach.memo_type = '';
      this.seach.seachType = '';
      this.seachInput = '';
      this.seach.warehouses_type = '';
      this.seach.currency = '';
      this.seach.order_source = '';
      this.getUserOrderlist();
    },
    // 确认收货
    onconfirmReceipt(row) {
      confirmReceipt({ tid: row.tid })
        .then((res) => {
          if (res.errcode == 0) {
            this.$message.success('操作成功');
            this.getUserOrderlist();
            this.getOrderPanelData();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    //获取洲列表
    async getContinents() {
      try {
        let res = await getContinents();
        if (res.errcode == 0) {
          this.Continents = res.data;
          return;
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 查看物流
    getOrderTrajectory(id, logi) {
      this.logisticName = logi;
      this.logi_no = id;
      let option = {
        logi_no: id,
      };
      getOrderTrajectory(option)
        .then((res) => {
          if (res.errcode == 0) {
            this.logshow = true;
            this.logdetaillist = res.data;
          } else {
            this.logdetaillist = [];
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 拼接规格字段
    specString(specStr) {
      let specArr = JSON.parse(specStr);
      let arr = specArr.map((item) => {
        return item.name + ':' + item.value;
      });
      return arr.join(';');
    },
    // 批量支付
    async bulkPayment() {
      if (!this.selectOrderCheckbox.length) {
        this.$message.warning('请选择订单');
        return;
      }

      let selectCurrency = this.orderList?.filter((item) =>
        this.selectOrderCheckbox?.includes(item?.tid)
      );
      // currency_code（币种） 1:人民币，2:美元，
      let isDollar = selectCurrency?.some((item) => item?.currency_code == '2');
      if (isDollar) {
        this.$message.warning('暂不支持美元订单的批量支付');
        return;
      }

      // 目前只有中文商品才有批量支付,判断人民币有无欠费就行
      await this.judgeArrearsInfo('1');
      if (this.arrearsLength) {
        this.payArrearsTipDialog = true;
        return;
      }

      let choseOrdersID = this.selectOrderCheckbox.join(',');

      checkOrderPay({ tids: choseOrdersID })
        .then((res) => {
          if (res.errcode == 0) {
            if (res.data.tid == '') {
              this.useChoseOrders();
              // this.$store.commit('addBulkPaymentId', choseOrdersID);
            } else {
              this.alreadyExistOrders = res.data.tid;
              this.alreadyExistOrdersShow = res.data.tid.replace(/,/g, '、');
              this.bulkPayTipDialog = true;
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 点击使用原支付单
    useOriginalOrders() {
      window.open(`/paychose?tid=${this.alreadyExistOrders}`);
      this.$store.commit('addBulkPaymentId', this.alreadyExistOrders);
    },
    // 点击继续支付
    useChoseOrders() {
      let choseOrdersID = this.selectOrderCheckbox.join(',');
      window.open(`/paychose?tid=${choseOrdersID}`);
      this.addBulkPaymentId(choseOrdersID);
    },
    // 关闭批量支付订单号已存在提示弹窗
    closeBulkPayDialog() {
      this.bulkPayTipDialog = false;
    },
    // 获取订单来源选项列表 #todo-228
    async fetchOrderSourceList() {
      try {
        const params = {};
        const res = await getOrderSourceList(params);
        if (res?.errcode == 0) {
          this.orderSourceOptions = res.data?.orderSource || [];
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 点击打开查看导入进度弹窗
    handleCheckImportProgress(id, status) {
      this.targetImportId = id;
      this.targetImportStatus = status;
      this.showOrderProgressDialog = true;
    },
    // 搜索导入id
    handleSearchImportId(id) {
      this.seach.seachType = 'import_id';
      this.seachInput = id;

      this.handleRefresh(); // 刷新列表

      this.showOrderImportDialog = false; // 关闭弹窗
    },
  },
};
