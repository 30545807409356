
export default {
  name: 'RefuseDialog',
  props: {
    // 币种
    currency: {
      type: String,
      default: '',
    },
    // 退货信息
    returnAddress: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      accept: ['png', 'jpg', 'jpeg', 'gif'], //上传限制
      ruleForm: {
        name: '', // 物流公司
        number: '', // 物流单号
        price: '', // 物流费
        remark: '', // 发货声明
        proof: '', // 证据图片/视频
      },
      rules: {
        name: [{ required: true, message: '请填写物流公司', trigger: 'blur' }],
        number: [
          { required: true, message: '请填写物流单号', trigger: 'blur' },
          {
            pattern: /^[A-Za-z0-9]+$/,
            message: '请输入合法的物流单号',
            trigger: ['change', 'blur'],
          },
        ],
        price: [
          { required: true, message: '请输入物流费', trigger: 'blur' },
          {
            pattern: /^[1-9]+\d*(\.\d{1,2})?$|^(0|0\.\d{1,2})$/,
            message: '请输入正确的物流费',
            trigger: ['change', 'blur'],
          },
        ],
      },
    };
  },
  computed: {
    // 验证规则
    rules() {
      // 自定义验证规则
      let checkRemark = (rule, value, callback) => {
        if (!value?.trim()) {
          callback(new Error('请输入备注'));
        }
        callback();
      };
      let supplierRule = {
        radio: [{ required: true, message: '请选择方案', trigger: 'change' }],
        price: [
          {
            required: true,
            message: '请输入退款金额',
            trigger: ['blur', 'change'],
          },
        ],
      };
      let platformRule = {
        remark: [
          { required: true, message: '请输入备注', trigger: 'blur' },
          { validator: checkRemark, trigger: 'blur' },
        ],
      };
      if (this.type == 'supplier') return supplierRule;
      if (this.type == 'platform') return platformRule;
    },
  },
  methods: {
    // 上传图片
    uploadFiles(files) {
      this.ruleForm.proof = files?.toString();
    },
    // 关闭弹窗
    closeDialog() {
      this.$emit('close-dialog');
    },
    // 提交
    submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let options = {
            logistics_company: this.ruleForm?.name, // 物流公司
            logistics_no: this.ruleForm?.number, // 物流单号
            logistics_fee: this.ruleForm?.price, // 物流费
          };
          if (this.ruleForm?.remark) options.remarks = this.ruleForm?.remark; // 备注
          if (this.ruleForm?.proof) options.evidence_pic = this.ruleForm?.proof; // 证明
          this.$emit('submit-return', options);
        }
      });
    },
  },
};
