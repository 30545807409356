
// import 'video.js/dist/video-js.css';
export default {
  name: 'XmVideo',
  props: {
    // 视频链接
    src: {
      type: String,
      default: '',
    },
    // 播放器宽度
    width: {
      type: String,
      default: '100%',
    },
    // 播放器高度
    height: {
      type: String,
      default: '500px',
    },
    // 是否自动播放
    autoplay: {
      type: Boolean,
      default: false,
    },
    // 当页面有多个 videojs 实例时，其 id 应当互不相同
    id: {
      type: String,
      default: 'default',
    },
    // 是否展示顶部的 banner 栏（包含关闭按钮）
    showTopBar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      player: null, // 播放器实例
    };
  },
  watch: {
    src() {
      this.initPlayer();
    },
  },
  methods: {
    handleClickClose() {
      this.$emit('close');
    },
    // 初始化播放器组件实例
    initPlayer() {
      this.player = videojs('my-xm-video-' + this.id, {
        controls: true,
        preload: 'auto',
        width: this.width,
        height: this.height,
        autoplay: this.autoplay,
        controlBar: {
          pictureInPictureToggle: false, // 播放器控制栏是否有画中画按钮
        },
        language: 'zh-cn',
        languages: {
          // 播放器中文标签
          'zh-cn': {
            Play: '播放',
            Pause: '暂停',
            'Current Time': '当前时间',
            Duration: '时长',
            'Remaining Time': '剩余时间',
            Fullscreen: '全屏',
            Mute: '静音',
            Unmute: '取消静音',
            'Play Video': '播放视频',
          },
        },
      });
    },
  },
  mounted() {
    if (!this.player) {
      this.initPlayer();
    }
  },
  beforeDestroy() {
    this.player?.dispose();
  },
};
