/**
 * 友情链接接口
 */
import request from '@/utils/request.js';
export function blogrollDisplay(data) {
  return request({
    url: '/MallHomePageBlogroll/blogrollDisplay',
    method: 'get',
    params: data,
  });
}
