
export default {
  name: 'OrderInfo',
  props: {
    orderList: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 订单数据
      orderData: [
        {
          title: '待支付订单',
          jumpFont: '查看待支付订单',
          num: '0',
          titlePic: require('@/assets/shop-home-index/stay_play.png'),
          url: '/trade/nopay',
          name: 'WAIT_BUYER_PAY',
        },
        {
          title: '待收货订单',
          jumpFont: '查看待收货订单',
          num: '0',
          titlePic: require('@/assets/shop-home-index/stay_receive_goods.svg'),
          url: '/trade/noreceived',
          name: 'WAIT_BUYER_CONFIRM_GOODS',
        },
        {
          title: '待评价订单',
          jumpFont: '查看待评价订单',
          num: '0',
          titlePic: require('@/assets/shop-home-index/stay_evaluation.svg'),
          url: '/trade/noevaluate',
          name: 'WAIT_COMMENT',
        },
        {
          title: '退换货/售后',
          jumpFont: '查看售后订单',
          num: '0',
          titlePic: require('@/assets/shop-home-index/return_goods.svg'),
          url: '/trade/disputeslist',
          name: 'IN_AFTER',
        },
      ],
    };
  },
  watch: {
    orderList: {
      immediate: true,
      deep: true,
      handler(val) {
        console.log(val, '监听到的订单数据');
        this.orderData.forEach((item) => {
          if (val.hasOwnProperty(item.name)) {
            item.num = val[item.name];
          }
        });
      },
    },
  },
  mounted() {},
  methods: {
    // 跳转对应订单
    jumpOrder(url) {
      this.openNewWindow(url);
    },
  },
};
