
import leftmenu from '@/components/comme/leftmenu.vue';
export default {
  name: 'ActivityLayout',
  components: {
    leftmenu,
  },
  data() {
    return {
      leftname: '营销活动',
      menulist: ['爆单挑战'],
      routerlist: ['/activity/challenge'],
      routerIndexHash: {
        '/activity/challenge': 0,
      },
    };
  },
  methods: {},
  //计算属性computed无法传递参数
  computed: {},
  mounted() {},
};
