
import { previewImageZip } from '../js/preview-zip-files';
export default {
  name: 'PreviewImagesDialog',
  props: {
    imgs: {
      type: Array,
      default: () => [],
    },
    imgIndex: {
      type: Number,
      default: -1,
    },
    zip: {
      type: Object,
      default: () => {},
    },
    isZip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currUrl: '',
      currIndex: 0,
      // isZip: !!this.zip,
    };
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    /**
     * 根据imgs数组索引更新当前展示的轮播图大图
     * @param index 将要展示的图片imgs[index]
     */
    handleChangeImg(index) {
      this.currIndex = index;
      if (index < 0) return;
      this.currUrl = this.imgs[index].fileUrl;
    },
    /**
     * 根据imgs数组索引更新当前展示的轮播图大图
     * @param index 将要展示的图片imgs[index]
     */
    handleChangeZipImg(index) {
      this.currIndex = index;
      if (index < 0) return;
      if (!process.client) {
        return;
      }
      const imgItem = this.imgs[index];
      let containerId = 'preview-image-container';
      let dom = document.getElementById(containerId);
      dom.innerHTML = '';
      previewImageZip(this.zip, imgItem.filePath, containerId);
    },
    /**
     * 切换展示的图片
     * @param dir 上一张还是下一张（`prev` or `next`）
     */
    handleMoveImg(dir) {
      let limit = this.imgs.length;
      let currIndex = this.currIndex;
      // 计算移动后的下一张图片的 imgs 索引
      let nextIndex =
        dir == 'prev'
          ? (currIndex - 1 + limit) % limit
          : (currIndex + 1) % limit;
      if (this.isZip) {
        this.handleChangeZipImg(nextIndex);
      } else {
        this.handleChangeImg(nextIndex);
      }
    },
  },
  mounted() {
    const index = this.imgIndex;
    if (this.isZip) {
      this.handleChangeZipImg(index);
    } else {
      this.handleChangeImg(index);
    }
  },
};
