
/**
 * 多语言配置
 */
import { RECHARGE_FONT } from '../js/label_config_arrearage';
export default {
  name: 'arrearageInfo',
  props: {
    // 页面语言
    lang: {
      type: String,
      default: 'zh-cn',
    },
    arrearageInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    // 标签多语言配置
    langConfig() {
      return RECHARGE_FONT[this.lang || 'zh-cn'];
    },
  },
  filters: {
    // 判断是否有值
    emptyValFilter(val) {
      return !val ? '--' : val;
    },
  },
  methods: {},
};
