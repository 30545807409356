/**
 * 友情链接模块
 * -
 */

import friendLink from '@/pages/friendly-links/FriendLink';

const friendRouter = [
  {
    path: '/friendlyLinks',
    component: friendLink,
    meta: {
      isAuth: false,
    },
  },
];

export default friendRouter;
