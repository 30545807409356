import { render, staticRenderFns } from "./ProtocolDetail.vue?vue&type=template&id=355364bc&scoped=true"
import script from "./ProtocolDetail.vue?vue&type=script&lang=js"
export * from "./ProtocolDetail.vue?vue&type=script&lang=js"
import style0 from "./ProtocolDetail.vue?vue&type=style&index=0&id=355364bc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "355364bc",
  null
  
)

export default component.exports