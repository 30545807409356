
export default {
  props: {
    title: {
      type: String,
      default: '主标题',
    },
    subTitle: {
      type: String,
      default: '副标题',
    },
  },
};
