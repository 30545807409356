
export default {
  name: 'OrderChallenge',
  props: {
    // 挑战状态 1未参加 2进行中 3已结束
    challengeTab: {
      type: [Number, String],
      default: '1',
    },
  },
  data() {
    return {
      selectValue: {
        name: '', // 挑战名称
        challenge: '', // 挑战状态
        award: '', // 奖励状态
        timeLabel: '', // 时间状态选择
        timeValue: [], // 时间日期选择
      },
      // 挑战状态可选项
      challengeStatus: [
        { label: '全部', value: '' },
        { label: '未完成', value: '1' },
        { label: '已完成', value: '2' },
      ],
      // 奖励状态可选项
      awardStatus: [
        { label: '全部', value: '' },
        { label: '待审核', value: '1' },
        { label: '待发放', value: '2' },
        { label: '已发放', value: '3' },
      ],
    };
  },
  computed: {
    // 时间筛选可选项
    timeStatus() {
      let joinArr = [
        { label: '参加开始时间', value: 'activity_start' },
        { label: '参加截止时间', value: 'activity_end' },
      ];
      let timeArr = [
        { label: '挑战开始时间', value: 'challenge_start' },
        { label: '挑战截止时间', value: 'challenge_end' },
      ];
      let awardArr = [
        { label: '挑战结束时间', value: 'challenge_finish' },
        { label: '奖励发放时间', value: 'issue_rewards' },
      ];
      if (this.challengeTab == '1') {
        return joinArr;
      }
      if (this.challengeTab == '2') {
        return timeArr;
      }
      if (this.challengeTab == '3') {
        return timeArr.concat(awardArr);
      }
    },
  },
  mounted() {},
  methods: {
    // 点击重置
    reset() {
      this.selectValue = {
        name: '', // 挑战名称
        challenge: '', // 挑战状态
        award: '', // 奖励状态
        timeLabel: '', // 时间状态选择
        timeValue: [], // 时间日期选择
      };
      this.$refs.XmOnlyInput.onChange();
      this.$refs.XmDatePicker.reVal();
      this.search();
    },
    // 名称搜索传值
    searchName(selectVal, inputVal) {
      this.selectValue.name = inputVal;
      this.search();
    },
    // 改变时间类型
    changeTimeType(val) {
      this.selectValue.timeLabel = val;
      this.search();
    },
    // 搜索
    search() {
      let options = {};
      // 挑战名称
      if (this.selectValue.name) {
        options.chal_name = this.selectValue.name;
      }
      // 挑战状态
      if (this.selectValue.challenge) {
        options.chal_status = this.selectValue.challenge;
      }
      // 奖励状态
      if (this.selectValue.award) {
        options.rew_status = this.selectValue.award;
      }
      // 时间筛选
      if (
        this.selectValue.timeLabel &&
        this.selectValue.timeValue?.length == 2
      ) {
        options.time_type = this.selectValue.timeLabel;
        options.start_time = this.selectValue.timeValue[0];
        options.end_time = this.selectValue.timeValue[1];
      }
      this.$emit('search', options);
    },
  },
};
