
import {
  rechargeTrans,
  getUSDBankCard,
} from '@/api/client-api/fund/fundCenter.js';
/**
 * 多语言配置
 */
import { BANKCARD_TRANSFER } from '../js/label_config_bank_transfer';
// 变量
import { curDefaultLanguage } from '@/utils/cur_default_config.js'; //默认语言
export default {
  data() {
    return {
      banlimgList: [], // 转账凭证
      USDBankObj: {}, // USD账户信息
      disableSubmit: false, // 是否禁用提交按钮
    };
  },
  props: {
    amount: {
      type: [String, Number],
      default: 0,
    },
    target_type: {
      type: [String, Number],
      default: '1',
    },
    // 币种类型 1-人民币 2-美元
    currency: {
      type: String,
      default: 1,
    },
  },
  computed: {
    // 语言
    lang() {
      return curDefaultLanguage;
    },

    // 标签多语言配置
    langConfig() {
      let lang = this.currency == '1' ? 'zh-cn' : curDefaultLanguage;
      return BANKCARD_TRANSFER[lang || curDefaultLanguage];
    },
    // 银行账户信息
    bankAccountMsg() {
      let info = [
        {
          name: this.langConfig['payee-account-name'],
          value: '深圳市利典信息技术有限公司',
          sign: 'ACCOUNT_NAME_OF_PAYEE',
        },
        {
          name: this.langConfig['payee-account-number'],
          value: '749776725348',
          sign: 'PAYEE_ACCOUNT_NUMBER',
        },
        {
          name: this.langConfig['bank-address'],
          value: '中国银行深圳锦绣支行',
          sign: 'OPENING_BANK_ADDRESS',
        },
      ];
      let USDInfo = [
        { name: this.langConfig['payee-bank'], value: '', sign: 'PAYEE_BANK' },
        { name: this.langConfig['Swift-BIC'], value: '', sign: 'SWIFT_BIC' },
      ];
      if (this.currency == '1') {
        return info;
      }
      if (this.currency == '2') {
        info.splice(2, 0, ...USDInfo);
        info.forEach((item) => {
          if (this.USDBankObj) {
            item.value = this.USDBankObj[item.sign] || '--';
          }
        });
        return info;
      }
    },
  },
  mounted() {
    if (this.$route.params.currency && this.$route.params.currency == '2') {
      this.getXimuUSDBankCard();
    }
  },
  methods: {
    closebank() {
      this.$emit('closeBank', 2);
    },
    configBank() {
      if (this.disableSubmit) return;
      if (this.banlimgList.length == 0) {
        this.$message.warning(this.langConfig['pic-empty']);
        return;
      }
      // 转账记录校验通过后再开启禁止提交按钮点击。
      this.disableSubmit = true;
      let bankobj = {
        beneficiary_name: this.bankAccountMsg[0].value,
        beneficiary_account: this.bankAccountMsg[1].value,
        bank_address: this.bankAccountMsg[2].value,
        // swift_code: '',
        transfer_amount: this.amount,
        img: this.banlimgList[0],
      };
      if (this.currency == '2') {
        bankobj = {
          transfer_amount: this.amount,
          img: this.banlimgList[0],
        };
      }
      let option = {
        API_LANG_TYPE: this.lang,
        target_type: this.target_type,
        amount: this.amount,
        method: '3',
        currency: this.currency,
        bank_transfer_info: bankobj,
      };

      rechargeTrans(option)
        .then((res) => {
          if (res.errcode == 0) {
            // this.$message.success(res.msg);
            this.$emit('closeBank', 1);
          }
          this.disableSubmit = false;
        })
        .catch((err) => {
          this.disableSubmit = false;
          console.error(err);
        });
    },
    getFileFunc(fileArray) {
      this.banlimgList = fileArray;
    },
    // 获取
    getXimuUSDBankCard() {
      getUSDBankCard({ API_LANG_TYPE: this.lang, MUTE_WARNING: 1 })
        .then((res) => {
          if (res?.errcode == 0) {
            this.USDBankObj = JSON.parse(JSON.stringify(res?.data || {}));
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
