/**
 * 交易模块模块
 * - 全部订单 待支付  支付处理中 待收货  待收货 订单详情 申请退款
 */
// 后台管理架子
import shophome from '@/pages/back-stage/index.vue';
// 商品模块
import TradingLayout from '@/pages/back-stage/trading-module/TradingLayout.vue'; //分销商后台交易模块布局
import allorder from '@/pages/back-stage/trading-module/all-order/allorder.vue'; //全部订单
import NoPay from '@/pages/back-stage/trading-module/no-pay/NoPay.vue'; //待支付
import PaymentProcessing from '@/pages/back-stage/trading-module/payment-processing/PaymentProcessing.vue'; //支付处理中
import NoReceived from '@/pages/back-stage/trading-module/no-received/NoReceived.vue'; //待收货
import NoDeliverGoods from '@/pages/back-stage/trading-module/no-deliver-goods/noDeliverGoods.vue'; //待收货
import OrderDetails from '@/pages/back-stage/trading-module/order-detail/orderDetails.vue'; //订单详情
import ApplyForreFund from '@/pages/back-stage/trading-module/apply-for-refund/ApplyForreFund.vue'; //申请退款

// 评价
import NoEvaluate from '@/pages/back-stage/trading-module/evaluate/NoEvaluate.vue'; //评价列表
import OrderEvaluation from '@/pages/back-stage/trading-module/evaluate/OrderEvaluation.vue'; //评价订单页面

// 纠纷
import DisputesList from '@/pages/back-stage/trading-module/disputes/DisputesList.vue'; //纠纷列表
import DisputesDetail from '@/pages/back-stage/trading-module/disputes/details'; //纠纷详情
// import Disputes from '@/pages/back-stage/trading-module/disputes/Disputes.vue'; //纠纷详情

const tradeRoutes = [
  {
    path: '/',
    component: shophome,
    meta: {
      isAuth: true,
    },
    children: [
      // 交易模块的
      {
        path: 'trade',
        component: TradingLayout,
        meta: {
          isAuth: true,
        },
        children: [
          // 全部订单
          {
            path: '',
            component: allorder,
            meta: {
              isAuth: true,
            },
          },
          {
            path: 'allorder',
            component: allorder,
            meta: {
              isAuth: true,
            },
          },
          // 待支付
          {
            path: 'nopay',
            name: 'NoPay',
            component: NoPay,
            meta: {
              isAuth: true,
            },
          },
          // 支付处理中
          {
            path: 'paymentprocessing',
            name: 'PaymentProcessing',
            component: PaymentProcessing,
            meta: {
              isAuth: true,
            },
          },
          // 待收货
          {
            path: 'noreceived',
            name: 'NoReceived',
            component: NoReceived,
            meta: {
              isAuth: true,
            },
          },
          // 待收货
          {
            path: 'nodelivergoods',
            name: 'NoDeliverGoods',
            component: NoDeliverGoods,
            meta: {
              isAuth: true,
            },
          },
          // 订单详情
          {
            path: 'orderdetails',
            name: 'OrderDetails',
            component: OrderDetails,
            meta: {
              isAuth: true,
            },
          },
          // 申请退款
          {
            path: 'applyforrefund',
            name: 'ApplyForreFund',
            component: ApplyForreFund,
            meta: {
              isAuth: true,
            },
          },
          // 待评价
          {
            path: 'noevaluate',
            name: 'NoEvaluate',
            component: NoEvaluate,
            meta: {
              isAuth: true,
            },
          },
          // 评价订单页面
          {
            path: 'orderevaluation',
            name: 'OrderEvaluation',
            component: OrderEvaluation,
            meta: {
              isAuth: true,
            },
          },
          // 纠纷列表
          {
            path: 'disputeslist',
            name: 'DisputesList',
            component: DisputesList,
            meta: {
              isAuth: true,
            },
          },
          // 纠纷详情
          {
            path: 'disputes',
            name: 'Disputes',
            component: DisputesDetail,
            meta: {
              isAuth: true,
            },
          },
        ],
      },
    ],
  },
];

export default tradeRoutes;
