
import { FORM_ASIDE_LABELS } from '../js/label_config_form_aside';
// 变量
import { curDefaultLanguage } from '@/utils/cur_default_config.js'; //默认语言
export default {
  name: 'ResourceItem',
  props: {
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
    // 资源名称
    title: {
      type: String,
      default: '默认标题',
    },
    // 是否有“预览”和“下载”按钮
    hasOperations: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    // 标签多语言配置
    labelConfig() {
      return FORM_ASIDE_LABELS[this.lang || curDefaultLanguage];
    },
  },
  methods: {
    /**
     * 点击标题
     */
    handleClickTitle() {
      this.$emit('click-title');
    },
    /**
     * 点击预览
     */
    handlePreview() {
      this.$emit('preview');
    },
    /**
     * 点击下载
     */
    handleDownload() {
      this.$emit('download');
    },
  },
};
