
export default {
  data() {
    return {
      isExtFilter: false, // 控制是否出现更多筛选条件
      // 价格范围筛选
      priceMin: '',
      priceMax: '',
      priceShow: false, //控制价格筛选弹框
      //时间范围
      timeOptions: [],
      // 更多筛选选中列表
      checkList: [],
      // 排序相关
      sortStr: this.comprehensiveSort, //筛选（名称）
      sortDesc: -1, //筛选（序号）
    };
  },
  props: {
    // 语言配置
    langConfig: {
      type: Object,
      default: () => {},
    },
    // 语言
    lang: {
      type: String,
      default: '',
    },
    // 综合排序字段
    comprehensiveSort: {
      type: String,
      default: 'comprehensive_order',
    },
  },
  mounted() {
    if (process.client) {
      document.addEventListener('click', this.bodyCloseMenus);
    }
  },
  beforeDestroy() {
    if (process.client) {
      document.removeEventListener('click', this.bodyCloseMenus);
    }
  },
  methods: {
    // 页面触发
    bodyCloseMenus(e) {
      const self = this;
      if (this.$refs.priceMain && !this.$refs.priceMain.contains(e.target)) {
        if (self.priceShow == true) {
          self.priceShow = false;
        }
      }
    },
    // 筛选公共函数
    sortListBefore(sortStr = '') {
      this.sortStr = sortStr;
      if (this.sortStr != '') {
        if (this.sortDesc != 1) {
          this.sortDesc = 1;
        } else {
          this.sortDesc = -1;
        }
      } else {
        this.sortDesc = 0;
      }
      if (sortStr == 'comprehensive_order') {
        this.sortDesc = -1;
      }
      this.$emit('handle-sort', this.sortStr, this.sortDesc);
    },
    // 价格范围筛选
    rePrice() {
      this.priceMin = '';
      this.priceMax = '';
      this.priceShow = !this.priceShow;
      this.$emit('price-select', this.priceMin, this.priceMax);
    },
    cofigBtn() {
      if (parseFloat(this.priceMin) > parseFloat(this.priceMax)) {
        this.$message.warning('搜索价格最小值不要大于最大值');
        return;
      }
      this.priceShow = !this.priceShow;
      this.$emit('price-select', this.priceMin, this.priceMax);
    },
    // 时间范围筛选
    selectTime() {
      this.$emit('select-time', this.timeOptions);
    },
    // 更多筛选事件
    changecheckboxScreening() {
      this.$emit('handle-moreoptions', this.checkList);
    },
  },
};
