
import invoiceTable from './components/invoiceTable.vue';
import { getInvoicingInfo } from '@/api/client-api/fund/invoice.js';
export default {
  components: { invoiceTable },
  data() {
    return {
      detailInfo: {}, // 头部详情
      tableTitle: [
        {
          label: '订单号',
          prop: 'tid',
          width: '168px',
        },
        {
          label: '下单时间',
          prop: 'create_time',
          width: '158px',
        },
        {
          label: '商品名称',
          prop: 'goods_title',
          width: '219px',
          isedit: true,
        },
        {
          label: '数量',
          prop: 'goods_num',
          width: '65px',
        },
        {
          label: '商品单价(￥)',
          prop: 'goods_price',
          width: '100px',
        },
        {
          label: '操作费(￥)',
          prop: 'operation',
          width: '80px',
        },
        {
          label: '快递费(￥)',
          prop: 'logistics',
          width: '80px',
        },
        {
          label: '可开票金额(￥)',
          prop: 'amount',
          width: '110px',
        },
      ],

      tableList: [],
    };
  },
  mounted() {
    this.getdeatil(this.$route.params.applyId);
  },
  methods: {
    goback() {
      this.$router.replace('/fund/invoice');
    },
    getdeatil(id) {
      let option = {
        application_id: id,
      };
      getInvoicingInfo(option)
        .then((res) => {
          if (res?.errcode == 0) {
            this.detailInfo = res?.data?.application_info;
            this.tableList = res?.data?.order_list;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
