
import { getCity, getRegions, getAdressCities } from '@/api/client-api/api.js';
export default {
  //isEnglish标识需要返回英文名称
  props: [
    'areaData',
    'langType',
    'placeholderKey',
    'isEnglish',
    'en_areaData',
    'chaType',
    'freightTempType',
    'haveArea',
    'tid',
  ],

  data() {
    return {
      showDropdown: false,
      tabName: '',
      city_list: [],
      areaList: [], // 区数据
      /**
       * 需要传出的数据：
       */
      addressData: {
        country: '',
        country_id: '',
        province: '',
        province_id: '',
        city: '',
        city_id: '',
        area: '',
        area_id: '',
      },
      en_addressData: {
        en_country: '',
        en_province: '',
        en_city: '',
        en_area: '',
      },
      // haveAreas: '1', // 标记所选国家是否有区
      isSelectArea: false, // 是否选择了区
    };
  },
  computed: {
    // 显示在搜索框内部的当前选中的完整地址信息
    completeAddress() {
      const addr = `${this.addressData.city}${
        this.addressData.area && this.addressData.area.length > 0 ? '/' : ''
      }${this.addressData.area}`;
      return addr;
    },
  },
  methods: {
    //点击x清空选择地址
    clearAddressFunc() {
      this.addressData.city = '';
      this.addressData.city_id = '';
      this.addressData.area = '';
      this.addressData.area_id = '';
      this.en_addressData.en_city = '';
      this.en_addressData.en_area = '';
      this.areaList = [];
      this.changeTab('city');
      this.$emit('update', this.addressData, this.en_addressData);
    },
    // 地址选择下拉框隐藏时
    handleSelectGHidden(ststus) {
      // 地址信息是否不完整
      if (!ststus) {
        let isInfoNoIntegrity =
          this.haveArea == '1' && !this.addressData.area_id;
        this.$emit('check-address', isInfoNoIntegrity);
      }
    },
    changeTab(val) {
      this.tabName = val;
    },

    /**
     * 用户点击下拉菜单的选项触发的函数
     *
     * index：点击的哪个菜单 -> 0：国家菜单；1：省份菜单；2：城市菜单
     * item: 点击的哪个选项
     */
    selectOption(index, item) {
      // 运费模板为市到市时，市不可选择
      if (index == 2 && this.freightTempType === 'city') return;
      if (index == 2) {
        this.areaList = []; // 将区数据置空
        this.addressData.city = this.langType == 'en' ? item.name : item.cname;
        this.addressData.city_id = item.id;
        this.addressData.area = '';
        this.addressData.area_id = '';
        if (this.isEnglish) {
          this.en_addressData.en_city = item.name;
        }
        if (this.haveArea == 1) {
          // 有区
          this.getAreaList(this.addressData.city_id);
        } else {
          // 可以传出数据了
          this.closeDropdown();
        }
        // 可以传出数据了
        // this.closeDropdown();
      } else if (index == 3) {
        if (this.isEnglish) {
          this.en_addressData.en_area = item.name;
        }
        this.addressData.area = this.langType == 'en' ? item.name : item.cname;
        this.addressData.area_id = item.id;
        if (this.addressData.area_id) {
          this.isSelectArea = true;
        }
        // 区
        this.closeDropdown();
      }
      this.$forceUpdate();
    },
    closeDropdown() {
      this.$emit('update', this.addressData, this.en_addressData);
      this.showDropdown = false;
      this.$refs.selector && this.$refs.selector.blur(); // 选择器的尖角符号向下
    },
    getCityList() {
      return new Promise(async (resolve, reject) => {
        const upperType =
          this.addressData.province_id && this.addressData.province_id != '0'
            ? ''
            : 'country';
        const upperId =
          upperType != 'country'
            ? this.addressData.province_id
            : this.addressData.country_id;
        const options = {
          id: upperId,
          type: upperType,
        };
        let that = this;
        try {
          let res = await getCity(options);
          if (res.errcode == 0) {
            // if (res?.msg) {
            //   that.$message.success(res?.msg);
            // }
            // 如果是 市级运费模板只能修改区，即只展示当前的市，其他城市不可选不展示。
            if (this.freightTempType === 'city') {
              let cityArray = res.data?.filter((x) => {
                return x.id == this.addressData.city_id;
              });
              that.city_list = cityArray;
              this.getAreaList(this.addressData.city_id);
            } else {
              that.city_list = res.data;
            }
            if (that.city_list.length > 0) {
              that.changeTab('city');
              resolve(res.data);
            } else {
              that.closeDropdown();
            }
            return;
          }
        } catch (err) {
          console.error(err);
          reject(err);
        }
      });
    },
    // 获取区的数据
    getAreaList(id) {
      let option = {
        id,
        MUTE_WARNING: 1,
      };
      getRegions(option)
        .then((res) => {
          if (res.errcode == 0) {
            this.changeTab('area');
            this.areaList = res.data; // 获取到区数据
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 重置并清空数据
    resetData() {
      this.addressData = {
        country: '',
        country_id: '',
        province: '',
        province_id: '',
        city: '',
        city_id: '',
      };
      this.en_addressData = this.$options.data().en_addressData;
      this.tabName = '';
      this.city_list = [];
      this.areaList = []; // 将区数据置空
    },
    // 获取可用城市
    getAdressCities(tid) {
      let option = {
        tid,
        MUTE_WARNING: 1,
      };
      getAdressCities(option)
        .then((res) => {
          if (res.errcode == 0) {
            this.city_list = res.data;
            this.changeTab('city');
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  beforeDestroy() {
    this.resetData();
  },
  async mounted() {
    // 获取省
    this.addressData.country = this.areaData.country;
    this.addressData.country_id = this.areaData.country_id;
    this.addressData.province = this.areaData.province;
    this.addressData.province_id = this.areaData.province_id;
    this.addressData.city = this.areaData.city;
    this.addressData.city_id = this.areaData.city_id;
    this.addressData.area = this.areaData.area;
    this.addressData.area_id = this.areaData.area_id;
    if (this.chaType == 'city') {
      // 物流模板是市类型，根据订单id获取城市
      this.getAdressCities(this.tid);
    } else {
      this.getCityList();
    }
  },
};
