
import {
  notAttendTableConfig,
  underwayTableConfig,
  overTableConfig,
} from '../js/challenge_table_config';
import {
  getExplosiveContent,
  endChallenge,
} from '@/api/client-api/activity/challenge';
import ChallengeActivity from '@/pages/mall-home/home/ChallengeActivity.vue';
import dayjs from 'dayjs';
export default {
  name: 'TableList',
  components: {
    ChallengeActivity,
  },
  props: {
    // 挑战状态 1未参加 2进行中 3已结束
    challengeTab: {
      type: [Number, String],
      default: '1',
    },
    // 表格数据
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectId: '', // 选中要操作的id 可能是挑战类型id或具体挑战项id
      challengeBoxShow: false, // 挑战档位选择弹窗
      hasGiveUpBtn: false, // 是否可以放弃挑战
      challengeList: [], // 爆单挑战内容

      sureFinishDialog: false, // 二次确认结束弹窗状态

      timers: [], // 定时器数组，每一行表格对应各自的定时器
    };
  },
  computed: {
    // 表格配置
    tableFormat() {
      if (this.challengeTab == '1') {
        return notAttendTableConfig;
      }
      if (this.challengeTab == '2') {
        return underwayTableConfig;
      }
      if (this.challengeTab == '3') {
        return overTableConfig;
      }
    },
  },
  watch: {
    // 侦听父组件传过来的列表数据
    tableData: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        if (newVal && newVal.length > 0) {
          this.countDown();
        }
      },
    },
  },
  mounted() {},
  methods: {
    // 参加挑战
    joinChallenge(id, giveUpStatus) {
      this.selectId = id;
      this.hasGiveUpBtn = giveUpStatus;
      this.getChallengeContent();
    },
    // 获取爆单挑战内容
    getChallengeContent() {
      let options = {
        explosive_id: this.selectId,
        MUTE_WARNING: 1,
      };
      getExplosiveContent(options)
        .then((res) => {
          if (res?.errcode == 0 && res?.data?.list?.length != 0) {
            this.challengeList = res?.data?.list?.map((obj, index) => {
              return { ...obj, selected: false, title: '挑战' + (index + 1) };
            });
            this.challengeBoxShow = true;
          } else {
            this.$message.warning('此挑战暂无可参加档次');
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 改变某个挑战条件状态
    changeSelected(id, status) {
      this.challengeList = this.challengeList.map((challenge) => {
        // 将不是指定ID的selected属性设置为false
        if (challenge.chal_id != id && !status) {
          challenge.selected = false;
        }
        // 将指定ID的selected属性取反
        if (challenge.chal_id == id) {
          challenge.selected = !status;
        }
        return challenge;
      });
    },
    // 成功接受挑战
    acceptChallenge() {
      this.closeDialog(); // 关闭弹窗
      this.$emit('change-tab', '2');
    },
    // 关闭弹窗
    closeDialog() {
      this.challengeBoxShow = false; // 关闭参加挑战弹窗
      this.sureFinishDialog = false; // 关闭二次确认结束挑战弹窗

      this.challengeList = [];
      this.selectId = '';
    },
    // 点击结束按钮
    finishChallenge(id) {
      this.selectId = id;
      this.sureFinishDialog = true;
    },
    // 确认结束当前挑战
    sureFinishChallenge() {
      endChallenge({ challenge_id: this.selectId })
        .then((res) => {
          if (res?.errcode == 0) {
            this.closeDialog(); // 关闭弹窗
            this.$emit('change-tab', '3');
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 点击查看订单详情
    orderDetail(start, end) {
      // let startTime = start;
      let endTime;
      // 进行中
      if (this.challengeTab == '2') {
        endTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
      }
      // 已结束
      if (this.challengeTab == '3') {
        endTime = end;
      }
      let pathHerf = this.$router.resolve({
        path: '/trade/allorder', // 跳转的路径
        query: {
          start: start,
          end: endTime,
        },
      });
      window.open(pathHerf.href, '_blank');
    },
    // 倒计时
    countDown() {
      this.timers.forEach((item) => {
        if (item) {
          clearInterval(item);
          item = null;
        }
      });
      if (this.tableData && this.tableData.length > 0) {
        this.tableData?.forEach((item, index) => {
          if (
            Number(item.rest_time) &&
            item.rest_time * 1000 - Date.parse(new Date()) > 0
          ) {
            // 剩余处理时间
            let handleTime =
              item.rest_time * 1000 - Date.parse(new Date()) > 0
                ? item.rest_time * 1000 - Date.parse(new Date())
                : 0;
            const deadlineTime = this.getNowTimeDay(handleTime);

            this.$nextTick(() => {
              const countdownEl = document.getElementById(
                'countdown-timer-' + item.cha_id
              );
              if (countdownEl) {
                countdownEl.innerHTML = deadlineTime;
              }
            });
          } else {
            this.$nextTick(() => {
              const countdownEl = document.getElementById(
                'countdown-timer-' + item.cha_id
              );
              if (countdownEl) {
                countdownEl.innerHTML = `<span>--</span>`;
              }
            });
          }
        });
        this.tableData?.forEach((item, index) => {
          if (
            Number(item.rest_time) &&
            item.rest_time * 1000 - Date.parse(new Date()) > 0
          ) {
            this.timers[index] = setInterval(() => {
              // 剩余处理时间
              let handleTime =
                item.rest_time * 1000 - Date.parse(new Date()) > 0
                  ? item.rest_time * 1000 - Date.parse(new Date())
                  : 0;
              // 解决表格单元格内倒计时不动问题
              // 强行用 getElementById 获取对应 DOM 元素，强行修改其 innerHTML
              const deadlineTime = this.getNowTimeDay(handleTime);

              this.$nextTick(() => {
                const countdownEl = document.getElementById(
                  'countdown-timer-' + item.cha_id
                );
                if (countdownEl) {
                  countdownEl.innerHTML = deadlineTime;
                }
              });

              if (handleTime <= 0) {
                clearInterval(this.timers[index]);
                this.timers[index] = null;
                this.$emit('countdown-over');
              }
            }, 1000);
          } else {
            this.$nextTick(() => {
              const countdownEl = document.getElementById(
                'countdown-timer-' + item.cha_id
              );
              if (countdownEl) {
                countdownEl.innerHTML = `<span>--</span>`;
              }
            });
          }
        });
      }
    },
    //获取当前时间并计算时间差(天时分秒)
    getNowTimeDay(time) {
      var date3 = time;
      if (date3 <= 0) {
        return `<span>--</span>`;
      } else {
        //计算出相差天数
        var days = Math.floor(date3 / (24 * 3600 * 1000));
        //计算出小时数
        var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
        var hours = Math.floor(leave1 / (3600 * 1000));
        //计算相差分钟数
        var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
        var minutes = Math.floor(leave2 / (60 * 1000));
        //计算相差秒数
        var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
        var seconds = Math.round(leave3 / 1000);
        let surplusTime =
          days + '天' + hours + '小时' + minutes + '分' + seconds + '秒';
        return surplusTime;
      }
    },
    // 成功放弃挑战 close-all触发有2种，但在列表页只会有确认放弃挑战一种情况
    successGiveUp() {
      this.closeDialog(); // 关闭弹窗
      this.$emit('success-abandon');
    },
  },
};
