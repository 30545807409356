
import SearchBox from '@/components/comme/searchBox.vue';
import applyLink from './components/applyLink.vue';
import IndexBottom from '@/components/indexbottom.vue';
import friendlink from './components/friendlink.vue';
export default {
  components: {
    SearchBox,
    applyLink,
    IndexBottom,
    friendlink,
  },
};
