
export default {
  name: 'ReviewItem',
  props: {
    // 评价配置
    config: {
      type: Array,
      default: () => [
        {
          label: '处理速度：',
          prop: 'deal_speed',
          isRate: true,
        },
        {
          label: '整体评价：',
          prop: 'overall_evaluation',
          isRate: true,
        },
        {
          label: '留言：',
          prop: 'comment',
          isRate: false,
        },
      ],
    },
    data: {
      type: Object,
      default: () => {},
    },
    evaluateTime: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      colors: ['#FF7802', '#FF7802', '#FF7802'], // 评分组件颜色配置
    };
  },
  computed: {
    evaluateData() {
      if (this.evaluateTime > 0) {
        this.data.deal_speed = Number(this.data?.deal_speed)
        this.data.overall_evaluation = Number(this.data?.overall_evaluation)
        return this.data
      }
    }
  }
};
