
export default {
  name: 'RefuseDialog',
  props: {
    // 区分拒绝方案类型 supplier拒绝供应商方案 platform拒绝平台方案
    type: {
      type: String,
      default: '',
    },
    // 币种
    currency: {
      type: String,
      default: '',
    },
    // 最大退款金额
    maxRefund: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      accept: ['png', 'jpg', 'jpeg', 'gif', 'webp', 'mp4'], //上传限制
      ruleForm: {
        radio: '', // 方案
        price: '', // 退款金额
        remark: '', // 备注
        proof: '', // 证据图片/视频
      },
    };
  },
  computed: {
    // 弹窗标题
    title() {
      if (this.type == 'supplier') return '拒绝并提供新方案';
      if (this.type == 'platform') return '拒绝平台方案并提供证据';
    },
    // 验证规则
    rules() {
      // 自定义验证规则
      let checkRemark = (rule, value, callback) => {
        if (!value?.trim()) {
          callback(new Error('请输入备注'));
        }
        callback();
      };
      let supplierRule = {
        radio: [{ required: true, message: '请选择方案', trigger: 'change' }],
        price: [{ required: true, message: '请输入退款金额', trigger: 'blur' }],
      };
      let platformRule = {
        remark: [
          { required: true, message: '请输入备注', trigger: 'blur' },
          { validator: checkRemark, trigger: 'blur' },
        ],
      };
      if (this.type == 'supplier') return supplierRule;
      if (this.type == 'platform') return platformRule;
    },
  },
  methods: {
    // 输入退款金额
    changeInput(e) {
      let val = e.target.value;
      val = val
        .replace(/[^\d.]/g, '') // 数字或者小数点
        .replace(/^0+(\d)/, '$1') // 以0开头后面为数字则过滤掉0
        .replace(/^\./, '0.') // 第一位是小数点则替换成 0.
        .replace(/^(\d{6})\d+|((\.\d{2})\d+)$/g, '$1$3');

      if (Number(e.target.value) > Number(this.maxRefund)) {
        // 输入金额大于最大退款金额的时候
        this.$message.warning('请不要超过最大退款金额');
        return (this.ruleForm.price = this.maxRefund);
      } else {
        //输入金额小于退款金额的时候
        return (this.ruleForm.price = val);
      }
    },
    // 上传图片
    uploadFiles(files) {
      this.ruleForm.proof = files?.toString();
    },
    // 关闭弹窗
    closeDialog() {
      this.$emit('close-dialog');
    },
    // 提交
    submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let options = {};
          // 拒绝供应商方案
          if (this.type == 'supplier') {
            options.programme = this.ruleForm?.radio; // 售后类型：1仅退款 2退货退款
            options.refund_price = this.ruleForm?.price; // 分销商退款金额
            // 备注
            if (this.ruleForm?.remark) options.remarks = this.ruleForm?.remark;
            // 证据
            if (this.ruleForm?.proof)
              options.evidence_pic = this.ruleForm?.proof;
          }
          // 拒绝平台方案
          if (this.type == 'platform') {
            options.remark = this.ruleForm?.remark; // 备注
            if (this.ruleForm?.proof) options.proof = this.ruleForm?.proof; // 证据
          }
          this.$emit('submit-refuse', options);
        }
      });
    },
  },
};
