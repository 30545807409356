
export default {
  name: 'LookForm',
  components: {},
  props: {
    // 认证类型 1个人 2企业
    type: {
      type: String,
      default: '1',
    },
    // 认证信息
    info: {
      type: Object,
      default: () => {},
    },
    // 帐号状态 0未认证 1一次审核 2已通过 3已驳回 5二次审核
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showPreviewimg: false, // 预览弹窗标识
      imgUrl: '', // 预览图片
    };
  },
  computed: {
    // 用于展示的数组
    userInfo() {
      let personal = [
        { label: this.nameLabel, sign: 'name', value: '' },
        { label: '手机号', sign: 'phone', value: '' },
        { label: '证件号', sign: 'idcard', value: '' },
        { label: '证件有效截止日', sign: 'idcardDate', value: '' },
        {
          label: '证件正面照片',
          sign: 'idcardPositiveImg',
          value: [],
          type: 'pic',
        },
        {
          label: '证件反面照片',
          sign: 'idcardBackImg',
          value: [],
          type: 'pic',
        },
      ];
      let corporate = [
        { label: '公司名称', sign: 'companyName', value: '' },
        { label: '公司经营地址', sign: 'address', value: '' },
        { label: '营业执照有效截止日', sign: 'licenseDate', value: '' },
        { label: '统一社会信用代码', sign: 'socialCode', value: '' },
        { label: '营业执照', sign: 'licenseImg', value: [], type: 'pic' },
      ];
      let showArr = personal;
      if (this.type == '2') showArr = [...personal, ...corporate];

      showArr.forEach((item) => {
        if (this.info) {
          item.value = this.info[item.sign];
        }
      });
      return showArr;
    },
    // 姓名label
    nameLabel() {
      if (this.type == '1') return '姓名';
      if (this.type == '2') return '法人姓名';
    },
  },
  methods: {
    //预览图片回调
    showPreviewimgFunc(url) {
      this.imgUrl = this.$options.filters.imgbaseurl(url);
      this.showPreviewimg = true;
    },
    //关闭预览回调
    previewCloseFunc() {
      this.showPreviewimg = false;
    },
    // 点击升级按钮
    upgrade() {
      this.$emit('upgrade');
    },
  },
};
