/**
 * 预览远程url文件的工具集合
 * 通过远程url下载blob文件流，再通过blob文件流预览文件
 * @author hukeyi
 * @description pdf, docx, xlsx, xls, jpg, jpeg, png, txt
 */

import {
  previewImageBlob,
  previewPdfBlob,
  previewWordBlob,
  previewExcelBlob,
  previewTxtBlob,
} from './preview-blob-files';

import { uploadFile } from './utils';
import axios from 'axios';

function downloadFile(url) {
  return new Promise((resolve, reject) => {
    if (!url) {
      return resolve();
    }
    axios({
      url: url, //下载的url
      method: 'get',
      responseType: 'blob',
    })
      .then((res) => {
        const filestream = res.data; // 返回的文件流
        const blob = new Blob([filestream], {
          type: res.headers['content-type'],
        });
        return resolve(blob);
      })
      .catch((err) => {
        console.log('下载失败：', err);
        return reject(err);
      });
  });
}

/**
 * 预览图片
 * @description 支持jpg，jpeg，png
 * @param { String } filePath 文件路径
 * @param { String } containerId 预览文件要插入的dom元素的id
 * @returns { Blob } 文件流
 */
function previewImageUrl(filePath, containerId) {
  return new Promise((resolve, reject) => {
    downloadFile(filePath)
      .then((blob) => {
        previewImageBlob(blob, containerId)
          .then((res) => {
            return resolve();
          })
          .catch((err) => {
            return reject(err);
          });
      })
      .catch(function (err) {
        console.error('预览文件失败：', err);
        return reject(err);
      });
  });
}

/**
 * 预览pdf
 * @description 支持pdf文件
 * @param { String } filePath 文件路径
 * @param { String } containerId 预览文件要插入的dom元素的id
 * @returns { Blob } 文件流
 */
function previewPdfUrl(filePath, containerId) {
  return new Promise(async (resolve, reject) => {
    try {
      const blob = await downloadFile(filePath);
      await previewPdfBlob(blob, containerId);
      return resolve();
    } catch (err) {
      console.error('预览文件失败', err);
      return reject(err);
    }
  });
}

/**
 * 预览word
 * @description 支持docx文件
 * @param { String } filePath 文件路径
 * @param { String } containerId 预览文件要插入的dom元素的id
 * @returns { Blob } 文件流
 */
function previewWordUrl(filePath, containerId) {
  return new Promise(async (resolve, reject) => {
    try {
      const blob = await downloadFile(filePath);
      await previewWordBlob(blob, containerId);
      return resolve();
    } catch (err) {
      console.error('预览文件失败', err);
      return reject(err);
    }
  });
}

/**
 * 用 office 提供的预览工具进行文件预览
 * @param {*} url
 * @param {*} containerId
 */
function previewOffice(url, containerId) {
  return new Promise((resolve, reject) => {
    if (!process.client) {
      return reject();
    }
    let dom = document.getElementById(containerId);
    dom.style = 'overflow-y: hidden;';
    let containerDom = document.getElementById('file-container');
    containerDom.style = 'padding: 0px;';
    let iframeUrl = 'https://view.officeapps.live.com/op/view.aspx?src=' + url;

    let iframeDom = document.createElement('iframe');
    iframeDom.src = iframeUrl;
    iframeDom.style = 'height: 100%; width: 100%;';
    iframeDom.frameBorder = '0';
    dom.append(iframeDom);
    return resolve();
  });
}

/**
 * 预览excel（特殊处理的api，先下载文件流，再上传到七牛云获取远程链接）
 * @description 支持xlsx和xls文件
 * @param { String } filePath 文件路径
 * @param { String } containerId 预览文件要插入的dom元素的id
 * @returns { Blob } 文件流
 */
function previewExcelUrl(filePath, containerId) {
  return new Promise(async (resolve, reject) => {
    try {
      const blob = await downloadFile(filePath);
      const res = await uploadFile(blob, `库存资料-${Date.now()}.xlsx`);
      let url = process.env.NUXT_ENV.VUE_APP_SHOP_DOMAIN + res.data.link;
      await previewOffice(url, containerId);
      return resolve();
    } catch (err) {
      console.error('预览文件失败', err);
      return reject(err);
    }
  });
}

/**
 * 预览txt
 * @description 支持txt文件
 * @param { String } filePath 文件路径
 * @param { String } containerId 预览文件要插入的dom元素的id
 * @returns { Blob } 文件流
 */
function previewTxtUrl(filePath, containerId) {
  return new Promise(async (resolve, reject) => {
    try {
      const blob = await downloadFile(filePath);
      previewTxtBlob(blob, containerId);
      return resolve();
    } catch (err) {
      console.error('预览文件失败', err);
      return reject(err);
    }
  });
}

export {
  previewImageUrl,
  previewPdfUrl,
  previewWordUrl,
  previewExcelUrl,
  previewOffice,
  previewTxtUrl,
};
