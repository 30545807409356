/**
 * 专用于计算规格高亮的算法
 * 【邻接矩阵】【加权无向图】
 * 
 * 为什么不能用单纯的无向图？
 * 无向图存在公共边污染问题，会出现的bug为：
 * 
 * const specList = [
  {
    name: '品牌',
    value: ['华为', '小米', '苹果'],
  },
  {
    name: '颜色',
    value: ['绿色', '天蓝色', '白色', '黑色'],
  },
  {
    name: '尺寸',
    value: ['5.1', '5.5', '6.1', '6.7'],
  },
  {
    name: '内存',
    value: ['128G', '256G', '512G', '1T'],
  },
];

const stockedSkuList = [
  {
    id: 0,
    specs: ['华为', '黑色', '6.7', '512G'],
  },
  {
    id: 1,
    specs: ['苹果', '天蓝色', '5.5', '128G'],
  },
  {
    id: 2,
    specs: ['小米', '绿色', '6.7', '512G'],
  },
  {
    id: 3,
    specs: ['华为', '绿色', '6.7', '128G'],
  },
  {
    id: 4,
    specs: ['华为', '白色', '6.7', '256G'],
  },
  {
    id: 5,
    specs: ['苹果', '白色', '6.7', '128G'],
  },
  {
    id: 6,
    specs: ['苹果', '绿色', '6.7', '128G'],
  },
];

当用户点击 const specsS = ['华为', '黑色', '6.7', '512G'];

用不加权的无向图将会高亮：[ '华为', '绿色', '黑色', '6.7', '512G' ]
而“绿色”不应当高亮
 * 
 * 
 * 时间复杂度：o(n)
 * 空间复杂度：o(n^2)
 */

/**
 * 邻接矩阵类
 * 【规格邻接矩阵继承于此父类】
 */
class AdjoinMatrix {
  constructor(vertx) {
    this.vertex = vertx;
    this.quantity = this.vertex.length;
    this.adjoinArray = [];
    this.init();
  }
  // 初始化数组
  init() {
    this.adjoinArray = Array(this.quantity * this.quantity).fill(0);
  }

  /*
   * @param id string
   * @param sides Array<string>
   *  传入一个顶点，和当前顶点可达的顶点数组，将对应位置设置权值
   */
  setAdjoinVertexs(id, sides, weight) {
    const pIndex = this.vertex.indexOf(id);
    sides.forEach((item) => {
      const index = this.vertex.indexOf(item);
      const cur = this.adjoinArray[pIndex * this.quantity + index];
      if (typeof cur !== 'number') {
        // specList.length > 3时，存在单边多权的情况
        this.adjoinArray[pIndex * this.quantity + index]?.push(weight);
      } else if (cur > 1) {
        this.adjoinArray[pIndex * this.quantity + index] = [cur, weight];
      } else {
        this.adjoinArray[pIndex * this.quantity + index] = weight;
      }
    });
  }

  /*
   * @param id string
   * 传入顶点的值，获取该顶点的列
   */
  getVertexCol(id) {
    const index = this.vertex.indexOf(id);
    const col = [];
    this.vertex.forEach((item, pIndex) => {
      col.push(this.adjoinArray[index + this.quantity * pIndex]);
    });
    return col;
  }

  /*
   *  @param params Array<string>
   * 传入一个顶点数组，求出并集
   */
  getCollection(params) {
    const paramsVertex = params.map((id) => this.getVertexCol(id));
    let collections = [];
    paramsVertex.forEach((col, index) => {
      if (col?.some((item) => item !== 0)) {
        collections.push(params[index]);
      }
    });
    return collections;
  }

  /*
   *  @param params Array<string>
   * 传入一个顶点数组，求出交集
   */
  getUnions(params) {
    const paramsVertex = params.map((id) => this.getVertexCol(id));
    let unions = [];
    this.vertex.forEach((type, index) => {
      const row = paramsVertex.map((col) => col[index]).filter((t) => t !== 1);
      if (this.isItemEqual(row)) {
        unions.push(type);
      }
    });
    return unions;
  }

  /*
   *  @param params
   * 传入一个交集行，判断内部是否互相相等
   */
  isItemEqual(params) {
    if (params.includes(0)) return false;

    let weight = -1;

    // 找出权值
    if (params.length) {
      params?.some((t) => {
        if (typeof t === 'number') weight = t;
        return typeof t === 'number';
      });
      if (weight === -1) {
        // 都是多权边数组的情况
        return this.isArrayUnions(params);
      }
    }

    return params.every((t) => {
      if (typeof t === 'number') {
        return t === weight;
      } else {
        return t.includes(weight);
      }
    });
  }

  /*
   *  @param params
   * 传入多个数组，判断是否有交集
   */
  isArrayUnions(params) {
    if (!params.length) return false;
    return params[0]?.some((t) => {
      return params.every((_t) => _t.includes(t));
    });
  }
}

/**
 * 规格邻接矩阵类
 * 暴露出一个api，获取当前应高亮的规格数组
 */
class SpecAdjoinMatrix extends AdjoinMatrix {
  constructor(specList, specCombinationList) {
    super(
      specList.reduce((total, current) => [...total, ...current.value], [])
    );
    this.specList = specList;
    this.specCombinationList = specCombinationList;
    // 根据可选规格列表矩阵创建
    this.initSpec();
    // 同级顶点创建
    this.initSameLevel();
  }

  /**
   * 根据可选规格组合填写邻接矩阵的值
   */
  initSpec() {
    this.specCombinationList.forEach((item, index) => {
      this.fillInSpec(item.specs, index + 2); // 0用于互不相连，1用于同级，权级就从2开始
    });
  }
  // 填写同级点
  initSameLevel() {
    // 获得初始所有可选项
    const specsOption = this.getCollection(this.vertex);
    this.specList.forEach((item) => {
      const params = [];
      // 获取同级别顶点
      item.value.forEach((value) => {
        if (specsOption.includes(value)) params.push(value);
      });
      // 同级点位创建
      this.fillInSpec(params, 1);
    });
  }
  /*
   * @params
   * 传入顶点数组，查询出可选规格
   */
  getSpecscOptions(params) {
    let specOptionCanchoose = [];
    if (params?.some(Boolean)) {
      // 获取可选项（交集）
      specOptionCanchoose = this.getUnions(params.filter(Boolean));
    } else {
      // 所有可选项
      specOptionCanchoose = this.getCollection(this.vertex);
    }
    return specOptionCanchoose;
  }

  /*
   * @params
   * 填写邻接矩阵的值
   */
  fillInSpec(params, weight) {
    params.forEach((param) => {
      this.setAdjoinVertexs(param, params, weight);
    });
  }
}

export { SpecAdjoinMatrix };
