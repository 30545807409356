
import TypeChose from './components/TypeChose.vue';
import UpButtonSelect from '@/pages/login/component/UpButtonSelectionItem.vue';
import RegisterProtocolAll from '@/pages/login/component/RegisterProtocolAll.vue';

export default {
  components: { TypeChose, UpButtonSelect, RegisterProtocolAll },
  data() {
    return {
      menuList: [
        {
          name: '已有账号？',
          link: '',
          isHighlight: false,
          hideDivider: true,
        },
        {
          name: '马上登录',
          link: '/login',
          isHighlight: true,
        },
        {
          name: '用户协议',
          link: 'https://home.ximu.cn/PlatRule/79',
          isOutside: 'true',
          isHighlight: false,
        },
        // {
        //   name: '帮助中心',
        //   link: '',
        //   isOutside: 'true',
        //   isHighlight: false,
        // },
      ],
      type: '',
      showFirstSightBox: false,

      isAgreed: false,
      isAgreedSupplier: false,
      tabKey: 0,

      activeNames: [],
    };
  },
  async asyncData(app) {
    let type;
    if (app.route.name == 'Register') {
      type = '';
    }
    if (app.route.name == 'RegisterShop') {
      type = 'shop';
    }
    if (app.route.name == 'RegisterSupplier') {
      type = 'supplier';
    }
    return { type };
    // let type = 'shop';
    // // 正确高亮注册页面的标签栏
    // const path = app.route.path;
    // if (path.includes('?')) {
    //   path = path.split('?')[0];
    // }
    // const params = path.split('/');
    // type = params[params.length - 1];
    // return { type };
  },
  watch: {
    // 监听路由
    $route: {
      handler: function (newVal, oldVal) {
        if (newVal?.name == 'Register') {
          this.type = '';
          this.showFirstSightBox = false;
        } else if (newVal?.name == 'RegisterShop') {
          this.type = 'shop';
        } else if (newVal?.name == 'RegisterSupplier') {
          this.type = 'supplier';
        }
      },
    },
  },
  computed: {
    // 注册页面标题
    regiTitle() {
      if (this.type == 'shop') {
        return '分销商注册';
      }
      return '供应商注册';
    },
  },
  methods: {
    handleClick(val) {
      this.type = val;
      this.$router.push('/register/' + val);
      this.showFirstSightBox = true;
      this.isAgreed = false;
      this.isAgreedSupplier = false;
    },
    handleClose() {
      this.showFirstSightBox = false;
    },
    handleAgree() {
      this.showFirstSightBox = false;
      if (this.type === 'shop') {
        this.isAgreed = true;
      } else if (this.type === 'supplier') {
        this.isAgreedSupplier = true;
      }
      this.tabKey++;
    },
    // 判断当前用户设备是PC还是移动端
    isMobile() {
      let res = window.navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return !!res;
    },
    // 获取查询参数的字符串
    getQueryStr(queryObj) {
      if (!queryObj) return '';
      const strs = [];
      for (let [key, val] of Object.entries(queryObj)) {
        strs.push(`${key}=${val}`);
      }
      return strs.length > 0 ? '?' + strs.join('&') : '';
    },
    // 跳转到移动端注册页面 mshop - 供应商移动端注册
    goMShop(queryStr = '') {
      window.location.assign(
        'https://m.shop.ximu.cn/pages/register/register' + queryStr
      );
    },
    // 跳转到移动端注册页面 H5 - 分销商 H5 注册
    goH5Register(queryStr = '') {
      let registerPath = '/pages/login/register' + queryStr;
      window.location.assign(
        process.env.NUXT_ENV.VUE_APP_H5_SHOP_HOST + registerPath
      );
    },
    // 返回上一步选择注册类型页面
    goPrevPage() {
      this.type = '';
      this.$router.push('/register');
    },
  },
  mounted() {
    let isMobile = this.isMobile();
    if (isMobile) {
      let queryStr = this.getQueryStr(this.$route.query);
      if (this.$route.name == 'RegisterShop') {
        this.goH5Register(queryStr);
      } else if (this.$route.name == 'RegisterSupplier') {
        this.goMShop(queryStr);
      }
    }

    // 正确高亮注册页面的标签栏
    // const path = this.$route.path;

    // if (path.includes('?')) {
    //   path = path.split('?')[0];
    // }
    // const params = path.split('/');
    // this.type = params[params.length - 1];
    if (this.$route.name == 'Register') {
      this.type = '';
    }
    if (this.$route.name == 'RegisterShop') {
      this.type = 'shop';
      this.showFirstSightBox = true;
    }
    if (this.$route.name == 'RegisterSupplier') {
      this.type = 'supplier';
      this.showFirstSightBox = true;
    }
  },
};
