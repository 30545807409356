
/**
 * 多语言配置
 */
import { PLATE_CORRELATION } from './js/label_config_board';
import PlateTitle from './components/PlateTitle.vue';
import BasicScrollThree from './components/BasicScrollThree.vue';
// 变量
import {
  curDefaultLanguage,
  CUR_TOKEN_NAME,
} from '@/utils/cur_default_config.js'; //默认语言
export default {
  name: 'HotCommodity',
  components: { PlateTitle, BasicScrollThree },
  props: {
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
    // 热销商品列表
    hotCommodityList: {
      type: Array,
      default: () => [],
    },
    // 活动板块二的标题
    activeNameContent: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      token: '',
    };
  },
  computed: {
    // 标签多语言配置
    langConfig() {
      return PLATE_CORRELATION[this.lang || curDefaultLanguage];
    },
  },
  mounted() {
    this.token = this.$cookies.get(CUR_TOKEN_NAME);
  },
  methods: {
    // 收藏/取消收藏
    clickCollect(item) {
      this.$emit('collect-goods', item);
    },
  },
};
