
import UpButtonSelection from '@/pages/login/component/UpButtonSelectionItem.vue';
import UpInput from '@/pages/login/component/UpInputItem.vue';
import WideButton from '@/pages/login/component/WideButtonItem.vue';
import CircleCheckbox from '@/pages/login/component/CircleCheckboxItem.vue';
import PrivacySupplierProtocol from '@/pages/protocol/privacySupplierProtocol.vue';
import RegisterProtocol from '@/pages/protocol/registerProtocol.vue';
import SupplierStoreDepositRule from '@/pages/protocol/SupplierStoreDepositRule.vue';
import SuccessDialog from '@/pages/login/component/SuccessDialog.vue';
import { registerSupplier } from '@/api/client-api/login/api.js';
import { encryptor } from '@/utils/XmAES128Encryptor.js';
import supplierSettledProtocol from '@/pages/protocol/supplierSettledProtocol.vue';

import { mapMutations } from 'vuex';

export default {
  components: {
    UpButtonSelection,
    UpInput,
    WideButton,
    CircleCheckbox,
    PrivacySupplierProtocol,
    RegisterProtocol,
    SuccessDialog,
    supplierSettledProtocol,
    SupplierStoreDepositRule,
  },
  props: {
    isAgreedSuppliercur: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    // 国内手机号验证
    const regPhone = /^((\+|00)86)?1\d{10}$/;
    // 8~20位数字或字母，不能纯数字和纯字母
    const regPass = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z]{8,20}$/;
    const validatePhone = (rule, value, callback) => {
      if (!regPhone.test(value)) {
        callback(new Error('请输入正确的手机号码'));
      } else {
        callback();
      }
    };
    const validateInvitationCode = (rule, value, callback) => {
      if (value === '') {
        callback();
      } else if (!regPhone.test(value)) {
        callback(new Error('请输入正确的邀请码'));
      } else {
        callback();
      }
    };

    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请设置正确格式的密码'));
      } else if (!regPass.test(value)) {
        callback(new Error('请设置正确格式的密码'));
      } else {
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.registerForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else if (!regPass.test(value)) {
        callback(new Error('请设置正确格式的密码'));
      } else {
        callback();
      }
    };
    const validateProtocol = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请勾选同意协议'));
      } else {
        callback();
      }
    };
    return {
      isAgreedSupplier: this.isAgreedSuppliercur,
      registerRules: {
        phone: [{ validator: validatePhone, trigger: 'blur' }],
        code: [
          { required: true, message: '请输入正确的验证码', trigger: 'blur' },
        ],
        password: [{ validator: validatePass, trigger: 'blur' }],
        password2: [{ validator: validatePass2, trigger: 'blur' }],
        isAgreed: [
          {
            validator: validateProtocol,
            trigger: 'blur',
          },
        ],
        invitationCode: [
          { validator: validateInvitationCode, trigger: 'blur' },
        ],
      },
      registerForm: {
        phone: '',
        code: '',
        password: '',
        password2: '',
        invitationCode: '',
        isAgreed: false,
      },
      showBoxIndex: 0, // 弹出哪个协议的弹窗
      showSuccessDialog: false, // 是否显示注册成功弹窗
      registeredToken: '',
      registeredStatus: '',
    };
  },
  computed: {
    supplierRedirectPage() {
      let host = process.env.NUXT_ENV.VUE_APP_SUPPLIER_HOST;
      return `${host}/redirect`;
    },
  },
  methods: {
    ...mapMutations('login', [
      'setRegisterSupplierByKey',
      'setUserInfo',
      'setToken',
    ]),
    handleClickRadio(val) {
      console.log('clicked', val);
    },
    async submitForm() {
      try {
        // 注册方式：1扫码注册 2自然注册 3邀请码注册
        let regWay = this.registerForm.invitationCode ? '3' : '2';
        let route = this.$route;
        const postData = {
          contact_tel: this.registerForm.phone,
          code: this.registerForm.code,
          psw: encryptor.encrypt(this.registerForm.password),
          invitation_code: this.registerForm.invitationCode,
          register_source: '1', // 注册来源，1-PC端 2-移动端
          register_way: regWay,
        };

        // 如果链接有来源，拿链接上的
        if (route?.query?.source) {
          postData.source_way = route?.query?.source;
        }
        // ximu-office-uid 中有来源的情况
        if (localStorage.getItem('ximu-office-uid')) {
          let info = JSON.parse(localStorage.getItem('ximu-office-uid'));
          // 有来源的情况
          if (info?.source) {
            postData.source_way = info?.source;
          }
        }

        const res = await registerSupplier(postData);
        if (res.errcode == 0) {
          this.registeredToken = res.data.token;
          this.registeredStatus = res.data.status;
          this.$store.commit('login/clearRegisterSupplierForm'); // 清空 vuex 的缓存
          // 显示注册成功弹窗
          this.handleShowSuccessDialog();
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 跳转到账号及认证的基本信息页面
    // toBasicInfoPage(token, status) {
    //   if (!token || !status) {
    //     this.$message.error('服务器繁忙，请稍后');
    //     return;
    //   }
    //   const url = process.env.NUXT_ENV.VUE_APP_SUPPLIER_HOST;
    //   window.open(`${url}/redirect?token=${token}&status=${status}`, '_self');
    // },
    toSupplier(data) {
      let targetUrl = this.supplierRedirectPage;
      const redirectIframe = document.createElement('iframe');
      redirectIframe.style = 'display: none;';
      redirectIframe.src = targetUrl;
      let containerDom = document.getElementById('xm-register-form');
      containerDom.appendChild(redirectIframe);
      redirectIframe.onload = function (event) {
        redirectIframe.contentWindow.postMessage(data, targetUrl);
        window.open(targetUrl, '_self');
      };
    },
    handleClickNext() {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          this.submitForm();
        } else {
          return false;
        }
      });
    },
    handleCloseProtocols() {
      this.showBoxIndex = 0;
    },
    handleShowProtocols(index) {
      this.showBoxIndex = index;
    },
    handleUpdateStore(val, key) {
      this.setRegisterSupplierByKey({ key: key, value: val });
    },
    /**
     * 注册成功弹窗
     */
    handleShowSuccessDialog() {
      this.showSuccessDialog = true;
    },
    handleCloseSuccessDialog() {
      this.showSuccessDialog = false;
      const token = this.registeredToken;
      const status = this.registeredStatus;
      this.toSupplier({ token: token, status: status });
    },
  },
  mounted() {
    this.registerForm.isAgreed = this.isAgreedSupplier;

    // 记住用户上次的输入
    for (const key of Object.keys(this.registerForm)) {
      if (this.$store.state.login.registerSupplierForm[key]) {
        this.registerForm[key] =
          this.$store.state.login.registerSupplierForm[key];
      }
    }
  },
};
