
export default {
  name: 'SummaryBox',
  props: {
    // 汇总数据
    dataList: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    // 展示循环汇总数据
    summaryList() {
      let basicList = [
        { label: '提现总额', value: '', sign: 'withdrawal_amount' },
        { label: '手续费', value: '', sign: 'commission' },
        { label: '净金额', value: '', sign: 'net_amount' },
        { label: '提现中总额', value: '', sign: 'in_process_amount' },
      ];
      basicList?.forEach((item) => {
        item.value = this.dataList?.[item.sign] || '--';
      });
      return basicList;
    },
  },
};
