
import { mapGetters } from 'vuex';
export default {
  props: {
    lang: String, // 页面语言类型
    type: String, // 地址选择器的类型，warehouse | logistic
    // 传入的地址数据
    // areaData 不一定会在 country_list or province_list
    // 因此需要特殊处理
    areaData: {
      type: [Array],
      default: () => [],
    },
    continentId: String,
    placeholderKey: {
      type: String,
      default: '请选择',
    },
    itemId: String,
    skuId: String,
    wareId: String,
  },
  data() {
    return {
      handleInputSearchCountry: null,
      showDropdown: false,
      tabName: '',
      initialActive: '', // 搜索出来的符合条件的首字母
      searchTarget: '', // 用户搜索框的输入
      searchIcon: require('@/assets/images/sousuo.svg'), // 搜索图标

      country_list: [],
      province_list: [],

      defaultAreaData: null, // 为了兼容【当国家仅有中国时隐藏国家标签页】的需求，所声明的中间变量
      isChinaOnly: false, // 初始化国家列表是否有且仅有中国
      /**
       * 需要传出的数据：
       */
      addressData: {
        country: '',
        country_id: '',
        province: '',
        province_id: '',
      },
    };
  },
  computed: {
    ...mapGetters('common', ['currLang']),
    // 显示在搜索框内部的当前选中的完整地址信息
    completeAddress() {
      const addr = `${this.addressData.country}${
        this.addressData.province && this.addressData.province.length > 0
          ? '/'
          : ''
      }${this.addressData.province}`;
      return addr;
    },
    // 国家列表中是否有且仅有中国
    // 如果是，则隐藏国家标签页
    // 不能用计算属性做，只能在初始化国家列表的时候查看是否仅有中国
    // 否则会出现一个诡异的bug：如果搜索国家的时候，搜索结果只有中国，则
    // 国家标签会被瞬间隐藏，并且无法回退
    // isChinaOnly() {
    //   let firstCountryObj = this.country_list?.[0]?.list?.[0];
    //   return (
    //     this.country_list?.length === 1 &&
    //     this.country_list[0].list?.length === 1 &&
    //     firstCountryObj.id == 44
    //   );
    // },
  },
  watch: {
    wareId: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.initData();
        }
      },
      immediate: true,
    },
    skuId: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.initData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 根据 id 获取国家对象
    getCountryById(id) {
      for (let group of this.country_list) {
        if (!group) continue;
        for (let country of group.list) {
          if (country.id === id) {
            return country;
          }
        }
      }
      return null;
    },
    // 检查当前传入 areaData 的国家是否存在于国家地址列表里
    isCountryExist(areaId) {
      let list = this.country_list;
      if (!list || list?.length === 0 || !areaId) {
        return false;
      }
      // list = [{initial: 'z', list: [{...}, ...]}, {...}]
      for (let group of list) {
        let groupList = group?.list || [];
        for (let i = 0; i < groupList.length; i++) {
          if (groupList[i].id == areaId) {
            return true;
          }
        }
      }
      return false;
    },
    // 检查当前传入 areaData 的省份是否存在于省份地址列表里
    isProvinceExist(areaId) {
      let list = this.province_list;
      // 特殊处理省份地址列表为空的情况
      // 如果地址列表为空，说明当前国家无省份或者【当前国家列表仅有中国】，则无论如何都是 true
      if (list?.length === 0) {
        return true;
      }
      if (!list || !areaId) {
        return false;
      }
      for (let area of list) {
        if (area.id == areaId) {
          return true;
        }
      }
      return false;
    },
    /**
     * 验证当前 areaData 是否为合法数据
     * 当 areaData.country_id 不存在 country_list 或者
     * areaData.province_id 不存在 province_list，
     * 则 areaData 不合法，需要将其清空
     * @param { String } areaKey 'province_id' or 'country_id'
     */
    validateAreaData(areaKey, areaData) {
      let areaVal = areaData?.[areaKey];
      let hasAreaData = !!areaVal;
      // 为了减少请求数，仅当 areaData 有数据时才校验是否合法
      if (hasAreaData) {
        // 检查当前传入的地址数据是否存在地址列表中
        // 若无，则清空选中的地址数据
        let isExist =
          areaKey === 'province_id'
            ? this.isProvinceExist(areaVal)
            : this.isCountryExist(areaVal);
        if (!isExist) {
          this.clearAddressFunc();
        }
        return isExist;
      }
      return false;
    },
    //点击x清空选择地址
    clearAddressFunc() {
      this.addressData = {
        country: '',
        country_id: '',
        province: '',
        province_id: '',
      };
      this.initialActive = '';
      this.searchTarget = '';
      this.changeTab('country'); // 切换标签页
      this.province_list = [];

      this.$emit('update', this.addressData);
    },
    changeTab(val) {
      this.tabName = val;
    },

    /**
     * 用户点击下拉菜单的选项触发的函数
     *
     * index：点击的哪个菜单 -> 0：国家菜单；1：省份菜单；2：城市菜单
     * item: 点击的哪个选项
     */
    selectOption(index, item) {
      if (index == 0) {
        // 选择国家
        this.addressData.country = this.lang == 'en' ? item.name : item.cname;
        this.addressData.country_id = item.id;
        // 清空状态
        this.addressData.province = '';
        this.addressData.province_id = '';

        if (item.has_state == '1') this.getProvinceList();
        else if (item.has_state == '0') {
          this.province_list = [];
          this.$emit('update', this.addressData, 'country');
          this.closeDropdown();
        }
      } else if (index == 1) {
        // 选择省份
        this.addressData.province = this.lang == 'en' ? item.name : item.cname;
        this.addressData.province_id = item.id;
        this.$emit('update', this.addressData, 'province');
        // 可以传出数据了
        this.closeDropdown();
      }
    },
    closeDropdown() {
      this.showDropdown = false;
      this.$refs.selector && this.$refs.selector.blur(); // 选择器的尖角符号向下
    },
    // 搜索国家，高亮用户输入数据后的首字母
    async searchCountry() {
      try {
        const params = {
          id: this.continentId ? this.continentId : '',
          name: this.searchTarget,
          is_initial_group: 1,
          API_LANG_TYPE: this.lang,
          MUTE_WARNING: 1,
        };
        if (this.itemId && this.skuId) {
          params.item_id = this.itemId;
          params.sku_id = this.skuId;
        }
        if (this.wareId) {
          params.war_id = this.wareId;
        }
        const res = await this.$getCountries(params);
        if (res.errcode == 0) {
          this.country_list = res.data;
          if (res.data.length > 0) {
            this.initialActive = res.data[0].initial;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    async getCountryList() {
      return new Promise(async (resolve, reject) => {
        try {
          const params = {
            id: this.continentId ? this.continentId : '',
            is_initial_group: 1,
            API_LANG_TYPE: this.currLang,
            MUTE_WARNING: 1,
          };
          // #memo 这里的写法决定了，物流地址选择器不会传 sku_id（因为物流地址选择器不传 itemId）
          // 也就是说，sku_id 改变触动物流地址选择器重新获取地址数据最终
          // 只是想重新获取物流模版列表,其实物流地址数据是不会改变的(?) ???
          if (this.itemId && this.skuId) {
            params.item_id = this.itemId;
            params.sku_id = this.skuId;
          }
          if (this.wareId) {
            params.war_id = this.wareId;
          }
          const res = await this.$getCountries(params);
          if (res.errcode == 0) {
            this.country_list = res.data;
          } else {
            this.closeDropdown();
          }
          this.changeTab('country');
          resolve();
        } catch (err) {
          console.error(err);
          reject(err);
        }
      });
    },
    async getProvinceList() {
      // #memo 避免重复请求，无上级 id 不请求省份数据
      if (!this.addressData?.country_id) return;
      return new Promise(async (resolve, reject) => {
        try {
          const params = {
            id: this.addressData.country_id,
            API_LANG_TYPE: this.currLang,
            MUTE_WARNING: 1,
          };
          // #memo 这里的写法决定了，物流地址选择器不会传 sku_id
          // 也就是说，sku_id 改变触动物流地址选择器重新获取地址数据最终
          // 只是想重新获取物流模版列表,其实物流地址数据是不会改变的(?) ???
          if (this.itemId && this.skuId) {
            params.item_id = this.itemId;
            params.sku_id = this.skuId;
          }
          const res = await this.$getStates(params);
          if (res.errcode == 0) {
            this.province_list = res.data;
            if (this.province_list.length > 0) {
              this.changeTab('province');
            } else {
              this.closeDropdown();
            }
          } else {
            this.closeDropdown();
          }
          resolve();
        } catch (err) {
          console.error(err);
          reject(err);
        }
      });
    },
    // 重置并清空数据
    resetData() {
      this.addressData = {
        country: '',
        country_id: '',
        province: '',
        province_id: '',
      };
      this.initialActive = '';
      this.searchTarget = '';
      this.tabName = '';
      this.country_list = [];
      this.province_list = [];
    },
    // 海外分销页面，初始化数据
    // 英文版商品详情页跨境仓默认发往美国的第一个州
    // 海外仓默认发往地址选择器仓库所在国的第一个选项（若只有国家则直接展示仓库所在国）
    // 英文版商城商品详情页选择目的地无中国选项
    async initEnData() {
      let defaultCountryId = '226'; // 美国
      let isCountryExist = this.validateAreaData('country_id', {
        country_id: defaultCountryId,
      });
      if (!isCountryExist) {
        // 无美国，则默认选中国家列表第一个国家
        defaultCountryId = this.country_list?.[0]?.list[0]?.id;
      }
      let area = this.getCountryById(defaultCountryId);
      this.addressData.country = area.name;
      this.addressData.country_id = area.id;

      if (area.has_state == 1) {
        // 有省份的国家
        await this.getProvinceList();
        if (this.province_list?.length > 0) {
          let state = this.province_list[0];
          this.addressData.province = state.name;
          this.addressData.province_id = state.id;
        }
      }
      this.$emit('update', this.addressData);
    },
    async initZhData() {
      // 循环传入的地址数组 areaData，
      // 当有一个地址对象满足条件（在当前的国家列表 && 省份列表中）
      // 则跳出循环

      // 传入数据为空（=仓库地址选择器）
      // 且 默认地址数据不为空（=当前国家列表仅有中国）
      let useDefaultAreaData =
        !this.areaData?.length && this.defaultAreaData?.length > 0;
      const areas = useDefaultAreaData ? this.defaultAreaData : this.areaData;
      for (let area of areas) {
        if (!area && !area.country_id) {
          continue;
        }
        // 检查当前传入的地址数据是否存在，如果不存在，则清空传入数据
        let isCountryExist = this.validateAreaData('country_id', area);
        if (!isCountryExist) {
          this.addressData = {
            country: '',
            country_id: '',
            province: '',
            province_id: '',
          };
          this.initialActive = '';
          this.searchTarget = '';
          this.tabName = 'country';
          // 这里清空数据跟 resetData() 的区别在，不清空国家列表数据，避免重复请求
          this.province_list = [];
          continue; // 当前国家不在列表里，则继续验证下一个地址
        }
        this.addressData.country = area.country || '';
        this.addressData.country_id = area.country_id || '';
        if (this.isChinaOnly || (area.province_id && area.province_id != '0')) {
          // 国家列表有且仅有中国 或者 传入地址数据有省份，才获取省份
          await this.getProvinceList();
        }
        // 如果是无省份的国家，那么 validateAreaData() 永远返回 true
        // 特殊处理在验证函数内，此处不需要特殊处理
        let isProvinceExist = this.validateAreaData('province_id', area);
        if (!isProvinceExist) {
          this.addressData.province = '';
          this.addressData.province_id = '';
        }

        // 如果国家和省份都在列表中，则跳出循环
        if (isCountryExist && isProvinceExist) {
          this.addressData.province = area.province || '';
          this.addressData.province_id = area.province_id || '';
          this.$emit('update', this.addressData);
          return;
        }
      }
    },
    // 当前国家列表是否仅有中国
    async handleIfChinaOnly() {
      let firstCountryObj = this.country_list?.[0]?.list?.[0];
      this.isChinaOnly =
        this.country_list?.length === 1 &&
        this.country_list[0].list?.length === 1 &&
        firstCountryObj.id == 44;
      if (this.isChinaOnly) {
        let firstCountryObj = this.country_list?.[0]?.list?.[0];
        this.defaultAreaData = [
          {
            country_id: '44',
            country:
              this.lang === 'en' ? firstCountryObj.name : firstCountryObj.cname,
          },
        ];
        await this.getProvinceList();
      }
    },
    // 初始化数据
    async initData() {
      // #memo 避免物流选择器初始化时多余的请求，一定需要 wareId 才请求物流地址数据
      if (this.type === 'logistic' && !this.wareId) return;

      await this.getCountryList();
      await this.handleIfChinaOnly();

      if (this.lang === 'en') {
        this.initEnData();
      } else {
        this.initZhData();
      }
    },
  },
  mounted() {
    this.handleInputSearchCountry = this._debounce(this.searchCountry, 500);
  },
  beforeDestroy() {
    this.resetData();
  },
};
