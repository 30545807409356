
// import { delFav, addFav } from '@/api/client-spi/publicApi.js';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  name: 'BasicGoodsBox',
  props: {
    // 当前信息的对象
    goodsMsg: {
      type: Object,
      default: () => {},
    },
    // 显示隐藏价重积比
    isRadio: {
      type: Boolean,
      default: false,
    },
    /**
     * 收藏商品需要
     * 可不可以对块进行批量选择操作
     */
    currentAction: {
      type: Boolean,
      default: false,
    },
    /**
     * 传入选中的商品数组
     */
    choseGoodsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      token: '',
      checkGoods: [],
      collectFont: false,
      chosenGoods: false, // 是否是已经被选中的商品
    };
  },
  filters: {
    rateFilter(val) {
      if (!val) return '--';
      if (val == 0) {
        return 0;
      }
      if (val && val != 0) {
        return val;
      }
    },
  },
  watch: {
    // 选择的商品列表数据
    choseGoodsList: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          let checkBox = val.find((item) => item.allId === this.goodsMsg.allId);
          if (checkBox) {
            this.chosenGoods = true;
          } else this.chosenGoods = false;
        }
      },
    },
  },
  mounted() {
    this.token = this.$cookies.get(CUR_TOKEN_NAME);
  },
  methods: {
    // 点击收藏商品
    isCollectGoods(item) {
      if (this.currentAction) return;
      const options = {
        item_id: item.item_id,
      };
      // 商品为已收藏状态
      if (item.is_fav == 1) {
        this.$delFav(options)
          .then((res) => {
            if (res?.errcode === 0) {
              item.is_fav = 0;
              this.collectFont = true;
              this.$emit('up-data');
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
      // 商品为未收藏状态
      else {
        this.$addFav(options)
          .then((res) => {
            if (res?.errcode === 0) {
              item.is_fav = 1;
              this.collectFont = true;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }

      setTimeout(() => {
        this.collectFont = false;
      }, 1000);
    },
    // 跳转
    goGoodsDetail(item) {
      if (this.currentAction) return;
      // 跳转商品详情的公共函数
      this.toCachedShopdetail(
        item.item_id,
        item.image_default_id,
        item.sku_lowest_price_img,
        '_blank',
        item.publish_language_id
      );
    },
    // 批量操作 点击勾选框
    choseBox(item) {
      this.$emit('chose-block-box', item);
    },
  },
};
