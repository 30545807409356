
import SearchItem from './SearchItem.vue';
import OrderListItem from './OrderListItem.vue';
import { getUserOrderList } from '@/api/client-api/chat/chat.js';
export default {
  name: 'OrderListBlock',
  components: { OrderListItem, SearchItem },
  props: {
    // 对象的id
    otherId: {
      type: String,
      default: '',
    },
    // “我”的id
    uid: {
      type: String,
      default: '',
    },
    // 是否为细目客服
    isCS: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      orderList: [],

      pageNum: 0,
      pageSize: 10,

      loading: false,
      busy: false,
      hitBottom: false, // 是否已经划到订单列表的底部
    };
  },
  methods: {
    clearOrderList() {
      this.pageNum = 1;
      this.orderList = [];
    },
    // 获取货币标志
    getCurrencyMark(code) {
      return code == 2 ? '$' : '¥';
    },
    // 组件初始化时会调用一次 handleLoadMore
    handleLoadMore() {
      if (!this.busy) {
        this.busy = true;
        this.fetchOrderList({ pageNum: ++this.pageNum });
      }
    },
    handleSearchOrder(val = '') {
      this.clearOrderList();
      let options = {};
      if (val) {
        options.tid = val;
      }
      this.fetchOrderList(options);
    },
    handleClearSearch() {
      this.clearOrderList();
      this.fetchOrderList();
    },
    handleClickSendOrder(item) {
      if (!item) {
        if (this.isLocalEnv()) {
          console.log('[methods][handleClickSendOrder]item is null. ');
        }
        return;
      }
      const data = {
        to: this.otherId.toLowerCase(),
        from: this.uid.toLowerCase(),
        type: 'link',
        content: {
          tid: item.tid,
          pic_path: item.pic_path,
          title: item.title,
          item_id: item.item_id,
          total_price: item.total_price, // 订单总价
          created_time: item.created_time, // 订单下单时间
          itemnum: item.num, // 订单下单的商品个数（#memo 'itemnum' 字段被废弃了）
          currency: this.getCurrencyMark(item.currency_code),
        },
        dataType: 'order', // 当前发送的卡片是订单（order）还是商品（good）
        price: item.total_price, // 发送订单的总价格
      };
      if (this.isLocalEnv()) {
        console.log('[methods][handleClickSendOrder]', data);
      }
      this.$emit('send-order', data);
    },
    async fetchOrderList(params) {
      try {
        if (!this.busy) {
          this.loading = true;
        }
        const options = Object.assign(
          {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            creat_start_time: '',
            creat_end_time: '',
            title: '',
          },
          params
        );
        if (!this.isCS) {
          // 如果是细目客服，则要展示“我”的全部订单
          options.supplier_id = this.otherId;
        }
        const res = await getUserOrderList(options);
        if (res?.errcode == 0) {
          // 如果是懒加载，就与旧列表合并；
          // 如果不是，则覆盖
          setTimeout(() => {
            let newOrderList = this.busy
              ? this.orderList.concat(res.data.list)
              : res.data.list;
            this.orderList = newOrderList;
            this.$forceUpdate();
            this.loading = false;
            if (res.data?.list?.length > 0 && this.busy) {
              this.busy = false;
            }
          }, 500);
          // 当前为 1）懒加载模式；2）订单列表上面有数据；3）最新一条查询无数据
          this.hitBottom =
            res.data?.list?.length === 0 &&
            this.orderList.length > 0 &&
            this.busy;
        }
      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    },
  },
  mounted() {
    this.pageNum = 0;
    this.orderList = [];
  },
};
