
import {
  getRegions,
  getCity,
  getStates,
  getCountries,
} from '@/api/client-api/mine/mineapi.js';
export default {
  // inject: [
  //   'country',
  //   'country_id',
  //   'province',
  //   'province_id',
  //   'city',
  //   'city_id',
  //   'region',
  //   'region_id',
  // ],
  props: [
    'areaData',
    'continentId',
    'langType',
    'placeholderKey',
    'isEn',
    'en_areaData',
    'isLiveUpdate', // 是否实时更新，每选择一次地址就更新父组件数据
  ],

  data() {
    return {
      showDropdown: false,
      tabName: '',
      initialActive: '', // 搜索出来的符合条件的首字母
      searchTarget: '', // 用户搜索框的输入
      searchIcon: require('@/assets/images/sousuo.svg'), // 搜索图标
      hasRegion: false, // 是否有区

      country_list: [],
      province_list: [],
      city_list: [],
      region_list: [],
      /**
       * 需要传出的数据：
       */
      addressData: {
        country: '',
        country_id: '',
        province: '',
        province_id: '',
        city: '',
        city_id: '',
        region: '',
        region_id: '',
      },
      en_addressData: {
        en_country: '',
        en_province: '',
        en_city: '',
        en_region: '',
      },
    };
  },
  computed: {
    // 显示在搜索框内部的当前选中的完整地址信息
    completeAddress() {
      let addr = `
      ${this.addressData.province}${
        this.addressData.city && this.addressData.city.length > 0 ? '/' : ''
      }${this.addressData.city}${
        this.addressData.region && this.addressData.region.length > 0 ? '/' : ''
      }${this.addressData.region}
      `;
      if (this.continentId != '44') {
        addr = `
        ${this.addressData.country}${
          this.addressData.province && this.addressData.province.length > 0
            ? '/'
            : ''
        }${this.addressData.province}${
          this.addressData.city && this.addressData.city.length > 0 ? '/' : ''
        }${this.addressData.city}${
          this.addressData.region && this.addressData.region.length > 0
            ? '/'
            : ''
        }${this.addressData.region}
        `;
      }
      return addr.trim();
    },
  },
  methods: {
    //点击x清空选择地址
    clearAddressFunc() {
      Object.keys(this.addressData).forEach((item) => {
        this.addressData[item] = '';
      });
      this.en_addressData = this.$options.data().en_addressData;
      this.changeTab('country');
      this.$emit('update', this.addressData, this.en_addressData);
      this.hasRegion = false; // 默认不显示区

      if (this.continentId == '44') {
        this.searchProvinceList();
        this.hasRegion = true; // 有区
      }
    },
    changeTab(val) {
      this.tabName = val;
    },

    /**
     * 用户点击下拉菜单的选项触发的函数
     *
     * index：点击的哪个菜单 -> 0：国家菜单；1：省份菜单；2：城市菜单；3：区菜单
     * item: 点击的哪个选项
     */
    selectOption(index, item) {
      if (index == 0) {
        this.province_list = [];
        this.city_list = [];
        this.region_list = [];
        // 选择国家
        this.addressData.country =
          this.langType == 'en' ? item.name : item.cname;
        this.addressData.country_id = item.id;
        // 清空状态
        this.addressData.province = '';
        this.addressData.province_id = '';
        this.addressData.city = '';
        this.addressData.city_id = '';
        this.addressData.region = '';
        this.addressData.region_id = '';

        if (this.isEn) {
          this.en_addressData.en_country = item.name;
          this.en_addressData.en_province = this.en_addressData.en_city = '';
        }
        if (this.isLiveUpdate) {
          this.$emit('update', this.addressData, this.en_addressData, false);
        }

        if (item.has_state == '1') this.getProvinceList();
        else if (item.has_state == '0') this.getCityList();
        // 该国家有无区 1 有  -1 没有
        if (item.have_areas == '1') {
          this.hasRegion = true;
        } else {
          this.hasRegion = false;
        }
      } else if (index == 1) {
        this.city_list = [];
        this.region_list = [];
        // 选择省份
        this.addressData.province =
          this.langType == 'en' ? item.name : item.cname;
        this.addressData.province_id = item.id;
        // 清空状态
        this.addressData.city = '';
        this.addressData.city_id = '';
        this.addressData.region = '';
        this.addressData.region_id = '';

        if (this.isEn) {
          this.en_addressData.en_province = item.name;
          this.en_addressData.en_city = '';
        }
        if (this.isLiveUpdate) {
          this.$emit('update', this.addressData, this.en_addressData, false);
        }

        this.getCityList();
      } else if (index == 2) {
        this.region_list = [];
        // 选择城市
        this.addressData.city = this.langType == 'en' ? item.name : item.cname;
        this.addressData.city_id = item.id;
        // 清空状态
        this.addressData.region = '';
        this.addressData.region_id = '';
        if (this.isEn) {
          this.en_addressData.en_city = item.name;
          this.en_addressData.en_region = '';
        }
        if (!this.hasRegion && this.isLiveUpdate) {
          this.$emit('update', this.addressData, this.en_addressData, false);
        }
        if (!this.hasRegion) {
          // 可以传出数据了
          this.closeDropdown();
        } else {
          this.getRegionList();
        }
      } else if (index == 3) {
        this.addressData.region =
          this.langType == 'en' ? item.name : item.cname;
        this.addressData.region_id = item.id;
        if (this.isEn) {
          this.en_addressData.en_region = item.name;
        }

        // 可以传出数据了
        this.closeDropdown();
      }
    },
    closeDropdown() {
      this.$emit('update', this.addressData, this.en_addressData, true);
      this.showDropdown = false;
      this.$refs.selector && this.$refs.selector.blur(); // 选择器的尖角符号向下
    },
    // 搜索国家，高亮用户输入数据后的首字母
    async searchCountry() {
      try {
        const params = {
          API_LANG_TYPE: this.langType,
          id: this.continentId ? this.continentId : '',
          name: this.searchTarget,
          is_initial_group: 1,
          no_china: 1,
        };
        const res = await getCountries(params);
        if (res?.errcode == 0) {
          this.country_list = res?.data;
          if (res.data.length > 0) {
            this.initialActive = res.data[0].initial;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    async getCountryList() {
      try {
        const params = {
          API_LANG_TYPE: this.langType,
          // id: this.continentId ? this.continentId : '',
          is_initial_group: 1,
          no_china: 1,
        };
        const res = await getCountries(params);
        if (res?.errcode == 0) {
          this.country_list = res?.data;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async searchProvinceList() {
      try {
        const params = {
          id: 44,
          API_LANG_TYPE: this.langType,
        };
        const res = await getStates(params);
        if (res?.errcode == 0) {
          this.province_list = res?.data;
          this.changeTab('province');
        }
      } catch (err) {
        console.error(err);
      }
    },
    async getProvinceList() {
      try {
        const params = {
          id: this.addressData.country_id,
          API_LANG_TYPE: this.langType,
        };
        const res = await getStates(params);
        if (res?.errcode == 0) {
          this.province_list = res?.data;
          this.changeTab('province');
        }
      } catch (err) {
        console.error(err);
      }
    },
    async getCityList() {
      try {
        // 上级id：没有省份的国家，其城市的上级id就是国家id；否则为省份id
        const upperType =
          this.addressData.province_id && this.addressData.province_id != '0'
            ? ''
            : 'country';
        const upperId =
          upperType != 'country'
            ? this.addressData.province_id
            : this.addressData.country_id;
        const params = {
          id: upperId,
          type: upperType,
          API_LANG_TYPE: this.langType,
        };
        const res = await getCity(params);
        if (res?.errcode == 0) {
          this.city_list = res?.data;
          if (this.city_list.length > 0) {
            this.changeTab('city');
          } else {
            this.closeDropdown();
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    async getRegionList() {
      try {
        const params = {
          id: this.addressData.city_id,
          API_LANG_TYPE: this.langType,
        };
        const res = await getRegions(params);
        if (res?.errcode == 0) {
          this.region_list = res?.data;
          if (this.region_list.length > 0) {
            this.changeTab('region');
          } else {
            this.closeDropdown();
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 重置并清空数据
    resetData() {
      this.addressData = {
        country: '',
        country_id: '',
        province: '',
        province_id: '',
        city: '',
        city_id: '',
        region: '',
        region_id: '',
      };
      this.en_addressData = this.$options.data().en_addressData;
      this.initialActive = '';
      this.searchTarget = '';
      this.tabName = '';
      this.country_list = [];
      this.province_list = [];
      this.city_list = [];
      this.region_list = [];
      this.hasRegion = false;
    },
  },
  async mounted() {
    if (this.continentId == '44') {
      this.searchProvinceList();
      this.hasRegion = true; // 有区
    } else {
      this.getCountryList();
      this.changeTab('country');
    }

    if (this.country_id) {
      // 使用 provide-inject 传参
      this.addressData.country = this.country || '';
      this.addressData.country_id = this.country_id || '';
      this.addressData.province = this.province || '';
      this.addressData.province_id = this.province_id || '';
      this.addressData.city = this.city || '';
      this.addressData.city_id = this.city_id || '';
      this.addressData.region = this.region || '';
      this.addressData.region_id = this.region_id || '';

      if (
        this.addressData.region_id != '0' &&
        this.addressData.region_id != ''
      ) {
        this.hasRegion = true;
      }
    } else {
      // 若父组件传入的地址数据，是在其组件渲染后（比如，在父组件的mounted函数中向后端请求数据）
      // 才获取的，那么就应当使用props传参，参数名为 `areaData`
      // console.log(this.areaData, this.areaData.country_id, 123);
      this.addressData.country = this.areaData
        ? this.areaData.country || ''
        : '';
      this.addressData.country_id = this.areaData
        ? this.areaData.country_id
        : '';
      this.addressData.province = this.areaData
        ? this.areaData.province || ''
        : '';
      this.addressData.province_id = this.areaData
        ? this.areaData.province_id
        : '';
      this.addressData.city = this.areaData ? this.areaData.city || '' : '';
      this.addressData.city_id = this.areaData ? this.areaData.city_id : '';
      this.addressData.region = this.areaData ? this.areaData.region || '' : '';
      this.addressData.region_id = this.areaData ? this.areaData.region_id : '';

      if (
        this.addressData.region_id != '0' &&
        this.addressData.region_id != ''
      ) {
        this.hasRegion = true;
      }
    }

    if (this.addressData.province_id && this.addressData.province_id != '0') {
      // 有省份的国家，才获取省份
      const res = await this.getProvinceList();
    }
    if (this.addressData.city_id && this.addressData.city_id != '0') {
      const res = await this.getCityList();
    }
    if (
      this.addressData.region_id &&
      this.addressData.region_id != '0' &&
      this.hasRegion
    ) {
      const res = await this.getRegionList();
    }
  },
  beforeDestroy() {
    this.resetData();
  },
};
