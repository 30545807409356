
import invoiceTable from './invoiceTable.vue';
import { orderInvoiceList } from '@/api/client-api/fund/invoice.js';
export default {
  components: { invoiceTable },
  props: {
    activeName: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      optionData: [],
      timeOptions: [
        { label: '订单完成时间', value: '1' },
        { label: '申请截至时间', value: '2' },
      ],
      searchObj: {
        key: '',
        value: '',
        invoiceMaker: '', // 开票方
        state: '',
        timeType: '', // 搜索时间类型
        time: [],
      },
      tableTitle: [],
      tableList: [],
      totalnum: 0,
      tableSize: 10, // table列表
      tablePageNum: 1, // table分页
      // 待审核订单表格配置
      waitAuditTableConfig: [
        {
          label: '订单号',
          prop: 'tid',
          width: '168px',
          isedit: true,
        },
        {
          label: '开票方',
          prop: 'attribution',
          width: '80px',
          isedit: true,
        },
        {
          label: '商品名称',
          prop: 'goods_title',
          width: '80px',
          isedit: true,
        },
        {
          label: '数量',
          prop: 'goods_num',
          width: '35px',
        },
        {
          label: '商品单价(￥)',
          prop: 'goods_price',
          width: '80px',
        },
        {
          label: '操作费(￥)',
          prop: 'operation',
          width: '64px',
          isedit: true,
        },
        {
          label: '到仓物流费(¥)',
          prop: 'ware_logistics',
          width: '92px',
        },
        {
          label: '快递费(￥)',
          prop: 'logistics',
          width: '64px',
          isedit: true,
        },
        {
          label: '可开票金额(￥)',
          prop: 'invoice_amount',
          width: '88px',
        },
        {
          label: '订单完成时间',
          prop: 'finish_time',
          width: '98',
        },
        {
          label: '申请时间',
          prop: 'create_time',
          width: '98px',
        },
        {
          label: '申请单号',
          prop: 'application_id',
          width: '94px',
        },
        {
          label: '操作',
          prop: 'opera',
          width: '70px',
          isedit: true,
          fixed: 'right',
        },
      ],
      // 超时未开票订单表格配置
      overtimeTableConfig: [
        {
          label: '订单号',
          prop: 'tid',
          width: '168px',
          isedit: true,
        },
        {
          label: '开票方',
          prop: 'attribution',
          width: '100px',
          isedit: true,
        },
        {
          label: '商品名称',
          prop: 'goods_title',
          width: '100px',
          isedit: true,
        },
        {
          label: '数量',
          prop: 'goods_num',
          width: '35px',
        },
        {
          label: '商品单价(￥)',
          prop: 'goods_price',
          width: '108px',
        },
        {
          label: '操作费(￥)',
          prop: 'operation',
          width: '68px',
          isedit: true,
        },
        {
          label: '到仓物流费(¥)',
          prop: 'ware_logistics',
          width: '92px',
        },
        {
          label: '快递费(￥)',
          prop: 'logistics',
          width: '68px',
          isedit: true,
        },
        {
          label: '订单完成时间',
          prop: 'finish_time',
          width: '98',
        },
        {
          label: '申请截止时间',
          prop: 'deadline_time',
          width: '98px',
        },
        {
          label: '可开票金额(￥)',
          prop: 'invoice_amount',
          width: '98px',
        },
        {
          label: '操作',
          prop: 'opera',
          width: '70px',
          isedit: true,
          fixed: 'right',
        },
      ],
      // 待审核订单列表 下拉搜索选项
      waitAuditOption: [
        { label: '申请单号', value: 'application_id' },
        { label: '订单号', value: 'tid' },
        { label: '商品名称', value: 'goods_title' },
      ],
      // 超时未开票
      overtimeOptions: [
        { label: '订单号', value: 'tid' },
        { label: '商品名称', value: 'goods_title' },
      ],
    };
  },
  computed: {
    // 表格配置
    tableConfig() {
      return this.activeName == 3
        ? this.waitAuditTableConfig
        : this.overtimeTableConfig;
    },
    // 下拉输入搜索 选项
    selectOptions() {
      return this.activeName == 3 ? this.waitAuditOption : this.overtimeOptions;
    },
  },
  methods: {
    xmSelectData(selectValue, inputValue) {
      this.searchObj.key = selectValue;
      this.searchObj.value = inputValue;
      this.tablePageNum = 1;
      this.getTable();
    },
    selectSortList(type) {
      this.searchObj.timeType = type;
      this.tablePageNum = 1;
      this.getTable();
    },
    inviceingDetail(obj) {
      this.$router.push(`/fund/invoicingInformation/${obj.application_id}`);
    },
    // 分页
    goPage(index, pageSize) {
      this.tablePageNum = Number(index);
      if (pageSize) {
        this.tableSize = pageSize;
      }
      this.getTable();
    },
    getTable() {
      let option = {
        type: this.activeName == 3 ? '2' : '4',
        pageNum: this.tablePageNum,
        pageSize: this.tableSize,
      };
      // 存数据
      let searchData = Object.assign(
        {
          pageNum: this.tablePageNum,
          type: this.activeName == 3 ? '2' : '4',
          activeName: this.activeName,
        },
        this.searchObj
      );
      this.setPageFilter(searchData);
      // 开票方
      if (this.searchObj.invoiceMaker) {
        option.invoice_maker = this.searchObj.invoiceMaker;
      }
      if (this.searchObj.key && this.searchObj.value) {
        option[this.searchObj.key] = this.searchObj.value;
      }
      if (this.searchObj.timeType && this.searchObj.time) {
        option.time_type = this.searchObj.timeType;
        option.start_time = this.searchObj.time[0];
        option.end_time = this.searchObj.time[1];
      }
      orderInvoiceList(option)
        .then((res) => {
          if (res?.errcode == 0) {
            this.tableList = res?.data?.list;
            this.totalnum = Number(res?.data?.total) || 0;
            // 处理当前页码过大的情况
            if (this.tablePageNum > 1 && this.tableList?.length == 0) {
              this.goPage(1);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    searchChange() {
      this.tablePageNum = 1;
      this.getTable();
    },
    resetData() {
      this.searchObj = this.$options.data().searchObj;
      this.tablePageNum = 1;
      this.$refs.XmSelect.reVal();
      this.$refs.XmDatePicker.reVal();
      this.getTable();
    },
  },
  mounted() {
    // 回显
    let searchInfo = this.getPageFilterData();
    if (searchInfo) {
      this.searchObj = JSON.parse(JSON.stringify(searchInfo));
      this.tablePageNum = searchInfo?.pageNum;
      this.$nextTick(() => {
        // 下拉搜索回显
        if (this.$refs.XmSelect) {
          this.$refs.XmSelect.reVal(this.searchObj.key, this.searchObj.value);
        }
        // 下拉选择时间筛选
        if (this.$refs.XmDatePicker) {
          this.$refs.XmDatePicker.reVal(this.searchObj?.timeType || '');
        }
      });
    }
    this.getTable();
  },
};
