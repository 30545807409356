
export default {
  name: 'StarRateItem',
  props: {
    rate: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      colors: ['#FF7802', '#FF7802', '#FF7802'],
    };
  },
};
