
import leftmenu from "@/components/comme/leftmenu.vue";
export default {
  name: "mineMange",
  components: {
    leftmenu,
  },
  data() {
    return {
      leftname: "我的",
      menulist: ["地址管理", "品牌授权管理"],
      routerlist: ["/mine/addressmannge", "/mine/authorizationmanage"],
      routerIndexHash: {
        "/mine/addressmannge": 0,
        "/mine/authorizationmanage": 1,
        "/mine/brandDetails": 1,
        "/mine/nweEmpower": 1,
      },
    };
  },
  methods: {},
  //计算属性computed无法传递参数
  computed: {},
  mounted() {},
};
