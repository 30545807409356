import request from '@/utils/request.js';
// 收藏商品
//类目数据
export function getCategoryInfo(data) {
  return request({
    url: 'Category/getAllCategoryInfo',
    method: 'get',
    params: data,
  });
}
// 获取用户收藏商品列表
export function getUserFavItemList(data) {
  return request({
    apiModule: 'goods',
    url: 'fav/getUserFavItemList',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
// 获取tabs数据
export function getUserFavItemCount(data) {
  return request({
    apiModule: 'goods',
    url: '/fav/getUserFavItemCount',
    method: 'get',
    params: data,
  });
}
// 上架
export function shelfItem(data) {
  return request({
    url: 'fav/shelfItem',
    method: 'get',
    params: data,
  });
}
// 删除
export function deleteFav(data) {
  return request({
    apiModule: 'goods',
    url: 'fav/deleteFav',
    method: 'get',
    params: data,
  });
}
// 加入黑名单
export function blockFav(data) {
  return request({
    apiModule: 'goods',
    url: 'fav/blockFav',
    method: 'get',
    params: data,
  });
}
// 移出黑名单
export function pullOutBlacklist(data) {
  return request({
    apiModule: 'goods',
    url: 'fav/pullOutBlacklist',
    method: 'get',
    params: data,
  });
}
// 下架
export function unShelfItem(data) {
  return request({
    url: 'fav/unShelfItem',
    method: 'get',
    params: data,
  });
}
// 收藏
export function addFav(data) {
  return request({
    apiModule: 'goods',
    url: 'fav/addFav',
    method: 'get',
    params: data,
  });
}
// 移出收藏商品
export function recycleFav(data) {
  return request({
    apiModule: 'goods',
    url: 'fav/recycleFav',
    method: 'get',
    params: data,
  });
}
// 恢复收藏
export function recoverFav(data) {
  return request({
    apiModule: 'goods',
    url: 'fav/recoverFav',
    method: 'get',
    params: data,
  });
}
// 下载库存资料
export function exportInventoryResource(data) {
  return request({
    url: 'item/exportInventoryResource',
    method: 'get',
    params: data,
  });
}
// 查看修改记录
export function getModifyList(data) {
  return request({
    url: 'item/getModifyList',
    method: 'get',
    params: data,
  });
}
// 最近到货信息
export function getWareArrivalList(data) {
  return request({
    url: 'ShopTrade/getItemWarehouses',
    method: 'get',
    params: data,
  });
}
// 下单
// 获取下单详情
export function getItemInfo(data) {
  return request({
    url: 'fav/getItemInfo',
    method: 'get',
    params: data,
  });
}
// 获取下单详情(李)
export function shopTrade(data) {
  return request({
    url: 'ShopTrade/getItemInfo',
    method: 'get',
    params: data,
  });
}
// 获取国家列表
export function getCountries(data) {
  return request({
    url: 'user/getCountries',
    method: 'get',
    params: data,
  });
}
// 获取省列表
export function getStates(data) {
  return request({
    url: 'user/getStates',
    method: 'get',
    params: data,
  });
}
// 获取市列表
export function getCity(data) {
  return request({
    url: 'user/getCity',
    method: 'get',
    params: data,
  });
}
// 下单
export function placeOrder(data) {
  return request({
    url: 'fav/placeOrder',
    method: 'get',
    params: data,
  });
}
// 地址列表
export function getUserAddressList(data) {
  return request({
    url: 'user/getUserAddressList',
    method: 'get',
    params: data,
  });
}
// 下单-李
export function addUserOrder(data) {
  return request({
    url: '/ShopTrade/addUserOrder',
    method: 'post',
    data: data,
  });
}
// 获取热门国家
export function getHostCountries(data) {
  return request({
    url: '/ShopTrade/getHostCountries',
    method: 'get',
    params: data,
  });
}
// 获取区
export function getRegions(data) {
  return request({
    url: '/user/getRegions',
    method: 'get',
    params: data,
  });
}
// 根据商品ID拿全部sku商品
export function getUserFavItemListMore(data) {
  return request({
    apiModule: 'goods',
    url: '/fav/getUserFavItemList',
    method: 'get',
    params: data,
  });
}
