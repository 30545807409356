
// api
import { examineExtendDeliveryTime } from '@/api/client-api/trade/orderlist.js';
export default {
  name: 'HandleDelayShipDialog',
  props: {
    // 订单id
    tid: {
      type: String,
      default: '',
    },
    // 剩余发货时间
    remainingTime: {
      type: String,
      default: '',
    },
    // 申请延长天数
    applyDelayDay: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      refuseReason: '', // 拒绝理由
      errTip: false, // 错误提示
      needRefuseReason: false, // 需要拒绝理由(点击拒绝按钮后出现拒绝原因)
    };
  },
  methods: {
    // 拒绝理由有内容清除错误提示
    judgeEmpty() {
      if (this.refuseReason?.trim()) this.errTip = false;
    },
    // 同意供应商延长发货申请
    async sure() {
      try {
      } catch (err) {
        console.error(err);
      }
    },
    // 拒绝
    refuse() {
      // 初次点击拒绝按钮，出现拒绝理由输入框
      if (!this.needRefuseReason) {
        this.needRefuseReason = true;
        return;
      }
      if (!this.refuseReason?.trim()) {
        this.errTip = true;
        return;
      }
      this.handleApply('2');
    },
    /**
     * 响应供应商申请
     * @param {String} status 1通过 2驳回
     */
    async handleApply(status) {
      try {
        let options = {
          tid: this.tid,
          status,
        };
        if (status == '2') options.reason = this.refuseReason?.trim();
        const res = await examineExtendDeliveryTime(options);
        if (res?.errcode == 0) {
          if (res?.msg) this.$message.success(res?.msg);
          this.$emit('handle-success');
        } else if (res?.errcode == 20001) {
          // 需要关闭弹窗&重新获取数据
          this.$emit('handle-success');
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 关闭弹窗
    closeDialog() {
      this.$emit('close-dialog');
    },
  },
};
