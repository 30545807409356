
export default {
  name: 'leftMenu',
  props: {
    title: {
      type: String,
      default: '',
    },
    menulist: {
      type: Array,
      default: () => [],
    },
    routerlist: {
      type: Array,
      default: () => [],
    },
    /**
     * 路由-索引哈希表
     *
     * 作用：使用路由路径（e.g. `/bankCard` 查询当前路由应当高亮左侧菜单的哪一项）
     * 路由路径字符串 => 主菜单项索引
     */
    routerIndexHash: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  data() {
    return {
      activeindex: 0,
    };
  },
  methods: {
    // 过滤路由
    filterRoute(route) {
      // 拿到当前的路由路径
      let params = route?.params;
      let pathArr = route.path.split('/');
      // 有参数的情况走的地方
      for (let i in params) {
        if (params[i]) {
          // 每一次过滤给路劲数组更新
          pathArr = pathArr.filter((item) => item != params[i]);
        }
      }
      // 将路由路径转为为字符串
      return pathArr.join('/');
    },
    updataindex() {
      //   this.activeindex = this.routerlist.findIndex((item) => {
      //     return item == this.$route.path;
      //   });
      // 路由 index
      let path = this.filterRoute(this.$route);
      this.activeindex = this.routerIndexHash[path];
      if (this.activeindex == undefined || this.activeindex == -1) {
        this.activeindex = 0;
      }
    },
    changeRouter(index) {
      console.log(index, '---------index');
      if (index == 1) {
        this.$store.dispatch('old/setAuthorizationFlagIndex', 1);
      }
      this.activeindex = index;
      this.$router.push(this.routerlist[index]);
    },
  },
  //计算属性computed无法传递参数
  computed: {},
  watch: {
    $route(to) {
      //   this.activeindex = this.routerlist.findIndex((item) => {
      //     return item == to.path;
      //   });
      let targetPath = this.filterRoute(to);
      this.activeindex = this.routerIndexHash[targetPath];
      if (this.activeindex == undefined || this.activeindex == -1) {
        this.activeindex = 0;
      }
    },
    // 监听routerIndexHash变化
    routerIndexHash: {
      immediate: true,
      deep: true,
      handler(val) {
        this.updataindex();
      },
    },
  },
  mounted() {
    this.updataindex();
  },
};
