/**
 * 聊天模块
 */
import chatBox from '@/pages/chat/index.vue'; // 聊天主界面，包含会话列表
import msgBox from '@/pages/chat/MessageWindow.vue';

const chatRoutes = [
  {
    path: '/chat',
    meta: {
      isAuth: true,
      hideContactSticker: true, // 是否隐藏右下侧的“联系客服”和“客户经理”
    },
    component: chatBox,
    children: [
      {
        path: ':otherId',
        meta: {
          isAuth: true,
          hideContactSticker: true,
        },
        component: msgBox,
      },
    ],
  },
];

export default chatRoutes;
