
export default {
  name: 'XmCarousel',
  props: {
    // 轮播图底部能够显示的最大图片数量
    maxImagesNum: {
      type: Number,
      default: 5,
    },
    // 要展示的图片列表
    medias: {
      type: Array,
      default: [
        // {
        //   url: 'xxx',
        //   type: 'video' / 'image'
        // }
      ],
    },
    // 是否需要插入 alt 属性
    needAlt: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currMedia:
        this?.medias?.length > 0 ? this.medias[0] : { url: '', type: 'image' },
      currMediaIndex: 0, // 当前轮播图放大展示的图片索引

      windowStart: 0,
      windowEnd: 0,

      // hasManifier: false, // 是否有图片放大镜
    };
  },
  computed: {
    hasManifier() {
      return this.currMedia?.type === 'image';
    },
    // 当前显示在预览小图行的图片列表
    // 为什么需要这个？
    // 因为传来的 medias 的数量可能大于限制的最大图片数量
    // 此时，轮播图底部显示的预览小图行需要使用滑动窗口算法，确定当前显示的是哪5张图片
    // e.g. [0,1,2,3,4,5,6] -> 限制五张，则开始时 [【0,1,2,3,4】,5,6]
    previewWindow() {
      const start = this.windowStart;
      const end = this.windowEnd;
      const amount = this.medias.length;

      let tempArr = [];
      for (let i = start; i <= end && i < amount; i++) {
        let obj = {
          key: i,
          value: this.getMediaImgUrl(this.medias[i].type, this.medias[i].url),
          type: this.medias[i].type,
        };
        if (this.needAlt) {
          obj.alt = this.medias[i].alt;
        }
        tempArr.push(obj);
      }
      return tempArr;
    },
  },
  watch: {
    // 务必 watch imgs，否则刚进入页面，轮播主图不会显示
    // 因为 medias 在当前组件 mounted 时，还未获取到父组件传来的 medias
    medias: function (list) {
      this.currMedia = list[this.currMediaIndex];
      this.windowEnd = this.windowStart + this.maxImagesNum - 1;
    },
  },
  methods: {
    /**
     * 数据处理
     */
    // 获取媒体元素的图片地址
    // （如果是图片，就是媒体地址本身；如果是视频，就是视频第一帧）
    getMediaImgUrl(type, url) {
      if (type === 'image') {
        return url;
      } else if (type === 'video') {
        return this.getVideoPoster(url);
      } else {
        return '';
      }
    },
    //鼠标移入范围显示遮罩层以及大图
    handleMouseIn() {
      if (this.hasManifier) {
        document.querySelector('.magnifier-mask').style.display = 'block';
        document.querySelector('.carousel-enlarge-item').style.display =
          'block';
      }
    },
    //鼠标移出范围隐藏遮罩层以及大图
    handleMouseOut() {
      if (this.hasManifier) {
        document.querySelector('.magnifier-mask').style.display = 'none';
        document.querySelector('.carousel-enlarge-item').style.display = 'none';
      }
    },
    //鼠标在视图中移动改变相应位置
    handleMouseMove() {
      if (process.client) {
        if (this.hasManifier) {
          let box = document.querySelector('.carousel-main-item');
          let mask = document.querySelector('.magnifier-mask');
          let big = document.querySelector('.carousel-enlarge-item');
          var x = event.pageX - box.offsetLeft;
          var y = event.pageY - box.offsetTop;
          // 遮挡层最大移动距离
          var maskMax = box?.offsetWidth - mask?.offsetWidth;
          var maskMaxY = box?.offsetHeight - mask?.offsetHeight;

          var maskX = x - mask?.offsetWidth / 2;
          var maskY = y - mask?.offsetHeight / 2;

          // 如果x坐标小于0，就让mask停在0的位置
          if (maskX <= 0) {
            maskX = 0;
          } else if (maskX >= maskMax) {
            maskX = maskMax;
          }
          // Y坐标
          if (maskY <= 0) {
            maskY = 0;
          } else if (maskY >= maskMaxY) {
            maskY = maskMaxY;
          }
          mask.style.left = maskX + 'px';
          mask.style.top = maskY + 'px';

          // 大图
          var bigimg = document.querySelector('.carousel-enlarge-item>img');
          // 大图可移动距离等于图片 - (大盒)容器宽度
          var bigmax = bigimg?.offsetWidth - big?.offsetWidth;
          var bigmaxY = bigimg?.offsetHeight - big?.offsetHeight;
          // 大图的移动距离,根据公式: 遮挡层/遮挡层最大移动距离 = 大图片移动距离/大图片最大移动距离
          var bigX = (maskX * bigmax) / maskMax;
          var bigY = (maskY * bigmaxY) / maskMaxY;
          bigimg.style.left = -bigX + 'px';
          bigimg.style.top = -bigY + 'px';
        }
      }
    },
    // 鼠标在轮播主图上移动位置，放大相应位置的图片部分
    handleMouseOverImg(index) {
      this.handleUpdateCurrentImgByIndex(index);
    },
    //点击左右箭头切换图片
    // 点击左箭头，图片左移，图片索引减一
    // 点击右箭头，图片右移，图片索引加一
    handleClickArrow(direction) {
      const [nextIndex, start, end] = this.handleMovePreviewImgs(direction);
      this.windowStart = start;
      this.windowEnd = end;
      this.handleUpdateCurrentImgByIndex(nextIndex);
    },
    handleMovePreviewImgs(direction) {
      // limit 为取模运算的除数，即当前的图片数量
      let limit = this.medias.length;
      let currIndex = this.currMediaIndex;
      // 利用取模运算计算左/右移动后图片的索引
      let nextIndex =
        direction == 'left'
          ? (currIndex - 1 + limit) % limit
          : (currIndex + 1) % limit;

      let [s, e] = this.handleCalculateWindow(nextIndex);

      return [nextIndex, s, e];
    },
    handleCalculateWindow(nextIndex) {
      let start = this.windowStart,
        end = this.windowEnd,
        size = this.maxImagesNum;
      if (nextIndex > end) {
        // 假设窗口已经移动到最后，则往前移动
        end = nextIndex;
        start = end - size + 1;
      } else if (nextIndex < start) {
        start = nextIndex;
        end = start + size - 1;
      }
      return [start, end];
    },
    // 根据索引更改当前轮播主图的图片
    // 不使用下排的预览小图
    handleUpdateCurrentImgByIndex(index) {
      this.currMediaIndex = index;
      this.currMedia = this.medias[index];
    },
    // 根据图片路径更改当前轮播主图的图片
    handleUpdateCurrentImgByUrl(url) {
      if (url) {
        this.currMedia = {
          url,
          type: 'image',
        };
        this.currMediaIndex = -1; // 避免下排的预览小图的错误高亮
      } else {
        // 如果传入的url为空，则默认展示回预览组图的第一张图片
        this.handleUpdateCurrentImgByIndex(0);
      }
    },
    handleClickPlayBtn() {
      this.$refs.myCarouselVideoPlayer?.handleClickPlayBtn();
    },
  },
  mounted() {
    // 默认展示第一张图片
    // 这里不一定能拿到值（比如 medias 是父组件从后端接口拿值后再传给当前组件的）。
    // 如果没拿到，则到 watch 中拿
    this.handleUpdateCurrentImgByIndex(0);
  },
};
