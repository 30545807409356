
import { CS_ROUTE_NAME } from '@/utils/chat_tools.js';
export default {
  name: 'XmContact',
  props: {
    type: {
      type: [String, Number],
      default: '1', // 1：分销商；2：供应商，跳转哪个系统的聊天界面
    },
    // 账号是否被限制（被冻结 or 被关闭）
    isLimitedAccount: {
      type: Boolean,
      default: false,
    },
    // 重定向到供应商聊天界面需要的数据
    redirectData: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: '联系客服',
    },
    labelColor: {
      type: String,
      default: '#666666',
    },
    // 联系人的账号id
    contactId: {
      type: String,
      default: CS_ROUTE_NAME, // 默认为客服
    },
    // 是否显示聊天icon
    showIcon: {
      type: Boolean,
      default: true,
    },
    // 聊天图标的类型。1：橙色方框；2：镂空椭圆
    iconType: {
      type: String,
      default: '1',
    },
    // flex对齐方式
    align: {
      type: String,
      default: 'center',
    },
    // flex 主轴方向
    direction: {
      type: String,
      default: 'row',
    },
    // “正在咨询”的商品id
    itemId: {
      type: String,
      default: '',
    },
    // 正在咨询的订单id
    orderId: {
      type: String,
      default: '',
    },
    // 查询的链接数组
    params: {
      type: Array,
      default: () => [],
    },
    // 文字跳转店铺列表,默认否
    labelJumpShopList: {
      type: Boolean,
      default: false,
    },
    // 文字悬浮变主题色
    labelHoverColor: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // 供应商重定向页面
    supplierRedirectPage() {
      let host = process.env.NUXT_ENV.VUE_APP_SUPPLIER_HOST;
      return `${host}/redirect`;
    },
  },
  methods: {
    toChat(path) {
      if (this.type == 2) {
        // 跳转供应商聊天
        // #memo 当供应商账号被冻结或关闭，分销商登录界面会出现一个联系客服的按钮
        // 点击按钮会从分销商跳转到供应商聊天，所以在这里加一个跳转函数
        if (!this.redirectData) {
          console.error('缺少跳转 supplier 的 postMessage 参数');
          return;
        }
        let data = this.redirectData;
        if (this.isLimitedAccount) {
          data.isLimitedAccount = 1; // 是否为被限制的账号（冻结 or 关闭）
        }
        let targetUrl = this.supplierRedirectPage;
        const redirectIframe = document.createElement('iframe');
        redirectIframe.style = 'display: none;';
        redirectIframe.src = targetUrl;
        let containerDom = document.getElementById('xm-contact-element');
        containerDom.appendChild(redirectIframe);
        redirectIframe.onload = function (event) {
          redirectIframe.contentWindow.postMessage(data, targetUrl);
          window.open(targetUrl, '_self');
        };
      } else {
        // 跳转分销商
        let routeData = this.$router.resolve({ path });
        window.open(routeData.href, '_blank');
      }
    },
    /**
     * 点击“联系客服/分销商/供应商”，跳转相应聊天页面
     * @param {String} type 值为label代表是点击label触发的
     */
    handleClick(type) {
      if (!this.contactId) {
        return;
      }
      // 点击文字 && 点击文字跳转店铺列表
      if (type == 'label' && this.labelJumpShopList) {
        this.openNewWindow('/shop_' + this.contactId + '.html');
        return;
      }
      let path = '/chat/' + this.contactId.toLowerCase();

      let paramArr = [];
      // 传入商品Id
      if (this.itemId) {
        paramArr.push(`item_id=${this.itemId}`);
      }
      // 传入订单id
      if (this.orderId) {
        paramArr.push(`order_id=${this.orderId}`);
      }
      // 传入参数数组
      for (let paramItem of this.params) {
        let key = paramItem.key,
          val = paramItem.value;
        paramArr.push(`${key}=${val}`);
      }
      if (paramArr.length > 0) {
        path += '?';
        path += paramArr.join('&');
      }
      this.toChat(path);
    },
  },
};
