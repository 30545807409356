
import dayjs from 'dayjs';
export default {
  name: 'XmDatePicker',
  data() {
    return {
      selectValue: '', // 选项的val
      // inputTime: [], // 时间范围搜索
    };
  },
  props: {
    value: {},
    width: {
      // 选择器的宽度
      type: Number,
      default: 112,
    },
    inputWidth: {
      // input的宽度
      type: Number,
      default: 157,
    },
    optionData: {
      // 选项数据
      type: Array,
      default: () => [],
    },
    defaultVal: {
      // 下拉框默认展示的选项值（defaultVal和placeholder二选一即可）
      type: String,
      default: '',
    },
    placeholder: {
      // 下拉选择的placeholder
      type: String,
      default: '请选择',
    },
    inputPlaceholder: {
      // input框的placehoder
      type: String,
      default: '关键词',
    },
    // 控制是否要下拉框
    isSelect: {
      type: Boolean,
      default: true,
    },
    // 控制需要什么时间范围
    // 1今天及今天之前 2今天及今天以后 3今天之前 4今天之后 不传不做限制
    timeOption: {
      type: Number,
      default: 0,
    },
    // 开始默认值
    startPlaceholder: {
      type: String,
      default: '开始时间',
    },
    // 结束默认值
    endPlaceholder: {
      type: String,
      default: '结束时间',
    },
    // 是否拼接时分秒
    hasDefaultTime: {
      type: Boolean,
      default: true,
    },
    // 是否可清除 （下拉选项）
    clearable: {
      type: Boolean,
      default: false,
    },
    // 是否可清除 （时间选择器）
    clearableDate: {
      type: Boolean,
      default: true,
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // el-select下拉框样式名称
    popperClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    // 组件v-model绑定的时间数组
    inputTime: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    pickerOptions() {
      return {
        disabledDate: (time) => {
          let nowDate = parseInt(dayjs().format('YYYYMMDD')),
            thenDate = parseInt(dayjs(time).format('YYYYMMDD'));
          switch (this.timeOption) {
            case 1:
              return thenDate > nowDate;
            case 2:
              return thenDate < nowDate;
            case 3:
              return thenDate >= nowDate;
            case 4:
              return thenDate <= nowDate;
            default:
              break;
          }
        },
      };
    },
  },
  mounted() {
    if (this.defaultVal && this.defaultVal != '') {
      this.val = this.defaultVal;
    }
  },
  methods: {
    // 传值出去的方法
    onSearch() {
      if (this.selectValue === '') {
        if (this.optionData && this.optionData.length > 0) {
          this.selectValue = this.optionData[0].value;
        }
      }
      this.$emit('change', this.selectValue);
    },
    // 切换下拉框值的方法
    onChange() {
      this.inputTime = [];
    },
    // 点击清空按钮的方法
    clearVal() {
      this.inputTime = [];
      this.selectValue = '';
      this.$emit('change', this.selectValue);
    },
    // 父组件点击重置方法
    // TODO 后续优化的点之一
    /**
     * @param {String} selectVaule 下拉框对应的值
     * 这里是为了父组件能够重置子组件中的数据
     * 也可以用作数据的回显
     */
    reVal(selectValue = '') {
      this.selectValue = selectValue;
    },
  },
};
