import request from '@/utils/request.js';

//地址管理列表
export function getSupAddrList(data) {
  return request({
    apiModule: 'user',
    url: '/address/distributorAddrList',
    method: 'get',
    params: data,
    controller: {
      openLoading: data?.openLoading || false,
    },
  });
}

// 地址管理详情
export function getSupAddrInfo(data) {
  return request({
    url: '/address/getDistributorAddrInfo',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

// 地址管理新增
export function addSupAddr(data) {
  return request({
    apiModule: 'user',
    url: '/address/addDistributorAddr',
    method: 'post',
    data: data,
    controller: {
      openLoading: data?.openLoading || false,
    },
  });
}

// 地址管理编辑
export function editSupAddr(data) {
  return request({
    apiModule: 'user',
    url: '/address/editDistributorAddr',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

// 获取手机地址区号
export function getPhoneCode(data) {
  return request({
    apiModule: 'user',
    url: '/address/getPhoneCode',
    method: 'get',
    params: data,
  });
}

// 地址删除
export function delSupAddr(data) {
  return request({
    apiModule: 'user',
    url: '/address/delDistributorAddr',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

// 设置默认地址
export function setSupAddr(data) {
  return request({
    apiModule: 'user',
    url: '/address/setDistributorAddr',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

// 品牌授权列表
export function authList(data) {
  return request({
    url: '/BrandAuthorization/getAuthInfoList',
    apiModule: 'goods',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 详情
export function getInfo(data) {
  return request({
    url: '/BrandAuthorization/getInfo',
    apiModule: 'goods',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
// 获取被授权方
export function getUserInfo(data) {
  return request({
    url: '/BrandAuthorization/getUserInfo',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 产品ID搜索
export function getItemInfo(data) {
  return request({
    url: 'AuthBookRe/getItemInfo',
    method: 'get',
    params: data,
  });
}

// 申请品牌授权
export function authAdd(data) {
  return request({
    url: '/BrandAuthorization/addAuth',
    apiModule: 'goods',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
// 获取可售所有平台
export function getSlaePlatformList(data) {
  return request({
    url: '/BrandAuthorization/getPlatformList',
    method: 'get',
    apiModule: 'goods',
    params: data,
  });
}
// 获取供应商id和供应商名称
export function getSupplierBaseInfo(data) {
  return request({
    url: '/BrandAuthorization/getSupplierBaseInfo',
    method: 'get',
    params: data,
  });
}

// 获取供应商id和供应商名称
export function getBrandList(data) {
  return request({
    url: '/BrandAuthorization/getBrandList',
    apiModule: 'goods',
    method: 'get',
    params: data,
  });
}

// 获取国家列表
export function getCountries(data) {
  return request({
    url: 'user/getCountries',
    method: 'get',
    params: data,
  });
}
// 获取省列表
export function getStates(data) {
  return request({
    url: 'user/getStates',
    method: 'get',
    params: data,
  });
}
// 获取市列表
export function getCity(data) {
  return request({
    url: 'user/getCity',
    method: 'get',
    params: data,
  });
}
// 获取区列表
export function getRegions(data) {
  return request({
    url: 'user/getRegions',
    method: 'get',
    params: data,
  });
}

// 获取洲列表
export function getContinents(data) {
  return request({
    url: '/user/getContinents',
    method: 'get',
    params: data,
  });
}
// 删除授权书
export function distributorAuthdelete(data) {
  return request({
    url: '/AuthBookRe/distributorAuthdelete',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
// 编辑授权书
export function authEdit(data) {
  return request({
    url: '/AuthBookRe/authEdit',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
// 编辑授权书
export function cancelBrand(data) {
  return request({
    url: '/BrandAuthorization/cancel',
    apiModule: 'goods',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

// 自动识别地址
export function identifyAddress(data) {
  return request({
    url: '/info/identifyAddress',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
