
import axios from 'axios';
export default {
  name: 'DownloadFileDialog',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    // 中文资源  .zip
    cnProfile: {
      type: String,
      default: '',
    },
    // 英文资源 .zip
    enProfile: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog');
    },

    // 下载
    handleDownload(url, fileName) {
      axios({
        url: url, //下载的url
        method: 'get',
        responseType: 'blob',
      })
        .then((res) => {
          const filestream = res.data; // 返回的文件流
          const blob = new Blob([filestream], {
            type: res.headers['content-type'],
          });
          if (!process.client) {
            return;
          }
          const a = document.createElement('a');
          const href = window.URL.createObjectURL(blob); // 创建下载连接
          a.href = href;
          fileName = `${fileName}_${Date.now()}`;
          a.download = decodeURI(fileName);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a); // 下载完移除元素
          //   document.bodyNaNpxoveChild(a); // 下载完移除元素
          window.URL.revokeObjectURL(href); // 释放掉blob对象
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
