/**
 * 用于过滤接口请求传参的工具
 * @author hukeyi
 * @description 有一些特殊的统一处理，需要通过请求传参，给请求报文头加参数
 * 比如不弹出黄色警告、接口响应的语言等
 */

const paramsMap = [
  {
    // 接口响应的语言
    paramsName: 'API_LANG_TYPE', // 在请求传参中的字段名
    headerPropName: 'language', // 在请求报文中的字段名
    // 转换操作
     // 转换操作#TODO 这里要不要引用默认语言的js文件？
    handler: function (data) {
      let str = 'en'
      return str?.toUpperCase();
    },
  },
  {
    // 是否关闭黄色的警告（当 errcode 异常时）
    paramsName: 'MUTE_WARNING',
    headerPropName: 'mute_msg_warning',
    handler: function (data) {
      return data;
    },
  },
  {
    // 是否关闭红色的报错
    paramsName: 'MUTE_ERROR',
    headerPropName: 'mute_msg_error',
    handler: function (data) {
      return data;
    },
  },
  {
    // 返回的响应中，不能为空的字段名称（逗号分隔字符串）放到 headers 中
    paramsName: 'NON_EMPTY_TARGETS',
    headerPropName: 'non_empty_targets',
    handler: function (data) {
      return data;
    },
  },
];

/**
 * 将 params 中特殊的字段转换到 headers 中
 * @param { Object } params
 * @param { Object } headers
 */
export function filterReqParams(params, headers) {
  for (let config of paramsMap) {
    let { paramsName, headerPropName, handler } = config;
    if (params?.[paramsName]) {
      headers[headerPropName] = handler(params?.[paramsName]);
      delete params[paramsName];
    }
  }
}
