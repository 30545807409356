
import {
  getRefundDetail,
  getPayDetail,
} from '@/api/client-api/trade/orderlist.js';
import { applyRefund } from '@/api/client-api/trade/disputes'; // 申请退款
import RefundMessageDialog from '../components/RefundMessageDialog.vue';
export default {
  components: { RefundMessageDialog },
  name: 'applyForrefund',
  data() {
    return {
      currency: '', // 币种符号
      item_tid: '', // 订单号
      orderList: [], // 订单信息(表格展示)
      orderMsg: {
        return_policy: {
          supplier_problem: [],
          user_problem: [],
        },
      }, // 订单信息(表单展示)
      reasonOpt: [
        { label: '货不对版', value: '1' },
        { label: '商品与描述不符', value: '2' },
        { label: '商品质量问题', value: '3' },
        { label: '商品缺少配件', value: '4' },
        { label: '未收到货物', value: '5' },
        { label: '物流时间太慢', value: '6' },
        { label: '假冒品牌', value: '7' },
        { label: '商品破损', value: '8' },
        { label: '未履行送/装服务', value: '9' },
        { label: '误购', value: '10' },
        { label: '其他', value: '11' },
      ], //提交原因
      // 表单信息
      formobj: {
        aftersales_order_type: '1',
        num: 1,
        reason: '',
        description: '',
        imgList: '',
      },
      formReasonTip: false, // 提交原因错误提示信息
      isNotSelectNum: false, // 是否不可选择退款商品数量(未发货商品不可选择数量)
      refundAmountMsg: {}, // 退款金额信息
      supplier_id: '',
      uploadAcceptFile: '.jpg, .png, .jpeg, .mp4', // 上传文件的限制格式
      refundMsgDialog: false, // 退款信息弹窗状态
    };
  },
  watch: {
    'formobj.reason'(val) {
      if (val !== '' && val !== undefined && val !== null) {
        this.formReasonTip = false;
      }
    },
  },
  mounted() {
    this.item_tid = this.$route.query.item_id;
    this.supplier_id = this.$route.query.supplier_id;
    if (this.item_tid != '') {
      this.getRefundGoodsDetail();
    }
    // this.getRefundOrderDetail();
  },
  filters: {
    // 空字符占位
    hasValue(val) {
      if (val && val != '') {
        return val;
      } else return '--';
    },
  },
  methods: {
    // 获取退款详情
    getRefundGoodsDetail() {
      let options = {
        tid: this.item_tid,
      };
      getRefundDetail(options)
        .then((res) => {
          if (res.errcode == 0) {
            this.orderMsg = res.data.list;
            this.orderList.push(res.data.list);
            this.currency = res?.data?.list?.currency || '￥'; // 币种符号
            // 处理供应商问题数据格式
            if (this.orderMsg.return_policy.supplier_problem) {
              this.orderMsg.return_policy.supplier_problem =
                this.orderMsg.return_policy.supplier_problem
                  .toString()
                  .replace(',', '；') + '；';
            }

            // 处理分销商问题数据格式
            if (this.orderMsg.return_policy.user_problem) {
              this.orderMsg.return_policy.user_problem =
                this.orderMsg.return_policy.user_problem
                  .toString()
                  .replace(',', '；') + '；';
            }
            // 判断是否是未发货订单 is_send:是否已发货  1-是 -1否
            if (res?.data?.list?.is_send == 0) {
              this.isNotSelectNum = true;
              this.formobj.num = Number(res.data.list.num);
              this.getRefundOrderDetail();
            } else {
              this.isNotSelectNum = false;
              this.getRefundOrderDetail();
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取退货/款 支付明细
    getRefundOrderDetail() {
      let options = {
        tid: this.item_tid,
        num: this.formobj.num,
      };
      getPayDetail(options)
        .then((res) => {
          if (res.errcode == 0) {
            this.refundAmountMsg = res.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    //确认提交
    async submit() {
      try {
        if (this.formobj.reason == '' || !this.formobj.reason) {
          this.formReasonTip = true;
          return;
        }
        let options = {
          tid: this.item_tid,
          aftersales_order_type: this.formobj.aftersales_order_type,
          num: this.formobj.num, //退货数量
          type: this.formobj.reason,
        };
        if (this.formobj.description.trim() !== '') {
          options.description = this.formobj.description.trim();
        }
        if (this.formobj.imgList !== '') {
          options.evidence_pic = this.formobj.imgList;
        }
        const res = await applyRefund(options);
        if (res.errcode == 0) {
          this.$message.success('操作成功');
          this.$router.push('/trade/disputeslist');
        }
      } catch (err) {
        console.error(err);
      }
    },
    backUp() {
      // this.$router.go(-1);
      this.$router.push('/trade');
    },
    // 点击售后政策跳转规则中心页面
    toPlatRule() {
      if (process.env.NUXT_ENV.NODE_ENV == 'production') {
        window.location.href = 'https://home.ximu.cn/PlatRule/60';
      } else if (process.env.NUXT_ENV.NODE_ENV == 'test') {
        window.location.href = 'http://192.168.2.188:8006/PlatRule';
      } else {
        window.open('http://192.168.2.188:8006/PlatRule');
      }
    },
    // 选择退款数量
    handleChoseNum() {
      this.getRefundOrderDetail();
    },
    // 获取上传文件信息
    uploadFile(file) {
      this.formobj.imgList = file;
    },
    // 打开退款金额详细数据弹窗
    openRefundDialog() {
      this.refundMsgDialog = true;
    },
    // 关闭退款金额详细数据弹窗
    closeRefundDialog() {
      this.refundMsgDialog = false;
    },
  },
};
