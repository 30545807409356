
import { complaintsAppeals } from '@/api/client-api/complaints/complaints.js';
export default {
  data() {
    return {
      // 弹框的值
      ruleForm: {
        appealGrounds: '', //申诉理由
        file: '', //上传的文件
      },
      rules: {
        appealGrounds: [
          { required: true, message: '申诉理由不能为空', trigger: 'blur' },
        ],
      },
      // 上传资料相关
      acceptFile: '.jpg,.png,.jpeg,.mp4,.pdf',
    };
  },
  props: {
    complaintId: {
      type: String,
      default: '',
    },
  },
  mounted() {},
  methods: {
    // 上传资料事件
    uploadFile(list) {
      this.ruleForm.file = list;
    },
    // 弹窗确认按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 校验成功走这
          this.lodgeComplaint();
        } else {
          // 校验失败走这
          return false;
        }
      });
    },
    // 申诉
    async lodgeComplaint() {
      try {
        const options = {
          complaint_id: this.complaintId, //举报/投诉ID
          appeal_reason: this.ruleForm.appealGrounds, //申诉理由
          auxiliary_material: this.ruleForm.file, //上传的附件
        };
        let res = await complaintsAppeals(options);
        if (res) {
          if (res?.errcode == 0) {
            if (res?.msg) {
              this.$message.success(res?.msg);
            }
            this.$emit('submit');
          }
        }
      } catch (err) {
        console.error(err);
      }
    },

    // 取消按钮事件
    cancelBtn() {
      this.$emit('cancel');
    },
  },
};
