
export default {
  props: {
    returnGoodsPolicy: {
      type: Object,
      default: () => {},
    },
    isAuthentication: {
      type: [String, Array],
      default: '',
    },
    isDelist: {
      type: String,
      default: '',
    },
    // 商品失效状态 0未失效 1供应商已退店 2仅已下架
    failureState: {
      type: [String, Number],
      default: '0',
    },
  },
};
