/**
 * 前台活动模块
 * -
 */

import PlatformSubsidy from '../pages/activity/platform-subsidy'; // 平台补贴
import FestivalActivity from '../pages/activity/festival-activity'; // 新春活动
import GoodsActivity from '@/pages/activity/goods-subsidy-activity/index.vue'; // 商品补贴展示模块

const fontActivityRouter = [
  // 平台补贴
  {
    path: '/activity/platform',
    component: PlatformSubsidy,
  },
  // 新春活动
  {
    path: '/activity/festival',
    component: FestivalActivity,
  },
  // 商品补贴展示模块
   {
    path: '/activity/goods',
    component: GoodsActivity,
  },
];

export default fontActivityRouter;
