
export default {
  data() {
    return {
      reason: '',
    };
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    // 是否编辑
    isEditor: {
      type: Boolean,
      default: false,
    },
    // 是否必填
    isRequired: {
      type: Boolean,
      default: false,
    },
    // 接口
    interface: {
      type: Function,
      default: () => {},
    },
    // 得到的数据
    info: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    // 驳回确认按钮
    async submitForm() {
      try {
        if (this.reason == '') {
          this.$message.warning('驳回原因不能为空');
          return;
        }
        const options = {
          id: this.info.id,
          appeal_status: '4',
          reject_reason: this.reason,
        };
        let res = await this.interface(options);
        if (res) {
          if (res?.errcode == 200) {
            if (res?.msg) {
              this.$message.success(res?.msg);
            }
            this.$emit('submit');
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 驳回取消按钮
    cancelBtn() {
      this.$emit('cancel');
    },
  },
};
