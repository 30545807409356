
import { cancelStorecancellationApply } from '@/api/client-api/account/logOutAccount.js';
export default {
  name: 'applyRefund',
  props: {
    // 账号相关信息
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      infoData: {}, // 用户信息
      infoList: [], // 退店待处理表格信息
      waitList: [], // 未处理完成事项
    };
  },
  mounted() {},
  watch: {
    info: {
      immediate: true,
      deep: true,
      handler(val) {
        this.infoData = JSON.parse(JSON.stringify(val || {}));

        this.infoList = []; // 重置一下
        for (let i in val) {
          this.infoList?.push(val[i]);
        }
        // 无待处理事项跳转材料审核
        if (this.infoList?.length != 0) {
          // this.next();
        }
      },
    },
  },
  methods: {
    // 跳转异常处理
    handlePro(waitPro, index) {
      if (waitPro?.abnormal) {
        switch (Number(index)) {
          case 0:
            // 投诉异常
            this.$router.push('/complaints/complaintslist');
            break;
          case 1:
            // 资金异常
            this.$router.push('/fund/fundcenter/1');
            break;
          case 2:
            // 订单异常
            this.$router.push('/trade');
            break;
          case 3:
            // 锁货异常
            break;
          default:
            break;
        }
      }
    },
    // 无待处理事项跳转材料审核
    next() {
      this.infoList?.map((item) => {
        if (item?.abnormal) {
          this.waitList?.push(item);
        }
      });
      if (this.waitList?.length == 0) {
        this.$emit('go-next', 2);
      }
    },
    // 撤销账号注销申请
    withdrawn() {
      cancelStorecancellationApply()
        .then((res) => {
          if (res?.errcode == 0) {
            if (res?.msg) {
              this.$message.success(res?.msg);
            }
            this.$emit('go-next', 0);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
