
import SearchBox from '@/components/comme/searchBox.vue';
import MainContent from './components/MainContent.vue';
import IndexBottom from '@/components/indexbottom.vue';
import BackToTop from '@/components/BackToTop.vue';
import { getMallSubsidyItemList } from '@/api/client-api/activity/activity';
import { mapGetters } from 'vuex';
export default {
  name: 'FestivalActivity',
  components: {
    SearchBox,
    MainContent,
    IndexBottom,
    BackToTop,
  },
  async asyncData(app) {
    let lang = app.store.state.common.currLanguage;
    // 商品列表
    let activityGoodsList = {
      plateTwo: [], // 板块二
      plateThree: [], // 板块三
      plateFour: [], // 板块四
      plateFive: [], // 板块五
    };
    // 需要分页数据的总页数
    let goodsListTotalPage = {
      plateTwo: 0, // 板块二
      plateThree: 0, // 板块三
      plateFour: 0, // 板块四
      plateFive: 0, // 板块五
    };
    let optionsGoods = {
      region: '1',
      pageSize: 8, // 页大小
      pageNum: 1, // 页码
      API_LANG_TYPE: lang,
      MUTE_WARNING: 1,
    };
    try {
      // 3 板块一 4 板块二 5 板块三 6板块4 7商城首页轮播 （后端接口）
      const [resPlateTwo, resPlateThree, resPlateFour, resPlateFive] =
        await Promise.all([
          // 板块二（前端页面）潮流新方向（数码）（后端：板块一）
          app.$getMallSubsidyItemList({
            ...optionsGoods,
            plate: '3',
          }),
          // 板块三 【前端】品质生活家（家电）  （后端：板块二）
          app.$getMallSubsidyItemList({
            ...optionsGoods,
            plate: '4',
          }),
          // 板块四 【前端】暖季焕新（好货精选）  （后端：板块三）
          app.$getMallSubsidyItemList({
            ...optionsGoods,
            plate: '5',
          }),
          // 板块五 【前端】暖季焕新（好货精选）  （后端：板块四）
          app.$getMallSubsidyItemList({
            ...optionsGoods,
            plate: '6',
          }),
        ]);

      // 板块二
      if (resPlateTwo?.errcode == 0) {
        activityGoodsList.plateTwo[0] =
          resPlateTwo?.data?.subsidyItemList || [];
        goodsListTotalPage.plateTwo =
          Math.ceil(Number(resPlateTwo?.data?.total) / 8) || 1;
      }
      // 板块三
      if (resPlateThree?.errcode == 0) {
        activityGoodsList.plateThree[0] =
          resPlateThree?.data?.subsidyItemList || [];
        goodsListTotalPage.plateThree =
          Math.ceil(Number(resPlateThree?.data?.total) / 8) || 1;
      }
      // 板块四
      if (resPlateFour?.errcode == 0) {
        activityGoodsList.plateFour[0] =
          resPlateFour?.data?.subsidyItemList || [];
        goodsListTotalPage.plateFour =
          Math.ceil(Number(resPlateFour?.data?.total) / 8) || 1;
      }
      // 板块五
      if (resPlateFive?.errcode == 0) {
        activityGoodsList.plateFive[0] =
          resPlateFive?.data?.subsidyItemList || [];
        goodsListTotalPage.plateFive =
          Math.ceil(Number(resPlateFive?.data?.total) / 8) || 1;
      }
      return { activityGoodsList, goodsListTotalPage };
    } catch (error) {
      console.error(error);
      if (error?.status) {
        // 请求报错
        console.log('/activity/festival', error?.status, error?.statusText);
      } else {
        // 代码报错
        console.log(error);
      }
      return { activityGoodsList, goodsListTotalPage };
    }
  },
  data() {
    return {
      lang: '', // 语言
      // 商品板块的列表
      activityGoodsList: {
        plateTwo: [], // 板块二
        plateThree: [], // 板块三
        plateFour: [], // 板块四
        plateFive: [], // 板块五
      },
      // 需要分页数据的总页数
      goodsListTotalPage: {
        plateTwo: 0, // 板块二
        plateThree: 0, // 板块三
        plateFour: 0, // 板块四
        plateFive: 0, // 板块五
      },
      // 板块二
      pageOptionsTwo: {
        pageSize: 8, // 页大小
        pageNum: 1, // 页码
      },
      // 板块三
      pageOptionsThree: {
        pageSize: 8, // 页大小
        pageNum: 1, // 页码
      },
      // 板块四
      pageOptionsFour: {
        pageSize: 8, // 页大小
        pageNum: 1, // 页码
      },
      // 板块五
      pageOptionsFive: {
        pageSize: 8, // 页大小
        pageNum: 1, // 页码
      },
    };
  },
  computed: {
    ...mapGetters({
      currLang: 'common/currLang',
    }),
  },
  mounted() {
    if (process.client) {
      this.lang = this.currLang;
      if (this.lang != 'zh-cn') {
        this.$router.push('/');
      }
      this.firstGetGoodsPlate('2'); // 获取板块二数据
      this.firstGetGoodsPlate('3'); // 获取板块三数据
      this.firstGetGoodsPlate('4'); // 获取板块四数据
      this.firstGetGoodsPlate('5'); // 获取板块五数据
    }
  },
  methods: {
    // 返回字母方法
    returnLetters(num) {
      if (num == '2') return 'Two';
      if (num == '3') return 'Three';
      if (num == '4') return 'Four';
      if (num == '5') return 'Five';
    },
    /**
     * 初次获取商品板块数据
     * @param {String,Number} num 1板块二/2板块三
     * 3 板块一 4 板块二 5 板块三 6板块4 7商城首页轮播 (后端)
     */
    async firstGetGoodsPlate(num) {
      let letters = this.returnLetters(num);
      let options = {
        region: '1',
        plate: Number(num) + 1,
        pageNum: 1,
        pageSize: 16,
        API_LANG_TYPE: this.lang,
        MUTE_WARNING: 1,
      };
      let res = await getMallSubsidyItemList(options);
      if (res?.errcode == 0) {
        this['pageOptions' + letters].pageNum = 2;
        let firstArray = this.activityGoodsList['plate' + letters];
        // 获取对应板块的列表数据
        let firstList = res?.data?.subsidyItemList || [];
        this.goodsListTotalPage['plate' + letters] =
          Math.ceil(Number(res?.data?.total) / 8) || 1;

        // 赋值列表
        if (firstList?.length <= 8) {
          firstArray[0] = firstList;
        } else {
          firstArray[0] = firstList?.slice(0, 8);
          firstArray[1] = firstList?.slice(8, firstList?.length);
        }
      }
    },
    /**
     * 板块二/三向右滚动需要向后继续获取数据
     * @param {String} num 板块num需要继续获取
     */
    scrollRight(num) {
      let letters = this.returnLetters(num);
      this['pageOptions' + letters].pageNum++;
      this.getAfterGoodsData(num);
    },
    // 获取后续的数据
    async getAfterGoodsData(num) {
      let letters = this.returnLetters(num);
      let lengths = this.activityGoodsList?.['plate' + letters]?.length;
      let options = {
        region: '1',
        plate: Number(num) + 1,
        pageNum: this['pageOptions' + letters].pageNum,
        pageSize: this['pageOptions' + letters].pageSize,
        API_LANG_TYPE: this.lang,
        MUTE_WARNING: 1,
      };
      let res = await getMallSubsidyItemList(options);
      if (res?.errcode == 0) {
        if (res?.data?.subsidyItemList) {
          this.activityGoodsList['plate' + letters][lengths] =
            res?.data?.subsidyItemList;
        }
        this.goodsListTotalPage['plate' + letters] =
          Math.ceil(Number(res?.data?.total) / 8) || 1;
      }
    },
  },
};
