import { render, staticRenderFns } from "./TypeChose.vue?vue&type=template&id=0194361e&scoped=true"
import script from "./TypeChose.vue?vue&type=script&lang=js"
export * from "./TypeChose.vue?vue&type=script&lang=js"
import style0 from "./TypeChose.vue?vue&type=style&index=0&id=0194361e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0194361e",
  null
  
)

export default component.exports