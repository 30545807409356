// // 商城首页
// import request from '@/utils/request.js';
export default ({ $axios }, inject) => {
  // 获取用户订单信息
  inject('homePageUserInfo', (data) =>
    $axios({
      url: '/Info/homePageUserInfo',
      method: 'get',
      params: data,
    })
  );

  // 获取热搜词(需要服务端渲染的自己加前缀)
  inject('getHotKeyword', (data) =>
    $axios({
      url: '/item/getHotKeyword',
      method: 'get',
      params: data,
      // getHotKeyword 只有商城首页的空数据才需要报警，因此用 params 传入
      // headers: {
      //   non_empty_targets: 'data',
      // },
    })
  );

  // 删除搜索记录
  inject('delUserHotKeyword', (data) =>
    $axios({
      url: '/item/delUserHotKeyword',
      method: 'post',
      data: data,
    })
  );

  // 添加新品需求
  inject('addNewItemNeed', (data) =>
    $axios({
      url: '/ItemNeed/addNewItemNeed',
      apiModule: 'goods',
      method: 'post',
      data: data,
    })
  );

  // 获取轮播图
  inject('getCarouselList', (data) =>
    $axios({
      url: '/index/getCarouselList',
      method: 'get',
      params: data,
      headers: {
        non_empty_targets: 'data.list',
      },
    })
  );

  // 获取公告
  inject('getBulletinList', (data) =>
    $axios({
      url: '/Notice/noticeDisplay',
      apiModule: 'notify',
      method: 'get',
      params: data,
    })
  );

  // 获取高利润商品
  inject('getHighProfitItemList', (data) =>
    $axios({
      url: '/item/getHighProfitItemList',
      method: 'get',
      params: data,
      headers: {
        non_empty_targets: 'data',
      },
    })
  );

  // 获取热销商品
  inject('getHotSaleList', (data) =>
    $axios({
      url: '/item/getHotSaleList',
      method: 'get',
      params: data,
      headers: {
        non_empty_targets: 'data',
      },
    })
  );

  // 获取智能推荐
  inject('getIntelligentRecommend', (data) =>
    $axios({
      url: '/Item/getRecommendItemList',
      method: 'get',
      params: data,
      headers: {
        non_empty_targets: 'data',
      },
    })
  );

  // 收藏商品
  inject('addFav', (data) =>
    $axios({
      apiModule: 'goods',
      url: '/fav/addFav',
      method: 'get',
      params: data,
    })
  );

  // 取消收藏商品
  inject('delFav', (data) =>
    $axios({
      apiModule: 'goods',
      url: '/fav/deleteFav',
      method: 'get',
      params: data,
    })
  );
  // 取消收藏商品
  inject('getCategoryClass', (data) =>
    $axios({
      url: '/categoryInfo/getCategory',
      method: 'get',
      params: data,
      headers: {
        non_empty_targets: 'data',
      },
    })
  );
};

// import request from '@/utils/request.js';

// // 获取热搜词
// export function getHotKeyword(data) {
//   return request({
//     url: '/item/getHotKeyword',
//     method: 'get',
//     params: data,
//     controller: {
//       closeLoading:true
//     }
//   });
// }

// // 删除搜索记录
// export function delUserHotKeyword(data) {
//   return request({
//     url: '/item/delUserHotKeyword',
//     method: 'post',
//     data: data,
//   });
// }

// // 添加新品需求
// export function addNewItemNeed(data) {
//   return request({
//     url: '/ItemNeed/addNewItemNeed',
//     method: 'post',
//     data: data,
//   });
// }

// // 获取类目
// export function getAllCategoryInfo(data) {
//   return request({
//     url: '/Category/getAllCategoryInfo',
//     method: 'get',
//     params: data,
//   });
// }

// // 获取轮播图
// export function getCarouselList(data) {
//   return request({
//     url: '/index/getCarouselList',
//     method: 'get',
//     params: data,
//   });
// }

// // 获取用户订单信息
// export function homePageUserInfo(data) {
//   return request({
//     url: '/Info/homePageUserInfo',
//     method: 'get',
//     params: data,
//   });
// }

// // 获取公告
// export function getBulletinList(data) {
//   return request({
//     url: '/Notice/noticeDisplay',
//     method: 'get',
//     params: data,
//   });
// }

// // 收藏商品
// export function addFav(data) {
//   return request({
//     url: '/fav/addFav',
//     method: 'get',
//     params: data,
//   });
// }

// // 取消收藏商品
// export function delFav(data) {
//   return request({
//     url: '/fav/deleteFav',
//     method: 'get',
//     params: data,
//   });
// }

// // 获取热门推荐

// // 获取高利润商品
// export function getHighProfitItemList(data) {
//   return request({
//     url: '/item/getHighProfitItemList',
//     method: 'get',
//     params: data,
//   });
// }

// // 获取热销商品
// export function getHotSaleList(data) {
//   return request({
//     url: '/item/getHotSaleList',
//     method: 'get',
//     params: data,
//   });
// }

// // 获取智能推荐
// export function getIntelligentRecommend(data) {
//   return request({
//     url: '/Item/getRecommendItemList',
//     method: 'get',
//     params: data,
//   });
// }
