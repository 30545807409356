
import { mapGetters } from 'vuex';
import {
  getNoticeSettings,
  getNoticeModules,
  postNoticeSettings,
} from '@/api/client-api/notify/notice_setting.js';
export default {
  name: 'NoticeSetting',
  data() {
    return {
      // 通知类型筛选 #memo 前端写死
      typeOptions: [],
      // 通知模块筛选
      moduleOptions: [],

      // 表单搜索
      formData: {
        message_type: '',
        module: '',
      },

      tableData: [],
      tableConfig: [
        {
          width: '137px',
          title: '通知类型',
          props: 'message_type_text',
          isEdit: false,
        },
        {
          width: '206px',
          title: '通知节点',
          props: 'notification_node',
          isEdit: false,
        },
        {
          width: '158px',
          title: '模块',
          props: 'module_text',
          isEdit: false,
        },
        {
          width: '245px',
          title: '通知标题',
          props: 'title',
          isEdit: false,
        },
        {
          width: '137px',
          title: '邮件通知',
          props: 'email_enabled',
          isEdit: true,
        },
        {
          width: '137px',
          title: '短信通知',
          props: 'message_notice',
          isEdit: true,
        },
      ],

      // 分页相关数据
      total: 0,
      pageNum: 1,
      pageSize: 10,

      // 提示弹窗数据
      showDialog: false,

      dialogData: {
        content: '默认',
        oldStatus: undefined, // 切换前的状态
        targetRowIndex: -1, // 切换的表格数据的行索引
        targetPropName: '', // 切换的状态的字段名
      },
    };
  },
  filters: {
    // 空值处理
    emptyValFilter(val) {
      return !val ? '--' : val;
    },
  },
  computed: {
    ...mapGetters({
      currLang: 'common/currLang',
    }),
  },
  methods: {
    scrollToTop() {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    /**
     * 页面触发
     */
    handleChangePage(pageNum = 1) {
      this.pageNum = Number(pageNum) || 1;
      // 获取列表
      this.fetchSettingList();
      // 回到顶部
      this.scrollToTop();
    },
    // 点击选择通知类型
    handleSelectType() {
      this.handleChangePage();
    },
    // 点击选择通知模块
    handleSelectModule() {
      this.handleChangePage();
    },
    // 点击重置按钮
    handleClickReset() {
      this.formData = { message_type: '', module: '' };
      this.handleChangePage(1);
    },
    handleSwitchNotice(propName, status, index) {
      this.dialogData.oldStatus = !status;
      this.dialogData.targetPropName = propName;
      this.dialogData.targetRowIndex = index;

      let text1 = status ? '开启' : '关闭';
      let text2 = propName === 'email_enabled' ? '邮件通知' : '短信通知';
      this.dialogData.content = `您确定要${text1}${text2}吗？`;
      this.showDialog = true;
    },
    // 点击关闭提示窗口
    handleCloseDialog() {
      // 恢复原本的状态
      let { targetRowIndex, targetPropName, oldStatus } = this.dialogData;
      if (
        this.tableData?.length > targetRowIndex &&
        targetRowIndex >= 0 &&
        targetPropName &&
        oldStatus !== undefined
      ) {
        this.tableData[targetRowIndex][targetPropName] = oldStatus ? '1' : '0';
      }
      this.showDialog = false;
    },
    // 点击提示窗口的确认按钮
    async handleConfirmDialog() {
      await this.postSettingList();
      this.showDialog = false;
      // 清空切换状态的值
      this.dialogData = {
        content: '',
        oldStatus: undefined,
        targetRowIndex: -1,
        targetPropName: '',
      };
    },

    /**
     * 请求接口
     */
    // 获取通知类型的列表
    async fetchTypeOtions() {
      console.log('获取通知类型的选项列表');
      // #memo 前端写死。如果之后要改成调接口，就在这里调
      this.typeOptions = [
        {
          label: '系统通知',
          value: '1',
        },
        {
          label: '交易通知',
          value: '2',
        },
      ];
    },
    // 获取通知模块的列表
    async fetchModuleOptions() {
      try {
        const options = {
          user_type: 1,
        };
        console.log('获取通知模块的选项列表', options);
        const res = await getNoticeModules(options);
        if (res?.errcode == 0) {
          this.moduleOptions = res.data?.list || [];
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 通知设置列表获取
    async fetchSettingList() {
      try {
        const options = Object.assign(
          {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
          },
          this.formData
        );
        let res = await getNoticeSettings(options);
        if (res?.errcode == 0) {
          this.tableData = res.data?.list || [];
          this.total = Number(res.data?.total) || 0;
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 更新通知设置
    postSettingList() {
      return new Promise(async (resolve, reject) => {
        try {
          let rowIndex = this.dialogData.targetRowIndex;
          if (rowIndex < 0 || rowIndex >= this.tableData.length) {
            return;
          }
          const options = JSON.parse(
            JSON.stringify([this.tableData[rowIndex]])
          );
          console.log('上传新的通知设置', options);
          let res = await postNoticeSettings(options);
          if (res?.errcode == 0) {
            this.$message.success(res.msg || '编辑通知设置成功');
            return resolve(true);
          }
          return reject(false);
        } catch (err) {
          console.error(err);
          return reject(false);
        }
      });
    },
  },
  mounted() {
    this.lang = this.currLang;
    this.fetchTypeOtions();
    this.fetchModuleOptions();
    this.handleChangePage(1);
  },
};
