
import { getBasicInformation } from '@/api/client-api/account/accountmanage.js';
import { sendSmsCode, sendVerificationSms } from '@/api/client-api/api.js';
export default {
  name: 'verifyPhoneNum',
  data() {
    return {
      phone_show: '', // 隐藏的手机号
      phone: '', // 展开的手机号
      provencode: '', // 验证手机验证码
      timer: null, // 定时器
      numscond: 60, // 剩余秒数
      errTip: false, // 错误提示的状态
    };
  },
  props: {
    /**
     * 当前弹窗的作用
     * 1 去编辑实名信息页面
     * 2 去升级页面
     * 3 跳转升级但按钮文字不变
     */
    type: {
      type: String,
      default: '1',
    },
  },
  computed: {
    // 提交按钮文字
    submitFont() {
      if (this.type == '2') return '升级到企业账号';
      return '提交认证';
    },
  },
  mounted() {
    this.getBasicInformation(); // 获取手机号
    // 防抖
    this.sendSmsCodeBtn = this._debounce(this.sendSmsCode, 300); // 获取验证码
    this.submitPhoneCodeBtn = this._debounce(this.submitPhoneCode, 300); // 提交认证
  },
  beforeDestroy() {
    // 清除定时器
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    // 获取手机号的信息
    getBasicInformation() {
      getBasicInformation()
        .then((res) => {
          if (res?.errcode == 0) {
            this.phone_show = res?.data?.phone_show;
            this.phone = res?.data?.phone;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 点击× 关闭弹窗
    closeDialog() {
      this.$emit('close-verify');
    },
    // 获取验证码
    sendSmsCode() {
      let options = {
        phone: this.phone,
        type: 'distributorEditInfo',
      };
      sendSmsCode(options)
        .then((res) => {
          if (res?.errcode == 0) {
            if (res?.msg) {
              this.$message.success(res?.msg);
            }
            this.timer = setInterval(() => {
              this.numscond = this.numscond - 1;
              if (this.numscond == 0) {
                clearInterval(this.timer);
                this.timer = null;
                this.numscond = 60;
              }
            }, 1000);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 验证码失焦
    inputBlur() {
      if (!this.provencode) {
        this.errTip = true;
        return;
      }
      this.errTip = false;
    },
    // 提交认证
    submitPhoneCode() {
      // 开发环境不开启校验
      if (process.env.NODE_ENV == 'development') {
        this.$emit('successback');
        localStorage.setItem('isVerify', 'true');
        this.$cookies.set('isVerify', 'true', {
          path: '/',
        });
        // 跳转编辑基本信息
        if (this.type == '1') {
          this.$router.push('/account/information/edit');
        }
        // 跳转升级页面(只有企业认证)
        if (this.type == '2' || this.type == '3') {
          this.$router.push('/account/information/edit/upgrade');
        }
        return;
      }
      if (!this.provencode) {
        this.errTip = true;
        return;
      }
      let options = {
        code: this.provencode,
        phone: this.phone,
      };
      sendVerificationSms(options)
        .then((res) => {
          if (res?.errcode == 0) {
            this.$emit('successback');
            if (res?.msg) {
              this.$message.success(res?.msg);
            }
            localStorage.setItem('isVerify', 'true');
            this.$cookies.set('isVerify', 'true', {
              path: '/',
            });
            // 跳转编辑基本信息
            if (this.type == '1') {
              this.$router.push('/account/information/edit');
            }
            // 跳转升级页面(只有企业认证)
            if (this.type == '2' || this.type == '3') {
              this.$router.push('/account/information/edit/upgrade');
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
