// 后端定的纠纷状态对应数字
export const STATUS_CONFIG = [
  { id: '0', text: '发起退货/退款' },
  { id: '1', text: '协商中' },
  { id: '2', text: '平台介入中' },
  { id: '3', text: '待分销商退货' },
  { id: '4', text: '待供应商收货' }, // 记得删
  { id: '5', text: '待供应商退款' },
  { id: '6', text: '结束' },
  { id: '7', text: '供应商拒付退款' },
  { id: '8', text: '分销商撤销纠纷' },
  { id: '9', text: '系统审核通过' },
];

// 处理人对应数字 （后端返回代表1分销商 2供应商 3平台 4分销商和供应商）
export const HANDLER_CONFIG = [
  { id: '1', text: '' },
  { id: '2', text: '供应商' },
  { id: '3', text: '平台' },
  { id: '4', text: '' }, // 前端展示需要
];
