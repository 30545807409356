
import { getCoupon } from '@/api/goods/good_detail';
export default {
  name: 'SingleCoupon',
  props: {
    // 优惠券详情
    info: {
      type: Object,
      default: () => {},
    },
    // 优惠券领取状态 wait待领取的券 usable本品可用券
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      canClickBtn: true, // 是否可以点击按钮
    };
  },
  mounted() {},
  computed: {
    // 优惠券领取类型
    collectStatus() {
      /**
       * 不能继续领取了 1 || 2 || 3
       * 1.在本品可用券范围内
       * 2.剩余优惠券总数==0
       * 3.有限制领取数量 && 当前领取数量>=限制领取数量
       */
      if (
        this.status == 'usable' ||
        this.info?.rest_num == '0' ||
        (this.info?.get_limit_num != '-1' &&
          this.info?.num >= this.info?.get_limit_num)
      ) {
        return 'already';
      } else if (
        /**
         * 可以继续领取
         * 当前领取数量 != 0 && 无限制领取数量
         * 当前领取数量 != 0 && 剩余优惠券总数 != 0 && 有限制领取数量 && 当前领取数量<限制领取数量
         */
        (this.info?.num != '0' && this.info?.get_limit_num == '-1') ||
        (this.info?.num != '0' &&
          this.info?.rest_num != '0' &&
          this.info?.get_limit_num != '-1' &&
          this.info?.num < this.info?.get_limit_num)
      ) {
        return 'continue';
      } else {
        return 'immediately';
      }
    },
    // 按钮文案
    buttonFont() {
      switch (this.collectStatus) {
        case 'already':
          return '已领取';
        case 'continue':
          return '继续领取';
        default:
          return '立即领取';
      }
    },
    // 适用范围文案
    applicableScopeText() {
      let text = '';
      if (this.info?.use_scope_type == '1') {
        text = '全部商品可使用';
      } else if (this.info?.use_scope_type == '2') {
        // 指定类目
        let length = this.info?.use_scope?.length - 1;
        text = this.info?.use_scope?.[length]?.name;
      } else if (this.info?.use_scope_type == '3') {
        let arr = this.info?.use_scope?.map((item) => {
          return item.title;
        });
        text = arr?.join(',');
      }
      return text;
    },
  },
  methods: {
    // 点击按钮区域
    async getNow() {
      // 已领取状态  不可以继续领取优惠券了
      if (this.collectStatus == 'already') return;
      this.canClickBtn = false; // 阻止按钮继续点击
      try {
        const res = await getCoupon({ coupon_id: this.info?.coupon_id });
        if (res?.errcode == 0) {
          if (res?.msg) this.$message.success(res?.msg);
          this.$emit('success-coupon', this.info?.coupon_id);
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.canClickBtn = true; // 允许点击按钮
      }
    },
  },
};
