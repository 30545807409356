
export default {
  name: 'XmStepIcon',
  props: {
    num: {
      // 圆形的内容
      type: [String, Number],
      default: '1',
    },
    diameter: {
      // 圆形直径
      type: String,
      default: '24px',
    },
    // 当前步骤状态
    // finish, process, wait
    status: {
      type: String,
      default: 'process',
    },
  },
  computed: {
    statusClass() {
      let className = '';
      switch (this.status) {
        case 'finish':
          className = 'is-finish';
          break;
        case 'process':
          className = 'is-process';
          break;
        case 'wait':
          className = 'is-wait';
          break;

        default:
          break;
      }
      return className;
    },
  },
};
