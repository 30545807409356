
// 组件
import XmIdentityCardVerify from '@/components/XimuComponents/XmIdentityCardVerify.vue';
export default {
  name: 'EditForm',
  components: { XmIdentityCardVerify },
  props: {
    // 认证类型 1个人 2企业
    type: {
      type: String,
      default: '1',
    },
    // 认证信息
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 日期选择器只能选择当前时间之后的
      expireTimeOption: {
        disabledDate(date) {
          return date.getTime() < Date.now();
        },
      },
      // 用户信息
      userInfo: {},
      judgeIdcard: false, // 身份证号的验证规则
      idcardVerify: false, // 身份证号的校验状态 false为校验通过
      selectAreaLast: false, // 判断当前的地址选择是否在可选的最后一级
      areaSelectKey: 0, // 地区选择刷新
    };
  },
  mounted() {
    this.submitBtn = this._debounce(this.submit, 300); // 点击提交按钮
  },
  watch: {
    // 监听type变化
    type(val) {
      this.clearVerify(); // 重置验证信息
    },
    // 获取数据
    info: {
      deep: true,
      handler(val) {
        this.selectAreaLast = true;
        this.areaSelectKey++; // 回显地址选择器需要
        this.userInfo = JSON.parse(JSON.stringify(val)) || {};
        this.clearVerify(); // 重置验证信息
      },
    },
  },
  computed: {
    // 规则
    rules() {
      // 证件有效截至日规则
      let checkIdcardTime = (rule, value, callback) => {
        if (!this.userInfo?.expireType && !value) {
          callback(new Error('请选择证件有效截止日'));
        }
        callback();
      };
      // 公司经营地址验证规则
      let checkSelectAddress = (rule, value, callback) => {
        if (value.province_id == '') {
          callback(new Error('请选择公司经营地址'));
        }
        if (!this.selectAreaLast) {
          callback(new Error('请完整选择公司经营地址'));
        }
        callback();
      };
      // 社会统一信用代码验证规则
      let checkSocialCode = (rule, value, callback) => {
        if (value?.length !== 15 && value?.length !== 18) {
          callback(new Error('请输入合法的统一社会信用代码'));
        }
        callback();
      };
      // 营业执照时间验证规则
      // let checkLicenseTime = (rule, value, callback) => {
      //   if (!this.userInfo?.licenseExpireType && !value) {
      //     callback(new Error("请选择营业执照有效截至日"));
      //   }
      //   callback();
      // };
      let personal = {
        name: [
          {
            required: true,
            message: '请输入' + this.nameLabel,
            trigger: ['blur', 'change'],
          },
          {
            max: 20,
            message: '请输入20个字符以内的' + this.nameLabel,
            trigger: ['blur', 'change'],
          },
        ],
        idcardTime: [{ validator: checkIdcardTime, trigger: 'change' }],
        idcardPositiveImg: [
          {
            required: true,
            message: '请输入上传证件正面照片',
            trigger: 'change',
          },
        ],
        idcardBackImg: [
          {
            required: true,
            message: '请输入上传证件反面照片',
            trigger: 'change',
          },
        ],
      };
      let corporate = {
        companyName: [
          {
            required: true,
            message: '请输入公司名称',
            trigger: ['blur', 'change'],
          },
          {
            max: 20,
            message: '请输入20个字符以内的公司名称',
            trigger: ['blur', 'change'],
          },
        ],
        selectAddress: [
          { required: true, message: '请选择公司经营地址', trigger: 'change' },
          { validator: checkSelectAddress, trigger: 'change' },
        ],
        detailAddress: [
          {
            required: true,
            message: '请输入营业执照上的地址',
            trigger: ['blur', 'change'],
          },
        ],
        socialCode: [
          {
            required: true,
            message: '请输入统一社会信用代码',
            trigger: 'blur',
          },
          { validator: checkSocialCode, trigger: 'blur' },
        ],
        licenseImg: [
          { required: true, message: '请上传营业执照', trigger: 'blur' },
        ],
        // licenseExpireTime: [{ validator: checkLicenseTime, trigger: "change" }],
      };
      // 企业验证
      if (this.type == '2') {
        return { ...personal, ...corporate };
      }
      return personal;
    },
    // 姓名label
    nameLabel() {
      if (this.type == '1') return '姓名';
      if (this.type == '2') return '法人姓名';
    },
  },
  methods: {
    // 清空验证信息
    clearVerify() {
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
        this.$refs.identityCardVerify.clearValidate();
        this.judgeIdcard = false; // 关闭校验身份证号
      });
    },
    // 上传图片 身份证正面/方面、营业执照
    uploadPic(img, type) {
      this.userInfo[type] = img;
      this.$refs.ruleForm.validateField(type);
    },
    // 地址选择器传出
    handleUpdateReturnAddr(areaData, isLast) {
      this.userInfo.selectAddress = JSON.parse(JSON.stringify(areaData));
      this.selectAreaLast = isLast;
      this.$refs.ruleForm.validateField('selectAddress');
    },
    // 获取身份证类型/身份证号
    getIdcardValue(hasErr, cardType, cardValue) {
      this.idcardVerify = hasErr;
      this.userInfo.idcardType = cardType;
      this.userInfo.idcard = cardValue;
    },
    // 改变时间选择类型
    changeTimeType(type) {
      this.$refs.ruleForm.validateField(type);
    },
    // 点击提交
    submit() {
      this.judgeIdcard = true; // 开始校验身份证号
      this.$refs.ruleForm.validate((valid) => {
        if (this.idcardVerify) return;
        if (this.type == '2' && !this.selectAreaLast) return;
        if (valid) {
          this.judgeIdcard = false; // 关闭校验身份证号
          let options = {
            type: this.type, // 认证类型 1个人 2企业
            idcard: this.userInfo?.idcard, // 证件号
            idcard_type: this.userInfo?.idcardType, // 证件类型
            idcard_time: this.userInfo?.expireType
              ? ''
              : this.userInfo?.idcardTime?.slice(0, 10), // 身份证有效截止日
            expire_type: this.userInfo?.expireType ? '2' : '1', // 身份证有效期类型 1-非长期 2-长期
            idcard_positive_img: this.userInfo?.idcardPositiveImg?.join(), // 身份证正面
            idcard_back_img: this.userInfo?.idcardBackImg?.join(), // 身份证反面
          };
          // 个人认证
          if (this.type == '1') {
            options.name = this.userInfo?.name; // 姓名
          }
          // 企业认证
          let address = this.userInfo?.selectAddress; // 地址
          if (this.type == '2') {
            options.business_name = this.userInfo?.name; // 法人姓名
            options.com_name = this.userInfo?.companyName; // 公司名称
            // 地址
            options.company_business_address_country_id = '44'; // 国id
            options.company_business_address_state_id = address?.province_id; // 省id
            options.company_business_address_city_id = address?.city_id; // 市id
            options.company_business_address_area_id = address?.area_id; // 区id
            options.detail_address = this.userInfo?.detailAddress; // 详细地址

            options.social_code = this.userInfo?.socialCode; // 社会信用代码
            options.license = this.userInfo?.licenseImg?.join(); // 营业执照
            // 营业执照有效期
            // options.business_license_expire_time = this.userInfo
            //   ?.licenseExpireType
            //   ? ''
            //   : this.userInfo?.licenseExpireTime?.slice(0, 10);
            // 营业执照有效期类型 1-非长期 2-长期
            // options.business_license_expire_type = this.userInfo
            //   ?.licenseExpireType
            //   ? '2'
            //   : '1';
          }
          this.$emit('submit', options);
        } else {
          return false;
        }
      });
    },
  },
};
