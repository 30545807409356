
export default {
  props: {
    specialService: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      lateCompensateContent: '', // 晚发必赔文案
      fakeCompensateContent: '', // 假一赔三文案
    };
  },
  computed: {
    // 特色服务展示的类型  1：假一赔三、晚发必赔都展示，2：只展示晚发必赔，3：只展示假一赔三
    showCharacterServiceType() {
      if (this.specialService?.length > 0) {
        let isFakeCompensateService = false;
        let isLateCompensateService = false;
        this.specialService?.forEach((item) => {
          if (item.type == '1') {
            this.lateCompensateContent = item.content;
            isLateCompensateService = true;
          } else if (item.type == '2') {
            this.fakeCompensateContent = item.content;
            isFakeCompensateService = true;
          } else {
            isLateCompensateService = false;
            isFakeCompensateService = false;
          }
        });
        if (isFakeCompensateService && isLateCompensateService) {
          return '1';
        } else if (isLateCompensateService) {
          return '2';
        } else if (isFakeCompensateService) {
          return '3';
        } else {
          return '';
        }
      } else {
        return '';
      }
    },
  },

  mounted() {},
  methods: {
    // 点击服务 查看
    handleClickCheckService(id) {
      let url = 'https://home.ximu.cn/platrule/' + id;
      window.open(url, '_blank');
    },
  },
};
