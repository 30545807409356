import { render, staticRenderFns } from "./EditPassword.vue?vue&type=template&id=521dc8f6&scoped=true"
import script from "./EditPassword.vue?vue&type=script&lang=js"
export * from "./EditPassword.vue?vue&type=script&lang=js"
import style0 from "./EditPassword.vue?vue&type=style&index=0&id=521dc8f6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "521dc8f6",
  null
  
)

export default component.exports