
// 变量
import { curDefaultLanguage } from '@/utils/cur_default_config.js'; //默认语言
export default {
  name: 'XimuClose',
  props: {
    width: {
      // 弹窗宽度
      type: String,
      default: '480px',
    },
    opacity: {
      // 遮罩层的透明度
      type: String,
      default: '0.25',
    },
    zIndex: {
      // 遮罩层的z轴坐标
      type: String,
      default: '2000',
    },
    content: {
      type: String,
      default: '',
    },
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
  },
  computed: {
    colse() {
      if (this.lang == 'zh-cn') return '关闭';
      if (this.lang == 'en') return 'close';
    },
  },
  data() {
    return {
      timer: null, // 倒计时三秒
      num: 3, // 倒计时三秒
    };
  },
  mounted() {
    this.timer = setInterval(() => {
      this.num--;
      if (this.num <= 0 && this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    }, 1000);
  },
  methods: {
    close() {
      this.$emit('closeConfig'); // 父组件绑定的事件
    },
  },
};
