
export default {
  name: 'LogisticsDialog',
  props: {
    // 用户物流信息
    userLogiInfo: {
      type: [Object, String],
      default: '',
    },
    // 用户物流轨迹
    logisticsTrack: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    // 关闭弹窗
    closeDialog() {
      this.$emit('close-dialog');
    },
  },
};
