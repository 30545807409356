
export default {
  name: 'XmReconfirm',
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: '确认',
    },
  },
  data() {
    return {
      xmReconfirmVisible: true,
    };
  },
  methods: {
    closeDeletedig() {
      this.xmReconfirmVisible = false;
      this.$emit('close-dialog'); // 父组件绑定的事件:关闭弹窗
    },
    sure() {
      this.xmReconfirmVisible = false;
      this.$emit('sure-dialog'); // 父组件绑定的事件:确认事件
    },
  },
};
