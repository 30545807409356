
import { getGoodsEvaluateList } from '@/api/client-api/goods/shopindex';
import { formatEvaluationTime } from '@/utils/time';
import { formatEvaluationCountLabel } from '../js/utils';

import EvaluationItem from './EvaluationItem';
import StarRateItem from './StarRateItem.vue';

import { EVALUATION_BLOCK_LABELS } from '../js/label_config_form_main';
import Report from '@/pages/back-stage/complaints-module/complaints-report/components/Report.vue'; //举报
import { COMPLAINT_SCENARIO } from '@/pages/back-stage/complaints-module/complaints-report/js/complaint_type.js'; //发起场景
// 变量
import { curDefaultLanguage } from '@/utils/cur_default_config.js'; //默认语言

export default {
  name: 'EvaluationBlock',
  components: {
    EvaluationItem,
    StarRateItem,
    Report,
  },
  props: {
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
    // 商品对象
    goodInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      COMPLAINT_SCENARIO,
      itemId: '',
      evaluationType: 'all', // 当前选择显示的评价类型
      goodEvaluationRate: '', //当前商品好评率
      // 评价分类数据统计
      evaluationStatistics: {
        allCount: 0,
        imgCount: 0,
        videoCount: 0,
        reviewCount: 0,
        positiveCount: 0,
        mediumCount: 0,
        negativeCount: 0,
      },
      evaluationOrderType: '1', // 评价列表的排序类型
      evaluationList: [], // 评价数据列表

      pages: 1, //当前页
      total: 0, //总记录数
      pageSize: 10, //每页显示数

      // 评价举报需要的
      reportObj: {},
      showeReportDialog: false,
    };
  },
  computed: {
    // 标签多语言配置
    labelConfig() {
      return EVALUATION_BLOCK_LABELS[this.lang || curDefaultLanguage];
    },
    // 评价评分
    totalScore() {
      let score = this.goodInfo?.evaluate_score || 0;
      return parseFloat(score);
    },
    /**
     * 评价标题行的标签名称们
     * 因为要显示评价数量，所以用计算属性做
     */
    // positiveRateLabel() {
    //   const rate = this.goodEvaluationRate;
    //   if (rate && rate.length > 0) {
    //     return `好评度：${rate}%`;
    //   } else {
    //     return '好评度';
    //   }
    // },
    allEvaluationLabel() {
      const count = formatEvaluationCountLabel(
        this.evaluationStatistics.allCount
      );
      return `${this.labelConfig['all']}(${count})`;
    },
    imgEvaluationLabel() {
      const count = formatEvaluationCountLabel(
        this.evaluationStatistics.imgCount
      );
      return `${this.labelConfig['pic']}(${count})`;
    },
    positiveEvaluationLabel() {
      const count = formatEvaluationCountLabel(
        this.evaluationStatistics.positiveCount
      );
      return `${this.labelConfig['good']}(${count})`;
    },
    mediumEvaluationLabel() {
      const count = formatEvaluationCountLabel(
        this.evaluationStatistics.mediumCount
      );
      return `${this.labelConfig['mid']}(${count})`;
    },
    negativeEvaluationLabel() {
      const count = formatEvaluationCountLabel(
        this.evaluationStatistics.negativeCount
      );
      return `${this.labelConfig['bad']}(${count})`;
    },
  },
  methods: {
    // 滑动到评价板块的顶部
    scrollToEvaluationBlockTop() {
      this.$nextTick(() => {
        let block = document.getElementById('good-detail-evaluation-block');
        block?.scrollIntoView();
      });
    },
    // 打开评价举报
    handleReport(item) {
      this.reportObj = item;
      this.showeReportDialog = true;
    },
    // 关闭评价举报
    handleCloseeReportForm() {
      this.reportObj = {};
      this.showeReportDialog = false;
    },
    // 评价举报确认按钮
    handleConfimeReport() {
      this.reportObj = {};
      this.showeReportDialog = false;
    },
    formatTime(time) {
      return formatEvaluationTime(time);
    },
    handleSelectMenu(index) {
      this.evaluationType = index;
      this.$nextTick(() => {
        if (this.$refs.pageTools) {
          this.$refs.pageTools.changecurPage();
        }
      });
      this.goPage(1);
    },
    // 切换排序类型
    handleSelectOrderType(order) {
      this.evaluationOrderType = order;
      this.goPage(1);
    },
    /**
     * 获取评论数据
     */
    async getEvaluationList() {
      return new Promise(async (resolve, reject) => {
        try {
          const option = {
            item_id: this.itemId,
            flag: '1',
            order: this.evaluationOrderType,
            type: this.evaluationType == 'all' ? '' : this.evaluationType,
            pages: this.pages,
            pageSize: this.pageSize,
            API_LANG_TYPE: this.lang,
          };
          const res = await getGoodsEvaluateList(option);

          if (res?.errcode == 0) {
            /**
             * 评价数据
             */
            this.evaluationCount = Number(res.data?.count) || 0;
            this.evaluationList = res.data?.list?.map((x) => {
              x.score = parseFloat(x.score);
              return x;
            });
            this.goodEvaluationRate = parseFloat(res.data?.good_rate) || 0;
            this.total = Number(res.data?.currCount) || 0;

            /**
             * 评价分类数据统计
             */
            this.evaluationStatistics.allCount = Number(res.data?.count) || 0;
            this.evaluationStatistics.imgCount =
              Number(res.data?.img_count) || 0;
            this.evaluationStatistics.positiveCount =
              Number(res.data?.good_count) || 0;
            this.evaluationStatistics.mediumCount =
              Number(res.data?.middle_count) || 0;
            this.evaluationStatistics.negativeCount =
              Number(res.data?.bad_count) || 0;

            /**
             * 同步评价总数数据
             */
            this.$emit(
              'update-evaluation-count',
              this.evaluationStatistics.allCount
            );
            resolve();
          }
        } catch (err) {
          console.error(err);
          reject(err);
        }
      });
    },
    //前往某一页
    async goPage(val) {
      if (val) {
        this.pages = val;
      }
      await this.getEvaluationList();
      // 不能直接加在这里，会导致刚进入页面时自动定位到“商品评价”的板块位置
      // this.scrollToEvaluationBlockTop();
    },
  },
  mounted() {
    this.itemId = this.$route.params.id;
    this.goPage(1);
  },
};
