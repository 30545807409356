
// 变量
import {
  curDefaultLanguage,
  CUR_TOKEN_NAME,
} from '@/utils/cur_default_config.js'; //默认语言
export default {
  props: {
    content: {
      //上传框中提示内容
      type: String,
      default: '上传图片',
    },
    maxNumber: {
      //最大上传数量(传0标识详情专用,不能删除)
      type: Number,
      default: 3,
    },
    file_type: {
      //支持哪些文件类型
      type: [Array],
      default: () => [],
    },
    propFileArray: {
      //回显专用
      type: Array,
      default: () => [],
    },
    flagType: {
      //当同一个页面多处使用时区分标识
      type: String,
      default: '',
    },
    isInfo: {
      //是否详情页只能查看
      type: Boolean,
      default: false,
    },
    // 不是视频也不是图片的文件上传限制
    singleMaxSize: {
      //单次最大上传大小
      type: Number,
      default: 5,
    },
    isPreview: {
      //是否展示预览图标
      type: Boolean,
      default: false,
    },
    noButton: {
      type: Boolean,
      default: true,
    },
    /**
     * 以kb为单位的上传
     * 值的单位为kb
     * eg: 传500限制500kb
     */
    kbUpload: {
      type: Number,
      default: -1,
    },
    /**
     * 限制可上传图片的文件数
     */
    limitUploadPicNum: {
      type: Number,
      default: 1000,
    },
    /**
     * 限制可上传视频的文件数
     */
    limitUploadVideoNum: {
      type: Number,
      default: 1000,
    },
    /**
     * 可上传的图片格式文件的最大值
     * 以M为单位的数字
     * 不传默认图片最大不可以超过5M
     */
    picMaxSize: {
      type: Number,
      default: 5,
    },
    /**
     * 可上传的视频格式文件的最大值
     * 以 M 为单位的数字
     * 不传默认视频最大不可以超过200M
     */
    videoMaxSize: {
      type: Number,
      default: 200,
    },
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
  },
  computed: {
    getAccept() {
      return this.file_type.map((item) => '.' + item).join(',');
    },
    // 失败提示词
    failure() {
      if (this.lang == 'zh-cn') return '上传失败';
      if (this.lang == 'en') return 'Upload failed';
    },
    // 上传中提示词
    conduct() {
      if (this.lang == 'zh-cn') return '上传中';
      if (this.lang == 'en') return 'Uploading';
    },
    // 图片上传大小限制
    picSizeLimit() {
      if (this.lang == 'zh-cn')
        return '图片最大不能超过' + this.picMaxSize + 'MB!';
      if (this.lang == 'en')
        return (
          'The maximum size of the image cannot exceed' +
          ' ' +
          this.picMaxSize +
          'MB!'
        );
    },
    // 不支持的类型文件
    unsupportedFileTypes() {
      if (this.lang == 'zh-cn') return '此处不支持该类型文件上传';
      if (this.lang == 'en')
        return 'This type of file upload is not supported here';
    },
  },
  watch: {
    propFileArray: {
      handler() {
        this.fileArray = JSON.parse(JSON.stringify(this.propFileArray));
        this.fileArray.forEach((item, index) => {
          if (!this.imgType.some((itemTwo) => itemTwo === item.split('.')[1])) {
            this.imgCheckType(item);
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      showPreviewdoc: false, //控制文档弹窗
      isDisabled: false, //控制能否禁用上传
      // failure: '上传失败', //失败提示词
      // conduct: '上传中', //上传中提示词
      token: this.$cookies.get(CUR_TOKEN_NAME), //获取token
      TanChuangflag: false, //控制上传阀门
      fileArray: [], //存储所有数据
      imgUrl: '', //存储大图url
      showPreviewimg: false, //控制图片弹窗
      currentImgType: '', //记录当前图片状态
      sourceFileArray: [], //存储文件数据
      imgType: ['jpg', 'jpeg', 'png', 'gif', 'ico', 'tif', 'tiff'], //图片类型
      currentStatus: '',
      fileImgMap: {
        //各种文件类型所展示的封面图
        pdf: require('@/assets/images/fileTypeDefaultIcon/pdf.svg'),
        xlsx: require('@/assets/images/fileTypeDefaultIcon/Excel.svg'),
        xls: require('@/assets/images/fileTypeDefaultIcon/Excel.svg'),
        doc: require('@/assets/images/fileTypeDefaultIcon/word.svg'),
        docx: require('@/assets/images/fileTypeDefaultIcon/word.svg'),
        zip: require('@/assets/images/fileTypeDefaultIcon/zip.svg'),
        rar: require('@/assets/images/fileTypeDefaultIcon/zip.svg'),
        '7z': require('@/assets/images/fileTypeDefaultIcon/zip.svg'),
      },
    };
  },
  methods: {
    //区分视频、文件、图片
    showFileType(type, file) {
      if (type == 'video') {
        return this.sourceFileArray?.some(
          (item) => item.index === file && item.type == 'video'
        );
      } else if (type == 'file') {
        return this.sourceFileArray?.some(
          (item) =>
            item.type != 'video' &&
            this.matchType(item.type) != 'image' &&
            item.index === file
        );
      } else if (type == 'image') {
        // 图片
        return true;
      }
    },
    reload() {
      this.currentStatus = '';
      setTimeout(() => {
        this.isDisabled = false;
      }, 0);
    },
    //上传失败回调
    uploadError() {
      this.isDisabled = true;
      this.currentStatus = 'failure';
    },
    //上传中回调
    progressFunc() {
      this.isDisabled = true;
      this.currentStatus = 'conduct';
    },
    exceedFunc() {
      this.$message('上传已上限!');
    },
    //上传前效验图片格式
    beforeUpload(file) {
      let fileType = file?.type?.split('/')[0];
      // console.log('上传前', file);
      // console.log('this.sourceFileArray', this.sourceFileArray);
      // console.log('this.fileArray', this.fileArray);
      // console.log('fileType', fileType);

      if (this.kbUpload !== -1) {
        let fileKB = file.size / 1024;
        if (fileKB > this.kbUpload) {
          this.$message.warning(
            '已超出上传最大限制，上传不可超过' + this.kbUpload + 'kb'
          );
          return false;
        }
      }

      let MB = file.size / 1024 / 1024;
      if (fileType == 'image' && MB > this.picMaxSize) {
        this.$message({
          type: 'warning',
          message: this.picSizeLimit,
        });
        return false;
      }
      if (fileType == 'video' && MB > this.videoMaxSize) {
        this.$message({
          type: 'warning',
          message: `视频最大不能超过${this.videoMaxSize}MB!`,
        });
        return false;
      }
      if (
        fileType != 'image' &&
        fileType != 'video' &&
        MB > this.singleMaxSize
      ) {
        this.$message({
          type: 'warning',
          message: `已超出单个上传最大限制,单个最大限制${this.singleMaxSize}MB!`,
        });
        return false;
      }
      // if (MB > 5) return false;
      let arr = file.name.split('.') || [];
      let fileSuffix = arr.length ? arr[arr.length - 1] : '';
      if (fileSuffix) {
        let temp = this.file_type.some(
          (item) => item.toLowerCase() == fileSuffix.toLowerCase()
        );
        if (!temp) {
          this.$message({
            type: 'warning',
            message: this.unsupportedFileTypes,
          });
          return false;
        }
      } else {
        this.$message({ type: 'warning', message: '上传异常!' });
        return false;
      }
      if (this.fileArray.length + 1 > this.maxNumber) {
        return this.$message({
          type: 'warning',
          message: '已超出最大上传个数!',
        });
      }
      // 限制最多上传的视频文件数
      let isLimitVideo =
        this.sourceFileArray.filter((item) => item.type === 'video').length >=
        this.limitUploadVideoNum;

      if (fileType == 'video' && isLimitVideo) {
        this.$message.warning(`最多可上传${this.limitUploadVideoNum}个视频`);
        return false;
      }
      // 限制最多上传的图片文件数
      let isLimitPic =
        this.sourceFileArray.filter(
          (item) => this.matchType(item.type) === 'image'
        ).length >= this.limitUploadPicNum;
      if (fileType == 'image' && isLimitPic) {
        this.$message.warning(`最多可上传${this.limitUploadPicNum}张图片`);
        return false;
      }
      return true;
    },
    //详情页点击查看大图
    showImgFunc(file, noImg, flag) {
      if (flag) {
        let temp = this.matchType(file);
        if (temp) {
          if (temp == 'image' || temp == 'video') {
            noImg = false;
          } else {
            noImg = true;
          }
        } else {
          this.$message({ type: 'warning', message: '文件/图片格式异常!' });
          return;
        }
      }
      this.imgUrl = this.$options.filters.imgbaseurl(file);
      if (noImg) {
        this.showPreviewdoc = true;
      } else {
        this.showPreviewimg = true;
      }
    },
    //关闭预览弹窗
    previewCloseFunc() {
      // console.log('---------close');
      this.showPreviewimg = this.showPreviewdoc = false;
    },
    //删除图片
    delImage(index, file) {
      let flag = -1;
      this.sourceFileArray.filter((item, indexTwo) => {
        if (item.index === file) {
          flag = indexTwo;
        }
      });
      flag != -1 ? this.sourceFileArray.splice(flag, 1) : '';

      this.fileArray.splice(index, 1);

      this.$emit(
        'statuChangeEvent',
        JSON.parse(JSON.stringify(this.fileArray)),
        this.flagType
      );
    },
    //上传成功回调
    uploadSuccess(res) {
      this.isDisabled = false;
      this.currentStatus = '';
      if (res?.errcode == 0) {
        this.imgCheckType(res.data?.link, false);
        if (this.currentImgType == 'application') {
          this.imgCheckType(res.data?.link, false);
        }
        this.fileArray.push(res.data?.link);
        this.$emit(
          'statuChangeEvent',
          JSON.parse(JSON.stringify(this.fileArray)),
          this.flagType
        );
      } else {
        this.$message({ type: 'warning', message: res.msg });
      }
    },
    //判断具体是什么文件
    imgCheckType(file) {
      //判断文件类型
      let flag = file.split('.')[file.split('.').length - 1];
      if (flag == 'pdf') {
        this.sourceFileArray.push({
          index: file,
          type: flag,
        });
      } else if (flag == 'doc' || flag == 'docx') {
        this.sourceFileArray.push({
          index: file,
          type: flag,
        });
      } else if (flag == 'xls' || flag == 'xlsx') {
        this.sourceFileArray.push({
          index: file,
          type: flag,
        });
      } else if (flag == 'zip' || flag == 'rar' || flag == '7z') {
        this.sourceFileArray.push({
          index: file,
          type: flag,
        });
      } else if (this.matchType(file) == 'video') {
        this.sourceFileArray.push({
          index: file,
          type: 'video',
        });
      } else if (this.matchType(file) == 'image') {
        this.sourceFileArray.push({
          index: file,
          type: flag,
        });
      }
    },
  },
};
