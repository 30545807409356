
import { getBasicInformation } from '@/api/client-api/account/accountmanage.js';
import { sendSmsCode, setPaymentPassword } from '@/api/client-api/api.js';
import { encryptor } from '@/utils/XmAES128Encryptor.js';
import { onlyNumber } from '@/utils/validator';

export default {
  data() {
    const configVal = (rule, value, callback) => {
      if (String(this.passwordObj.configPassword).length < 6) {
        callback(new Error('请输入6位支付密码'));
      } else if (this.passwordObj.configPassword != this.passwordObj.password) {
        callback(new Error('两次密码不一致，请确认'));
      } else {
        callback();
      }
    };
    const passWordVal = (rule, value, callback) => {
      if (String(this.passwordObj.password).length < 6) {
        callback(new Error('密码格式不正确'));
      } else {
        callback();
      }
    };
    return {
      passwordObj: {
        phone: '', // 手机号
        code: '', // 验证码
        password: '', // 支付密码
        configPassword: '', // 确认密码
      },
      passwordRules: {
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        password: [{ validator: passWordVal, required: true, trigger: 'blur' }],
        configPassword: [
          {
            validator: configVal,
            required: true,
            trigger: 'blur',
          },
        ],
      },
      configShow: false,
      hidePhone: '',
      timer: null, // 验证码倒计时
      codeTime: 60,
      pay_password: false, // 是否有支付密码
    };
  },
  mounted() {
    this.getBaseInfo();
  },
  methods: {
    clickbtn() {
      this.$router.push('/account/accountset');
    },

    // 获取基本信息
    getBaseInfo() {
      let option = {};
      getBasicInformation(option)
        .then((res) => {
          if (res?.errcode == 0) {
            this.hidePhone = res?.data?.phone_show;
            this.passwordObj.phone = res?.data?.phone;
            this.pay_password = res?.data?.pay_password;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取验证码
    getCode() {
      let option = {
        phone: this.passwordObj.phone,
        type: 'distributorSetPaymentPassword',
      };

      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }

      sendSmsCode(option)
        .then((res) => {
          if (res?.errcode == 0) {
            if (res?.msg) {
              this.$message.success(res?.msg);
            }
            this.timer = setInterval(() => {
              this.codeTime--;
              if (this.codeTime == 0) {
                clearInterval(this.timer);
                this.timer == null;
                this.codeTime = 60;
              }
            }, 1000);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 确定设置支付密码
    setPaymentPassword() {
      this.$refs.passwordObj.validate((valid) => {
        if (valid) {
          let option = {
            password: encryptor.encrypt(this.passwordObj.configPassword),
            code: this.passwordObj.code,
          };
          setPaymentPassword(option)
            .then((res) => {
              if (res?.errcode == 0) {
                // this.$message.success(res.msg);
                this.configShow = true;
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      });
    },
    /**
     * 限制输入数字
     * @param {String} name passwordObj的对象名
     * @param {String} val 当前输入框的值
     */
    limitNumber(name, val) {
      this.passwordObj[name] = val.replace(new RegExp(onlyNumber, 'g'), ''); // 只能输入数字
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
};
