
// import { getHotKeyword, delUserHotKeyword } from '@/utils/api/home/home';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  name: 'InputSearchBox',
  components: {},
  props: {
    // 需要几个按钮 搜索  搜全站/搜本店
    btnNum: {
      type: [Number, String],
      default: 1,
    },
    // 供应商ID
    supplierIDStore: {
      type: String,
      default: '',
    },
    // 是否出现推荐搜索栏
    hasRecommend: {
      type: Boolean,
      default: true,
    },
    // 热搜词
    searchRecommend: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      token: '',
      searchKey: '', // 搜索关键词
      oldsearchKey: '', //存储搜索到的值
      searchRecommendList: this.searchRecommend, // 推荐搜索关键词
      searchHistory: [], // 搜索历史
      searchCorrelation: [], // 搜索联想词
      searchHelpShow: false, // 搜索下拉框是否出现
      stillShow: false, // 常驻show
      placeholder: '搜索商品',
      supplierId: '', //供应商ID
    };
  },
  mounted() {
    if (process.client) {
      if (this.$route.params?.keyword && this.$route.params?.keyword != '') {
        const keyword = this.$route.params.keyword;
        this.oldsearchKey = keyword;
        this.searchKey = keyword;
        this.placeholder = keyword;
      }
      if (
        this.$route.params?.supplier_id &&
        this.$route.params?.supplier_id != ''
      ) {
        this.supplierId = this.$route.params.supplier_id;
      }
      this.token = this.$cookies.get(CUR_TOKEN_NAME);
      this.getSearchWord(); // 获取热搜词
      this.searchWord = this._debounce(this.getSearchCorrelation, 500);
    }
  },
  methods: {
    // 输入框失焦隐藏下拉框
    hideSearchHelp() {
      if (this.stillShow == false) {
        this.searchHelpShow = false;
      }
    },
    // 进入搜索栏
    mouseEnterBox() {
      this.stillShow = true;
    },
    // 离开搜索栏
    mouseLeaveBox() {
      this.stillShow = false;

      if (this.stillShow == false && this.searchHelpShow == true) {
        setTimeout(() => {
          this.searchHelpShow = false;
        }, 1000);
      }
    },
    // 是否登录
    // hasToken() {
    //   if (!this.token) {
    //     this.$router.push('/login');
    //     return;
    //   }
    // },
    // 联想词变色
    brightenKeyword(item) {
      if (item.slice(0, this.searchKey.length) === this.searchKey) {
        item = item.replace(
          item,
          item.slice(0, this.searchKey.length) +
            '<span style="font-weight: bold">' +
            item.substring(this.searchKey.length) +
            '</span>'
        );
      }
      return item;
    },
    // 点击关键词搜索
    searchRecommendWord(words) {
      // 注释掉这行代码的原因：
      // 路由拦截器 beforeEach 会把无token的用户强制弹回 /login，不需要在这里进行判断
      // this.hasToken();
      window.open('/keywords=' + words);
    },
    // 获取热搜词
    async getSearchWord() {
      try {
        const res = await this.$getHotKeyword({ type: 2 });
        if (res?.errcode == 0) {
          this.searchRecommendList = res?.data?.map((item) => item.words);
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 获取搜索历史
    async getSearchHistory() {
      try {
        if (this.token && this.searchKey.trim() === '') {
          const res = await this.$getHotKeyword({ type: 1 });
          if (res?.errcode == 0) {
            this.searchHelpShow = true;
            this.searchHistory = res?.data?.slice(0, 10);
          }
        }

        if (this.token && this.searchKey !== '') {
          this.getSearchCorrelation();
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 获取联想搜索词
    async getSearchCorrelation() {
      try {
        if (!this.token || this.searchKey.trim() === '') return;
        const res = await this.$getHotKeyword({ word: this.searchKey.trim() });
        if (res?.errcode == 0) {
          this.searchHelpShow = true;
          let list = res?.data?.map((item) => item.words);
          this.searchCorrelation = list?.splice(0, 10);
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 删除一条历史记录
    async delOneHistory(e, id) {
      try {
        e.stopPropagation();
        if (id == 'all') {
          id = -1;
        }
        let options = {
          type_id: id,
        };
        const res = await this.$delUserHotKeyword(options);
        if (res?.errcode == 0) {
          this.getSearchHistory();
          // this.searchHelpShow = false;
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 点击/enter搜索按钮 搜全站
    searchMsg() {
      if (this.searchKey.trim() === '') return;
      if (this.oldsearchKey === this.searchKey && this.supplierId == '') {
        this.$router.go(0);
      }
      // 注释掉下面几行代码的原因：
      // 1）执行到当前行时，this.searchKey.trim() 必然不等于''，因为等于''的值会在上一行return
      // 2）路由拦截器 beforeEach 会把无token的用户强制弹回 /login，不需要在这里进行判断
      // if (!this.token && this.searchKey.trim() !== '') {
      //   this.$router.push('/login');
      //   return;
      // }
      let searchKey = encodeURIComponent(this.searchKey.trim());
      window.open('/keywords=' + searchKey);
      // this.$router.push('/keywords=' + searchKey);
    },
    // 点击搜索本店
    searchStoreMsg() {
      if (this.searchKey.trim() === '') return;
      if (this.oldsearchKey === this.searchKey) {
        this.$router.go(0);
      }
      // 注释掉下面几行代码的原因：
      // 1）执行到当前行时，this.searchKey.trim() 必然不等于''，因为等于''的值会在上一行return
      // 2）路由拦截器 beforeEach 会把无token的用户强制弹回 /login，不需要在这里进行判断
      // if (!this.token && this.searchKey.trim() !== '') {
      //   this.$router.push('/login');
      //   return;
      // }
      window.open(
        '/shop_' +
          this.supplierIDStore +
          '.html/keyword=' +
          encodeURIComponent(this.searchKey.trim())
      );
      // this.$router.push(
      //   '/shop_' +
      //     this.supplierIDStore +
      //     '.html/keyword=' +
      //     encodeURIComponent(this.searchKey.trim())
      // );
    },
  },
};
