
import { language } from '../static/switchLanguage';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  data() {
    return {
      languageObj: language,
      currentLanguage: {},
    };
  },
  computed: {
    currLanguage() {
      return this.$store.state.common.currLanguage;
    },
  },
  watch: {
    currLanguage: {
      handler(val) {
        if (val == 'zh-cn') {
          // 中文
          this.currentLanguage = this.languageObj.chObj;
        } else if (val == 'en') {
          // 英文
          this.currentLanguage = this.languageObj.enObj;
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 回去首页
    goHome() {
      if (!localStorage.getItem(CUR_TOKEN_NAME)) {
        this.$router.push('/login');
        return;
      }
      this.$router.push('/');
    },
  },
};
