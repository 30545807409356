
import { language } from '../static/switchLanguage';
import { getSupAddrList } from '@/api/client-api/mine/mineapi.js';
import newAddress from './newAddress.vue';
export default {
  components: { newAddress },
  data() {
    return {
      languageObj: language,
      currentLanguage: {},
      subaddlist: [], // 地址数据
      currrentaddress: {}, // 当前选择的地址数据
      addressShow: false,
      addressTitle: '', // 地址弹框title
      currentOperaObj: {}, // 当前操作的地址对象
      operaType: '1', // 操作类型
      temporaryAddress: [], // 临时地址
      temporaryIndex: 0, // 临时地址序列
      showClose: true,
      wareType: '1', // 1是国内仓，2是跨境仓，3是海外仓
      eu: 0, // 是否是欧盟
      countryId: '', // 仓库所在地id
      wareId: '', // 仓库ID

      isAdd: '', //是否为新增编辑操作 ''-首次进入的情况 add-新增 editor-编辑
      editedAddressId: '', // 编辑成功的地址对象 id
    };
  },
  props: {
    addressInfo: {
      type: Object,
      default: () => {},
    },
    // 是否拥有 sku 优惠
    hasSkuSubsidy: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    currLanguage() {
      return this.$store.state.common.currLanguage;
    },
  },
  watch: {
    currLanguage: {
      handler(val) {
        if (val == 'zh-cn') {
          // 中文
          this.currentLanguage = this.languageObj.chObj;
        } else if (val == 'en') {
          // 英文
          this.currentLanguage = this.languageObj.enObj;
        }
      },
      immediate: true,
    },
    addressInfo: {
      handler(obj) {
        // console.log(obj, "仓库ID");
        if (obj.wareType) {
          this.wareType = obj.wareType;
          this.eu = obj.eu;
          this.countryId = obj.countryId;
          this.wareId = this.$route.query.war_id;
          this.getInit();
        }
      },
      immediate: true,
    },
  },
  async mounted() {},
  methods: {
    // 初始化地址管理
    async getInit() {
      await this.getSupAddrList();
      if (this.subaddlist.length == 0) {
        // this.newaddressshow(1);
        this.operaType = 1;
        this.addressShow = true;
        this.showClose = false;
        if (this.currLanguage == 'zh-cn') {
          this.addressTitle = '新增收货地址';
        } else {
          this.addressTitle = 'Add shipping address';
        }
      }
    },
    // 获取地址
    getSupAddrList() {
      let options = {
        pages: 1,
        pageSize: 100000,
        API_LANG_TYPE: this.currLanguage,
        war_id: this.wareId,
        MUTE_WARNING: 1,
      };
      return new Promise((resolve, reject) => {
        getSupAddrList(options)
          .then((res) => {
            if (res.errcode == 0) {
              let list = res.data.data;
              list.forEach((item) => {
                item.temporary = false; // 是否是临时地址
              });
              this.subaddlist = list;

              /**
               * 1.首次进入下单页面，有默认的自动选默认的（第一个）
               * 2,首次进入，地址列表中没有默认地址的情况，默认选第一个
               * 3.如果在下单页面新增了地址，默认选中新增的地址
               */
              if (!this.isAdd) {
                this.checkaddress(this.subaddlist?.[0] || null);
              } else if (this.isAdd == 'add') {
                // 新增地址的情况
                let latestObject = null;
                let latestTime = 0;

                this.subaddlist?.forEach((item) => {
                  if (!item.create_time) return; // 确保对象有 create_time 属性
                  const time = new Date(item.create_time);
                  if (time > latestTime) {
                    latestTime = time;
                    latestObject = item;
                  }
                });
                this.checkaddress(latestObject || null);
              } else if (this.isAdd === 'editor' && this.editedAddressId) {
                const editedAddressInfo = this.subaddlist?.find(
                  (x) => x?.id === this.editedAddressId
                );
                editedAddressInfo && this.checkaddress(editedAddressInfo);
              }
              resolve();
            } else {
              reject();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },
    checkaddress(item) {
      if (!item) return;
      this.endcityid = item.country_id;
      // 切换地址
      // this.getItemFreight(item.country_id, item.province_id);
      this.currrentaddress = item;
      this.endcountname = item.countries_name;
      if (
        item.province_id == 35 ||
        item.province_id == 36 ||
        item.province_id == 56 ||
        item.province_id == 43 ||
        item.province_id == 67 ||
        item.province_id == 68
      ) {
        this.endcityname = item.province_name;
      } else {
        this.endcityname = item.city_name;
      }
      this.$emit('checkaddress', this.currrentaddress);
    },
    // 管理收货地址
    mangeaddrress() {
      this.$router.push('/mine/addressmannge');
    },
    // 新建地址
    newaddressshow(type) {
      // 1是新地址，2是临时地址
      this.operaType = type;
      this.addressShow = true;
      this.currentOperaObj = {};
      this.showClose = true;
      if (type == 1) {
        if (this.currLanguage == 'zh-cn') {
          this.addressTitle = '新增收货地址';
        } else {
          this.addressTitle = 'Add shipping address';
        }
      } else {
        if (this.currLanguage == 'zh-cn') {
          this.addressTitle = '临时收货地址';
        } else {
          this.addressTitle = 'Temporary shipping address';
        }
      }
    },
    // 修改地址
    editaddshow(obj, index) {
      // index == -1 ,是修改正式地址
      // 3是编辑地址，4是编辑临时地址
      this.currentOperaObj = obj;
      this.addressShow = true;
      this.showClose = true;
      this.addressTitle = '编辑地址';
      if (index == -1) {
        this.operaType = '3';
      } else {
        this.temporaryIndex = index;
        this.operaType = '4';
      }
    },
    // 新建/编辑成功，刷新地址
    getAddress(type = '', editId) {
      // 新建地址
      this.isAdd = type;
      this.editedAddressId = editId ?? '';
      // this.currentOperaObj = {};
      this.getSupAddrList();
      this.addressShow = false; // 关闭弹框
      this.showClose = true;
    },
    // 获取临时地址
    getTemporary(obj, type, index) {
      if (type == 2) {
        // 新增临时地址
        this.temporaryAddress.unshift(obj);
        this.checkaddress(obj);
      } else if (type == 4) {
        this.temporaryAddress.splice(index, 1, obj);
        if (this.currrentaddress?.id === obj?.id) {
          this.checkaddress(obj);
        }
      }
      this.$forceUpdate();
      this.addressShow = false; // 关闭弹框
      this.showClose = true;
    },
    closeAddress() {
      this.addressShow = false; // 关闭弹框
      this.showClose = true;
    },
  },
};
