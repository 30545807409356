
import { setExtendDeliveryTime } from '@/api/client-api/trade/orderlist.js';
export default {
  data() {
    const checkInput = (rule, value, callback) => {
      if (!this.formobj.extendDays) {
        callback(new Error('请输入具体的延长天数'));
      } else {
        callback();
      }
    };
    return {
      formobj: {
        extendDays: '',
      },
      rules: {
        extendDays: [{ validator: checkInput, trigger: ['blur', 'change'] }],
      },
    };
  },
  props: {
    tid: {
      type: String,
      default: '',
    },
    timeStr: {
      type: String,
      default: '',
    },
    // 可延长天数
    extendableDays: {
      type: String,
      default: '',
    },
  },
  methods: {
    // 关闭弹框
    handleClose() {
      this.$emit('close-dialog');
    },
    // 确认延长收货
    extendReceipt() {
      this.$refs.formobj.validate((valid) => {
        if (valid) {
          let option = {
            tid: this.tid,
            extend_time: this.formobj.extendDays,
          };
          setExtendDeliveryTime(option)
            .then((res) => {
              if (res.errcode == 0) {
                if (res?.msg) this.$message.success(res?.msg);
                this.$emit('close-extend');
              } else if (res?.errcode == 20001) {
                // 需要关闭弹窗&重新获取数据
                this.$emit('close-extend');
              }
            })
            .catch((err) => {
              console.log(err, 'err');
            });
        }
      });
    },
    // 正则表达式校验，延迟天数
    oninput() {
      this.formobj.extendDays = this.formobj.extendDays
        .replace(/^0/g, '1') // 0不可以开头，0要变成1
        .replace(/[^\d]/g, ''); // 只能输入数字
      if (Number(this.formobj.extendDays) > Number(this.extendableDays)) {
        this.formobj.extendDays = this.extendableDays;
      }
    },
  },
};
