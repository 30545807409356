import request from '@/utils/request.js';
// 商品需求
//现有列表
export function getList(data) {
  return request({
    url: 'ItemNeed/getList',
    method: 'get',
    apiModule: 'goods',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
//新品列表
export function getNewList(data) {
  return request({
    url: 'ItemNeedNew/getList',
    method: 'get',
    apiModule: 'goods',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 通过商品ID获取商品标题和ID
export function getItemIdTitleList(data) {
  return request({
    url: 'item/getItemIdTitleList',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 新增现有商品需求
export function addItemNeed(data) {
  return request({
    url: 'ItemNeed/addItemNeed',
    apiModule: 'goods',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
// 新增新品商品需求
export function addNewItemNeed(data) {
  return request({
    url: 'ItemNeed/addNewItemNeed',
    apiModule: 'goods',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}

// 接受/不接受（完成开发状态）
export function dealNeedResult(data) {
  return request({
    url: 'ItemNeed/dealNeedResult',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
