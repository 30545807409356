
import SkuCompareItem from './SkuCompareItem.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'XmSkuCompareColumn',
  components: { SkuCompareItem },
  props: {
    // 当前页面的sku对象
    currSku: {
      type: Object,
      default: () => {
        // return {
        //   sku_id: '',
        //   item_id: '', // 商品id
        //   title: '', // 商品标题
        //   price: '', // 商品价格（有优惠价格就用优惠价格，无则用价格）
        //   imgUrl: '', // 展示在对比栏的图片（有sku图片就用sku图片，无则用商品主图）
        //   mainImgUrl: '', // 商品主图（用于跳转商品详情）
        // };
      },
    },
  },
  data() {
    return {
      // 空的sku对象
      emptySku: {
        isEmpty: true,
      },
      tip: '', // 对比栏顶部展示的提示语
    };
  },
  computed: {
    ...mapGetters('good', [
      'getGoodsCompareList',
      'getGoodsCompareSize',
      'getGoodsCompareItemIndexBySkuId',
    ]),
    // 当前的商品对比列表
    list() {
      let currList = this.getGoodsCompareList.slice();
      let maxNum = this.$store.state.good.maxGoodsCompareNum;

      while (currList.length < maxNum) {
        // 填充空sku对象
        let newObj = Object.assign({}, this.emptySku);
        currList.push(newObj);
      }
      return currList;
    },
    // 当前商品对比的数量
    compareNum() {
      let size = this.getGoodsCompareSize;
      return size;
    },
  },
  methods: {
    // 当前sku currSku 是否已存在商品对比栏中
    isInCompareCol(skuId) {
      if (!skuId) {
        return false;
      }
      let hasExist = this.getGoodsCompareItemIndexBySkuId(skuId) >= 0;
      return hasExist;
    },
    /**
     * 商品对比栏顶部显示提示语
     * @param { String } tip 提示语内容
     */
    showTip(tip) {
      this.tip = tip;
      // 五秒后提示语消失
      setTimeout(() => {
        this.tip = '';
      }, 5000);
    },
    /**
     * 向商品对比栏插入sku
     * 暴露给父组件调用的工具函数
     * @param { Object } skuObj - sku对象
     * @param { String } skuObj.sku_id
     * @param { String } skuObj.item_id - 商品id
     * @param { String } skuObj.title - 商品标题
     * @param { String } skuObj.price - 商品的优惠价格（若无优惠则用价格）
     * @param { String } skuObj.imgUrl - sku的图片（若无则用商品主图）
     * @param { String } skuObj.mainImgUrl - 商品主图
     */
    insertSku(skuObj) {
      let hasExist = this.isInCompareCol(skuObj?.sku_id);
      if (hasExist) {
        return;
      }
      if (this.compareNum >= 4) {
        this.showTip('对比栏已满，最多选择4款商品进行对比哦！');
        return;
      }
      const payload = {
        data: skuObj,
      };

      this.$store.dispatch('good/insertGoodsCompareList', payload);
    },
    /**
     * 从商品对比栏删除sku
     * 暴露给父组件调用的工具函数
     * @param { String } skuId
     */
    deleteSku(skuId) {
      if (this.compareNum <= 0) {
        return;
      }
      const payload = {
        sku_id: skuId,
      };
      this.$emit('delete', skuId);
      this.$store.dispatch('good/deleteGoodsCompareItemBySkuId', payload);
    },

    /**
     * 页面触发事件
     */

    // 点击隐藏按钮
    handleClickHide() {
      this.$emit('hide');
    },
    /**
     * 删除商品对比栏中的指定sku_id商品
     * @param { String } skuId
     */
    handleClickDeleteSku(skuId) {
      this.deleteSku(skuId);
    },
    // 清空商品对比列表
    handleClickClear() {
      this.$store.dispatch('good/clearGoodsCompareList');
      this.$emit('clear');
    },
    // 跳转商品对比页面
    handleClickCompare() {
      let list = this.getGoodsCompareList;
      let itemIds = list.map((x) => x.item_id),
        skuIds = list.map((x) => x.sku_id);
      let comparePage = this.$router.resolve({
        path: '/goods/contrast',
        query: { item_id: itemIds.join(','), sku_id: skuIds.join(',') },
      });
      window.open(comparePage.href, '_blank');
    },
  },
  mounted() {
    // 打开商品对比栏，若有sku对象则自动将当前商品页的sku加入对比栏
    if (this.currSku && !this.isEmptyObject(this.currSku)) {
      this.insertSku(this.currSku);
    }
  },
};
