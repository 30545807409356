
export default {
  data() {
    return {
      currentPages: this.pages,
      currentPagesize: this.pagesize,
      currentTotal: this.total,
    };
  },
  props: {
    pages: {
      type: Number,
      default: 1,
    },
    pagesize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    total(n) {
      this.currentTotal = n;
    },
    // pages(n, o) {
    //   if (n) {
    //     this.currentPages = n;
    //     this.handleCurrentChange(this.currentPages);
    //   }
    // },
    pagesize(n) {
      this.currentPagesize = n;
    },
  },
  computed: {
    isXmPaged() {
      if (this.total > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.$emit('go-pages', val);
    },
  },
};
