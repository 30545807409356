
import GoodsSort from './components/GoodsSort.vue';
import CarouselPic from './components/CarouselPic.vue';
import NotLogBulletin from './components/NotLogBulletin.vue';
import AlreadyLogBulletin from './components/AlreadyLogBulletin.vue';
// 变量
import {
  curDefaultLanguage,
  CUR_TOKEN_NAME,
} from '@/utils/cur_default_config.js'; //默认语言
export default {
  name: 'PlateJoinContainer',
  components: {
    GoodsSort,
    CarouselPic,
    NotLogBulletin,
    AlreadyLogBulletin,
  },
  props: {
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
    // 类目
    CategoryTree: {
      type: Array,
      default: [],
    },
    // 公告列表
    bulletinList: {
      type: Array,
      default: () => [],
    },
    // 轮播图
    carouselList: {
      type: Array,
      default: () => [],
    },
    // 控制类目是否能使用
    isCategory: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      token: '',
    };
  },
  mounted() {
    if (process.client) {
      this.token = this.$cookies.get(CUR_TOKEN_NAME);
    }
  },
  methods: {},
};
