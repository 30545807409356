
export default {
  name: 'SelectCategory',
  props: {
    value: {},
    checkStrictly: {
      type: Boolean,
      default: true,
    },
    cascaderFlag: '',
    placeholderProps: {
      type: String,
      default: '全部类目',
    },
  },
  data() {
    return {
      categoryList: [],
      keyindex: 0,
    };
  },
  mounted() {
    this.categoryList = this.$store.state.category.categoryData; // 获取类目
  },
  computed: {
    cascaderValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    categoryOptions() {
      return {
        label: 'name',
        value: 'supplier_class_id',
        children: '_child',
        checkStrictly: this.checkStrictly,
      };
    },
  },
  methods: {
    valchang(val) {
      if (!val && !this.categoryList.length) {
        this.keyindex++;
      }
    },
    changeFunc(data) {
      if (!data.length) {
        this.keyindex++;
      }
      this.$refs.xmRadioCascade.dropDownVisible = false;
      this.$emit('change', data, this.cascaderFlag);
    },
  },
};
