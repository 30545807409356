
import { getEvaluateReplyDetail } from '@/api/client-api/trade/orderlist.js';
export default {
  name: 'EvaluateDetailDialog',
  props: {
    tid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      colors: ['#FF7802', '#FF7802', '#FF7802'],
      evaluateInfo: {},
      mediaData: [],
      replyImgData: [],
      showPreviewMedia: false, // 预览图片、视频
      showReplyImg: false, // 供应商回复预览
      currentMeidaIndex: -1,
      currentReplyImgIndex: -1,
    };
  },
  filters: {
    // 判断是否有值
    emptyValFilter(val) {
      return !val ? '--' : val;
    },
  },
  mounted() {
    this.getEvaluateDialogDetail();
  },
  methods: {
    handleClickToGoodsDetail() {
      let currency =
        this.evaluateInfo?.currency == '￥' ||
        this.evaluateInfo?.currency == '¥'
          ? '2'
          : '3';
      this.toCachedShopdetail(
        this.evaluateInfo.item_id,
        this.evaluateInfo.image_default_id,
        this.evaluateInfo.sku_lowest_price_img,
        '_blank',
        currency
      );
    },
    previewMedia(index) {
      this.currentMeidaIndex = index;
      this.showPreviewMedia = true;
    },
    previewMediaReply(index) {
      this.currentReplyImgIndex = index;
      this.showReplyImg = true;
    },
    closePreviewMedia() {
      this.showPreviewMedia = false;
      this.showReplyImg = false;
    },
    // 获取评价详情
    async getEvaluateDialogDetail() {
      try {
        let option = {
          tid: this.tid,
          MUTE_WARNING: 1,
        };
        let res = await getEvaluateReplyDetail(option);
        if (res?.errcode == 0) {
          this.evaluateInfo = res.data || {};
          this.evaluateInfo.score = Number(this.evaluateInfo?.score || 0);
          this.evaluateInfo.logistics_score = Number(
            this.evaluateInfo?.logistics_score || 0
          );
          this.evaluateInfo.reply_score = Number(
            this.evaluateInfo?.reply_score || 0
          );

          let imageData = [],
            videoData = [];

          this.evaluateInfo?.img?.forEach((item) => {
            imageData?.push({ url: item, type: 'image' });
          });
          if (this.evaluateInfo?.video?.length > 0) {
            this.evaluateInfo?.video?.forEach((item) => {
              videoData?.push({ url: item, type: 'video' });
            });
          }

          this.evaluateInfo?.reply_img?.forEach((item) => {
            this.replyImgData?.push({ url: item, type: 'image' });
          });

          this.mediaData = [...imageData, ...videoData];
          // console.log('mediaData', this.mediaData);

          // this.$emit('get-media-info', this.mediaData);
          // this.$emit('get-reply-img', this.replyImgData);
        }
      } catch (err) {
        console.error(err);
      }
    },

    closeDialog() {
      this.$emit('close-detail');
    },
  },
};
