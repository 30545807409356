
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  props: {
    maxNumber: {
      //最大上传数量(传0标识详情专用,不能删除)
      type: Number,
      default: 3,
    },
    file_type: {
      //支持哪些文件类型
      type: Array,
      default: () => [],
    },
    propFileArray: {
      //回显专用
      type: [Array, String],
      default: () => [],
    },
    flagType: {
      //当同一个页面多处使用时区分标识
      type: String,
      default: '',
    },
    singleMaxSize: {
      //单次(图片/文档)最大上传大小
      type: Number,
      default: 5,
    },
    singleVideoMaxSize: {
      //单次视频最大上传大小
      type: Number,
      default: 200,
    },
    isPreview: {
      //是否展示预览图标
      type: Boolean,
      default: false,
    },
    isdel: {
      //是否展示删除图标
      type: Boolean,
      default: true,
    },
    noButton: {
      //是否是点击按钮触发
      type: Boolean,
      default: true,
    },
    isSingle: {
      //是否单张上传(传string或array都行)
      type: Boolean,
      default: false,
    },
    //是否禁止上传
    isDisabled: {
      type: Boolean,
      default: false,
    },
    //多种图片之间的右外边距
    imgMarginRight: {
      type: String,
      default: '6px',
    },
    //后端限制文件大小，不传默认5M
    limitType: {
      type: String,
      default: '',
    },
    //是否是详情
    isDetails: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    //回显
    propFileArray: {
      handler() {
        // //多文件回显
        if (!this.isSingle) {
          let imgArr = [];
          this.fileArray = [];
          imgArr = JSON.parse(JSON.stringify(this.propFileArray));
          imgArr.forEach((item) => {
            const options = {
              link: item,
              name: item,
            };
            this.fileArray.push(options);
          });
          this.fileArray.forEach((item, index) => {
            this.imgCheckType(item);
          });
        } else {
          //单文件回显
          this.fileArray = [];
          if (this.propFileArray) {
            this.fileArray.push(this.propFileArray);
            this.imgCheckType(this.propFileArray);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      showPreviewdoc: false, //控制文档弹窗
      // isDisabled: false, //控制能否禁用上传
      failure: '上传失败', //失败提示词
      conduct: '上传中', //上传中提示词
      token: this.$cookies.get(CUR_TOKEN_NAME), //获取token
      TanChuangflag: false, //控制上传阀门
      fileArray: [
        // {
        //   index: {
        //     link:
        //       'https://img.ximu.cn/test_upload_ZH12304010001_1694414928151018.jpg',
        //     name: 'aaa.jpg',
        //   },
        //   judgment: 'success',
        //   type:''
        // },
        // {
        //   index: {
        //     link: 'test_upload_ZH12304010001_1694416385839712.doc',
        //     name:
        //       '商标不侵权承诺开始就打卡机圣诞卡洒咯技术的卡就是老款的就撒了看得见啊拉开圣诞节雷克萨就打算大卡司打开就(95).doc',
        //   },
        //   judgment: 'error',
        //   type:'doc'
        // },
      ], //存储所有数据
      imgUrl: '', //存储大图url
      showPreviewimg: false, //控制图片弹窗
      currentImgType: '', //记录当前图片状态
      fileNameArray: [], //记录上传素材的名字
      sourceFileArray: [], //存储文件数据
      currentStatus: '', //记录当前上传状态
      fileImgMap: {
        //各种文件类型所展示的封面图
        pdf: require('@/assets/material/pdf.svg'),
        xlsx: require('@/assets/material/excel.svg'),
        xls: require('@/assets/material/excel.svg'),
        doc: require('@/assets/material/word.svg'),
        docx: require('@/assets/material/word.svg'),
        zip: require('@/assets/material/zip.svg'),
        rar: require('@/assets/material/zip.svg'),
        '7z': require('@/assets/material/zip.svg'),
      },
    };
  },
  methods: {
    getCheckClass(judgment) {
      // if (judgment === 'success') {
      return 'el-icon-circle-check check-style';
      // }
      // if (judgment === 'error') {
      //   return 'el-icon-circle-close close-style';
      // }
    },
    //判断文件类型
    isFileType(isDoc, file) {
      if (isDoc) {
        return this.sourceFileArray.some(
          (item) => item.index.link === file && item.type != 'video'
        );
      }
    },
    //获取文档url默认封面
    getDocDefaultImg(file) {
      return this.fileImgMap[
        this.sourceFileArray[
          this.sourceFileArray.reduce((box, item, indexTwo) => {
            item.index.link === file ? box.push(indexTwo) : '';
            return box;
          }, [])[0]
        ].type
      ];
    },
    //失败点击指定区域才能触发上传
    reload() {
      // this.currentStatus = '';
      // setTimeout(() => {
      //   this.isDisabled = false;
      // }, 0);
    },
    //上传失败回调
    uploadError(res) {
      // this.isDisabled = true;
      // this.currentStatus = 'failure';
      this.$message.error(res.msg);
      return false;
    },
    //上传中回调
    progressFunc(progressFunc, res) {},
    //上传上限提示
    exceedFunc() {
      this.$message.warning('上传已上限!');
    },
    //上传前效验图片格式
    beforeUpload(file) {
      let MB = file.size / 1024 / 1024;
      if (MB > this.singleMaxSize) {
        this.$message.warning(
          `已超出单个上传最大限制,单个最大限制${this.singleMaxSize}MB!`
        );
        return false;
      } else if (
        file.type.indexOf('video/') != -1 &&
        MB > this.singleVideoMaxSize
      ) {
        this.$message.warning(
          `已超出单个上传最大限制,单个最大限制${this.singleVideoMaxSize}MB!`
        );
        return false;
      }
      let arr = file.name.split('.') || [];
      let fileSuffix = arr.length ? arr[arr.length - 1] : '';
      if (fileSuffix) {
        let temp = this.file_type.some(
          (item) => item.toLowerCase() == fileSuffix.toLowerCase()
        );
        if (!temp) {
          this.$message({
            type: 'warning',
            message: '此处不支持该类型文件上传!',
          });
          return false;
        }
      } else {
        this.$message({ type: 'warning', message: '上传异常!' });
        return false;
      }
      if (this.fileArray.length + 1 > this.maxNumber) {
        this.$message({
          type: 'warning',
          message: '已超出最大上传个数!',
        });
        return false;
      }
      // 当能够进入下一阶段时需要的东西
      console.log(file, 123123);
      return true;
    },
    //详情页点击查看大图
    showImgFunc(file, noImg, flag) {
      if (flag) {
        let temp = this.matchType(file);
        if (temp) {
          if (temp == 'image' || temp == 'video') {
            noImg = false;
          } else {
            noImg = true;
          }
        } else {
          this.$message({ type: 'warning', message: '文件/图片格式异常!' });
          return;
        }
      }
      this.imgUrl = this.$options.filters.imgbaseurl(file);
      if (noImg) {
        this.showPreviewdoc = true;
      } else {
        this.showPreviewimg = true;
      }
    },
    //关闭预览弹窗
    previewCloseFunc() {
      this.showPreviewimg = this.showPreviewdoc = false;
    },
    //删除图片
    delImage(index) {
      let flag = -1;
      this.sourceFileArray.filter((item, indexTwo) => {
        if (item.index.link === index.link) {
          flag = indexTwo;
        }
      });
      flag != -1 ? this.sourceFileArray.splice(flag, 1) : '';
      this.fileArray.splice(index, 1);
      this.fileNameArray.splice(index, 1);
      let imgArr = [];
      this.fileArray.forEach((item) => {
        imgArr.push(item.link);
      });
      this.$emit(
        'statuChangeEvent',
        JSON.parse(JSON.stringify(imgArr)),
        this.flagType,
        this.fileNameArray.slice()
      );
    },
    //上传成功回调
    uploadSuccess(res) {
      this.isDisabled = false;
      this.currentStatus = '';
      if (res.errcode == 0) {
        // 暂存原名和服务器返回的名字
        const options = {
          link: res.data.link,
          name: res.data.name,
          // judgment: 'success',
        };
        this.imgCheckType(options, false);
        this.fileArray.push(options);
        this.fileNameArray.push(res.data.name);
        let imgArr = [];
        this.fileArray.forEach((item) => {
          imgArr.push(item.link);
        });
        this.$emit(
          'statuChangeEvent',
          JSON.parse(JSON.stringify(imgArr)),
          this.flagType,
          this.fileNameArray.slice()
        );
      } else {
        this.$message({ type: 'warning', message: res.msg });
      }
    },
    //判断具体是什么文件
    imgCheckType(file) {
      //判断文件类型
      let flag = file.link.split('.')[1];
      if (flag == 'pdf') {
        this.sourceFileArray.push({
          index: file,
          type: flag,
        });
      } else if (flag == 'doc' || flag == 'docx') {
        this.sourceFileArray.push({
          index: file,
          type: flag,
        });
      } else if (flag == 'xls' || flag == 'xlsx') {
        this.sourceFileArray.push({
          index: file,
          type: flag,
        });
      } else if (flag == 'zip' || flag == 'rar' || flag == '7z') {
        this.sourceFileArray.push({
          index: file,
          type: flag,
        });
      } else if (this.matchType(file) == 'video') {
        this.sourceFileArray.push({
          index: file,
          type: 'video',
        });
      }
    },
  },
  computed: {
    //限制上传文件类型
    getAcceptData() {
      return this.file_type.map((item) => '.' + item).join(',');
    },
  },
};
