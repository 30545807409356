
// 组件
import GoodsCardsBox from './GoodsCardsBox.vue';
export default {
  name: 'PlateGoodsShowBox',
  components: { GoodsCardsBox },
  props: {
    // 商品列表
    goodsList: {
      type: Array,
      default: () => [],
    },
    // 当前列表应有长度
    goodsListLength: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      scrollOffset: 0, // 当前滚动的距离
      scrollNum: 0, // 滚动的次数
    };
  },
  computed: {},
  mounted() {
    // 防抖
    this.scrollLeftBtn = this._debounce(this.scrollLeft, 300);
    this.scrollRightBtn = this._debounce(this.scrollRight, 300);
  },
  methods: {
    // 点击向左滚动
    scrollLeft() {
      if (!this.scrollOffset) return;
      this.scrollOffset = this.scrollOffset + 1020;
      this.scrollNum--;
    },
    // 点击向右滚动
    scrollRight() {
      // 滚动次数等于数组应有长度-1 即滚动完成
      if (this.scrollNum == this.goodsListLength - 1) return;
      // 如果当前的数据长度小于应有的长度，即需要获取接口数据
      if (this.goodsList.length < Number(this.goodsListLength)) {
        this.$emit('scroll-right');
      }
      this.scrollOffset = this.scrollOffset - 1020;
      this.scrollNum++;
    },
  },
};
