
import leftmenu from '@/components/comme/leftmenu.vue';
export default {
  name: 'trade',
  components: {
    leftmenu,
  },
  data() {
    return {
      leftname: '订单管理',
      menulist: [
        '全部订单',
        // '授权店铺订单',
        '待支付',
        '支付处理中',
        '待发货',
        '待收货',
        '评价管理',
        '退款&纠纷',
      ],
      routerlist: [
        '/trade/allorder',
        // '/trade/authorizedStoreOrder',
        '/trade/nopay',
        '/trade/paymentprocessing',
        '/trade/nodelivergoods',
        '/trade/noreceived',
        '/trade/noevaluate',
        '/trade/disputeslist',
      ],
      routerIndexHash: {
        '/trade/allorder': 0,
        '/trade/orderoetails': 0,
        '/trade/nopay': 1,
        '/trade/paymentprocessing': 2,
        '/trade/nodelivergoods': 3,
        '/trade/noreceived': 4,
        '/trade/noevaluate': 5,
        '/trade/orderevaluation': 5,
        '/trade/disputeslist': 6,
        '/trade/applyforrefund': 6,
        '/trade/disputes': 6,
      },
    };
  },
  methods: {},
  computed: {},
  watch: {
    $route(to, from) {
      switch(to.path){
        case '/trade/allorder':
          this.routerIndexHash['/trade/orderdetails'] = 0
        break;
        case '/trade/nopay':
          this.routerIndexHash['/trade/orderdetails'] = 1
        break;
        case '/trade/paymentprocessing':
          this.routerIndexHash['/trade/orderdetails'] = 2
        break;
        case '/trade/nodelivergoods':
          this.routerIndexHash['/trade/orderdetails'] = 3
        break;
        case '/trade/noreceived':
          this.routerIndexHash['/trade/orderdetails'] = 4
        break;
      }
      this.$forceUpdate()
    },
  },
  mounted() {},
};
