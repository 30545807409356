
// import { getBulletinList } from '@/utils/api/home/home';
// import vueSeamlessScroll from 'vue-seamless-scroll';
/**
 * 多语言配置
 */
import { NOT_LOGIN_FONT } from '../js/label_config_bulletin';
// 变量
import { curDefaultLanguage } from '@/utils/cur_default_config.js'; //默认语言
export default {
  name: 'NotLogBulletin',
  // components: { vueSeamlessScroll },
  props: {
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
    // 公告列表
    bulletinList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // bulletinList: [], // 公告列表
      // scrollSpeed: 0, // 滚动速度
      // scrollNum: 0, // 滚动轮数
      // pageSize: 12, // 内容数
      // pageNum: 1, // 页码
    };
  },
  computed: {
    // optionScrollData() {
    //   this.scrollSpeed = this.bulletinList.length < 4 ? 0 : 0.1;
    //   return {
    //     step: this.scrollSpeed, // 数值越大速度滚动越快
    //     limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
    //     hoverStop: true, // 是否开启鼠标悬停stop
    //     direction: 1, // 0向下 1向上 2向左 3向右
    //     openWatch: true, // 开启数据实时监控刷新dom
    //     singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
    //     singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
    //     waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    //   };
    // },
    // 标签多语言配置
    langConfig() {
      return NOT_LOGIN_FONT[this.lang || curDefaultLanguage];
    },
    // 滚动轮数
    scrollNum() {
      return Math.ceil(this.bulletinList.length / 4);
    },
  },
  mounted() {
    // this.getBulletin(); // 获取公告列表
  },
  methods: {
    // 跳转注册
    register() {
      this.$router.push('/register/shop');
    },
    // 跳转登录
    login() {
      this.$router.push('/login');
    },
    // 获取公告列表
    // getBulletin() {
    //   let options = {
    //     pageSize: this.pageSize,
    //     pageNum: this.pageNum,
    //   };
    //   this.$getBulletinList(options).then((res) => {
    //     if (res.errcode === 0 && res.data.list) {
    //       if (res.data.list.length > 12) {
    //         this.bulletinList = res.data.list.slice(0, 12);
    //       } else {
    //         this.bulletinList = res.data.list;
    //       }
    //       this.scrollNum = Math.ceil(this.bulletinList.length / 4);
    //     } else {
    //       this.$message.warning(res.msg);
    //     }
    //   });
    // },
    // 处理vueSeamlessScroll插件复制出来的无事件问题
    // handleClick(event) {
    //   if (event.target.dataset.id) {
    //     this.lookDetail(event.target.dataset.id);
    //   }
    // },
    // 查看公告详情
    lookDetail(id) {
      this.$router.push('/notify/bulletindetail/' + id);
    },
  },
};
