
import searchBox from '@/components/comme/searchBox.vue';
import RatingBoard from './components/RatingBoard';
import IconButton from './components/IconButton';
import CategoryBreadcrumb from './components/CategoryBreadcrumb.vue';

import { BTN_LABELS } from './js/label_config_header';
// 变量
import { curDefaultLanguage } from '@/utils/cur_default_config.js'; //默认语言

export default {
  name: 'DetailHeader',
  components: {
    IconButton,
    searchBox,
    RatingBoard,
    CategoryBreadcrumb,
  },
  props: {
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
    // 供应商ID
    supplierId: {
      type: String,
      default: '',
    },
    // 供应商名称
    supplierName: {
      type: String,
      default: '',
    },
    // 商品ID
    itemId: {
      type: String,
      default: '',
    },
    // sku ID
    skuId: {
      type: String,
      default: '',
    },
    // 仓库ID
    warehouseId: {
      type: String,
      default: '',
    },
    // 商品的评分
    goodScores: {
      type: [Array, Object],
      default: () => [],
    },
    // 商品信息
    goodInfo: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    /**
     * 联系客服的get查询参数
     */
    contactQueryParams() {
      // 当前无skulist，直接返回空数组
      if (!this.skuId || !this.warehouseId) {
        return [];
      }
      const params = [
        { key: 'type', value: 'good' },
        { key: 'item_id', value: this.itemId },
        { key: 'sku_id', value: this.skuId },
        { key: 'ware_id', value: this.warehouseId },
      ];
      return params;
    },
    // 商品类目
    goodCategories() {
      return this.goodInfo?.good_categories || [];
    },
    // 按钮标签
    labelConfig() {
      return BTN_LABELS[this.lang || curDefaultLanguage];
    },
    // 当前商品失效状态 0 未失效  1 供应商已退店  2 仅下架
    failureState() {
      return this.goodInfo?.failure_state ? this.goodInfo?.failure_state : '0';
    },
  },
  methods: {
    /**
     * 点击进入店铺
     */
    handleEnterShop() {
      this.$router.push('/shop_' + this.supplierId + '.html');
    },
  },
};
