/**
 * 支付美元公共语言设置
 */
// 收银台文字
export const CASH_DESK_LABEL = {
  'zh-cn': {
    'cash-desk': '收银台',
    'current-payment': '当前支付币种为',
    currency: '人民币',
  },
  en: {
    'cash-desk': 'Cash desk',
    'current-payment': 'Current payment currency',
    currency: 'USD',
  },
};

// 没有支付密码提示弹窗
export const NO_PASSWORD_TIP = {
  'zh-cn': {
    title: '提示',
    tip: '您还未设置支付密码，请先设置支付密码后再使用余额支付。',
    'set-button': '去设置',
  },
  en: {
    title: 'Tip',
    tip: 'You have not set a payment password yet. Please set a payment password before using the balance for payment.',
    'set-button': 'Go set up',
  },
};

// 余额支付文字
export const BALANCE_PAYMENT = {
  'zh-cn': {
    title: '支付验证',
    tip: '请输入6位数字支付密码',
    forget: '忘记支付密码？',
    'input-tip': '请输入6位数字密码',
    'sure-button': '确认支付',
    'cancel-button': '取消支付',
    'err-tip': (num, time) =>
      `支付密码有误，您还可以输入${num}次，${num}次错误后，密码将被锁定${time}分钟`,
  },
  en: {
    title: 'Payment verification',
    tip: 'Please enter a 6-digit payment password',
    forget: 'Forgot payment password?',
    'input-tip': 'Please enter a 6-digit password',
    'sure-button': 'Confirm',
    'cancel-button': 'Cancel',
    'err-tip': (num, time) =>
      `The payment password is incorrect. You can also enter it ${num} times. After ${num} errors，the password will be locked ${time} minute.`,
  },
};
