import { render, staticRenderFns } from "./SaleArea.vue?vue&type=template&id=ab087fda&scoped=true"
import script from "./SaleArea.vue?vue&type=script&lang=js"
export * from "./SaleArea.vue?vue&type=script&lang=js"
import style0 from "./SaleArea.vue?vue&type=style&index=0&id=ab087fda&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab087fda",
  null
  
)

export default component.exports