
export default {
  data() {
    return {
      limltDetatil: {},
    };
  },
  props: {
    limtObj: {
      type: Object,
      default: () => {},
    },
    limitTip: {
      type: String,
      default: '',
    },
  },

  watch: {
    limtObj: {
      handler(obj) {
        if (Object.keys(obj).length > 0) {
          this.limltDetatil = obj;
        }
      },
      immediate: true,
    },
  },

  methods: {
    handleColoe() {
      this.$emit('closeSupplier');
    },
  },
};
