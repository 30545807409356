/**
 * 落地页
 * -
 */

import LandingPage from '@/pages/landing-page'; // 落地页

const landingPageRouter = [
  // 落地页
  {
    path: '/landing',
    component: LandingPage,
  },
];

export default landingPageRouter;
