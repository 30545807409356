
import invoiceTable from './components/invoiceTable.vue';
import { getInvoiceInfo, invoiceEmail } from '@/api/client-api/fund/invoice.js';
export default {
  components: { invoiceTable },
  data() {
    let checkEmail = (rule, value, callback) => {
      //验证邮箱
      const regEmail =
        /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (this.ruleForm.email != '' && !regEmail.test(this.ruleForm.email)) {
        callback(new Error('请输入有效的邮箱'));
      } else {
        callback();
      }
    };
    return {
      tableTitle: [
        {
          label: '发票代码',
          prop: 'invoice_code',
          width: '152px',
          isedit: true,
        },
        {
          label: '发票号码',
          prop: 'invoice_num',
          width: '96px',
          isedit: true,
        },
        {
          label: '开票方',
          prop: 'drawer',
          width: '80px',
        },

        {
          label: '开票金额(￥)',
          prop: 'amount',
          width: '120px',
        },

        {
          label: '物流公司',
          prop: 'logistics_name',
          width: '78px',
          isedit: true,
        },
        {
          label: '物流单号',
          prop: 'logistics_id',
          width: '78px',
          isedit: true,
        },
        {
          label: '开票时间',
          prop: 'create_time',
          width: '98px',
        },
        {
          label: '红票/蓝票',
          prop: 'invoice_type',
          width: '100px',
        },
        {
          label: '备注',
          prop: 'remark',
          width: '135px',
          isedit: true,
        },
        {
          label: '操作',
          prop: 'opera',
          width: '80px',
          isedit: true,
        },
      ],

      electronicInvoice: [
        // 电子发票
        {
          label: '发票代码',
          prop: 'invoice_code',
          width: '152px',
          isedit: true,
        },
        {
          label: '发票号码',
          prop: 'invoice_num',
          width: '106px',
          isedit: true,
        },
        {
          label: '开票方',
          prop: 'drawer',
          width: '228px',
        },

        {
          label: '开票金额(￥)',
          prop: 'amount',
          width: '120px',
        },

        {
          label: '开票时间',
          prop: 'create_time',
          width: '99px',
        },
        {
          label: '红票/蓝票',
          prop: 'invoice_type',
          width: '100px',
        },
        {
          label: '备注',
          prop: 'remark',
          width: '135px',
          isedit: true,
        },
        {
          label: '操作',
          prop: 'opera',
          width: '80px',
          isedit: true,
        },
      ],

      lastTableTitle: [], // 最终table

      tableList: [],
      ruleForm: {
        email: '',
      },
      rules: {
        email: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' },
        ],
      },
      emailShow: false, // 邮箱弹框
      currentId: '',
      invoiceNum: '',
    };
  },
  mounted() {
    if (this.$route.params.type == 1) {
      // 纸质发票
      this.lastTableTitle = this.tableTitle;
    } else if (this.$route.params.type == 2) {
      // 电子发票
      this.lastTableTitle = this.electronicInvoice;
    }
    this.getdeatil(this.$route.params.applyId);
    this.currentId = this.$route.params.applyId;
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    // 下载发票
    downInvice(obj) {
      this.down(obj.invoice_url, '');
    },
    // 发送邮箱
    sendEmil(obj) {
      this.invoiceNum = obj.invoice_num;
      this.emailShow = true;
    },
    closeEmail() {
      this.emailShow = false;
    },
    configEmail() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let option = {
            application_id: this.currentId,
            invoice_num: this.invoiceNum,
            email_address: this.ruleForm.email,
          };
          invoiceEmail(option)
            .then((res) => {
              if (res?.errcode == 0) {
                this.emailShow = false;
                if (res?.msg) {
                  this.$message.success(res?.msg);
                }
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      });
    },
    getdeatil(id) {
      let option = {
        application_id: id,
        MUTE_WARNING: 1,
      };
      getInvoiceInfo(option)
        .then((res) => {
          if (res?.errcode == 0) {
            this.tableList = res?.data;
            // this.lastTableTitle = this.electronicInvoice;
            // if (res.data?.invoice_medium == 1) {
            //   // 纸质发票
            //   this.lastTableTitle = this.tableTitle;
            // } else if (res.data?.invoice_medium == 2) {
            //   // 电子发票
            //   this.lastTableTitle = this.electronicInvoice;
            // }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
