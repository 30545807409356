
import ReportSearch from './components/search-box/ReportSearch'; // （首次）投诉&举报的搜索栏
import ReportTable from './components/table-box/ReportTable'; // （首次）投诉&举报的表格模版

import { reportBlockConfig, twiceReportBlockConfig } from './js/report_config'; // 投诉的标签页配置(一次/二次)
import {
  launchReportTableConfig,
  receiveReportTableConfig,
  twiceLaunchReportTableConfig,
  twiceReceiveReportTableConfig,
} from './js/report_table_config'; // 投诉的表格配置(一次/二次)

// 弹框相关
import InitiateComplaints from './components/InitiateComplaints'; //发起投诉&举报

// 接口
import { getComplainList } from '@/api/client-api/complaints/complaints.js';

export default {
  name: 'OverviewList',
  components: { ReportSearch, ReportTable, InitiateComplaints },
  data() {
    return {
      activeName: 1, // 选中的主标签页 id
      activeBlock: 'receive', // 选中的次标签页 key

      tabConfig: [
        { id: 1, name: '投诉&举报列表' },
        { id: 2, name: '二次投诉列表' },
      ],

      // 列表相关
      searchData: {}, //搜索的值
      tableList: [], // 表格数据
      listTotal: 0, // 表格数据总数
      pageNum: 1, // 第几页
      pageSize: 10, // 页大小

      // 弹框相关
      // 发起&举报弹框
      showComplaintsDialog: false,
      scene: '1', //投诉场景
    };
  },
  computed: {
    // 当前激活的表单组件的名字
    currSearchItem() {
      switch (this.activeName) {
        // （首次）投诉、举报
        case 1:
          return 'ReportSearch';
        // 二次投诉、举报
        case 2:
          return 'ReportSearch';
        default:
          return '';
      }
    },
    // 当前激活的标签页的板块配置
    currBlockConfig() {
      // #todo 目前做的标签页中，只有一次投诉有次标签页
      // 之后做二次投诉的时候，记得在这里加上二次投诉次标签页的配置
      return this.activeName == 1 ? reportBlockConfig : twiceReportBlockConfig;
    },
    // 当前激活的表格组件名字
    currTableItem() {
      switch (this.activeName) {
        // （首次）投诉、举报
        case 1:
          return 'ReportTable';
        // #todo 二次投诉、举报
        case 2:
          return 'ReportTable';
        // 维权投诉、举报
        default:
          return '';
      }
    },
    // 当前激活的表格配置
    currTableFormat() {
      // 一次投诉的表格
      if (this.activeName == 1) {
        return this.activeBlock == 'launch'
          ? launchReportTableConfig
          : receiveReportTableConfig;
      }
      // 二次投诉的表格
      if (this.activeName == 2) {
        return this.activeBlock == 'launch'
          ? twiceLaunchReportTableConfig
          : twiceReceiveReportTableConfig;
      }
    },
  },
  methods: {
    /**
     * 页面触发
     */
    /**
     * 弹框相关
     */
    // 点击二次投诉列表
    changeTab() {
      this.activeName = 2;
    },
    // 点击“发起投诉/举报”， 打开发起&举报弹框
    handleComplaints() {
      this.scene = '1';
      this.showComplaintsDialog = true;
    },
    // 关闭发起&举报弹框
    handleCloseComplaintsForm() {
      this.showComplaintsDialog = false;
    },
    handleConfimComplaints() {
      this.handleCloseComplaintsForm();
      this.getReceiveReportList();
    },
    /**
     * 列表相关
     */
    // 切换 tab 栏（activeName）
    choseTab(id) {
      this.activeName = id;
      this.pageNum = 1;
      this.activeBlock = 'receive';
      this.handleSearch();
    },
    // 切换小标题栏（activeBlock）
    handleChangeBlock() {
      this.handleSearch();
    },
    // 表单搜索
    handleSearch(options) {
      this.searchData = options;
      this.pageNum = 1;
      this.getList();
    },
    // 改变列表页码
    handleChangePage(num, pageSize) {
      this.pageNum = num;
      if (pageSize) {
        this.pageSize = pageSize;
      }
      this.getList();
    },

    /**
     * 中转
     */
    // 获取列表数据
    getList() {
      let key = `${this.activeName}-${this.activeBlock}`;
      this.getReceiveReportList();
    },

    /**
     * 请求接口
     */

    /**
     * 获取投诉收到/发起
     * @param {Boolean} isCache 控制是否对筛选条件进行缓存
     */
    async getReceiveReportList(isCache = true) {
      try {
        const options = {
          ...this.searchData,
          num_type: this.activeName.toString(),
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        };
        if (this.activeBlock == 'receive') {
          // 获取投诉举报收到数据
          options.send_type = '1';
        }
        if (this.activeBlock == 'launch') {
          // 获取投诉举报发起数据
          options.send_type = '2';
        }
        if (isCache) {
          let searchData = JSON.parse(JSON.stringify(options));
          delete searchData.pageSize;
          this.setPageFilter(searchData);
        }
        delete options.keyword;
        delete options.value;
        let res = await getComplainList(options);
        if (res) {
          if (res?.errcode == 0) {
            this.tableList = res?.data?.list || [];
            this.listTotal = Number(res?.data?.count) || 0;
            // 处理当前页码过大的情况
            if (this.pageNum > 1 && this.tableList?.length == 0) {
              this.handleChangePage(1);
            }
          } else {
            this.listTotal = 0;
            this.tableList = [];
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 更新数据
    updata() {
      this.getReceiveReportList();
    },
  },
  mounted() {
    if (process.client) {
      // 获取vuex中的数据
      let searchInfo = this.getPageFilterData();
      if (searchInfo) {
        this.searchData = JSON.parse(JSON.stringify(searchInfo)); // 搜索条件
        this.pageNum = searchInfo?.pageNum || 1;
        this.activeName = Number(searchInfo?.num_type) || 1; // 一级标签
        this.activeBlock = searchInfo?.send_type == '2' ? 'launch' : 'receive'; // 二级标签
        delete this.searchData.pageNum; // 删除this.searchData.pageNum 避免回显后切换页数数据被覆盖。
        delete this.searchData?.activeName;
        delete this.searchData?.activeBlock;

        this.$nextTick(() => {
          this.$refs.searchFrom.echoSearchData();
        });
      }
      this.getReceiveReportList(false);
    }
  },
};
