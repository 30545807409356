
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  name: 'BackToTop',
  data() {
    return {
      scrollTop: 0,
      btnFlag: false,
      token: '',
    };
  },
  mounted() {
    if (process.client) {
      window.addEventListener('scroll', this.scrollToTop);
      this.token = this.$cookies.get(CUR_TOKEN_NAME);
    }
  },
  beforeDestroy() {
    if (process.client) {
      window.removeEventListener('scroll', this.scrollToTop);
    }
  },
  methods: {
    // 返回顶部
    totop() {
      if (!process.client) {
        return;
      }
      let timer = setInterval(() => {
        let ispeed = Math.floor(-this.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          this.scrollTop + ispeed;
        if (this.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 20);
    },
    scrollToTop() {
      if (!process.client) {
        return;
      }
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 900) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
  },
};
