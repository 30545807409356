
import emojiData from '@/assets/json/emojis-data.json';

export default {
  props: {
    show_arrow: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    categories() {
      return Object.keys(emojiData);
    },

    category_emojis: () => (category) => {
      return Object.values(emojiData[category]);
    },
  },
  methods: {
    handleEmojiClick(e, emoji) {
      e.preventDefault();
      this.$emit('click-emoji', emoji);
    },
    handleClickOutside() {
      this.$emit('close-emoji');
    },
  },
};
