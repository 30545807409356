
import invoiceTable from './invoiceTable.vue';
import replaceInvice from './replaceInvice.vue';
import logisticsDetails from './logisticsDetails.vue';
import {
  orderInvoiceList,
  // getReplaceLogistics,
  getReplaceRejectReason,
} from '@/api/client-api/fund/invoice.js';
export default {
  components: { invoiceTable, replaceInvice, logisticsDetails },
  props: {
    activeName: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      optionData: [
        { label: '申请单号', value: 'application_id' },
        // { label: "供应商ID", value: "supplier_id" },
        // { label: "商品名称", value: "goods_title" },
      ],
      searchObj: {
        key: '',
        value: '',
        invoiceMaker: '', // 开票方
        invoiceType: '', // 发票类型
        state: '', // 发票状态
        invoiceMedium: '', // 发票介质
        time: [], // 申请时间
      },
      tableTitle: [
        {
          label: '申请单号',
          prop: 'application_id',
          width: '152px',
          isedit: true,
        },
        {
          label: '开票方',
          prop: 'attribution',
          width: '130px',
          isedit: true,
        },

        {
          label: '申请时间',
          prop: 'create_time',
          width: '102px',
        },
        {
          label: '订单数量',
          prop: 'order_num',
          width: '96px',
          isedit: true,
        },
        {
          label: '开票金额(￥)',
          prop: 'invoice_amount',
          width: '186px',
        },
        {
          label: '发票类型',
          prop: 'invoice_type',
          width: '84px',
        },
        {
          label: '发票介质',
          prop: 'invoice_medium',
          width: '84px',
        },
        {
          label: '发票状态',
          prop: 'state',
          width: '92px',
        },

        {
          label: '操作',
          prop: 'opera',
          width: '94px',
          isedit: true,
        },
      ],
      tableList: [],
      replaceShow: false,
      totalnum: 0,
      tableSize: 10, // table列表
      tablePageNum: 1, // table分页
      logshow: false,
      reasonShow: false, // 查看原因弹框
      rejectText: '',
      replaceArr: [], // 换开id
      tidArr: [],
      tidShow: false,
      currentId: '', // 当前订单
      attributionCode: '', // 类型，1是平台2是供应商
    };
  },
  methods: {
    // 获取列表数据
    async getTableList(tid) {
      try {
        let option = {
          type: 5,
          pageNum: this.tablePageNum,
          pageSize: this.tableSize,
        };
        // 存数据
        let searchData = Object.assign(
          {
            pageNum: this.tablePageNum,
            type: 5,
            activeName: 2,
          },
          this.searchObj
        );
        this.setPageFilter(searchData);
        // 发票介质
        if (this.searchObj.invoiceMedium) {
          option.invoice_medium = this.searchObj.invoiceMedium;
        }
        // 发票类型
        if (this.searchObj.invoiceType) {
          option.invoice_type = this.searchObj.invoiceType;
        }
        // 开票方
        if (this.searchObj.invoiceMaker) {
          option.invoice_maker = this.searchObj.invoiceMaker;
        }
        // 状态
        if (this.searchObj.state) {
          option.apply_type = this.searchObj.state;
        }

        if (tid) {
          option.tid = tid;
        }
        if (this.searchObj.time) {
          option.time_type = 3;
          option.start_time = this.searchObj.time[0];
          option.end_time = this.searchObj.time[1];
        }
        if (this.searchObj.key && this.searchObj.value) {
          option[this.searchObj.key] = this.searchObj.value;
        }
        const res = await orderInvoiceList(option);
        if (res?.errcode == 0) {
          this.tableList = res?.data?.list;
          this.totalnum = Number(res?.data?.total) || 0;
          // 处理当前页码过大的情况
          if (this.tablePageNum > 1 && this.tableList?.length == 0) {
            this.goPage(1);
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    searchChange() {
      this.tablePageNum = 1;
      this.getTableList();
    },
    xmSelectData(selectValue, inputValue) {
      this.searchObj.key = selectValue;
      this.searchObj.value = inputValue;
      this.tablePageNum = 1;
      this.getTableList();
    },
    selectSortList() {},
    // 开票信息
    inviceingDetail(obj) {
      this.$router.push(`/fund/invoicingInformation/${obj.application_id}`);
    },
    // 发票明细
    invoiceDeatil(obj) {
      this.$router.push(
        `/fund/inviceDetail/${obj.invoice_medium_code}/${obj.application_id}`
      );
    },
    // 物流明细
    logisticsDetails(obj) {
      this.logshow = true;
      this.currentId = obj.application_id;
    },
    // 关闭物流信息弹框
    closelog() {
      this.getTableList();
      this.logshow = false;
    },
    // 申请换开
    replaceInvice(obj) {
      this.replaceArr = [obj.application_id];
      this.attributionCode = obj.attribution_code;
      this.replaceShow = true;
    },
    closeReplace() {
      this.replaceShow = false;
      this.getTableList();
    },
    // 分页
    goPage(index, pageSize) {
      this.tablePageNum = Number(index);
      if (pageSize) {
        this.tableSize = pageSize;
      }
      this.getTableList();
    },
    // 重置
    resetData() {
      this.tablePageNum = 1;
      this.searchObj = this.$options.data().searchObj;
      this.$refs.XmSelect.reVal();
      this.getTableList();
    },
    // 关闭查看原因弹框
    closeFunc() {
      this.reasonShow = false;
    },
    viewReject(obj) {
      let option = {
        application_id: obj.application_id,
      };
      getReplaceRejectReason(option)
        .then((res) => {
          if (res?.errcode == 0) {
            this.rejectText = res?.data?.reject_reason;
            this.reasonShow = true;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 订单展示弹框
    onNum(arr) {
      this.tidArr = arr;
      this.tidShow = true;
    },
    closeTip() {
      this.tidShow = false;
    },
    toDetail(tid) {
      this.$router.push({
        path: '/trade/orderdetails',
        query: { item_id: tid },
      });
    },
  },
  mounted() {
    if (this.$route.params.type == 2 && this.$route.params.tid) {
      let tid = this.$route.params.tid;
      this.getTableList(tid);
    } else {
      // 获取vuex中的数据
      let searchInfo = this.getPageFilterData();
      if (searchInfo) {
        this.searchObj = JSON.parse(JSON.stringify(searchInfo));
        this.tablePageNum = searchInfo?.pageNum;
        this.$nextTick(() => {
          // 下拉搜索回显
          if (this.$refs.XmSelect) {
            this.$refs.XmSelect.reVal(this.searchObj.key, this.searchObj.value);
          }
        });
      }
      this.getTableList();
    }
  },
};
