import request from '@/utils/request.js';
//交易记录-账单明细
export function getTransactionList(data) {
  return request({
    url: '/MoneyManage/getTransactionList',
    method: 'get',
    params: data,
    controller: {
      openLoading:true
    }
  });
}
//交易记录-账单明细
export function getSecurityDepositList(data) {
  return request({
    url: '/MoneyManage/getSecurityDepositList',
    method: 'get',
    params: data,
    controller: {
      openLoading:true
    }
  });
}

// 支付方式
export function getPaymentChannel(data) {
  return request({
    url: '/MoneyManage/getPaymentChannel',
    method: 'get',
    params: data,
  });
}
// 交易汇总
export function getTransactionSummary(data) {
  return request({
    url: '/MoneyManage/getTransactionSummary',
    method: 'get',
    params: data,
  });
}
