/**
 * 临时使用的兼容移动端和 pc 端字段不一致问题的函数
 */

// 获取文本消息内容
export function getMsgText(msg, myAccount) {
  if (msg?.text) return msg.text;
  else if (msg?.body) return msg.body;
  else if (msg?.type == 'tip') {
    // 特殊处理撤回消息
    return `${
      msg.from === myAccount ? '你' : msg.fromNick + ' '
    }撤回了一条消息`;
  } else return undefined;
}

// 获取消息附件对象
export function getMsgContent(msg) {
  if (msg?.content) return JSON.parse(msg.content);
  else if (msg?.attach) return msg.attach;
  else return null;
}

// 获取消息的附件对象中的数据
export function getMsgContentData(msg) {
  const content = getMsgContent(msg);
  return content?.content ?? null;
}

// 获取消息类型
export function getMsgType(msg) {
  if (msg.type === 'text') return 'text';
  if (msg.type === 'recallMsg' || msg.type === 'tip') return 'recall';
  if (msg.type === 'time') return 'time';

  const content = getMsgContent(msg);
  return content?.type !== 'link' ? content.type : content.dataType;
}

/**
 * 获取撤回提示消息的时间
 * 撤回提示消息的时间应该是被撤回消息的时间
 */
export function getRecallMsgTime(msg) {
  const originalMsg = JSON.parse(msg.custom || '{}')?.originalMsg;
  return originalMsg?.time || msg.time;
}
