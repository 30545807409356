
export default {
  data() {
    return {};
  },
  props: {
    // 一级类目
    leaveOneInfo: {
      type: Object,
      default: null,
    },
    // 二级类目
    leaveTwoInfo: {
      type: Object,
      default: null,
    },
    // 当前二级类目所拥有的列表
    leaveTwoList: {
      type: Array,
      default: null,
    },
    // 三级类目
    leaveThreeInfo: {
      type: Object,
      default: null,
    },
    // 当前三级类目所拥有的列表
    leaveThreeList: {
      type: Array,
      default: null,
    },
  },
  methods: {
    // 给二级类目加a链接
    changeleaveTwoId(item) {
      const id = [item.pid, item.supplier_class_id].join(',');
      // todo 本来是二级分类也能跳,但是现在先放弃
      return this.routeJump('/list_' + id + '.html');
    },
    // 给三级类目加a链接
    changeleaveThreeId(n) {
      const id = [this.leaveTwoInfo?.pid, n.pid, n.supplier_class_id].join(',');
      return this.routeJump('/list_' + id + '.html');
    },
  },
};
