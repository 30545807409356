export const TRANSACTIONTYPE = {
  ALL: '1', //全部
  PAY: '2', //支付
  REFUND: '3', //退款
  RECHARGE: '4', //充值
  TRANSFORMONEY: '5', //转账
  DEDUCTION: '6', //扣款
  INCOME: '7', //收入
  WITHDRAW: '8', // 提现
};
