
/**
 * 多语言配置
 */
import { COMMON_LABELS } from '../js/label_config_common';
// 变量
import { curDefaultLanguage } from '@/utils/cur_default_config.js'; //默认语言
export default {
  name: 'saleArea',
  props: {
    saleAreaData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
  },
  computed: {
    // 标签多语言配置
    labelConfig() {
      return COMMON_LABELS[this.lang || curDefaultLanguage];
    },
  },
  methods: {
    closedig() {
      this.$emit('closesale');
    },
  },
};
