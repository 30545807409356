
// import { getCarouselList } from '@/utils/api/home/home';
import { mapGetters } from 'vuex';
export default {
  name: 'CarouselPic',
  data() {
    return {
      carouselList: this.carouselData, // 轮播图
      lang: '',
    };
  },
  props: {
    carouselData: {
      type: Array,
      default: [],
    },
  },
  computed: {
    ...mapGetters({
      currLoading: 'loading/currLoading',
      currLang: 'common/currLang',
    }),
  },
  mounted() {
    if (process.client) {
      this.lang = this.currLang;
      this.getCarousel();
    }
  },
  methods: {
    // 获取轮播图 getCarouselList
    getCarousel() {
      // if (this.carouselData && this.carouselData.length > 0) {
      //   return;
      // }
      let option = {
        API_LANG_TYPE: this.lang,
      };
      this.$getCarouselList(option)
        .then((res) => {
          if (res.errcode == 0) {
            this.carouselList = res.data.list;
            this.$store.dispatch('loading/setLoading', false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // jumpLink(link) {
    //   window.open(link, '_blank');
    // },
  },
};
