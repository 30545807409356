
import { getCategory } from '@/api/client-api/complaints/illegal.js';
export default {
  data() {
    return {
      cate: '', //大类目
      subCate: '', //相对应的小类目
      cateList: [], //大类目列表
      subCateList: [], //大类目对应的小类目列表
    };
  },
  computed: {},
  mounted() {
    this.getData();
    // 回显
    let searchInfo = this.getPageFilterData();
    if (searchInfo) {
      this.cate = searchInfo?.violation_category_id;
      this.subCate = searchInfo?.violation_subcategory_id;
    }
  },
  methods: {
    /**
     * 页面触发
     */
    // 获取数据
    getData() {
      const options = {
        type: '1',
      };
      getCategory(options)
        .then((res) => {
          if (res) {
            if (res?.errcode == 0) {
              let list = JSON.parse(JSON.stringify(res?.data));

              this.cateList = list;
              // 获取大类目数据后 根据回显的cate筛选出小类目的数据
              this.getSubCateList();
            } else {
              this.cateList = [];
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 大类目筛选
    cateSelected() {
      // 每一次选中大类目，要初始化小类目
      this.subCateList = [];
      this.subCate = '';
      // 找出选中大类目下的小类目
      this.getSubCateList();
      if (!this.cate || this.cate == '') {
        // 如果选择全部大类目或者点击叉叉图标
        this.subCateList = [];
        this.subCate = '';
      }
      this.upData();
    },
    // 找出选中大类目下的小类目
    getSubCateList() {
      if (this.cateList && this.cateList?.length > 0 && this.cate != '') {
        // 找出选中大类目相对应的数据
        let selectInfo =
          this.cateList.filter((item) => item.id == this.cate)?.[0] || {};
        if (selectInfo?.children && selectInfo?.children?.length > 0) {
          this.subCateList = JSON.parse(JSON.stringify(selectInfo.children));
        }
      }
    },
    // 传出去的值
    upData() {
      const options = {
        cate: this.cate,
        subCate: this.subCate,
      };
      this.$emit('select-value', options);
    },
    // ref 重置数据用的
    reData() {
      this.cate = '';
      this.subCate = '';
      this.subCateList = [];
    },
  },
};
