// 违规
import request from '@/utils/request.js';

//获取分类问题列表
export function getCategory(data) {
  return request({
    url: '/ComplainReport/getCategory',
    method: 'get',
    params: data,
  });
}

// 获取违规次数
export function violationNum(data) {
  return request({
    url: '/complainReport/violationNum',
    method: 'get',
    params: data,
  });
}

// 获取违规列表
export function getViolationList(data) {
  return request({
    url: '/complainReport/getViolationList',
    method: 'get',
    params: data,
    controller: {
      openLoading:true
    }
  });
}

// 获取违规详情
export function violationsDetails(data) {
  return request({
    url: '/ComplainReport/violationsDetails',
    method: 'get',
    params: data,
    controller: {
      openLoading:true
    }
  });
}

// 违规申诉
export function violationsAppeal(data) {
  return request({
    url: '/ComplainReport/violationsAppeal',
    method: 'post',
    data: data,
    controller: {
      openLoading:true
    }
  });
}


