
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
const __default__ = {
  data() {
    return {
      list: [], //商品列表
      times: null, //计时器
      token: null,

      downSite: null, // 按下鼠标时候的位置
      leftSite: null, // 左边移动的距离(数字)
      canRoll: false, // 是否允许滚动
      rollSite: null, // 鼠标移动的距离
      maxWidth: null, // 接口获取数据盒子的宽度
      spacing: null, // 子节点与子节点之间的间距
    };
  },
  props: {
    // 控制子节点之间的间隙
    spacingRight: {
      type: [String, Number],
      default: '20',
    },
    // 控制滚动速率
    rollSpeed: {
      type: [Number],
      default: -1,
    },
    // 列表数据
    goodsDataList: {
      type: Array,
      default: null,
    },
  },
  computed: {
    // 卡片之间的间距
    marginRightCard() {
      let right = (this.spacingRight?.toString() || '0') + 'px';
      return right;
    },
  },
  watch: {
    goodsDataList: {
      handler(newVal, oldVal) {
        this.rollGoods();
      },
      deep: true,
    },
  },
  mounted() {
    if (process.client) {
      this.token = this.$cookies.get(CUR_TOKEN_NAME);
      this.rollGoods();
    }
  },

  methods: {
    // 滚动
    /**
     *
     * @param {Number} times 时间例如 2.5
     * @param {Number} distanceNum 移动的距离  例如 180
     */
    rollGoods() {
      // 数据初始化
      this.stopRoll();
      let data = this.goodsDataList || [];
      this.list = [...data, ...data];
      // -------------------------------------------------------
      // 获取最外层盒子的宽度
      let outerBoxDom = document.getElementById('roll-box');
      let outerBoxDomWidth = outerBoxDom?.offsetWidth || 0;
      // 获取盒子节点
      let dom = document.getElementById('roll-max');
      // 获取盒子子节点
      this.$nextTick(() => {
        let lis = document.querySelectorAll('.goods-card-box');
        // 子节点个数
        let lisLength = lis?.length || 0;
        // 没有子元素的时候不给滚动
        if (lisLength == 0) {
          return;
        }
        // 获取真实数据子元素的整体宽度
        let lisWidth =
          ((lis[0]?.offsetWidth + Number(this.spacingRight)) * lisLength) / 2 ||
          0;
        // 如果真实数据的子元素整体宽度没有最外层盒子的宽度大的话，不给滚动
        if (Number(lisWidth || 0) < Number(outerBoxDomWidth || 0)) {
          console.log(lisWidth, 'lisWidth');
          this.list = data;
          return;
        }

        // 每次滚动的跨度
        let spa = this.rollSpeed;
        // 子节点与子节点之间的间距
        this.spacing = Number(this.spacingRight || 0) * (lisLength / 2);
        this.times = setInterval(() => {
          // 计算并设置盒子宽度
          dom.style.width = lis[0].offsetWidth * lisLength + 'px';
          if (Number(dom?.offsetLeft) + this.spacing < -dom?.offsetWidth / 2) {
            // 判断是否走完
            dom.style.left = '0';
          }
          this.maxWidth = dom?.offsetWidth / 2;
          // 设置偏移位置
          dom.style.left = dom.offsetLeft + spa + 'px';
          this.leftSite = Number(dom.offsetLeft + spa);
        }, 15);
      });
    },
    // 鼠标移入停止滚动
    stopRoll() {
      if (this.times) {
        clearInterval(this.times);
        this.times = null;
      }
    },
    // 鼠标移出继续滚动
    goRoll() {
      this.rollGoods();
      this.upRoll();
    },
    // 鼠标移动事件
    moveRoll(e) {
      if (!this.canRoll) return;
      let dom = document.getElementById('roll-max');
      // 左滑
      if (
        e.clientX > this.downSite &&
        this.leftSite + (e.clientX - this.downSite) >= 0
      ) {
        this.$message.warning('已经在最前面啦~');
        dom.style.left = '0px';
        return;
      }

      var link = document.querySelectorAll('.roll-alink');
      link?.forEach((i) => {
        i.addEventListener('click', function (e) {
          e.preventDefault();
        });
      });
      this.rollSite = e.clientX - this.downSite; // 鼠标移动的距离
      dom.style.left = this.leftSite + (e.clientX - this.downSite) + 'px';
    },
    // 鼠标按下事件
    downRoll(e) {
      this.rollSite = null; // 重置鼠标移动的距离
      this.downSite = e.clientX; // 按下鼠标时候的位置
      this.canRoll = true; // 允许滚动
    },
    // 鼠标抬起事件
    upRoll(e) {
      this.canRoll = false; // 不允许滚动
      this.downSite = null; // 重置鼠标按下的位置
      let dom = document.getElementById('roll-max');
      this.leftSite = Number(dom?.offsetLeft);

      if (Math.abs(Number(dom?.offsetLeft)) > this.maxWidth + this.spacing) {
        dom.style.left =
          Number(dom?.offsetLeft) + this.maxWidth + this.spacing + 'px';
        this.leftSite = Number(dom?.offsetLeft);
      }
    },
    // 跳转商品详情
    jumpALink(item) {
      if (this.rollSite) return; // 如果是存在鼠标移动距离，就不能跳转
      window.open(item + '.html');
    },
  },
  destroyed() {
    if (process.client) {
      if (this.times) {
        clearInterval(this.times);
        this.times = null;
      }
    }
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "7c3b7ef0": (_vm.marginRightCard)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__