/**
 * 欠费支付美元人民币设置
 * recharge.vue
 */
export const RECHARGE_FONT = {
  'zh-cn': {
    reason: '欠费原因',
    remark: '备注',
    detail: '费用明细',
  },
  en: {
    reason: 'Reason for arrears',
    remark: 'Notes',
    detail: 'Cost details',
  },
};
