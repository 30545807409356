
export default {
  props: {
    intro: {
      type: String,
      default: '--',
    },
    price: {
      type: [String, Number],
      default: '',
    },
    showBtn: {
      type: Boolean,
      default: true,
    },
    imgUrl: {
      type: String,
      default: '',
    },
    itemId: {
      type: String,
      default: '',
    },
    tid: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    // 货币标志
    currency: {
      type: String,
      default: '',
    },
  },
  computed: {
    offerPrice() {
      let hasCurrencyMark =
        typeof this.price === 'string' &&
        (this.price?.[0] === '$' ||
          this.price?.[0] === '￥' ||
          this.price?.[0] === '¥');
      if (hasCurrencyMark) {
        return this.price;
      } else if (this.price || this.price === 0) {
        return this.currency + this.price;
      } else {
        return '暂无报价';
      }
    },
  },
  methods: {
    handleClick() {
      this.$emit('send');
    },
    // 跳转商品详情
    toShopDetail() {
      // #memo 限制的账号不给发送请求或跳转页面（限制：冻结 or 关闭）
      if (this.hasAccountLimited()) {
        return;
      }
      if (this.itemId) {
        this.toCachedShopdetail(this.itemId, this.imgUrl);
      }
    },
    // 跳转订单详情
    toOrderDetail() {
      // #memo 限制的账号不给发送请求或跳转页面（限制：冻结 or 关闭）
      if (this.hasAccountLimited()) {
        return;
      }
      if (this.tid) {
        let routeData = this.$router.resolve({
          path: '/trade/orderdetails',
          query: {
            item_id: this.tid,
          },
        });

        // 打开新页面
        window.open(routeData.href, '_blank');
      }
    },
  },
};
