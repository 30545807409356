/**
 * @FileDescription: 商城列表合店铺列表接口
 */
export default ({ $axios }, inject) => {
  inject('getNavbar', (data) =>
    $axios({
      url: '/Item/getNavbar',
      method: 'get',
      params: data,
    })
  );
  inject('getLogo', (data) =>
    $axios({
      url: '/Item/getLogo',
      method: 'get',
      params: data,
    })
  );
};
