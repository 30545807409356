import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1e388b7d&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=1e388b7d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e388b7d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Indexbottom: require('E:/ximu/nuxt-shop-oversea/components/indexbottom.vue').default,XimuDialog: require('E:/ximu/nuxt-shop-oversea/components/XimuComponents/XimuDialog.vue').default})
