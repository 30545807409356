import { render, staticRenderFns } from "./SettingPayPassword.vue?vue&type=template&id=50178006&scoped=true"
import script from "./SettingPayPassword.vue?vue&type=script&lang=js"
export * from "./SettingPayPassword.vue?vue&type=script&lang=js"
import style0 from "./SettingPayPassword.vue?vue&type=style&index=0&id=50178006&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50178006",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {XimuNoTitleConfig: require('E:/ximu/nuxt-shop-oversea/components/XimuComponents/ximu-no-title-config/XimuNoTitleConfig.vue').default})
