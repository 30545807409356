
/**
 * 多语言配置
 */
import { CASH_DESK_LABEL } from '../js/label_config_public';
export default {
  name: 'Cashier',
  data() {
    return {};
  },
  props: {
    // 页面语言
    lang: {
      type: String,
      default: 'zh-cn',
    },
  },
  computed: {
    // 标签多语言配置
    langConfig() {
      return CASH_DESK_LABEL[this.lang || 'zh-cn'];
    },
  },
  mounted() {},
  methods: {},
};
