
export default {
  props: {
    label: {
      // 按钮组的标题
      type: String,
      default: '选项标题',
    },
    options: {
      // 按钮的名字和值列表
      type: Array,
      default: () => [],
    },
    isRequired: {
      // 是否必选
      type: Boolean,
      default: false,
    },
    totalWidth: {
      // 按钮组的总宽度
      type: String,
      default: '520px',
    },
    btnWidth: {
      // 单个按钮的宽度
      type: String,
      default: '160px',
    },
    selected: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedCur: this.selected, // 被选中的按钮的 value
    };
  },
  methods: {
    handleClick(val) {
      this.selectedCur = val;
      this.$emit('update:selected', this.selectedCur);
    },
  },
};
