
export default {
  name: 'RefundDetail',
  props: {
    returnPolicy: {
      type: Object,
      default: () => {},
    },
    returnAddress: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      addressFormat: [
        {
          label: '收件人',
          prop: 'person',
        },
        {
          label: '电话',
          prop: 'phone',
        },
        {
          label: '邮编',
          prop: 'postal',
        },
        {
          label: '国家',
          prop: 'country',
        },
        {
          label: '省份/地区/州',
          prop: 'province',
        },
        {
          label: '城市',
          prop: 'city',
        },
        {
          label: '退货地址',
          prop: 'address',
        },
      ],
    };
  },
  computed: {
    /**
     * 退货政策中的供应商问题
     */
    supplierProblem() {
      if (this.returnPolicy && this.returnPolicy.supplier_problem) {
        return this.returnPolicy.supplier_problem;
      }
      return [];
    },
    /**
     * 退货政策中的分销商问题
     */
    userProblem() {
      if (this.returnPolicy && this.returnPolicy.user_problem) {
        return this.returnPolicy.user_problem;
      }
      return [];
    },
  },
  methods: {},
};
