const emptySet = new Set(['', null, undefined, '-1', 0]);

/**
 * 过滤掉对象中值为空的字段
 * @author hukeyi
 * @param {*} obj
 * @returns 有值的字段组成的新对象
 */
export function filterEmptyKey(obj) {
  let newObj = {};
  for (let key of Object.keys(obj)) {
    // 特殊处理数组
    if (Array.isArray(obj[key])) {
      if (obj[key].length === 0) {
        continue;
      }
      newObj[key] = obj[key];
    }
    if (!emptySet.has(obj[key])) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}

