/**
 * 首页板块中英文设置
 * NotLogBulletin.vue
 */

export const PLATE_CORRELATION = {
  'zh-cn': {
    'hight-profit-title': '高利润商品',
    'hot-commodity': '热销商品',
    'intelligent-recommend': '智能推荐',
    'look-more': '查看更多',
    'no-more': '很抱歉，没有更多商品可以展示了...',
    'money-type': '￥',
    sales: '销量',
    'no-data': '暂无数据',
  },
  en: {
    'hight-profit-title': 'High Profit Goods',
    'hot-commodity': 'High Selling Goods',
    'intelligent-recommend': 'Intelligent Recommendation',
    'look-more': 'View more',
    'no-more': 'Sorry, there are no more products to showcase...',
    'money-type': '$',
    sales: 'Sales',
    'no-data': 'No Data',
  },
};
