import { render, staticRenderFns } from "./HotSellersList.vue?vue&type=template&id=38cb1658&scoped=true"
import script from "./HotSellersList.vue?vue&type=script&lang=js"
export * from "./HotSellersList.vue?vue&type=script&lang=js"
import style0 from "./HotSellersList.vue?vue&type=style&index=0&id=38cb1658&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38cb1658",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Indexbottom: require('E:/ximu/nuxt-shop-oversea/components/indexbottom.vue').default})
