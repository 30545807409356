
import { getProtocolData } from '@/api/client-api/login/api.js';
export default {
  data() {
    return {
      protocolContent: '',
    };
  },

  methods: {
    async getProtocol() {
      try {
        const options = { id: '2', MUTE_WARNING: 1 };
        let res = await getProtocolData(options);
        if (res.errcode == 0) {
          this.protocolContent = res.data?.[0]?.content;
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  mounted() {
    this.getProtocol();
  },
};
