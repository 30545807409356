
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  name: 'CouponBoxShow',
  data() {
    return {
      token: '',
      smallShow: false, // 小弹窗状态
      bigShow: true, // 大弹窗状态

      numscond: 300, // 初始5分钟
      timer: null, // 计时器
    };
  },
  computed: {
    // 图片地址
    picUrl() {
      if (!this.token) {
        return 'test_upload_CN02310230001_1704881332.png';
      } else {
        return 'test_upload_CN02310230001_1704881393.png';
      }
    },
  },
  mounted() {
    this.token = this.$cookies.get(CUR_TOKEN_NAME);
  },
  beforeDestroy() {
    // 离开页面清除计时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    // 点击关闭小图
    closeSmall() {
      this.smallShow = false;
      this.numscond = 300;

      if (this.timer) {
        clearInterval(this.timer);
      }
      if (!this.token) {
        this.timer = setInterval(() => {
          this.numscond--;
          if (this.numscond == 0) {
            clearInterval(this.timer);
            this.smallShow = true;
            this.timer = null;
            this.numscond = 300;
          }
        }, 1000);
      }
    },
    // 点击小图片
    zoomPic() {
      this.bigShow = true;
    },
    // 点击关闭大图
    closeBig() {
      this.bigShow = false;
      if (this.token) {
        this.$emit('close-dialog');
      }
      if (!this.token) {
        this.smallShow = true;
      }
    },
    // 跳转注册
    goRegister() {
      if (this.token) {
        this.$router.push('/fund/coupon');
      } else this.$router.push('/register');
    },
    // 跳转权益介绍
    goExplain() {
      window.open('/explain/coupon', '_blank');
    },
  },
};
