
import PlateGoodsShowBox from './PlateGoodsShowBox'; // 板块二/三的展示组件
// 变量
import { curDefaultLanguage } from '@/utils/cur_default_config.js'; //默认语言
export default {
  name: 'FestivalActivityContent',
  components: { PlateGoodsShowBox },
  props: {
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
    // 活动图片信息
    activityPicMsg: {
      type: Object,
      default: () => {},
    },
    // 活动商品信息
    activityGoodsMsg: {
      type: Object,
      default: () => {},
    },
    // 需要分页的信息总页数
    goodsListTotalPage: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    // 板块二/三向右滚动需要后续数据
    scrollRight(num) {
      this.$emit('scroll-right', num);
    },
  },
};
