
import authenTicate from '../components/authenticate.vue';
import applyRefund from '../components/applyRefund.vue';
import materialAudit from '../components/materialAudit.vue';
import confirmAccount from '../components/confirmAccount.vue';
import bankRefund from '../components/refund.vue';
import successRefund from '../components/successRefund.vue';
import { storecancellationDetail } from '@/api/client-api/account/logOutAccount.js';
export default {
  name: 'logOutAccount',
  components: {
    authenTicate,
    applyRefund,
    materialAudit,
    confirmAccount,
    bankRefund,
    successRefund,
  },
  data() {
    return {
      active: -1,
      info: {}, // 退店详情
      applyStatus: '', // 账号注销状态0未申请 1申请中，2审核中，3审核通过，4审核驳回，5取消注销申请，6退款中，7已退款
      refundStatus: '', // 打款状态1无需打款 2账户未确认 3未打款 4已打款
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    onNext(n) {
      // this.active = Number(n);
      this.getInfo(); // 获取注销详情
    },
    /**
     *  获取注销详情
     * 1申请中，2审核中，3审核通过，4审核驳回，5取消注销申请，6退款中，7已退款
     */
    getInfo() {
      storecancellationDetail()
        .then((res) => {
          if (res?.errcode == 0) {
            this.applyStatus = res?.data?.apply_status?.toString() || '';
            this.refundStatus = res?.data?.refund_status?.toString() || '';
            if (res?.data?.list?.userInfo?.status) {
              let accountStatus = res?.data?.list?.userInfo?.status?.toString();
              this.$cookies.set('statusShow', accountStatus, {
                maxAge: 60 * 60 * 24 * 30,
                path: '/',
              });
              localStorage.setItem('statusShow', accountStatus);
            }

            switch (this.applyStatus) {
              case '0':
                this.active = 0;
                break;
              case '1':
                this.active = 1;
                break;
              case '2':
                this.active = 2;
                break;
              case '3':
                this.active = 3;
                break;
              case '4':
                this.active = 2;
                break;
              case '5':
                this.active = 0;
                break;
              case '6':
                this.active = 4;
                break;
              case '7':
                this.active = 5;
                break;
              default:
                break;
            }
            this.info = JSON.parse(JSON.stringify(res?.data?.list || {}));
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
