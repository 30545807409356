import request from '@/utils/request.js';
// 获取智能推荐
export function getIntelligentRecommend(data) {
  return request({
    url: '/Item/getRecommendItemList',
    method: 'get',
    params: data,
    headers: {
      non_empty_targets: 'data',
    },
  });
}

// 是否有新用户优惠券
export function haveNewUserCoupon(data) {
  return request({
    url: '/CouponPopWindow/haveNewUserCoupon',
    method: 'get',
    params: data,
  });
}

// 获取首页活动名称
export function getActivePlateName(data) {
  return request({
    url: '/Order/getActivityTitle',
    method: 'get',
    params: data,
  });
}
