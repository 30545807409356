
import { bankcardPayment } from "@/api/client-api/goods/pay.js";
import BalancePayment from "./BalancePayment.vue";
export default {
  name: "DollerPayMethod",
  components: {
    BalancePayment,
  },
  data() {
    return {
      currentMethod: "2",
      showPayDialog: false,
      showSuccessDialog: false, // 提交成功弹窗
      imgData: [], // 转账记录
      orderId: this.$store.state.pay.choseOrdersId.toString(), // 订单号
      payMethods: [
        // {
        //   id: "1",
        //   name: "Bank Card Transfer",
        //   payImg: require("@/assets/bankCard.png"),
        // },
        {
          id: "2",
          name: "Balance Payment",
          payImg: require("@/assets/enBalance.png"),
        },
      ],
      countSecond: 3, // 倒计时秒
      timer: null, // 计时器

      // payInfo: {
      //   accountNameOfPayee  // 收款方户名
      //   payeeAccountNumber // 收款方账号
      //   payeeBank  // 收款方银行
      //   SwiftBIC // Swift BIC
      //   openingBankAddress // 开户银行地址
      // }
      balanceShow: false, // 余额支付弹框
    };
  },
  props: {
    // 平台端银行卡信息 以及付款金额
    payInfo: {
      type: Object,
      default: () => {},
    },
    balanceUSD: {
      type: String,
      default: "",
    },
  },

  filters: {
    // 判断是否有值
    emptyValFilter(val) {
      return !val ? "--" : val;
    },
  },

  computed: {
    currLanguage() {
      return this.$store.state.common.currLanguage;
    },
    payAfterBalance() {
      return this.keepTwoDecimalFull(
        (Number(this.balanceUSD) * 1000 -
          Number(this.payInfo.priceUSD) * 1000) /
          1000
      );
    },
  },

  methods: {
    clearTimer() {
      //清除定时器
      clearInterval(this.timer);
      this.timer = null;
    },
    // 倒计时
    countDown() {
      this.timer = setInterval(() => {
        //创建定时器
        if (this.countSecond === 1) {
          this.clearTimer(); //关闭定时器
          let currentRouter = this.$route.path;
          if (currentRouter !== "/trade/paymentprocessing") {
            this.$router.replace("/trade/paymentprocessing");
          }
        } else {
          this.countSecond--;
        }
      }, 1000);
    },
    // 选择支付方式
    selectPayMethod(payMethodId) {
      this.currentMethod = payMethodId;
    },
    // 点击立即支付
    handlePayment() {
      if (this.currentMethod == 1) {
        this.showPayDialog = true;
      } else if (this.currentMethod == 2) {
        this.balanceShow = true;
      }

      this.$emit("fixed-rate", true);
    },
    // 关闭余额支付
    closebalance() {
      this.balanceShow = false;
    },
    // 关闭线下银行卡转账弹窗
    closePayDialog() {
      this.showPayDialog = false;
      this.$emit("fixed-rate", false);
    },
    paySuccess() {
      this.showSuccessDialog = true;
    },
    // 关闭提交成功弹窗
    closeSuccessDialog() {
      this.showSuccessDialog = false;
      this.$router.replace("/trade/paymentprocessing");
    },
    uploadImg(img) {
      this.imgData = img;
    },
    // 点击提交 确认支付
    async confirmPayment() {
      try {
        if (this.imgData?.length == 0) {
          this.$message.warning("Please upload the transfer record");
          return;
        }
        let options = {
          tid: this.orderId,
          payment_type: "11", // 支付方式  （11表示美元银行卡转账）
          payment_voucher: this.imgData.join(","),
          API_LANG_TYPE: this.currLanguage,
        };
        let res = await bankcardPayment(options);

        if (res.errcode == 0) {
          this.closePayDialog(); // 关闭银行卡转账弹窗
          this.paySuccess(); // 打开支付成功弹窗
          this.countDown(); // 打开倒计时
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 去美元充值
    goRecharge() {
      this.$router.push("/recharge/balance/2");
    },
  },
};
