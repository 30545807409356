
import addressinner from './addressinner.vue';
import {
  invoiceSettingsDetail,
  applyInvoice,
  applyReplaceInvoice,
  supplierInvoiceSetting,
} from '@/api/client-api/fund/invoice.js';
import { string } from 'mathjs';
export default {
  data() {
    let validatePass = (rule, value, callback) => {
      if (
        this.replaceObj.address.province_id &&
        this.replaceObj.address.city_id &&
        this.replaceObj.address.region_id
      ) {
        callback();
      } else {
        callback(new Error('请选择收票人地址'));
      }
    };
    let validPhone = (rule, value, callback) => {
      const regMobile =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (this.replaceObj.phone.trim() == '') {
        callback(new Error('请输入收票人手机号'));
      } else if (!regMobile.test(this.replaceObj.phone)) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback();
      }
    };
    let checkLandline = (rule, value, callback) => {
      const regMobile =
        /^(((\d{3,4}-)?[0-9]{7,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/;
      if (
        this.replaceObj.registrationPhone.trim() != '' &&
        !regMobile.test(this.replaceObj.registrationPhone.trim())
      ) {
        callback(new Error('请输入正确的电话号码'));
      } else {
        callback();
      }
    };
    return {
      areaSelectKey: 0, // 地区选择刷新
      replaceObj: {
        reason: '', // 换开原因
        type: '', // 发票类型
        medium: '', // 发票介质
        mediumId: '', // 介质Id
        content: '', // 发票内容
        headingType: '', // 抬头类型
        headingTypeContent: '', // 抬头类型文本
        heading: '', // 发票抬头
        taxpayerNumber: '', // 纳税人识别号
        registrantAddress: '', // 注册地址
        registrationPhone: '', // 注册电话
        bank: '', // 开户银行
        bankAccount: '', // 银行账号
        acceptName: '', // 收票人姓名
        phone: '', // 收票人手机号
        remark: '', // 备注
        address: {
          province: '',
          province_id: '',
          city: '',
          city_id: '',
          region: '',
          region_id: '',
        }, // 收票人地址
        detailaddress: '', // 详细地址
      },
      rules: {
        // 校验规则
        reason: [
          { required: true, message: '请选择换开原因', trigger: 'change' },
        ],
        type: [
          { required: true, message: '请选择发票类型', trigger: 'change' },
        ],
        content: [
          { required: true, message: '请选择发票内容', trigger: 'change' },
        ],
        acceptName: [
          { required: true, message: '请输入收票人姓名', trigger: 'blur' },
        ],
        detailaddress: [
          { required: true, message: '请输入收票人详细地址', trigger: 'blur' },
        ],
        phone: [{ validator: validPhone, required: true, trigger: 'blur' }],
        address: [
          { validator: validatePass, required: true, trigger: 'change' },
        ],
        registrationPhone: [{ validator: checkLandline, trigger: 'blur' }],
        heading: [
          { required: true, message: '请输入发票抬头', trigger: 'blur' },
        ],
      },
      replaceOptions: [
        // 申请换开原因
        {
          label: '分销商税号重叠',
          value: '分销商税号重叠',
        },
        {
          label: '注册地址及电话有误',
          value: '注册地址及电话有误',
        },
        {
          label: '银行账号及账号有误',
          value: '银行账号及账号有误',
        },
        {
          label: '公司名称有误',
          value: '公司名称有误',
        },
        {
          label: '换电票',
          value: '换电票',
        },
        {
          label: '金额有误',
          value: '金额有误',
        },
        {
          label: '退款',
          value: '退款',
        },
        {
          label: '其他',
          value: '其他',
        },
      ],
      // 发票类型
      repliceTypeLIst: [
        {
          label: '普通发票',
          value: '1',
        },
        {
          label: '专用发票',
          value: '2',
        },
      ],
      // 发票类型
      invoiceTypeObj: {
        1: [
          {
            label: '普通发票',
            value: '1',
          },
        ],
        2: [
          {
            label: '专用发票',
            value: '2',
          },
        ],
        3: [
          {
            label: '普通发票',
            value: '1',
          },
          {
            label: '专用发票',
            value: '2',
          },
        ],
      },
      // 发票介质
      invoiceMedium: '3',
      // 发票内容
      invoceContent: '3', // 1,商品明细，2商品类别，3两者皆有
      headInputShow: false, // 判断发票抬头是否为空
    };
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    // 1是申请开票，2是申请换开
    inviceType: {
      type: String,
      default: '1',
    },
    tidArr: {
      type: Array,
      default: () => [],
    },
    attributionCode: {
      type: String,
      default: '1',
    },
  },
  watch: {
    // tidArr: {
    //   async handler(val) {
    //     // 获取供应商发票设置详情
    //     if (this.inviceType == 2 && this.attributionCode == 2) {
    //       await this.getinviceSet();
    //     } else {
    //       await this.getinviceDetail();
    //     }
    //   },
    //   immediate: true,
    // },
    // attributionCode: {
    //   async handler(val) {
    //     // 获取供应商发票设置详情
    //     if (this.inviceType == 2 && this.attributionCode == 2) {
    //       await this.getinviceSet();
    //     } else {
    //       await this.getinviceDetail();
    //     }
    //   },
    //   immediate: true,
    // },
    getDetaul: {
      async handler(val) {
        if (val) {
          await this.getinviceDetail();
          // 获取供应商发票设置详情
          if (this.inviceType == 2 && this.attributionCode == 2) {
            await this.getinviceSet();
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    getDetaul() {
      let flag = false;
      if (this.attributionCode && this.tidArr) {
        flag = true;
      } else {
        flag = false;
      }
      return flag;
    },
  },
  components: { addressinner },
  mounted() {},
  methods: {
    closeFunc() {
      this.$emit('closeReplace');
    },
    // 申请换开
    repleceInvice() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let option = {
            // tid: this.tidArr.join(","), // 订单号
            invoice_type: this.replaceObj.type, // 发票类型
            invoice_medium: this.replaceObj.mediumId, // 发票介质
            invoice_content: this.replaceObj.content, // 发票内容
            invoice_header_type: this.replaceObj.headingType, // 发票抬头类型
            invoice_header: this.replaceObj.heading, // 发票抬头

            // invoice_header: "999999999999999999", // 发票抬头
            tax_number: this.replaceObj.taxpayerNumber, // 税号
            // tax_number: "9090909090", // 税号
            consignee_name: this.replaceObj.acceptName, // 收票人姓名
            phone: this.replaceObj.phone, // 收票人电话
            province: this.replaceObj.address.province_id, // 收票地址
            city: this.replaceObj.address.city_id,
            area: this.replaceObj.address.region_id, //
            detail_address: this.replaceObj.detailaddress, // 详细地址
            register_address: this.replaceObj.registrantAddress, // 注册地址
            register_phone: this.replaceObj.registrationPhone, // 注册手机号
            register_bank: this.replaceObj.bank, //  开户银行
            bank_card: this.replaceObj.bankAccount, // 银行卡号
          };
          if (this.inviceType == 1) {
            // 申请开票
            option.tid = this.tidArr.join(',');
            applyInvoice(option)
              .then((res) => {
                if (res?.errcode == 0) {
                  if (res?.msg) {
                    this.$message.success(res?.msg);
                  }
                  this.$emit('closeReplace');
                }
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            // 申请换开
            option.application_id = this.tidArr[0]; // 申请Id
            option.replace_reason = this.replaceObj.reason; // 换开原因
            option.remark = this.replaceObj.remark; // 备注
            applyReplaceInvoice(option)
              .then((res) => {
                if (res?.errcode == 0) {
                  if (res?.msg) {
                    this.$message.success(res?.msg);
                  }
                  this.$emit('closeReplace');
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }
        }
      });
    },
    updataAddress(info, enInfo, status) {
      this.replaceObj.address = {
        country: info.country, // 国
        country_id: info.country_id, // 国id
        province: info.province, // 省
        province_id: info.province_id, // 省id
        city: info.city, // 市
        city_id: info.city_id, // 市id
        region: info.region, // 区
        region_id: info.region_id, // 区id
      };
    },
    getinviceDetail() {
      return new Promise((resolve, reject) => {
        invoiceSettingsDetail()
          .then((res) => {
            if (res?.errcode == 0) {
              resolve();
              this.replaceObj.type = res?.data?.invoice_type; // 发票类型
              this.replaceObj.medium = res?.data?.invoice_medium_content; // 发票介质
              this.replaceObj.mediumId = res?.data?.invoice_medium; // 发票介质
              this.replaceObj.content = res?.data?.invoice_content; // 商品内容
              this.replaceObj.headingType = res?.data?.invoice_header_type; // 抬头类型
              this.replaceObj.headingTypeContent =
                res?.data?.invoice_header_type_content; // 抬头类型
              this.replaceObj.heading = res?.data?.invoice_header; // 发票抬头
              this.headInputShow = this.replaceObj.heading == '';
              this.replaceObj.taxpayerNumber = res?.data?.tax_number; //纳税人识别号
              this.replaceObj.registrantAddress = res?.data?.register_address; // 注册地址
              this.replaceObj.registrationPhone = res?.data?.register_phone; // 注册电话
              this.replaceObj.bank = res?.data?.register_bank; // 开户银行
              this.replaceObj.bankAccount = res?.data?.bank_card; // 银行账号
              this.replaceObj.acceptName = res?.data?.consignee_name; // 收票人姓名
              this.replaceObj.phone = res?.data?.phone; // 收票人电话
              this.replaceObj.address.province_id = res?.data?.province;
              this.replaceObj.address.province = res?.data?.province_name;
              this.replaceObj.address.city_id = res?.data?.city;
              this.replaceObj.address.city = res?.data?.city_name;
              this.replaceObj.address.region = res?.data?.area_name;
              this.replaceObj.address.region_id = res?.data?.area;
              this.replaceObj.detailaddress = res?.data?.address_detail; // 详细地址
              this.areaSelectKey++;
            } else {
              reject();
            }
          })
          .catch((err) => {
            console.error(err);
            reject();
          });
      });
    },
    // 获取供应商发票设置详情
    getinviceSet() {
      return new Promise((resolve, reject) => {
        let option = {
          application_id: this.tidArr[0],
        };
        supplierInvoiceSetting(option)
          .then((res) => {
            if (res?.errcode == 0) {
              resolve();
              this.repliceTypeLIst =
                this.invoiceTypeObj[res?.data?.invoice_type]; // 发票类型
              // 判断发票介质
              this.invoiceMedium = res?.data?.invoice_medium;
              if (res?.data?.invoice_medium == 1) {
                this.replaceObj.medium = '纸质发票';
                this.replaceObj.mediumId = 1;
              } else if (res?.data?.invoice_medium == 2) {
                his.replaceObj.medium = '电子发票';
                this.replaceObj.mediumId = 2;
              }
              // 发票内容
              this.invoceContent = res?.data?.invoice_content;
              if (res?.data?.invoice_content == 1) {
                this.replaceObj.content = 1;
              } else if (res?.data?.invoice_content == 2) {
                this.replaceObj.content = 2;
              }
            } else {
              reject();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },
  },
};
