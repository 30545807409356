/**
 * 预览各种文件的blob文件流的工具函数集合
 * @author hukeyi
 * @description pdf, docx, xlsx, xls, jpg, jpeg, png, txt
 */
import { read, utils } from 'xlsx';

/**
 * 预览图片
 * @description 使用原生 img 标签预览，支持jpg，jpeg，png
 * @param { Blob } img文件流
 * @param { String } containerId 预览文件要插入的dom元素的id
 * @returns
 */
function previewImageBlob(blob, containerId) {
  return new Promise((resolve, reject) => {
    if (!process.client) {
      return reject();
    }
    let dom = document.getElementById(containerId);
    const img = document.createElement('img');
    img.src = URL.createObjectURL(blob);
    img.classList.add('imgitem');
    img.style = 'max-width: 80vw; max-height: 90vh; margin: auto;';
    dom.appendChild(img);
    return resolve();
  });
}

/**
 * 预览pdf
 * @description 使用原生 embed 标签预览，支持pdf文件
 * @param { Blob } pdf文件流
 * @param { String } containerId 预览文件要插入的dom元素的id
 * @returns
 */
function previewPdfBlob(blob, containerId) {
  return new Promise((resolve, reject) => {
    if (!process.client) {
      return reject();
    }
    let dom = document.getElementById(containerId);
    dom.style.overflow = 'hidden';
    let url = window.URL.createObjectURL(
      new Blob([blob], { type: 'application/pdf' })
    );
    const embed = document.createElement('embed');
    embed.src = url + '#toolbar=0&navpanes=0&scrollbar=0';
    embed.type = 'application/pdf';
    embed.width = '100%';
    embed.height = '100%';
    dom.appendChild(embed);
    return resolve();
  });
}

/**
 * 预览word
 * @description 使用docx-preview库，支持docx文件
 * @param { Blob } word文件流
 * @param { String } containerId 预览文件要插入的dom元素的id
 * @returns
 */
function previewWordBlob(blob, containerId) {
  return new Promise((resolve, reject) => {
    if (!process.client) {
      return reject();
    }
    let dom = document.getElementById(containerId);
    let docx = require('docx-preview');
    docx
      .renderAsync(blob, dom)
      .then((x) => {
        return resolve();
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

/**
 * 预览excel
 * @description 使用sheetjs库，支持xlsx和xls文件
 * @param { Blob } excel 文件流
 * @param { String } containerId 预览文件要插入的dom元素的id
 * @returns
 */
function previewExcelBlob(blob, containerId) {
  return new Promise(async (resolve, reject) => {
    try {
      if (!process.client) {
        return reject();
      }
      let dom = document.getElementById(containerId);
      const workbook = read(await blob.arrayBuffer());
      const sheetArr = workbook.SheetNames;
      for (let i = 0; i < sheetArr.length; i++) {
        let sheet = workbook.Sheets[sheetArr[i]];
        let html = utils.sheet_to_html(sheet);

        let sheetHeader = document.createElement('header');
        sheetHeader.innerText = `sheet ${i + 1}`;
        sheetHeader.style = 'height: 50px; line-height: 50px;';
        let sheetDom = document.createElement('div');
        sheetDom.innerHTML = html;
        let sheetContainer = document.createElement('div');
        sheetContainer.appendChild(sheetHeader);
        sheetContainer.appendChild(sheetDom);
        dom.appendChild(sheetContainer);
      }
      return resolve();
    } catch (err) {
      console.error(err);
      return reject(err);
    }
  });
}

/**
 * 预览txt
 * @description 使用原生对象FileReader，支持txt文件
 * @param { Blob } txt文件流
 * @param { String } containerId 预览文件要插入的dom元素的id
 * @returns
 */
function previewTxtBlob(blob, containerId) {
  return new Promise((resolve, reject) => {
    if (!process.client) {
      return reject();
    }
    let dom = document.getElementById(containerId);
    let reader = new FileReader();
    reader.onload = (e) => {
      let content = e.target.result;
      dom.innerText = content;
      return resolve();
    };
    reader.onerror = (e) => {
      return reject(e);
    };
    reader.readAsText(blob, 'utf-8');
  });
}

export {
  previewImageBlob,
  previewPdfBlob,
  previewWordBlob,
  previewExcelBlob,
  previewTxtBlob,
};
