
export default {
  name: 'BasicScrollTable',
  props: {
    tableFont: {
      type: Object,
      default: () => {},
    },
    scrollData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tryShow: false,
      scrollDataHeight: '240px', // 滚动高度
      scrollSpeed: 0,
      scrollKey: 0,
    };
  },
  filters: {
    // 判断是否有值
    emptyVal(val) {
      if (val && val != '') {
        return val;
      } else {
        return '--';
      }
    },
  },
  computed: {
    optionScrollData() {
      if (this.tableFont.hasPic == false) {
        this.scrollSpeed = this.scrollData.length < 6 ? 0 : 0.2;
        this.scrollDataHeight =
          this.scrollData.length > 5
            ? '240px'
            : 48 * this.scrollData.length + 'px';
      } else {
        this.scrollSpeed = this.scrollData.length < 4 ? 0 : 0.2;
        this.scrollDataHeight =
          this.scrollData.length > 3
            ? '240px'
            : 64 * this.scrollData.length + 'px';
      }
      this.scrollKey++;
      return {
        step: this.scrollSpeed, // 数值越大速度滚动越快
        limitMoveNum: this.scrollData.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    // 处理vueSeamlessScroll插件复制出来的无事件问题
    handleClick(event) {
      if (event.target.dataset.id) {
        this.toCachedShopdetail(
          event.target.dataset.id,
          event.target.dataset.img
        );
      }
    },
  },
};
