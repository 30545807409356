
import BasicGoodsBox from '@/pages/activity/components/BasicGoodsBox.vue';
// 变量
import { curDefaultLanguage } from '@/utils/cur_default_config.js'; //默认语言
export default {
  name: 'PlatformSubsidyContent',
  components: { BasicGoodsBox },
  props: {
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
    // 商品信息
    goodsListMsg: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
