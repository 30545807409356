
import {
  couponListPanel,
  couponList,
  delCoupon,
  recoverCoupon,
} from '@/api/client-api/fund/coupon';
import CouponShowBox from './components/CouponShowBox.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'Coupon',
  components: {
    CouponShowBox,
  },
  data() {
    return {
      lang: '', // 当前页面语言
      // 优惠券状态
      couponStatusList: [
        { id: '1', sign: 'usable', title: '可用优惠券', num: 0 },
        { id: '2', sign: 'inUse', title: '使用中优惠券', num: 0 },
        { id: '3', sign: 'used', title: '已用优惠券', num: 0 },
        { id: '4', sign: 'expired', title: '过期优惠券', num: 0 },
        { id: '5', sign: 'recycled', title: '回收站优惠券', num: 0 },
      ],
      statusActive: '1', // 优惠券选中状态

      // 优惠券类型
      couponTypeList: [
        { key: '3', name: '全部' },
        { key: '1', name: '商品券' },
        { key: '2', name: '运费券' },
      ],
      typeActive: '3', // 优惠券选中类型

      useTime: '', // 优惠券使用时间

      pageSize: 9, // 页大小
      pageNum: 1, // 页码
      total: 0, // 总数
      couponShowList: [], // 展示的优惠券列表

      choseCouponNo: '', // 当前选中优惠券编号
      tipDialog: false, // 提示框弹窗状态
    };
  },
  computed: {
    ...mapGetters({
      currLoading: 'loading/currLoading',
      currLang: 'common/currLang',
    }),
    // 弹窗提示文字
    tipFont() {
      switch (this.statusActive?.toString()) {
        case '1':
          return '确定要删除该优惠券吗？';
        case '5':
          return '确定将此优惠券恢复到可使用状态？';
        default:
          return '';
      }
    },
  },
  filters: {
    couponNumber(val) {
      if (val && Number(val) > 999) {
        return '999+';
      } else return val;
    },
  },
  mounted() {
    this.lang = this.currLang;
    this.getCouponListPanel(); // 获取面板数据
    this.getCouponList(); // 获取优惠券数据
  },
  methods: {
    // 获取优惠券面板tab数据
    getCouponListPanel() {
      couponListPanel({ API_LANG_TYPE: this.lang, MUTE_WARNING: 1 })
        .then((res) => {
          if (res?.errcode == 0) {
            this.couponStatusList.forEach((item) => {
              if (res?.data) {
                item.num = res?.data[item.sign] || 0;
              }
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取优惠券数据
    async getCouponList() {
      try {
        let options = {
          API_LANG_TYPE: this.lang,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          status: this.statusActive, // 优惠券使用状态
          // type: this.typeActive, // 优惠券类型
        };
        // 优惠券使用时间
        if (this.useTime == '1') {
          options.useTime = this.useTime;
        }
        const res = await couponList(options);
        if (res?.errcode == 0) {
          this.couponShowList = res?.data?.list || [];
          this.total = Number(res?.data?.count) || 0;
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 切换优惠券tab栏状态
    changeStatus(id) {
      this.statusActive = id;
      this.getCouponListPanel(); // 获取面板数据
      this.resetList();
    },
    // 切换优惠券类型tab栏
    changeType(id) {
      this.typeActive = id;
      this.resetList();
    },
    // 重置搜索优惠券列表数据
    resetList() {
      this.pageNum = 1;
      this.getCouponList(); // 获取优惠券数据
    },
    // 跳转分页
    goPages(n) {
      this.pageNum = n;
      this.getCouponList(); // 获取优惠券数据
    },
    // 点击加入回收站/恢复优惠券
    changeCouponStatus(id) {
      this.choseCouponNo = id; // 当前选中优惠券编号
      this.tipDialog = true; // 打开二次确认弹窗
    },
    // 关闭提示弹窗
    closeTipDialog() {
      this.choseCouponNo = ''; // 清空选中优惠券编号
      this.tipDialog = false; // 关闭二次确认弹窗
    },
    // 点击弹窗确定按钮
    async sureAction() {
      try {
        let res;
        let options = {
          API_LANG_TYPE: this.lang,
          coupon_no: this.choseCouponNo,
        };
        // 判断当前页面的优惠券张数
        if (this.couponShowList.length == 1 && this.pageNum > 1) {
          this.pageNum = this.pageNum - 1;
        }
        // 可用 -> 回收站
        if (this.statusActive == '1') {
          res = await delCoupon(options);
        }
        // 回收站 -> 可用
        if (this.statusActive == '5') {
          res = await recoverCoupon(options);
        }
        if (res?.errcode == 0) {
          if (res?.msg) {
            this.$message.success(res?.msg);
          }
          this.closeTipDialog(); // 关闭提示弹窗
          this.getCouponListPanel(); // 获取面板数据
          this.getCouponList(); // 获取优惠券数据
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
