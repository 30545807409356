/**
 * 账号及认证模块
 * -
 */

// 后台管理架子
import shophome from '@/pages/back-stage/index.vue';
// 商品模块
import AccountsLayout from '@/pages/back-stage/account-module/AccountLayout.vue'; //分销商后台账号及认证模块布局
import AccountSet from '@/pages/back-stage/account-module/account-set/AccountSettings.vue'; //账号设置
// import AccountManagement from '@/pages/back-stage/account-module/account-management/AccountManagement.vue';//账号管理
import BasicInformation from '@/pages/back-stage/account-module/basic-information/BasicInformation.vue'; // 基础信息
import Information from '@/pages/back-stage/account-module/information'; //基本信息
import LogOutAccount from '@/pages/back-stage/account-module/log-out-account/LogOutAccount.vue'; //账号注销
import SettingPayPassword from '@/pages/back-stage/account-module/set-pay-password/SettingPayPassword.vue'; //设置支付密码
import EditPayPassword from '@/pages/back-stage/account-module/edit-pay-password/EditPayPassword.vue'; //修改支付密码
import EditPhone from '@/pages/back-stage/account-module/edit-phone/EditPhone.vue'; //修改手机号
import EditPassword from '@/pages/back-stage/account-module/edit-password/EditPassword.vue'; //修改密码
import InvoiceSetting from '@/pages/back-stage/account-module/invoice-set/InvoiceSetting.vue'; //发票设置

const accountRoutes = [
  {
    path: '/',
    component: shophome,
    meta: {
      isAuth: true,
    },
    children: [
      // 商品模块的
      {
        path: 'account',
        component: AccountsLayout,
        meta: {
          isAuth: true,
        },
        children: [
          // 账号设置
          {
            path: '',
            component: AccountSet,
            meta: {
              isAuth: true,
            },
          },
          {
            path: 'accountset',
            name: 'AccountSet',
            component: AccountSet,
            meta: {
              isAuth: true,
            },
          },
          // 账号管理
          // {
          //   path: 'accountmanagement',
          //   name: 'AccountManagement',
          //   component: AccountManagement,
          //   meta: {
          //     isAuth: true,
          //   },
          // },
          // 基础信息
          {
            path: 'basic/information/:type?',
            name: 'BasicInformation',
            component: BasicInformation,
            meta: {
              isAuth: true,
            },
          },
          // 基本信息
          {
            path: 'information/:type?/:need?',
            name: 'Information',
            component: Information,
            meta: {
              isAuth: true,
            },
          },
          // 账号注销
          {
            path: 'logoutaccount',
            name: 'LogOutAccount',
            component: LogOutAccount,
            meta: {
              isAuth: true,
            },
          },
          // 设置支付密码
          {
            path: 'setpaypassword',
            name: 'SettingPayPassword',
            component: SettingPayPassword,
            meta: {
              isAuth: true,
            },
          },
          // 修改支付密码
          {
            path: 'editpaypassword',
            name: 'EditPayPassword',
            component: EditPayPassword,
            meta: {
              isAuth: true,
            },
          },
          // 修改手机号
          {
            path: 'editphone',
            name: 'EditPhone',
            component: EditPhone,
            meta: {
              isAuth: true,
            },
          },
          // 修改密码
          {
            path: 'editpassword',
            name: 'EditPassword',
            component: EditPassword,
            meta: {
              isAuth: true,
            },
          },
          // 发票设置
          {
            path: 'invoiceset',
            name: 'InvoiceSetting',
            component: InvoiceSetting,
            meta: {
              isAuth: true,
            },
          },
        ],
      },
    ],
  },
];

export default accountRoutes;
