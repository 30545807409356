/**
 * 营销活动模块
 */

// 后台管理架子
import shophome from '@/pages/back-stage/index.vue';
// 营销活动模块
import ActivityLayout from '../pages/back-stage/marketing-activity/ActivityLayout.vue'
// 爆单挑战
import OrderChallenge from '../pages/back-stage/marketing-activity/order-challenge/OrderChallenge.vue'

const activityRoutes = [
  {
    path: '/',
    component: shophome,
    meta: {
      isAuth: true,
    },
    children: [
      // 营销活动模块的
      {
        path: 'activity',
        component: ActivityLayout,
        meta: {
          isAuth: true,
        },
        children: [
          // 爆单挑战列表
          {
            path: '',
            component: OrderChallenge,
            meta: {
              isAuth: true,
            },
          },
          {
            path: 'challenge',
            name: 'OrderChallenge',
            component: OrderChallenge,
            meta: {
              isAuth: true,
            },
          },
        ],
      },
    ],
  },
];

export default activityRoutes;
