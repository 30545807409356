
// 组件
import indexbottom from '@/components/indexbottom.vue';
import searchBox from '@/components/comme/searchBox.vue';
import CategoryBox from './components/CategoryBox.vue'; //类别筛选组件
import SelectedItems from './components/SelectedItems'; //精选商品
import ListSelect from './components/ListSelect'; //筛选组件
import CategorySelection from './components/CategorySelection.vue'; //类目选择器

import { PUBLIC_CONFIHURATION, GOODS_BOX } from './js/label_config_list_main';
import { visitsLimit } from '@/api/client-api/api.js';
import { mapGetters } from 'vuex';
// 变量
import { curDefaultLanguage } from '@/utils/cur_default_config.js'; //默认语言
export default {
  head() {
    return {
      title: this.categoryTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.categoryDescription,
        },
        {
          hid: 'Keywords',
          name: 'Keywords',
          content: this.categoryKeywords,
        },
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: 'favicon.ico' }],
    };
  },
  name: 'goodsClassificat',
  components: {
    indexbottom,
    searchBox,
    CategoryBox,
    SelectedItems,
    ListSelect,
    CategorySelection,
  },
  data() {
    return {
      lang: '',
      // goodProperty: [ ], //品牌等
      goodsSelectedL: [], //精选商品

      isBlockLayout: true, //块状显示

      // 数据相关（块状）
      // tableData: [],
      // total: 0,
      pages: 1,
      pageSize: 60,

      // categoryId: '-1', //地址携带的参数ID
      // leaveOneInfo: {}, //一级的类目
      // leaveTwoInfo: {}, //二级的类目
      // leaveTwoList: [], //二级类目列表
      // leaveThreeInfo: {}, //三级的类目
      // leaveThreeList: [], //三级的类目列表
      categoryList: [], //类目列表

      sortStr: 'comprehensive_order', //筛选（名称）
      sortDesc: -1, //筛选（序号）
      priceMin: '', //价格筛选
      priceMax: '', //价格筛选
      timeOptions: [], //时间筛选
      checkList: [], //预测等按钮

      brandId: [], //品牌ID
      brandName: [], //品牌名

      btnFlag: false, //是否出现回到顶部的按钮

      // 埋点
      selectGoodsPoint: [], // 精选商品埋点上报数据
      goodsSortPoint: [], // 商品分类埋点上报数据

      // 对比相关
      checkBoxList: [], //选中的列表
      currSku: {}, //选中的对象
      currSkuId: '', //选中的ID
      showCompareCol: false, //控制商品对比栏
    };
  },
  watch: {
    // 监听路由,发生改变刷新
    '$route.fullPath'(n, o) {
      if (n) {
        this.$router.go(0);
      }
    },
  },
  computed: {
    ...mapGetters({
      currLang: 'common/currLang',
    }),
    // 列表页公共多语言配置
    langConfig() {
      return PUBLIC_CONFIHURATION[this.lang || curDefaultLanguage];
    },
    langConfigGoodsBox() {
      return GOODS_BOX[this.lang || curDefaultLanguage];
    },
  },
  // 需要seo的
  async asyncData(app) {
    let lang = app.store.state.common.currLanguage;
    let params = app.route.params;
    let categoryId = params?.id.split(','); //记录类目ID
    let categoryList = []; //存储类目
    let leaveOneInfo = {}; //一级类目
    let leaveTwoInfo = {}; //二级类目
    let leaveThreeInfo = {}; //三级类目
    let leaveTwoList = []; //二级类目列表
    let leaveThreeList = []; //三级类目列表
    let tableData = []; //列表
    let goodsSelectedL = []; //精选商品
    let goodProperty = []; //类别
    let brandName = []; //品牌列表
    let total = 0; //总数
    let categoryTitle = '分销供应链_分销商城-细目商城'; //类目搜索的标题
    let categoryDescription =
      '细目商城致力于构建全球分销供应链平台，为您提供各样商品电商分销供应链，为经营全流程提效降本'; //类目搜索的描述
    let categoryKeywords = '分销供应链,分销商城,细目商城'; //类目搜索的关键字
    try {
      // 类目
      if (lang == 'zh-cn') {
        // 中文类目
        if (
          app.store.state.category.categoryData &&
          app.store.state.category.categoryData.length > 0
        ) {
          categoryList = app.store.state.category.categoryData;
        } else if (
          app.store.state.category.categoryData &&
          app.store.state.category.categoryData.length == 0
        ) {
          // 类目
          let resCategoryTree = await app.$getAllCategoryInfo({
            MUTE_WARNING: 1,
          });
          app.store.dispatch('category/setCategoryData', resCategoryTree.data);
          if (
            resCategoryTree &&
            resCategoryTree?.errcode == 0 &&
            resCategoryTree?.data
          ) {
            categoryList = resCategoryTree.data;
          }
        }
      }
      // 英文类目
      if (lang == 'en') {
        if (
          app.store.state.category.categoryEnData &&
          app.store.state.category.categoryEnData.length > 0
        ) {
          if (lang == 'en') {
            categoryList = app.store.state.category.categoryEnData;
          }
        } else if (
          app.store.state.category.categoryEnData &&
          app.store.state.category.categoryEnData.length == 0
        ) {
          // 类目
          let resCategoryTree = await app.$getAllCategoryInfo({
            language: '3',
            MUTE_WARNING: 1,
          });
          app.store.dispatch(
            'category/setCategoryEnData',
            resCategoryTree.data
          );
          if (
            resCategoryTree &&
            resCategoryTree?.errcode == 0 &&
            resCategoryTree?.data
          ) {
            categoryList = resCategoryTree.data;
          }
        }
      }
      if (categoryList && categoryList.length > 0) {
        if (categoryId && categoryId.length > 0) {
          // 一级回显
          if (categoryId.length >= 1) {
            leaveOneInfo = JSON.parse(
              JSON.stringify(
                categoryList.filter(
                  (item) => item.supplier_class_id == categoryId[0]
                )[0]
              )
            );
            categoryTitle = leaveOneInfo.name + '_分销供应链_分销商城-细目商城';
            categoryDescription =
              '细目商城致力于构建全球分销供应链平台，为您提供' +
              leaveOneInfo.name +
              '商品电商分销供应链，为经营全流程提效降本';
            categoryKeywords =
              leaveOneInfo.name + ',分销供应链,分销商城,细目商城';
          }
          // 二级回显
          if (categoryId && categoryId.length >= 2) {
            if (leaveOneInfo?._child && leaveOneInfo._child.length > 0) {
              leaveTwoInfo = JSON.parse(
                JSON.stringify(
                  leaveOneInfo._child.filter(
                    (item) => item.supplier_class_id == categoryId[1]
                  )[0]
                )
              );
              categoryTitle = leaveTwoInfo.name + '_全球供应链平台-细目商城';
              categoryDescription =
                '细目商城致力于构建全球分销供应链平台，为您提供' +
                leaveTwoInfo.name +
                '商品电商分销供应链，精选好货，构建美好生活';
              categoryKeywords = leaveTwoInfo.name + ',全球供应链平台,细目商城';
              leaveTwoList = leaveOneInfo._child;
            }
          }
          // 三级回显
          if (categoryId && categoryId.length == 3) {
            if (leaveTwoInfo?._child && leaveTwoInfo._child.length > 0) {
              leaveThreeInfo = JSON.parse(
                JSON.stringify(
                  leaveTwoInfo._child?.filter(
                    (item) => item.supplier_class_id == categoryId[2]
                  )[0]
                )
              );
              if (leaveThreeInfo) {
                categoryTitle =
                  leaveThreeInfo?.name + '_货源供应链_电商商品分销-细目商城';
                categoryDescription =
                  '细目商城致力于构建全球分销供应链平台，为您提供' +
                  leaveThreeInfo?.name +
                  '电商分销供应链，从“一件”开始，让每一件顺利到达';
                categoryKeywords =
                  leaveThreeInfo?.name + ',全球供应链平台,细目商城';
              }
              leaveThreeList = leaveTwoInfo._child;
            }
          }
        }
      }
      // 商品列表
      const options = {
        page_size: 60,
        page_num: 1,
        API_LANG_TYPE: lang,
        comprehensive_order: 'desc',
        MUTE_WARNING: 1,
      };
      if (categoryId && categoryId.length > 0) {
        options.cat_1 = categoryId[0]; //一级分类
        options.cat_2 = categoryId[1]; //二级分类
        options.cat_id = categoryId[2]; //三级分类
      }
      const [resSearch, resSelectedItemList] = await Promise.all([
        // es搜索接口
        app.$search(options),
        // 精选商品
        app.$getSelectedItemList({
          page_size: '6',
          page_num: '1',
          API_LANG_TYPE: lang,
          MUTE_WARNING: 1,
        }),
      ]);

      // 搜索列表
      if (resSearch && resSearch?.errcode === 0) {
        if (resSearch.data?.list) {
          let list = JSON.parse(JSON.stringify(resSearch.data.list));
          total = Number(resSearch.data.count);
          tableData = list;
          // 品牌类别
          brandName = JSON.parse(
            JSON.stringify(resSearch.data?.brandList || [])
          );
          const objArr = {
            title: PUBLIC_CONFIHURATION[lang || curDefaultLanguage]['brand'],
            is_radio: true,
            is_ext: false,
            radio: [],
            checkbox: [],
            children: brandName,
          };
          goodProperty.push(objArr);
        }
      }
      // 精选商品
      if (resSelectedItemList && resSelectedItemList?.errcode == 0) {
        goodsSelectedL = resSelectedItemList.data.list;
      }
    } catch (error) {
      console.error(error);
      if (error?.status) {
        // 请求报错
        console.log(
          'search-list/CategoryList.vue',
          error?.status,
          error?.statusText
        );
      } else {
        // 代码报错
        console.log(error);
      }
    }

    return {
      categoryTitle,
      categoryDescription,
      categoryKeywords,
      tableData,
      goodsSelectedL,
      leaveOneInfo,
      leaveTwoInfo,
      leaveThreeInfo,
      categoryId,
      leaveTwoList,
      leaveThreeList,
      goodProperty,
      total,
    };
  },
  beforeDestroy() {
    if (process.client) {
      window.removeEventListener('scroll', this.scrollToTop);
      this.leavePagePoint();
    }
  },
  mounted() {
    if (process.client) {
      this.visitsLimit();
      this.lang = this.currLang;
      this.scrollToTopPage();
      let params = this.$route.params;
      // 类目
      if (params.id) {
        this.categoryId = params.id.split(',');
        this.getCategoryListTree();
      }
      window.addEventListener('scroll', this.scrollToTop);
      // this.searchList();
      this.getSelectedItem();
      if (
        this.$store.state.good.goodsCompareList &&
        this.$store.state.good.goodsCompareList.length === 0
      ) {
        this.showCompareCol = false;
      }
      if (
        this.$store.state.good.goodsCompareList &&
        this.$store.state.good.goodsCompareList.length > 0
      ) {
        this.showCompareCol = true;
      }
    }
  },
  methods: {
    // 访问限制接口
    // 访问限制接口
    visitsLimit() {
      visitsLimit().then((res) => {
        console.log(res, 'res');
      });
    },
    // 商品对比相关
    // 控制商品对比栏的显隐
    showGoodCompare() {
      this.showCompareCol = !this.showCompareCol;
    },
    // 点击清空对比列表
    handleClickClear() {
      this.checkBoxList = [];
      this.showCompareCol = false;
    },
    // 商品对比列表删除的时候传出来的值
    deleteList(skuId) {
      let idx = this.checkBoxList.findIndex((item) => item === skuId);
      if (idx > -1) {
        this.checkBoxList.splice(idx, 1);
      }
      if (this.checkBoxList && this.checkBoxList.length === 0) {
        this.showCompareCol = false;
      }
    },
    /**
     * 复选进商品对比
     * @param { Boolean } checkbox 是否被选中
     * @param {Object} item 选中的数据对象
     */
    handleClickCompare(checked, item) {
      this.showCompareCol = true;
      const currSku = {
        sku_id: item.sku_id,
        item_id: item.item_id,
        title: item.title,
        price: item.price,
        imgUrl: item.sku_image || item.image_default_id, // 首选sku图片，若无，则用商品主图
        mainImgUrl: item.image_default_id,
      };
      const currSkuId = item.sku_id;
      this.$nextTick(() => {
        if (!checked) {
          // 取消选中，删除
          this.$refs.XmSkuCompareColumn?.deleteSku(currSkuId);
        } else {
          // 选中，检查商品对比栏是否已满
          if (this.$store.getters.isCompareListFull) {
            this.deleteList(currSkuId); // 从 checkBoxList 中删除
            this.$refs.XmSkuCompareColumn?.showTip(
              '对比栏已满，最多选择4款商品进行对比哦！'
            );
          } else {
            this.$refs.XmSkuCompareColumn?.insertSku(currSku);
          }
        }
      });
    },
    // -------------------------------------------------------
    // 交互/获取数据等
    /**
     * @description 回到顶部
     */
    totop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 20);
    },
    scrollToTop() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 900) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },

    // 获取精选列表
    getSelectedItem() {
      const options = {
        page_size: '6',
        page_num: '1',
        API_LANG_TYPE: this.lang,
        MUTE_WARNING: 1,
      };
      this.$getSelectedItemList(options)
        .then((res) => {
          if (res.errcode == 0) {
            this.goodsSelectedL = res.data.list;
            // 埋点上报
            if (res.data.list && res.data.list.length !== 0) {
              let dataList = JSON.parse(JSON.stringify(res.data.list));
              this.selectGoodsPoint = this.handlePointData(dataList);
              this.burialPoint(this.selectGoodsPoint);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // 重置分页数
    researchList() {
      this.pages = 1;
      if (this.$refs.pagTools) {
        this.$refs.pagTools.changecurPage(this.pages);
      }
      this.goodProperty = [];
      this.searchList();
    },
    // 商品列表
    async searchList() {
      try {
        const options = {
          page_size: this.pageSize,
          page_num: this.pages,
          API_LANG_TYPE: this.lang,
          MUTE_WARNING: 1,
        };
        if (this.categoryId != '-1') {
          (options.cat_1 = this.categoryId[0]), //一级分类
            (options.cat_2 = this.categoryId[1]), //二级分类
            (options.cat_id = this.categoryId[2]); //三级分类
        }
        // 排序
        if (this.sortDesc == 1) {
          options[this.sortStr] = 'asc';
        } else if (this.sortDesc == -1) {
          options[this.sortStr] = 'desc';
        }
        // 价格
        if (this.priceMax != '') {
          if (this.priceMin == '') {
            options.price_min = '0';
          } else {
            options.price_min = this.priceMin;
          }
          options.price_max = this.priceMax;
        }
        // 上架时间
        if (
          this.timeOptions != [] &&
          this.timeOptions != null &&
          this.timeOptions.length
        ) {
          options.shelf_time_min = this.timeOptions[0];
          options.shelf_time_max = this.timeOptions[1];
        }

        // 更多筛选
        if (this.checkList != [] && this.checkList.length > 0) {
          this.checkList.forEach((item) => {
            options[item] = '1';
          });
        }
        // 品牌
        if (this.brandId != [] && this.brandName != []) {
          options.brand_id = this.brandId.join(',');
        }
        const res = await this.$search(options);
        if (res.errcode == 0) {
          this.brandName = [];
          this.total = Number(res.data.count);
          let list = JSON.parse(JSON.stringify(res.data.list));

          this.tableData = list;
          // 根据商品对比回显
          let arr = this.$store.state.good.goodsCompareList;
          arr.forEach((item) => {
            this.checkBoxList.push(item.sku_id);
          });

          // 埋点上报
          if (res.data.list && res.data.list.length !== 0) {
            let dataList = JSON.parse(JSON.stringify(res.data.list));
            this.goodsSortPoint = this.handlePointData(dataList);
            this.burialPoint(this.goodsSortPoint);
          }
          // 初始化
          this.goodProperty = [];
          this.brandName = JSON.parse(JSON.stringify(res.data.brandList));
          const objArr = {
            title: this.langConfig['brand'],
            is_radio: true,
            is_ext: false,
            radio: [],
            checkbox: [],
            children: this.brandName,
          };
          this.goodProperty.push(objArr);
        }
      } catch (err) {
        console.error(err);
        this.total = 0;
        this.brandId = [];
      }
    },
    // 分页事件
    goPageBtn(val) {
      this.pages = val;
      window.scrollTo(0, 0);
      this.searchList();
    },

    // 类目信息
    async getCategoryList() {
      try {
        if (this.lang == 'zh-cn') {
          // 中文类目
          if (
            this.$store.state.category.categoryData &&
            this.$store.state.category.categoryData.length > 0
          ) {
            if (this.lang == 'zh-cn') {
              this.categoryList = this.$store.state.category.categoryData;
            }
          } else if (
            this.$store.state.category.categoryData &&
            this.$store.state.category.categoryData.length == 0
          ) {
            // 类目
            let resCategoryTree = await this.$getAllCategoryInfo({
              MUTE_WARNING: 1,
            });
            this.$store.dispatch(
              'category/setCategoryData',
              resCategoryTree.data
            );
            if (resCategoryTree.errcode == 0 && resCategoryTree.data) {
              this.categoryList = resCategoryTree.data;
            }
          }
        }
        // 英文类目
        if (this.lang == 'en') {
          if (
            this.$store.state.category.categoryEnData &&
            this.$store.state.category.categoryEnData.length > 0
          ) {
            if (this.lang == 'en') {
              this.categoryList = this.$store.state.category.categoryEnData;
            }
          } else if (
            this.$store.state.category.categoryEnData &&
            this.$store.state.category.categoryEnData.length == 0
          ) {
            // 类目
            let resCategoryTree = await this.$getAllCategoryInfo({
              language: '3',
              MUTE_WARNING: 1,
            });
            this.$store.dispatch(
              'category/setCategoryEnData',
              resCategoryTree.data
            );
            if (resCategoryTree.errcode == 0 && resCategoryTree.data) {
              this.categoryList = resCategoryTree.data;
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 获取类目（树状）
    async getCategoryListTree() {
      this.getCategoryList();
      if (this.categoryId != '-1') {
        // 一级回显
        if (this.categoryId.length >= 1) {
          if (this.categoryList && this.categoryList.length > 0) {
            this.leaveOneInfo = JSON.parse(
              JSON.stringify(
                this.categoryList.filter(
                  (item) => item.supplier_class_id == this.categoryId[0]
                )[0]
              )
            );
          }
        }
        // 二级回显
        if (this.categoryId.length >= 2) {
          if (
            this.leaveOneInfo?._child &&
            this.leaveOneInfo._child.length > 0
          ) {
            this.leaveTwoInfo = JSON.parse(
              JSON.stringify(
                this.leaveOneInfo._child.filter(
                  (item) => item.supplier_class_id == this.categoryId[1]
                )[0]
              )
            );
            this.leaveTwoList = this.leaveOneInfo._child;
          }
        }
        // 三级回显
        if (this.categoryId.length == 3) {
          if (
            this.leaveTwoInfo?._child &&
            this.leaveTwoInfo?._child.length > 0
          ) {
            this.leaveThreeInfo = JSON.parse(
              JSON.stringify(
                this.leaveTwoInfo._child.filter(
                  (item) => item.supplier_class_id == this.categoryId[2]
                )[0]
              )
            );
            this.leaveThreeList = this.leaveTwoInfo._child;
          }
        }
      }
    },

    // 筛选公共函数
    sortListBefore(sortStr, sortDesc) {
      this.sortStr = sortStr;
      this.sortDesc = sortDesc;

      this.researchList();
    },
    // 价格筛选
    handlePriceSelect(priceMin, priceMax) {
      this.priceMin = priceMin;
      this.priceMax = priceMax;
      this.researchList();
    },
    // 上架时间
    handleSelectTime(time) {
      this.timeOptions = time;
      this.researchList();
    },
    // 更多筛选复选
    changecheckboxScreening(checkList) {
      this.checkList = checkList;
      this.researchList();
    },

    // 处理埋点数据
    handlePointData(dataList) {
      for (let i = 0; i < dataList.length; i++) {
        let item = {};
        item['item_id'] = dataList[i].item_id;
        item['time_in'] = Date.now();
        item['route'] = this.getStayRoute(this.$route);
        item['operating_system'] = this.getOperatingSystem();
        item['action_type'] = 1;
        dataList[i] = item;
      }
      return dataList;
    },
    // 离开页面时候埋点数据处理
    leavePagePoint() {
      let dataPointList = [...this.selectGoodsPoint, ...this.goodsSortPoint];
      for (let i = 0; i < dataPointList.length; i++) {
        dataPointList[i].time_out = Date.now();
      }

      if (dataPointList.length !== 0) {
        this.burialPoint(dataPointList);
      }
    },
  },
};
