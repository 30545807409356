
const IDRE18 =
  /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/; // 居民身份证
const HMIDCARD = /^[HMhm]{1}([0-9]{10}|[0-9]{8})$/; // 港澳
const PASSPORT =
  /^1[45][0-9]{7}$|([P|p|S|s]\d{7}$)|([S|s|G|g]\d{8}$)|([Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\d{8}$)|([H|h|M|m]\d{8,10})$/; // 护照
const TAIWAN = /^(\d{8}|[a-zA-Z0-9]{10}|\d{18})$/; // 台湾

// 手机号验证
const TELNUMBER = /^1\d{10}$/; // 大陆手机号验证
const XGPHONE = /^([6|9])\d{7}$/; // 香港手机号验证
const AMPHONE = /^[6]([8|6])\d{5}$/; // 澳门手机号验证
const TWPHONE = /^[0][9]\d{8}$/; // 台湾手机号验证

const BANKCARD = /^[0-9]{13,19}$/; // 银行卡验证
import { refundApply } from '@/api/client-api/account/logOutAccount.js';
import { sendSmsCode } from '@/api/client-api/api.js';

export default {
  name: 'confirmAccount',
  props: {
    // 账号相关信息
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      infoData: {},
      accountList: [],
      // 银行卡信息
      bankForm: {
        name: '', // 持卡人姓名
        idType: '', // 证件类型
        idCard: '', // 证件号
        bankId: '', // 银行卡号
        phoneType: '1', // 手机号类型
        phone: '', // 手机号
        code: '', // 验证码
      },
      bankFormTip: {
        name: false, // 持卡人姓名
        idType: false, // 证件类型
        idCardTip: false, // 证件号
        bankIdTip: false, // 银行卡号
        phoneType: false, // 手机号类型
        phoneTip: false, // 手机号
        code: false, // 验证码
      },
      // 证件号类型的下拉选择数据
      idTypeOptions: [
        { label: '居民身份证', value: '1' },
        { label: '台湾居民来往大陆通行证', value: '4' },
        { label: '护照', value: '2' },
        { label: '港澳居民来往内地通行证', value: '3' },
      ],
      // 手机号类型的下拉选择数据
      phoneTypeOptions: [
        { label: '中国大陆 +86', value: '1' },
        // { label: '中国香港 +852', value: '2' },
        // { label: '中国澳门 +853', value: '3' },
        // { label: '中国台湾 +886', value: '4' },
      ],

      timer: null, // 定时器
      numscond: 60,
    };
  },
  mounted() {},
  beforeDestroy() {
    // 离开页面清除计时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  watch: {
    bankForm: {
      deep: true,
      handler(newVal) {
        for (let key in newVal) {
          if (
            this.bankFormTip[key] == true &&
            typeof this.bankForm[key] === 'string' &&
            this.bankForm[key].trim() !== '' &&
            this.bankForm[key] !== undefined
          ) {
            this.bankFormTip[key] = false;
          }
        }
        // 居民身份证
        if (
          this.bankForm.idType == '1' &&
          IDRE18.test(this.bankForm.idCard?.trim())
        ) {
          this.bankFormTip.idCardTip = false;
        }
        // 台湾居民来往大陆通行证
        if (
          this.bankForm.idType == '4' &&
          TAIWAN.test(this.bankForm.idCard?.trim())
        ) {
          this.bankFormTip.idCardTip = false;
        }
        // 护照
        if (
          this.bankForm.idType == '2' &&
          PASSPORT.test(this.bankForm.idCard?.trim())
        ) {
          this.bankFormTip.idCardTip = false;
        }
        // 港澳居民来往内地通行证
        if (
          this.bankForm.idType == '3' &&
          HMIDCARD.test(this.bankForm.idCard?.trim())
        ) {
          this.bankFormTip.idCardTip = false;
        }

        // 大陆手机号
        if (
          this.bankFormTip.phoneTip &&
          this.bankForm.phoneType == '1' &&
          TELNUMBER.test(this.bankForm.phone?.trim())
        ) {
          this.bankFormTip.phoneTip = false;
        }

        // 银行卡号
        if (BANKCARD.test(this.bankForm.bankId)) {
          this.bankFormTip.bankIdTip = false;
        }
      },
    },
    // 账号信息
    info: {
      immediate: true,
      deep: true,
      handler(val) {
        if (process.client) {
          this.infoData = JSON.parse(JSON.stringify(val || {}));

          if (this.infoData?.fund_details) {
            this.getDetailMoney();
          }
        }
      },
    },
  },
  methods: {
    getDetailMoney() {
      let accountMoneyList = Object.entries(this.infoData?.fund_details);
      this.accountList = accountMoneyList?.map((item) => {
        return { ...item[1] };
      });
    },
    // 获取验证码
    sendSmsCode() {
      if (this.numscond != 60) {
        return;
      }
      if (this.bankForm.phone == '' || !TELNUMBER.test(this.bankForm.phone)) {
        this.$message.warning('请输入正确的手机号');
        return;
      }
      let options = {
        phone: this.bankForm.phone,
        type: 'distributorRefund',
      };
      sendSmsCode(options)
        .then((res) => {
          if (res?.errcode == 0) {
            if (res?.msg) {
              this.$message.success(res?.msg);
            }

            if (this.timer) {
              clearInterval(this.timer);
            }
            this.timer = setInterval(() => {
              this.numscond = this.numscond - 1;
              if (this.numscond == 0) {
                clearInterval(this.timer);
                this.timer = null;
                this.numscond = 60;
              }
            }, 1000);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 点击提交申请按钮
    btngo() {
      this.updateTip();
      this.$nextTick(() => {
        if (document.getElementsByClassName('err-color').length !== 0) {
          this.$message.warning('请检查填写的信息');
          return;
        }
        let options = {
          name: this.bankForm.name, // 持卡人姓名
          idcard_type: this.bankForm.idType, // 证件类型
          idcard: this.bankForm.idCard, // 证件号
          card_no: this.bankForm.bankId, // 银行卡号
          phone_type: this.bankForm.phoneType, // 手机号类型
          phone: this.bankForm.phone, // 手机号类型
          code: this.bankForm.code, // 验证码
        };
        refundApply(options)
          .then((res) => {
            if (res?.errcode == 0) {
              if (res?.msg) {
                this.$message.success(res?.msg);
              }
              this.$emit('go-next', 4);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },
    // 提交账户信息必填提示
    updateTip() {
      for (const key in this.bankFormTip) {
        if (
          typeof this.bankForm[key] === 'string' &&
          this.bankForm[key].trim() === ''
        ) {
          this.bankFormTip[key] = true;
        } else {
          this.bankFormTip[key] = false;
        }
      }
      // 身份证不能为空
      if (this.bankForm.idCard?.trim() == '') {
        this.bankFormTip.idCardTip = true;
      }
      // 居民身份证
      if (
        this.bankForm.idType == '1' &&
        !IDRE18.test(this.bankForm.idCard?.trim())
      ) {
        this.bankFormTip.idCardTip = true;
      }
      // 台湾居民来往大陆通行证
      if (
        this.bankForm.idType == '4' &&
        !TAIWAN.test(this.bankForm.idCard?.trim())
      ) {
        this.bankFormTip.idCardTip = true;
      }
      // 护照
      if (
        this.bankForm.idType == '2' &&
        !PASSPORT.test(this.bankForm.idCard?.trim())
      ) {
        this.bankFormTip.idCardTip = true;
      }
      // 港澳居民来往内地通行证
      if (
        this.bankForm.idType == '3' &&
        !HMIDCARD.test(this.bankForm.idCard?.trim())
      ) {
        this.bankFormTip.idCardTip = true;
      }
      // 银行卡号
      if (
        this.bankForm.bankId?.trim() == '' ||
        !BANKCARD.test(this.bankForm.bankId?.trim())
      ) {
        this.bankFormTip.bankIdTip = true;
      }
      // 大陆手机号
      if (
        this.bankForm.phoneType == '1' &&
        !TELNUMBER.test(this.bankForm.phone?.trim())
      ) {
        this.bankFormTip.phoneTip = true;
      }
    },
  },
};
