
import leftmenu from '@/components/comme/leftmenu.vue';
export default {
  name: 'ShopNotification',
  components: {
    leftmenu,
  },
  data() {
    return {
      leftname: '通知',
      menulist: ['通知设置', '平台公告', '交易通知', '系统通知'],
      routerlist: [
        '/notify/setting',
        '/notify/bulletinlist',
        '/notify/ordernotify',
        '/notify/systemnotify',
      ],
      routerIndexHash: {
        '/notify/setting': 0,
        '/notify/bulletinlist': 1,
        '/notify/bulletindetail': 1,
        '/notify/ordernotify': 2,
        '/notify/ordernotifydetails': 2,
        '/notify/systemnotify': 3,
        '/notify/systemnotifydetails': 3,
      },
    };
  },
  methods: {},
  //计算属性computed无法传递参数
  computed: {},
  mounted() {},
};
