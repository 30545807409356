import request from '@/utils/request.js';

//获取订单列表
export function getUserOrderList(data) {
  return request({
    url: '/order/getUserOrderList',
    method: 'get',
    params: data,
    controller: {
      openLoading: false,
    },
  });
}

// 获取商品的基本信息（标题、图和价格）
export function getItemBasicDetail(data) {
  return request({
    url: '/item/getItemBasicDetails',
    method: 'get',
    params: data,
    controller: {
      openLoading: false,
    },
  });
}

// 获取分销商信息
export function getDistributorInfo() {
  return request({
    url: '/adminUser/getDistributorInfo',
    method: 'get',
  });
}

/**
 * 常用语管理
 */
export function getCommonPhraseList(data) {
  return request({
    url: '/CommonPhrases/getList',
    method: 'get',
    params: data,
    controller: {
      closeLoading: true,
    },
  });
}
// 批量修改常用语列表
export function editCommonPhraseList(data) {
  return request({
    url: '/CommonPhrases/editList',
    method: 'post',
    data: data,
    controller: {
      closeLoading: true,
    },
  });
}
// 添加常用语
export function addCommonPhrase(data) {
  return request({
    url: '/CommonPhrases/add',
    method: 'post',
    data: data,
    controller: {
      closeLoading: true,
    },
  });
}

// 获取被分配的客服 id
export function getCSAccid(data) {
  return request({
    url: '/Chat/getCustomer',
    method: 'get',
    params: data,
    controller: {
      closeLoading: true,
    },
  });
}
