
// 商品评分类型
import { SCORE_TYPES } from '../js/label_config_header';
// DetailInfoAside 使用的是英文缩写版
import { SCORE_TYPES_ABBR } from '../js/label_config_info_aside';
// 变量
import { curDefaultLanguage } from '@/utils/cur_default_config.js'; //默认语言

export default {
  name: 'RatingBoard',
  props: {
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
    // 展示的英文的版本
    // 'addr' -> 缩写版
    type: {
      type: String,
      default: '',
    },
    // 是否有评分升降状态展示
    hasStatus: {
      type: Boolean,
      default: false,
    },
    // flex布局方式，默认横轴为主轴
    layout: {
      type: String,
      default: 'row',
    },
    labelLetterSpacing: {
      type: String,
      default: '0',
    },
    // 评分数组
    scores: {
      type: [Array, Object],
      default: () => [],
    },
  },
  filters: {
    // 空值处理
    emptyValFilter(val) {
      return !val ? '--' : val;
    },
  },
  computed: {
    // 评分类型的配置，特殊处理英文缩写版
    scoreTypes() {
      return this.type === 'abbr' ? SCORE_TYPES_ABBR : SCORE_TYPES;
    },
    ratingData() {
      if (!this.scores) {
        return [];
      }
      const data = this.scoreTypes[this.lang || curDefaultLanguage].map((x) => {
        x.value = this.scores[x.prop];
        return x;
      });
      return data;
    },
  },
};
