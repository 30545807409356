
// api
import { readMark } from '@/api/client-api/notify/allnotify.js';
export default {
  data() {
    return {
      selectedRows: this.curSelectedRows, //被选中的列表
    };
  },
  props: {
    // 当前被选中的列表
    curSelectedRows: {
      type: Array,
      default: [],
    },
    // 表格数据
    tableData: {
      type: Array,
      default: [],
    },
    // 路由跳转的位置
    routerPath: {
      type: String,
      default: '',
    },
    // 通知类型
    messageType: {
      type: String,
      default: '',
    },
  },
  watch: {
    curSelectedRows: {
      handler(n) {
        this.selectedRows = n;
      },
      deep: true,
    },
    tableData: {
      handler(n) {
        this.recoveryCheck(n);
      },
      deep: true,
    },
  },
  methods: {
    // 页面触发
    // 列表点击
    handleRowClick(row, column) {
      // 点击非复选区域跳转
      if (column?.type != 'selection') {
        if (this.routerPath) {
          if (row?.read == '0') {
            this.handleReadMark(row.id);
          }
          this.$router.push('/notify/' + this.routerPath + '/' + row.id);
        }
      }
    },
    // 设为标记按钮
    handleReadMark(id) {
      const options = {
        message_type: this.messageType,
        id: id,
        read_all: '0',
      };

      try {
        readMark(options);
      } catch (error) {
        console.error(error);
      }
    },

    // 列表复选相关
    handleSelect(selection, row) {
      let checkBox = '';
      let idx = '';
      // 判断是否选中
      checkBox = selection.find((item) => item['id'] === row['id']);
      /**
       * 使用这个方式比较有个注意点，对象中的属性，序列要一致
       */
      idx = this.selectedRows.findIndex((item) => item['id'] === row['id']);
      // 未选中
      if (!checkBox) {
        if (idx > -1) {
          this.selectedRows.splice(idx, 1);
        }
      } else {
        if (idx === -1) {
          this.selectedRows.push(row);
        }
      }
      this.$emit('select', this.selectedRows);
    },
    handleSelectAll(selection) {
      // 找出没有的列表
      const filteredSelection = selection?.filter((item) => {
        return !this.selectedRows.some((row) => {
          return row.id === item.id;
        });
      });
      filteredSelection?.forEach((item) => {
        this.selectedRows.push(item);
      });
      // 取消全选的情况
      if (selection?.length === 0) {
        const arr = this.selectedRows?.filter((item) => {
          return !this.tableData.some((row) => {
            return row.id === item.id;
          });
        });
        this.selectedRows = JSON.parse(JSON.stringify(arr || [])) || [];
      }
      // 可以另写个方法传出去
      this.$emit('select', this.selectedRows);
    },
    // 根据数据恢复选中状态
    recoveryCheck(arr) {
      if (arr?.length > 0) {
        // 有特定字段的情况
        arr?.forEach((item) => {
          if (
            this.selectedRows.findIndex((item2) => item2['id'] === item['id']) >
            -1
          ) {
            this.$nextTick(() => {
              this.$refs.allTable.toggleRowSelection(item, true);
            });
          }
        });
      }
    },
  },
};
