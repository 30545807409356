
export default {
  data() {
    return {};
  },
  props: {
    // 标题
    title: {
      type: String,
      default: '',
    },
    // 规格
    spc: {
      type: String,
      default: '',
    },
    // ID
    item_id: {
      type: String,
      default: '',
    },
    // id名字
    idText: {
      type: String,
      default: '商品ID：',
    },
    // 图片
    src: {
      type: String,
      default: '',
    },
    sku_lowest_price_img: {
      type: String,
      default: '',
    },
    /**
     * 父组件当前二级标签的值
     * 1:分仓sku 2:分仓spu 3:合仓sku 4:合仓spu
     */
    secondTab: {
      type: String,
      default: '-1',
    },
    // 数据信息
    itemInfo: {
      type: Object,
      default: null,
    },
  },
  computed: {
    // 在合仓SPU的二级标签的商品ID悬浮出提示
    canJumpTip() {
      if (this.secondTab == '4') {
        return '点击查看该商品的分仓-SKU信息';
      } else return '';
    },
  },
  mounted() {},
  methods: {
    clickEvents() {
      const obj = {
        item_id: this.item_id,
        image_default_id: this.src,
        sku_lowest_price_img: this.sku_lowest_price_img,
        publish_language_id: this.itemInfo?.publish_language_id,
      };
      this.$emit('comDetails', obj);
    },
    // 在合仓spu点击商品id跳转分仓sku标签
    goOtherSecondTab() {
      if (this.secondTab != '4') {
        return;
      }
      this.$emit('jump-sku-tab', this.item_id);
    },
  },
};
