
import XmStepIcon from './XmStepIcon.vue';

const __default__ = {
  name: 'XmSteps',
  components: { XmStepIcon },
  props: {
    space: {
      // 步骤之间的间距（横向分割线的长度）
      //   只传数字，不传单位
      type: [String, Number],
      default: '250',
    },
    currentStep: {
      // 当前高亮的步骤的id
      type: [Number, String],
      default: 1,
    },
    steps: {
      // 定义的步骤条列表
      type: Array,
      default: () => [
        {
          id: 0,
          title: '步骤1',
        },
        {
          id: 1,
          title: '步骤2',
        },
        {
          id: 2,
          title: '步骤3',
        },
      ],
    },
    // icon 的背景色
    iconBgColor: {
      type: String,
      default: '#fff',
    },
  },
  computed: {
    // 要把icon与分割线的间隔算进去
    // 所以要加上 icon 占的 44px
    realSpace() {
      return parseInt(this.space) + 44 + 'px';
    },
    // 当前激活的步骤
    activeStep() {
      // 避免数据出问题，因此多做一步验证
      const active = parseInt(this.currentStep);
      return !isNaN(active) ? active : 0;
    },
  },
  methods: {
    // 当前步骤的状态
    getCurrentStatus(step) {
      if (step < this.currentStep) {
        return 'finish'; // 已过、已完成
      } else if (step == this.currentStep) {
        return 'process'; // 当前的步骤
      } else {
        return 'wait'; // 还未经过的步骤
      }
    },
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "f7a64a72": (_vm.iconBgColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__