import request from '@/utils/request.js';

// 优惠券管理列表面板数据
export function couponListPanel(data) {
  return request({
    url: '/coupon/couponListPanel',
    method: 'get',
    params: data,
  });
}

// 获取优惠券列表
export function couponList(data) {
  return request({
    url: '/coupon/couponList',
    method: 'get',
    params: data,
    controller: {
      openLoading:true
    }
  });
}

// 删除优惠券(加入回收站)
export function delCoupon(data) {
  return request({
    url: '/coupon/delCoupon',
    method: 'post',
    data: data,
  });
}

// 恢复优惠券
export function recoverCoupon(data) {
  return request({
    url: '/coupon/recoverCoupon',
    method: 'post',
    data: data,
  });
}
