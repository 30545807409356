
import {
  PROBLEM_LIST,
  GET_PC_LIST,
  GET_MOBILE_LIST,
  CATE_PC_LIST,
  CATE_MOBILE_LIST,
  COME_LIST,
} from './js/show_list';
export default {
  head() {
    return {
      script: [
        { src: 'https://hm.baidu.com/hm.js?f33b77b68536bfed0847374dc351484c' },
      ],
    };
  },
  name: 'LandingPage',
  props: {},
  data() {
    return {
      isMobile: false, // 是否是手机
      problemList: PROBLEM_LIST, // 问题
      comeList: COME_LIST, // 来细目

      version: 6, // 二维码生成的版本，数字越大，二维码越复杂

      needFloat: false, // 底部注册是否需要浮动，否认否
    };
  },
  mounted() {
    if (process.client) {
      this.scrollToTopPage(); // 初次打开页面为顶部
      this.judgeMobile(); // 判断设备类型
      window.addEventListener(
        'scroll',
        this.throttle(this.scrollToTopNav, 200)
      );
    }
  },
  beforeDestroy() {
    window.removeEventListener(
      'scroll',
      this.throttle(this.scrollToTopNav, 50)
    );
  },
  computed: {
    // 得到
    getList() {
      if (this.isMobile) return GET_MOBILE_LIST;
      if (!this.isMobile) return GET_PC_LIST;
    },
    // 品类
    cateList() {
      if (this.isMobile) return CATE_MOBILE_LIST;
      if (!this.isMobile) return CATE_PC_LIST;
    },
  },
  methods: {
    // 监听页面滚动
    scrollToTopNav() {
      let clientHeight = document.documentElement.clientHeight; // 获取视口高度
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop >= clientHeight) {
        this.needFloat = true;
      } else {
        this.needFloat = false;
      }
    },
    // 判断是否是手机端，如果是，返回true
    judgeMobile() {
      this.isMobile = navigator?.userAgent?.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      // #memo 二维码改为市场部提供，不再自动生成
      // if (!this.isMobile)
      //   this.handleCreateQRcode(
      //     process.env.NUXT_ENV.VUE_APP_H5_SHOP_HOST +
      //       '/pages/login/register/?source=pc4'
      //   );
    },
    // 生成二维码
    async handleCreateQRcode(content) {
      try {
        const options = {
          text: content,
          version: this.version,
          margin: 0,
          MUTE_WARNING: 1,
        };
        let that = this; // 必须要，否则 this 指向会错误
        function callbackFn(err, url) {
          if (err) {
            console.error(err);
            return;
          }
          that.$refs.qrcodeImg.src = url;
        }
        await this.createQRcode(options, callbackFn);
      } catch (err) {
        console.error(err);
      }
    },
    /**
     * 跳转注册页
     * @param {String} num 根据市场需求分为 pc1 pc2 pc3 pc4 yd1 yd2 yd3
     */
    goRegister(num) {
      if (this.isMobile) {
        // 移动端三个跳转注册的入口链接调整为
        window.open(
          process.env.NUXT_ENV.VUE_APP_H5_SHOP_HOST +
            '/pages/login/register?type=shop&invitation=13417500898&reg_way=1'
        );
      }
      if (!this.isMobile) {
        // PC端三个跳转注册的入口链接调整为
        window.open(
          process.env.NUXT_ENV.VUE_APP_H5_SHOP_HOST +
            '/pages/login/register?type=shop&invitation=15361575065&reg_way=1'
        );
      }
    },
    // 获取爆款货盘表
    getHotEcharts() {
      if (this.isMobile) {
        // 移动端
        window.open(
          'https://affim.baidu.com/unique_56813326/mobile/chat?siteId=20823447&userId=56813326&siteToken=f33b77b68536bfed0847374dc351484c'
        );
      } else {
        // PC 端
        window.open(
          'https://affim.baidu.com/unique_56813326/chat?siteId=20823447&userId=56813326&siteToken=f33b77b68536bfed0847374dc351484c'
        );
      }
    },
  },
};
