
// 组件
import InputPassword from '@/pages/back-stage/account-module/components/inputPassword.vue';

import { BALANCE_PAYMENT } from '../js/label_config_public';
export default {
  name: 'BalancePayDialog',
  props: {
    // 语言
    lang: {
      type: String,
      default: 'zh-cn',
    },
    // 余额支付错误提示语，1不展示错误提示，2展示密码位数少，3展示业务提示错误
    passWorldTip: {
      type: String,
      default: '1',
    },
    // 接口返回的错误提示内容
    errTip: {
      type: String,
      default: '',
    },
  },
  components: { InputPassword },
  data() {
    return {
      password: '', // 支付密码
    };
  },
  computed: {
    // 标签多语言配置
    langConfig() {
      return BALANCE_PAYMENT[this.lang || 'zh-cn'];
    },
  },
  mounted() {},
  methods: {
    // 关闭弹窗
    closeDialog() {
      this.$emit('close-dialog');
    },
    // 设置密码
    setPassword() {
      this.$router.push('/account');
    },
    // 获取余额支付密码
    getBankPass(item) {
      this.password = item;
    },
    // 点击确认支付
    surePay() {
      this.$emit('sure-pay', this.password);
    },
  },
};
