/**
 * 存储地址、网站导航数据
 */
export const state = () => ({
  provinceList: [], // 中国省列表
  siteNavList: [], // 网站导航数据
  changeTime: null, // 改变数据的时间
});
export const getters = {};
export const mutations = {
  /**
   * 存储省列表
   */
  addProvinceList(state, newVal) {
    state.provinceList = newVal;
    state.changeTime = new Date().getTime();
  },
  /**
   * 存储网站导航数据
   */
  addSiteNavList(state, newVal) {
    state.siteNavList = newVal;
    state.changeTime = new Date().getTime();
  },
  // 删除存储数据
  delProvAndSite(state) {
    state.provinceList = []; // 中国省列表
    state.siteNavList = []; // 网站导航数据
    state.changeTime = ''; // 改变数据的时间
  },
};
export const actions = {};
