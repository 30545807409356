
/**
 * 多语言配置
 */
import { CURRENCY_CONFIG } from '../../good-detail/js/label_config_common';
// 变量
import {
  curDefaultLanguage,
  CUR_TOKEN_NAME,
} from '@/utils/cur_default_config.js'; //默认语言
export default {
  name: 'BasicGoodsBox',
  props: {
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
    // 商品信息
    goodsMsg: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      token: '',
    };
  },
  computed: {
    // 标签多语言配置
    currencyMark() {
      return CURRENCY_CONFIG[this.lang || curDefaultLanguage];
    },
    // 图片懒加载
    imgURL() {
      if (this.goodsMsg?.image_default_id) {
        return this.changeImgProperties(this.goodsMsg?.image_default_id);
      }
    },
  },
  mounted() {
    this.token = this.$cookies.get(CUR_TOKEN_NAME);
  },
  methods: {},
};
