
export default {
  name: 'XimuDialog',
  props: {
    title: {
      type: String,
      default: '默认标题',
    },
    width: {
      // 弹窗宽度
      type: String,
      default: '480px',
    },
    opacity: {
      // 遮罩层的透明度
      type: String,
      default: '0.25',
    },
    zIndex: {
      // 遮罩层的z轴坐标
      type: String,
      default: '9999',
    },
  },
  methods: {
    close() {
      this.$emit('close-dialog'); // 父组件绑定的事件
    },
  },
};
