
/**
 *
 * 实际使用示例在：src/pages/login/loginHome.vue
 *
 * 特点：
 * 1. 可自定义头部标题栏背景颜色
 * 2. 可自定义头部标题栏右侧的菜单栏【可自定义菜单项的：1）是否高光；2）跳转链接；3）是否隐藏分割线】
 * 3. 可自定义头部标题栏左侧的语言
 *
 * 使用方法（已注册全局）：
 *
 * <xm-header
 *   :backgroundColor="xxx"
 *   :menuList="yyy"
 *   :language="zzz"></xm-header>
 */
export default {
  name:'XimuHeader',
  props: {
    // 网站头部栏的背景颜色
    backgroundColor: {
      type: String,
      default: '#f7f7f7',
    },
    // header 右边的菜单列表
    // name：菜单项名称（显示在页面上的名称）
    // link：点击目标菜单项会跳转的链接
    // isHighlight: 是否橘色高亮，默认否
    // hideDivider: 是否隐藏菜单项右边的竖向分割线，默认否
    menuList: {
      type: Array,
      default: () => [
        {
          name: '用户协议',
          link: '',
          isHighlight: false,
          hideDivider: false,
        },
        {
          name: '帮助中心',
          link: '',
          isHighlight: false,
          hideDivider: false,
        },
      ],
    },
    // 当前网站的语言
    // 默认中文
    // 传入语言名称以及其图标
    language: {
      type: Object,
      default: () => {
        return {
          name: '中文',
          imgUrl: require('@/assets/China_icon.svg'),
        };
      },
    },
  },
  methods: {
    // 点击菜单项跳转链接
    clickMenuItem(item) {
      const link = item.link;
      const isOutside = item.isOutside;
      if (link) {
        if (isOutside) {
          // 外部链接
          window.open(link);
        } else {
          // 网站内连接
          this.$router.push(link);
        }
      }
      if (item.name == '帮助中心') {
        this.beImprovingFunc();
      }
    },
    // 点击网站头logo跳转首页
    jumpindex() {
      this.$router.push('/');
    },
  },
};
