
import AppealForm from '../components/AppealForm';
import { violationsDetails } from '@/api/client-api/complaints/illegal.js';
export default {
  components: {
    AppealForm,
  },
  data() {
    return {
      illegalId: '', //违规ID
      illegalInfo: {},
      // 弹框相关
      showAppealDialog: false,
    };
  },
  filters: {
    // 判断是否有值
    emptyValFilter(val) {
      return !val ? '--' : val;
    },
  },
  computed: {
    /**
     * 是否申诉或超时
     * 申诉状态 0-未申诉 1-待申诉2.待审核3.已通过 4.已驳回 5-超时未申诉
     * 处罚状态 1-处罚中2-处罚结束3-处罚撒销
     */
    // 按钮
    hasViolationBtn() {
      return (
        this.illegalInfo?.appeal_status == '1' &&
        this.illegalInfo?.penalty_status != '3'
      );
    },
    // 申诉材料，理由
    hasViolationInfo() {
      return (
        this.illegalInfo?.appeal_status != '1' &&
        this.illegalInfo?.appeal_status != '5'
      );
    },
    // 是否出现申诉结果
    appealResult() {
      return (
        this.illegalInfo?.appeal_status == '3' ||
        this.illegalInfo?.appeal_status == '4'
      );
    },
  },
  methods: {
    // 获取详情信息
    getInfo() {
      const options = {
        id: this.illegalId,
      };
      violationsDetails(options)
        .then((res) => {
          if (res) {
            if (res?.errcode == 0) {
              this.illegalInfo = JSON.parse(JSON.stringify(res?.data || {}));
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 点击“返回”
    goBack() {
      // this.$router.go(-1);
      this.$router.replace('/complaints/illegallist');
    },
    // 关闭申诉弹框
    handleCloseAppealDialog() {
      this.showAppealDialog = false;
    },
    // 申诉弹框点击确认
    handleSubmitAppealDialog() {
      this.handleCloseAppealDialog();
      this.getInfo();
    },
  },
  mounted() {
    let params = this.$route.params;
    if (params?.id && params?.id != '') {
      this.illegalId = params?.id;
      this.getInfo();
    } else {
      this.$router.push('/error');
    }
  },
};
