
import GridBlock from './components/GridBlock';
import MulticolBlock from './components/MulticolBlock';
import EvaluationBlock from './components/EvaluationBlock';
import StickyHeader from './components/StickyHeader.vue';
import { formatEvaluationCountLabel, filterEmptyData } from './js/utils';

import {
  INFO_MAIN_LABELS,
  BASIC_INFO_LABELS,
  PACK_INFO_LABELS,
  SPECIAL_INFO_LABELS,
} from './js/label_config_info_main';

import { BLOCK_LABELS } from './js/label_config_common';
// 变量
import { curDefaultLanguage } from '@/utils/cur_default_config.js'; //默认语言

export default {
  name: 'DetailInfoMain',
  components: { GridBlock, MulticolBlock, EvaluationBlock, StickyHeader },
  props: {
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
    // 货币标志
    currency: {
      type: String,
      default: '¥',
    },
    itemId: {
      type: String,
      default: '',
    },
    // 商品信息（与sku无关的）
    goodInfo: {
      type: Object,
      default: () => {},
    },
    // sku 信息
    skuInfo: {
      type: Object,
      default: () => {},
    },
    // 商品的所有 sku 列表数据
    skuList: {
      type: Array,
      default: () => [],
    },
    // 当前选中的仓库id
    warehouseId: {
      type: String,
      default: '',
    },
    // 当前选中的仓库的类型
    warehouseType: {
      type: [String, Number],
      default: '',
    },
    // 禁售平台
    prohibitionPlatform: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      focusedTabName: 'good-introduce', // tabs绑定值
      showShadow: false, // 标签页头部是否显示阴影
      evaluationCount: '', // 评价总数
    };
  },
  computed: {
    // 标签多语言配置
    labelConfig() {
      return INFO_MAIN_LABELS[this.lang || curDefaultLanguage];
    },
    // 基本信息多语言配置
    basicInfoLabelConfig() {
      return BASIC_INFO_LABELS[this.lang || curDefaultLanguage];
    },
    // 包装与规格多语言配置
    packInfoLabelConfig() {
      return PACK_INFO_LABELS[this.lang || curDefaultLanguage];
    },
    // 分销商专属信息多语言配置
    specialInfoLabelConfig() {
      return SPECIAL_INFO_LABELS[this.lang || curDefaultLanguage];
    },
    // 公共配置
    commonLabelConfig() {
      return BLOCK_LABELS[this.lang || curDefaultLanguage];
    },
    // 商品评价板块的标题
    // 当评价数大于 1000 时，显示 1000+
    evaluationLabel() {
      let countLabel = formatEvaluationCountLabel(this.evaluationCount);
      return `${this.labelConfig['reviews']}(${countLabel})`;
    },

    //商品介绍
    productIntroduction() {
      const goodInfo = this.goodInfo;
      const skuInfo = this.skuInfo;

      // 商品介绍 商品属性
      let goodsAttribute = [];
      if (Array.isArray(goodInfo?.attribute)) {
        goodsAttribute = goodInfo?.attribute.map((x) => {
          return {
            label: x.key,
            type: 'text',
            data: x.value,
          };
        });
      }
      const infos = [
        {
          label: this.basicInfoLabelConfig['title'],
          type: 'text',
          data: goodInfo?.title,
        },
        // {
        //   label: '商品ID',
        //   type: 'text',
        //   data: goodInfo?.item_id,
        // },
        {
          label: this.basicInfoLabelConfig['weight'],
          type: 'text',
          data: skuInfo?.weight,
        },
        {
          label: this.basicInfoLabelConfig['size'],
          type: 'text',
          data: skuInfo?.size,
        },
        {
          label: this.basicInfoLabelConfig['authentication_name'],
          type: 'text',
          data: goodInfo?.authentication_name,
        },
        {
          label: this.basicInfoLabelConfig['no_saleable_platform'],
          type: 'text',
          data: this.prohibitionPlatform,
        },
        {
          label: this.basicInfoLabelConfig['ccc_certificate'],
          type: 'text',
          data: goodInfo?.ccc_certificate,
        },
        {
          label: this.basicInfoLabelConfig['brand_name'],
          type: 'text',
          data: goodInfo?.brand_name,
        },
        {
          label: this.basicInfoLabelConfig['is_battery'],
          type: 'text',
          data: goodInfo?.is_battery,
        },
        {
          label: this.basicInfoLabelConfig['goods_states'],
          type: 'text',
          data: goodInfo?.goods_states,
        },
        {
          label: this.basicInfoLabelConfig['texture_name'],
          type: 'text',
          data: goodInfo?.texture_name,
        },
        {
          label: this.basicInfoLabelConfig['fragile_level'],
          type: 'text',
          data: goodInfo?.fragile_level,
        },
        ...goodsAttribute, // 商品属性
        // this.getSalesRestriction(2),
        {
          label: this.basicInfoLabelConfig['origin_place_text'],
          type: 'text',
          data: goodInfo?.origin_place_text,
        },
        // {
        //   label: this.basicInfoLabelConfig['defects_liability_period'],
        //   type: 'text',
        //   data: goodInfo?.defects_liability_period,
        // },
        {
          label: this.basicInfoLabelConfig['goods_version'],
          type: 'text',
          data: goodInfo?.goods_version,
        },
        {
          label: this.basicInfoLabelConfig['upgrade_cycle'],
          type: 'text',
          data:
            goodInfo?.upgrade_cycle == '1970-01-01'
              ? '--'
              : goodInfo?.upgrade_cycle,
        },
      ];
      return filterEmptyData(infos, 'data');
    },

    // 包装字段对象
    packingInfoList() {
      const skuInfo = this.skuInfo;
      const goodInfo = this.goodInfo;

      // 运输包装方式图片
      let pack_logistics_img = goodInfo?.pack_logistics_img
        ? JSON.parse(goodInfo?.pack_logistics_img)[0]
        : '';
      const infos = [
        {
          label: this.packInfoLabelConfig['packing_size'],
          type: 'text',
          data: skuInfo?.packing_size,
        },
        {
          label: this.packInfoLabelConfig['packing_weight'],
          type: 'text',
          data: skuInfo?.packing_weight,
        },
        {
          label: this.packInfoLabelConfig['measurement_unit'],
          type: 'text',
          data: goodInfo?.measurement_unit,
        },
        {
          label: this.packInfoLabelConfig['sale_method'],
          type: 'text',
          data: goodInfo?.sale_method,
        },
        {
          label: this.packInfoLabelConfig['texture_name'],
          type: 'text',
          data: goodInfo?.texture_name,
        },
        {
          label: this.packInfoLabelConfig['fragile_level'],
          type: 'text',
          data: goodInfo?.fragile_level,
        },
        {
          label: this.packInfoLabelConfig['goods_feature'],
          type: 'text',
          data: goodInfo?.goods_feature,
        },
        {
          label: this.packInfoLabelConfig['goods_states'],
          type: 'text',
          data: goodInfo?.goods_states,
        },
        {
          label: this.packInfoLabelConfig['good_pack_mode'],
          type: 'text',
          data: goodInfo?.good_pack_mode,
        },
        {
          label: this.packInfoLabelConfig['pack_logistics'],
          type: 'text',
          data: goodInfo?.pack_logistics,
        },
        {
          label: this.packInfoLabelConfig['pack_logistics_img'],
          type: 'image',
          data: pack_logistics_img,
          isCrossCols: true, // 项目是否跨行
        },
      ];

      return filterEmptyData(infos, 'data');
    },
    // 规格字段对象
    specificationInfoList() {
      if (!this.skuInfo?.spec_info) {
        return [];
      }
      const info = JSON.parse(this.skuInfo.spec_info);

      const res = info.map((x) => {
        return {
          label: x.name,
          type: 'text',
          data: x.value,
        };
      });
      return res;
    },
    // 分销商专属信息数据
    shopSpecialInfoList() {
      const skuInfo = this.skuInfo;
      const goodInfo = this.goodInfo;
      const wareInfo =
        skuInfo?.ware_info_list?.find(
          (item) =>
            item.sku_id == skuInfo.sku_id && this.warehouseId == item.ware_id
        ) || {};
      // 销售政策
      const salePolicy = {
        isSample: Number(goodInfo?.risk_rate) > 0, // 样品
        isPredict: Number(goodInfo?.is_accept_predict) > 0, // 预测
        isLock: Number(goodInfo?.is_accept_lock) > 0, // 锁货
        isPrepare: Number(goodInfo?.is_stockup) > 1, // 备货
        isPriceControl: Number(goodInfo?.price_control) === 1, // 建议零售价
      };
      // 备货政策
      const stockPolicy = {
        tableData: wareInfo?.stock_detail || [],
        tableFormat: [
          {
            prop: 'stock_quantity',
            label: this.specialInfoLabelConfig['stock_quantity'],
            width: this.specialInfoLabelConfig['stock_quantity_col_width'],
          },
          // {
          //   prop: 'discount',
          //   label: '折扣',
          //   width: '70',
          // },
          {
            prop: 'discount_price',
            label: this.specialInfoLabelConfig['discount_price'],
            width: this.specialInfoLabelConfig['discount_price_col_width'],
          },
        ],
      };

      // 用于“商品险”字段的保险区域
      const insuranceArea = goodInfo?.insurance_area
        ? goodInfo?.insurance_area.split(',')
        : [];

      const infos = [
        {
          label: this.specialInfoLabelConfig['measurement_unit'],
          type: 'text',
          data: goodInfo?.measurement_unit,
        },
        {
          label: this.specialInfoLabelConfig['sale_method'],
          type: 'text',
          data: goodInfo?.sale_method,
        },
        {
          label: this.specialInfoLabelConfig['good_pack_mode'],
          type: 'text',
          data: goodInfo?.good_pack_mode,
        },
        {
          label: this.specialInfoLabelConfig['suggest_price'],
          type: 'text',
          data:
            skuInfo?.suggest_price && skuInfo.suggest_price !== '--'
              ? this.currency + skuInfo.suggest_price
              : '--',
        },
        {
          label: this.specialInfoLabelConfig['price_rise_type'],
          type: 'text',
          data: goodInfo?.price_rise_type,
        },
        {
          label: this.specialInfoLabelConfig['salePolicy'],
          type: 'tag',
          data: salePolicy,
        },
        {
          label: this.specialInfoLabelConfig['stockPolicy'],
          type: goodInfo?.is_stockup == 2 ? 'table' : 'text',
          data:
            goodInfo?.is_stockup == 2
              ? stockPolicy
              : this.specialInfoLabelConfig['not-support-stockup'],
        },
        {
          label: this.specialInfoLabelConfig['sample_policy'],
          type: 'text',
          data: goodInfo?.sample_policy,
        },
        {
          label: this.specialInfoLabelConfig['packing_weight'],
          type: 'text',
          data: skuInfo?.packing_weight,
        },
        {
          label: this.specialInfoLabelConfig['freight_calculation_rules'],
          type: 'long-text',
          data: skuInfo?.freight_calculation_rules || '--',
          moreType: 'popover',
          isOverflow:
            skuInfo?.freight_calculation_rules &&
            skuInfo?.freight_calculation_rules != '--',
          moreText: skuInfo?.freight_calculation_rules || '',
          overflowText: this.commonLabelConfig['all'],
          textMaxWidth: '22em',
        },
        {
          label: this.specialInfoLabelConfig['packing_size'],
          type: 'text',
          data: skuInfo?.packing_size,
        },
        {
          label: this.specialInfoLabelConfig['volume_weight'],
          type: 'text',
          data: wareInfo?.volume_weight,
        },
        {
          label: this.specialInfoLabelConfig['price_weight_ratio'],
          type: 'before-popover',
          popoverText: this.specialInfoLabelConfig['price_weight_ratio_tip'],
          data: wareInfo?.price_weight_ratio,
        },
        {
          label: this.specialInfoLabelConfig['fragile_level'],
          type: 'text',
          data: goodInfo?.fragile_level,
        },
        {
          label: this.specialInfoLabelConfig['texture_name'],
          type: 'text',
          data: goodInfo?.texture_name,
        },
        {
          label: this.specialInfoLabelConfig['requirements'],
          type: 'text',
          data: goodInfo?.requirements,
        },
        {
          label: this.specialInfoLabelConfig['is_battery'],
          type: 'text',
          data: goodInfo?.is_battery,
        },
        {
          label: this.specialInfoLabelConfig['product_qualification_rate'],
          type: 'text',
          data: goodInfo?.product_qualification_rate,
        },
        {
          label: this.specialInfoLabelConfig['actual_pass_rate'],
          type: 'text',
          data: goodInfo?.actual_pass_rate,
        },
        {
          label: this.specialInfoLabelConfig['in_time_send_ratio'],
          type: 'text',
          data: goodInfo?.in_time_send_ratio,
        },
        {
          label: this.specialInfoLabelConfig['return_ratio'],
          type: 'text',
          data: goodInfo?.return_ratio,
        },
        {
          label: this.specialInfoLabelConfig['manufacture_time'],
          type: 'text',
          data: wareInfo?.manufacture_time,
        },
        {
          label: this.specialInfoLabelConfig['origin_place_text'],
          type: 'text',
          data: goodInfo?.origin_place_text,
        },
        /**
         * 现在只存在禁售平台的选项（
         * 用getSalesRestriction()方法会有个坑（例如：可售平台和禁售平台都有数据），以前的数据存在可售平台等数据，所以需要在源头进行处理掉）
         * #TODO 这个方法是拿到 可售平台，禁售平台，可售区域，禁售区域处理后的数据（目前只保留禁售平台）
         */
        // this.getSalesRestriction(5),

        // 下面这个就是专门只拿禁售平台数据的处理方法
        {
          label: this.basicInfoLabelConfig['no_saleable_platform'],
          type: 'text',
          data: this.prohibitionPlatform,
        },
        // 以下五个为会根据仓库类型动态显隐的字段
        // 仓库类型：1国内仓 2跨境仓 3海外仓
        {
          label: this.specialInfoLabelConfig['strn'],
          type: 'text',
          data: goodInfo?.strn,
          hide: this.warehouseType == '1',
        },
        {
          label: this.specialInfoLabelConfig['eu_name'],
          type: 'text',
          data: goodInfo?.eu_name,
          hide: this.warehouseType == '1',
        },
        {
          label: this.specialInfoLabelConfig['customs_bn'],
          type: 'text',
          data: goodInfo?.customs_bn,
          hide: this.warehouseType == '1' || this.warehouseType == '3',
        },
        {
          label: this.specialInfoLabelConfig['declare_name'],
          type: 'text',
          data: goodInfo?.declare_name,
          hide: this.warehouseType == '1' || this.warehouseType == '3',
        },
        {
          label: this.specialInfoLabelConfig['declare_cname'],
          type: 'text',
          data: goodInfo?.declare_cname,
          hide: this.warehouseType == '1' || this.warehouseType == '3',
        },
        {
          label: this.specialInfoLabelConfig['market_time'],
          type: 'text',
          data:
            goodInfo?.market_time == '1970-01-01'
              ? '--'
              : goodInfo?.market_time,
        },
        {
          label: this.specialInfoLabelConfig['expected_delisting'],
          type: 'text',
          data:
            goodInfo?.expected_delisting == '1970-01-01'
              ? '--'
              : goodInfo?.expected_delisting,
        },
        {
          label: this.specialInfoLabelConfig['goods_version'],
          type: 'text',
          data: goodInfo?.goods_version,
        },
        {
          label: this.specialInfoLabelConfig['upgrade_cycle'],
          type: 'before-popover',
          popoverText: this.specialInfoLabelConfig['upgrade_cycle_tip'],
          data:
            goodInfo?.upgrade_cycle == '1970-01-01'
              ? '--'
              : goodInfo?.upgrade_cycle,
        },
        {
          label: this.specialInfoLabelConfig['insurance_area'],
          type: 'long-arr',
          moreType: 'popover',
          isOverflow: insuranceArea.length > 5,
          moreText: insuranceArea.join('，'),
          overflowText: this.commonLabelConfig['all'],
          data: insuranceArea,
          dataText: insuranceArea.join('，').split('，', 5).join('，'),
          number: 5,
        },
      ];
      return infos;
    },
    // 详情描述中英文
    descriptionContent() {
      return this.goodInfo?.goods_describe || '';
    },
  },
  methods: {
    handleBuyNow() {
      this.$emit('buyNow');
    },
    // 点击复制商品 sku 编号
    handleClickCopySkuId() {
      this.copyText(this.skuInfo?.sku_id);
    },
    /**
     * 数据处理
     */
    getSaleableArea(data) {
      if (data == '全部' || data == 'all') {
        return [];
      } else if (data) {
        let areaName = [];
        data.forEach((item) => {
          if (item && item.list) {
            item.list.forEach((x) => {
              areaName.push(x);
            });
          }
        });
        return areaName;
      }
    },
    // 可售平台，禁售平台，可售区域，禁售区域处理
    getSalesRestriction(showNum) {
      let goodInfo = this.goodInfo;
      let salesRestrictionObj = {};
      if (goodInfo?.saleable_area != '') {
        salesRestrictionObj.label = this.basicInfoLabelConfig['saleable_area'];
        salesRestrictionObj.type = 'area';
        salesRestrictionObj.data = goodInfo?.saleable_area;
        salesRestrictionObj.dataText = this.getSaleableArea(
          goodInfo?.saleable_area
        );
        salesRestrictionObj.number = showNum;
      } else if (goodInfo?.no_saleable_area != '') {
        salesRestrictionObj.label =
          this.basicInfoLabelConfig['no_saleable_area'];
        salesRestrictionObj.type = 'area';
        salesRestrictionObj.data = goodInfo?.no_saleable_area;
        salesRestrictionObj.dataText = this.getSaleableArea(
          goodInfo?.no_saleable_area
        );
        salesRestrictionObj.number = showNum;
      } else if (goodInfo?.saleable_platform != '') {
        salesRestrictionObj.label =
          this.basicInfoLabelConfig['saleable_platform'];
        salesRestrictionObj.type = 'long-arr';
        salesRestrictionObj.data = goodInfo?.saleable_platform.split('、');
        salesRestrictionObj.dataText = goodInfo?.saleable_platform
          .split('、', showNum)
          .join('、');
        salesRestrictionObj.moreType = 'popover';
        salesRestrictionObj.isOverflow =
          goodInfo?.saleable_platform.split('、').length > showNum;
        salesRestrictionObj.moreText = goodInfo?.saleable_platform;
        salesRestrictionObj.number = showNum;
        salesRestrictionObj.overflowText = this.commonLabelConfig['all'];
      } else if (goodInfo?.no_saleable_platform != '') {
        salesRestrictionObj.label =
          this.basicInfoLabelConfig['no_saleable_platform'];
        salesRestrictionObj.type = 'long-arr';
        salesRestrictionObj.data = goodInfo?.no_saleable_platform.split(',');
        salesRestrictionObj.dataText = goodInfo?.no_saleable_platform
          .split(',', showNum)
          .join('、');
        salesRestrictionObj.moreType = 'popover';
        salesRestrictionObj.isOverflow =
          goodInfo?.no_saleable_platform.split(',').length > showNum;
        salesRestrictionObj.moreText = goodInfo?.no_saleable_platform
          .split(',')
          .join('、');
        salesRestrictionObj.number = showNum;
        salesRestrictionObj.overflowText = this.commonLabelConfig['all'];
      } else {
        salesRestrictionObj.hide = '1';
      }
      return salesRestrictionObj;
    },
    // 更新评价总数
    handleUpdateEvaluationCount(count) {
      this.evaluationCount = count;
      this.$emit('update-evaluation-count', count);
    },
    isElementPinned() {
      if (!process.client) {
        return;
      }
      const elem = document.querySelector(
        '#detail-info-main-tabs > div.el-tabs__header.is-top'
      );
      if (elem) {
        let { top } = elem.getBoundingClientRect();
        this.showShadow = top == 0;
      }
    },
  },
  mounted() {
    /**
     * 监听滚动事件，实时改变标签页的底部阴影
     */
    window.addEventListener('scroll', this.throttle(this.isElementPinned, 150));
  },
};
