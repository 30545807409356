// 未参加的爆单挑战表格
export const notAttendTableConfig = [
  {
    label: '爆单挑战名称',
    prop: 'chal_name',
    width: '318px',
    isSlot: true,
  },
  {
    label: '参加开始时间',
    prop: 'act_start_time',
    width: '188px',
    content: 'time',
  },
  {
    label: '参加截止时间',
    prop: 'act_end_time',
    width: '188px',
    content: 'time',
  },
  {
    label: '参加人数限制',
    prop: 'limit_num',
    width: '187px',
  },
  {
    label: '操作',
    prop: 'action',
    width: '139px',
    isSlot: true,
  },
];

// 进行中的爆单挑战表格
export const underwayTableConfig = [
  {
    label: '爆单挑战名称',
    prop: 'chal_name',
    width: '150px',
    isSlot: true,
  },
  {
    label: '挑战档次',
    prop: 'chal_content',
    width: '252px',
    content: 'level',
  },
  {
    label: '挑战期订单金额',
    prop: 'order_amount',
    width: '140px',
  },
  {
    label: '挑战期订单数',
    prop: 'order_num',
    width: '99px',
  },
  {
    label: '挑战状态',
    prop: 'chal_status_name',
    width: '84px',
  },
  {
    label: '挑战开始时间',
    prop: 'chal_start_time',
    width: '105px',
    content: 'time',
  },
  {
    label: '挑战截止时间',
    prop: 'chal_end_time',
    width: '106px',
    content: 'time',
  },
  {
    label: '操作',
    prop: 'action',
    width: '84px',
    isSlot: true,
  },
];

// 已结束的爆单挑战表格
export const overTableConfig = [
  {
    label: '爆单挑战名称',
    prop: 'chal_name',
    width: '170px',
    isSlot: true,
  },
  {
    label: '挑战档次',
    prop: 'chal_content',
    width: '220px',
    content: 'level',
  },
  {
    label: '挑战期订单金额',
    prop: 'order_amount',
    width: '122px',
  },
  {
    label: '挑战期订单数',
    prop: 'order_num',
    width: '92px',
  },
  {
    label: '挑战状态',
    prop: 'chal_status_name',
    width: '74px',
  },
  {
    label: '奖励状态',
    prop: 'rew_status_name',
    width: '74px',
  },
  {
    label: '挑战开始时间',
    prop: 'chal_start_time',
    width: '100px',
    content: 'time',
  },
  {
    label: '挑战截止时间',
    prop: 'chal_end_time',
    width: '100px',
    content: 'time',
  },
  {
    label: '挑战结束时间',
    prop: 'chal_finish_time',
    width: '100px',
    content: 'time',
  },
  {
    label: '剩余审核时间',
    prop: 'rest_time',
    width: '100px',
    isSlot: true,
  },
  {
    label: '奖励发放时间',
    prop: 'rew_time',
    width: '100px',
    content: 'time',
  },
  {
    label: '操作',
    prop: 'action',
    width: '84px',
    isSlot: true,
  },
];
