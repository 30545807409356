
import { Decimal } from 'decimal.js'; // 精度计算
import { getUserDetailInfo } from '@/api/client-api/account/information.js';
import { signAgreement } from '@/api/client-api/fund/fundCenter.js';
import NoticeDialog from '@/pages/back-stage/fund-module/fund-centre/components/NoticeDialog.vue';
export default {
  name: 'CNYPayMethods',
  components: {
    NoticeDialog,
  },
  props: {
    // 应付总金额
    amountPayable: {
      type: [Number, String],
      default: 0,
    },
    // 余额
    balanceValue: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      payMentMethod: '3', // 目前1是微信, 2是支付宝，3是余额支付
      showNoticeDialog: false, // 用户充值告知书弹窗
      isSignRecharge: '', // 是否签署账户余额充值、使用与提现协议
    };
  },
  computed: {
    // 支付后余额
    afterPayShow() {
      return new Decimal(this.balanceValue || 0)
        .minus(new Decimal(this.amountPayable || 0))
        .toFixed(2);
    },
    // 立即支付/去充值按钮文字
    payButtonFont() {
      if (this.afterPayShow < 0 && this.payMentMethod == '3') return '去充值';
      return '立即支付';
    },
  },

  filters: {
    // 判断是否有值
    emptyValFilter(val) {
      console.log(val > 0, 'mie');
      return !val ? '--' : val;
    },
  },
  methods: {
    // 选择支付方式
    selectPayWay(id) {
      this.payMentMethod = id;
    },
    /**
     * 点击立即支付
     * payMentMethod 目前1是微信, 2是支付宝，3是余额支付
     */
    immediatePay() {
      // 余额不足跳转充值页
      if (this.afterPayShow < 0 && this.payMentMethod == '3') {
        // 跳转充值页面之前 判断是否签署充值协议，签署了跳转充值页，没签署弹出弹窗
        if (this.isSignRecharge == '1') {
          this.$router.push('/recharge/balance/1'); // 跳转充值人民币页面
        } else {
          this.showNoticeDialog = true;
        }
        return;
      }
      this.$emit('chose-method', this.payMentMethod);
    },

    /**
     * 接口请求
     */

    // 获取用户信息 是否签署了《账户余额充值、使用与提现协议》
    async getUserInfo() {
      try {
        const res = await getUserDetailInfo();
        if (res?.errcode == 0) {
          //  是否签署账户余额充值、使用与提现协议【1：是 -1：否】
          this.isSignRecharge = res.data?.is_sign_agreement;
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 签署协议
    async signAgreementPost() {
      const options = {
        id: '9',
      };
      try {
        const res = await signAgreement(options);
        if (res?.errcode == 0) {
          // 跳转充值人民币页面
          this.$router.push('/recharge/balance/1');
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  mounted() {
    this.getUserInfo();
  },
};
