/**
 * 银行卡转账设置
 * balance.vue
 */
export const BANKCARD_TRANSFER = {
  'zh-cn': {
    'money-type': '￥',
    'dialog-title': '银行卡转账',
    'account-info': '银行账户信息',
    'payee-account-name': '收款方户名',
    'payee-account-number': '收款方账号',
    'payee-bank': '收款银行',
    'Swift-BIC': 'Swift BIC', // 中文没有占个位置
    'bank-address': '开户银行地址',
    'transfer-info': '转账信息',
    'transfer-amount': '转账金额',
    'transfer-records': '转账记录',
    'upload-pic': '上传图片',
    'upload-tip': '请上传转账记录，大小5M以内，jpg、jpeg、png。',
    'pic-empty': '转账记录截图不能为空',
    'submit': '提交',
    'cancel': '取消',
  },
  en: {
    'money-type': '$',
    'dialog-title': 'Bank card transfer',
    'account-info': 'Bank account information',
    'payee-account-name': 'Account name of payee',
    'payee-account-number': 'Account number of payee',
    'payee-bank': 'Payee bank', // 中文不展示
    'Swift-BIC': 'Swift BIC', // 中文没有
    'bank-address': 'Opening bank address',
    'transfer-info': 'Transfer information',
    'transfer-amount': 'Transfer amount',
    'transfer-records': 'Transfer records',
    'upload-pic': 'picture',
    'upload-tip': 'Please upload transfer records within 5M in jpg, jpeg, and png formats.',
    'pic-empty': 'Transfer record screenshot cannot be empty',
    'submit': 'submit',
    'cancel': 'cancel',
  },
};
