
// 组件
import SingleCoupon from './SingleCoupon.vue';
export default {
  name: 'CouponDrawer',
  components: { SingleCoupon },
  props: {
    // 待领取的券
    waitCollectList: {
      type: Array,
      default: () => [],
    },
    // 本品可用券
    usableList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visibleStatus: false, // 显示状态
    };
  },
  mounted() {},
  computed: {},
  methods: {
    // 打开抽屉
    openDrawer() {
      this.visibleStatus = true;
    },
    // 成功领取优惠券
    handleSuccessCoupon(id) {
      this.$emit('success-coupon', id);
    },
    // 关闭抽屉
    closeDrawer() {
      this.$emit('refresh-coupon');
    },
  },
};
