import request from '@/utils/request.js';
// 商品对比
//保存商品对比分组
export function saveUserItemComparisonGroupList(data) {
  return request({
    apiModule: 'goods',
    url: 'fav/saveUserItemComparisonGroupList',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

//根据名称查商品
export function getiteminforma(data) {
  return request({
    apiModule: 'goods',
    url: 'fav/getItemInformation',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
// 根据id查商品信息/
export function getItemInformationByIds(data) {
  return request({
    apiModule: 'goods',
    url: 'fav/getItemCompareInformation',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
//获取商品对比分组
export function getuseritemcomparisongrouplist(data) {
  return request({
    apiModule: 'goods',
    url: 'fav/getUserItemComparisonGroupList',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
// 删除分组
export function deleteUserItemComparisonGroup(data) {
  return request({
    apiModule: 'goods',
    url: 'fav/deleteUserItemComparisonGroup',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
// 获取平台列表
export function getSlaePlatformList(data) {
  return request({
    url: '/item/getSlaePlatformList',
    method: 'get',
    params: data,
  });
}
// 导出表格
export function exportCompareExcel(data) {
  return request({
    url: 'item/exportCompareExcel',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
