
import UpButtonSelection from '@/pages/login/component/UpButtonSelectionItem.vue';
import UpInput from '@/pages/login/component/UpInputItem.vue';
import WideButton from '@/pages/login/component/WideButtonItem.vue';
import CircleCheckbox from '@/pages/login/component/CircleCheckboxItem.vue';
import PrivacyProtocol from '@/pages/protocol/privacyProtocol.vue';
import RegisterProtocol from '@/pages/protocol/registerProtocol.vue';
import SuccessDialog from '@/pages/login/component/SuccessDialog.vue';
import UploadImgItem from '@/pages/login/component/UploadImgItem.vue';
import { registerDistributor } from '@/api/client-api/login/api.js';
import { encryptor } from '@/utils/XmAES128Encryptor.js';
import shopSettledProtocol from '../protocol/shopSettledProtocol';
import { mapMutations } from 'vuex';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  components: {
    UpButtonSelection,
    UpInput,
    WideButton,
    CircleCheckbox,
    PrivacyProtocol,
    RegisterProtocol,
    SuccessDialog,
    shopSettledProtocol,
    UploadImgItem,
  },
  props: ['isAgreed'],
  data() {
    // 国内手机号验证
    const regPhone = /^((\+|00)86)?1\d{10}$/;
    // 8~20位数字或字母，不能纯数字和纯字母
    const regPass = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z]{8,20}$/;
    const validatePhone = (rule, value, callback) => {
      if (!regPhone.test(value)) {
        callback(new Error('请输入正确的手机号码'));
      } else {
        callback();
      }
    };

    const validateInvitationCode = (rule, value, callback) => {
      if (value === '') {
        callback();
      } else if (!regPhone.test(value)) {
        callback(new Error('请输入正确格式的注册邀请码'));
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请设置正确格式的密码'));
      } else if (!regPass.test(value)) {
        callback(new Error('请设置正确格式的密码'));
      } else {
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.registerForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else if (!regPass.test(value)) {
        callback(new Error('请设置正确格式的密码'));
      } else {
        callback();
      }
    };
    const validateProtocol = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请勾选同意协议'));
      } else {
        callback();
      }
    };
    return {
      options: [
        {
          label: '国内分销',
          value: '国内分销',
        },
        {
          label: '跨境分销',
          value: '跨境分销',
        },
        {
          label: '全球分销',
          value: '全球分销',
        },
      ],
      registerRules: {
        shipArea: [
          { required: true, message: '请选择分销区域', trigger: 'blur' },
        ],
        phone: [{ validator: validatePhone, trigger: 'blur' }],
        code: [
          { required: true, message: '请输入正确的验证码', trigger: 'blur' },
        ],
        password: [{ validator: validatePass, trigger: 'blur' }],
        password2: [{ validator: validatePass2, trigger: 'blur' }],
        isAgreed: [{ validator: validateProtocol, trigger: 'blur' }],
        invitationCode: [
          { validator: validateInvitationCode, trigger: 'blur' },
        ],
      },
      registerForm: {
        shipArea: '',
        phone: '',
        code: '',
        password: '',
        password2: '',
        invitationCode: '',
        contactName: '',
        businessLicenseImg: '',
        doorPhotographImg: '',
        isAgreed: false,
      },
      showBoxIndex: 0, // 弹出哪个协议的弹窗
      showSuccessDialog: false, // 是否显示注册成功弹窗
      showProtocolTip: false, // 勾选协议提示
    };
  },
  methods: {
    ...mapMutations('login', [
      'setRegisterShopByKey',
      'setUserInfo',
      'setToken',
    ]),
    async submitForm() {
      try {
        // 注册方式：1扫码注册 2自然注册 3邀请码注册
        let regWay = this.registerForm.invitationCode ? '3' : '2';
        let route = this.$route;
        const postData = {
          distribution_area: this.registerForm.shipArea,
          phone: this.registerForm.phone,
          code: this.registerForm.code,
          psw: encryptor.encrypt(this.registerForm.password),
          invitation_code: this.registerForm.invitationCode,
          register_source: '1', // 注册来源，1-PC端 2-移动端
          register_way: regWay,
        };
        // 营业执照
        if (this.registerForm.businessLicenseImg) {
          postData.business_license = this.registerForm.businessLicenseImg;
        }
        // 门头照片
        if (this.registerForm.doorPhotographImg) {
          postData.door_photograph = this.registerForm.doorPhotographImg;
        }
        // 联系人
        if (this.registerForm.contactName.trim()) {
          postData.contact_person = this.registerForm.contactName.trim();
        }

        // 由于要跟外部跳转区分 本站落地页跳转给的来源query是landing
        if (route?.query?.landing) {
          postData.source_way = route?.query?.landing;
        }
        // 如果链接有来源，拿链接上的
        if (route?.query?.source) {
          postData.source_way = route?.query?.source;
        }
        // ximu-office-uid 中有来源的情况
        if (localStorage.getItem('ximu-office-uid')) {
          let info = JSON.parse(localStorage.getItem('ximu-office-uid'));
          // 有来源的情况
          if (info?.source) {
            postData.source_way = info?.source;
          }
        }

        const res = await registerDistributor(postData);
        if (res.errcode == 0) {
          // 保存分销商数据
          this.handleSaveLocalStorageShop(res.data);
          this.$store.commit('login/clearRegisterShopForm'); // 清空 vuex 的缓存
          this.$router.push('/');
          // 显示注册成功弹窗
          // this.handleShowSuccessDialog();
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 跳转到账号及认证的基本信息页面
    toBasicInfoPage() {
      this.$router.push('/account/information/edit');
    },
    // 保存分销商数据
    handleSaveLocalStorageShop(data) {
      localStorage.setItem(
        CUR_TOKEN_NAME,
        JSON.parse(JSON.stringify(data.token))
      );
      localStorage.setItem('userdetail', JSON.stringify(data.user));
      localStorage.setItem('statusShow', data.user.status);
      this.setUserInfo({ key: 'userdetail', value: JSON.stringify(data.user) });
      this.setUserInfo({ key: 'statusShow', value: data.user.status });
      this.setToken(JSON.parse(JSON.stringify(data.token)));
    },
    handleClickSubmit() {
      // 点击提交同时校验表单和是否同意协议
      if (!this.registerForm.isAgreed) {
        this.showProtocolTip = true;
      } else {
        this.showProtocolTip = false;
      }
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          // 未勾选协议 不发起注册请求
          if (!this.registerForm.isAgreed) {
            return;
          } else {
            this.submitForm();
          }
        } else {
          return false;
        }
      });
    },
    handleCloseProtocols() {
      this.showBoxIndex = 0;
    },
    handleShowProtocols(index) {
      this.showBoxIndex = index;
    },
    handleUpdateStore(val, key) {
      this.setRegisterShopByKey({ key: key, value: val });
    },
    // 限制联系人姓名
    handleContactName(e, key) {
      this.registerForm.contactName = e.replace(
        /[^a-zA-Z\u4E00-\u9FA5.·-]/g,
        ''
      );
      this.handleUpdateStore(this.registerForm.contactName, key);
    },
    handleShowSuccessDialog() {
      this.showSuccessDialog = true;
    },
    handleCloseSuccessDialog() {
      this.showSuccessDialog = false;
      this.toBasicInfoPage();
    },
    // 为什么不在父组件做获取验证码的接口？
    // 因为倒计时。倒计时的定时器写在子组件内部，因此获取验证码接口最好做在子组件内部
    // async handleGetCode() {},

    // 获取营业执照
    getBusinessLicenseImg(file) {
      let fileSrt = file?.join(',');
      this.registerForm.businessLicenseImg = fileSrt;
      console.log('businessLicenseImg', fileSrt);
      this.handleUpdateStore(fileSrt, 'businessLicenseImg');
    },
    // 获取门头照片
    getDoorPhotographImg(file) {
      let fileSrt = file?.join(',');
      this.registerForm.doorPhotographImg = fileSrt;
      this.handleUpdateStore(fileSrt, 'doorPhotographImg');
    },
  },
  mounted() {
    this.registerForm.isAgreed = this.isAgreed;
    // 记住用户上次的输入
    for (const key of Object.keys(this.registerForm)) {
      if (this.$store.state.login.registerShopForm[key]) {
        this.registerForm[key] = this.$store.state.login.registerShopForm[key];
      }
    }
  },
};
