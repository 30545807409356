// 需要服务端渲染的  统一放这里
export default ({ $axios }, inject) => {
  inject('getPoint', (data) =>
    $axios({
      url: '/BuryPoint/getPoint',
      method: 'post',
      data: data,
    })
  );
  // 前端预警接口
  inject('abnormalAlarm', (data) =>
    $axios({
      url: '/FrontEndAbnormalAlarm/abnormalAlarm',
      method: 'post',
      data: data,
    })
  );
  // 获取平台补贴商品
  inject('getSubsidizedGoods', (data) =>
    $axios({
      url: '/item/getSubsidizedGoods',
      method: 'get',
      params: data,
    })
  );
  /**
   * 春节活动相关
   */
  // 图片板块 板块一/四
  inject('getFeativalPlatePhoto', (data) =>
    $axios({
      url: '/festivalActivity/getPlatePhoto',
      method: 'get',
      params: data,
    })
  );
  // 商品板块 板块二/三
  inject('getFeativalPlateGoods', (data) =>
    $axios({
      url: '/festivalActivity/getPlateItem',
      method: 'get',
      params: data,
    })
  );
};
