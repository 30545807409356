
import { formatChatSessionTime } from '@/utils/time.js';
import { getCustomerServiceId } from '@/utils/chat_tools.js';
import { mapGetters } from 'vuex';
export default {
  props: {
    // 会话对象id
    otherId: {
      type: String,
      default: '',
    },
    // 最近一条消息
    lastMsg: {
      type: Object,
      default: () => {},
    },
    isRead: {
      type: Boolean,
      default: true,
    },
    isHighlight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      avatarDefault: require('@/assets/images/chat/default-avatar-03.png'), // 用户头像
    };
  },
  computed: {
    ...mapGetters('chat', ['userInfoById']),
    // 当前对话对象是否为客服
    isCustomerService() {
      const customerServiceId = getCustomerServiceId();
      return this.otherId == customerServiceId;
    },
    userInfo() {
      return this.userInfoById(this.otherId) || {};
    },
    formatChatTime() {
      if (!this.lastMsg?.time) {
        return '';
      }
      return formatChatSessionTime(this.lastMsg.time);
    },
    latestMsg() {
      if (this.lastMsg?.type === 'text') {
        return this.lastMsg.text || '';
      }
      let msgObj = JSON.parse(this.lastMsg?.content || '{}');
      if (msgObj.type === 'quote') {
        return msgObj.content;
      }
      if (msgObj.type === 'image') {
        return '[图片]';
      }
      if (msgObj.dataType === 'good') {
        return '[商品卡片]';
      }
      if (msgObj.dataType === 'order') {
        return '[订单卡片]';
      }
      return '';
    },
    theAvatar() {
      return this.userInfo.avatar || this.avatarDefault;
    },
    theName() {
      let lastMsgNick =
        this.lastMsg &&
        this.otherId?.toLowerCase() === this.lastMsg.from?.toLowerCase()
          ? this.lastMsg.fromNick
          : '--';
      return this.userInfo.nick || lastMsgNick;
    },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
