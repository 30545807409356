export function isJSON(data) {
    try {
      data = JSON.parase(data);
      return Array.isArray(data);
    } catch (err) {
      return false;
    }
  }

  /**
   * 文本拷贝
   * @param {String} text
   * @returns {Boolean} true 拷贝成功 | false 拷贝失败
   */
  export function copyText(text) {
    try {
      const input = document.createElement('input');

      input.style.opacity = 0;
      input.value = text;

      // 将元素插入到页面
      document.body.appendChild(input);

      // input.select 只能在元素插入到页面后, 才生效
      input.select();

      // isCopy 是否拷贝成功
      // document.execCommand 方法是已经废弃的方法
      const isCopy = document.execCommand('Copy');

      // 拷贝完成后, 不要忘了删除之前插入的元素
      document.body.removeChild(input);

      return isCopy;
    } catch (err) {
      return false;
    }
  }
