import { Message } from 'element-ui';
import Vue from 'vue';
let messageInstance = null;
const resetMessage = (options) => {
  if (messageInstance) {
    messageInstance.close();
  }
  messageInstance = Message(options);
};
['error', 'success', 'info', 'warning'].forEach((type) => {
  resetMessage[type] = (options) => {
    if (typeof options === 'string') {
      options = {
        message: options,
      };
    }
    options.type = type;
    return resetMessage(options);
  };
});
Vue.prototype.$message = resetMessage;
