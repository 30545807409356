
import GoodListItem from './GoodListItem.vue';
export default {
  name: 'QuoteBlock',
  components: { GoodListItem },
  props: {
    nick: {
      type: String,
      default: '--',
    },
    pos: {
      type: String,
      default: 'input', // input -> 输入框上方；message -> 消息上方
    },
    type: {
      type: String,
      default: 'text', // text -> 文字，image -> 图，goods -> 商品卡片，order -> 订单卡片
    },
    // 文本的引用内容
    content: {
      type: String,
      default: '引用内容',
    },
    // 订单信息 or 商品卡片信息
    info: {
      type: Object,
      default: () => {
        // 订单：
        // return {
        //   tid: '', // 订单号
        //   itemnum: '', // 下单的商品数量
        //   total_price: '', // 总价
        //   created_time: '', // 下单时间
        // };
        // 商品：
        // return {
        // sitem_id: '', // 商品id（用于跳转链接）
        //   title: '', // 商品标题
        //   price: '', // 价格
        //   image_default_id: '', // 商品主图
        // };
      },
    },
  },
  computed: {
    // 展示纯文字
    showText() {
      return (
        this.type === 'text' ||
        (this.type === 'image' && this.pos === 'input') ||
        (this.type === 'good' && this.pos === 'input')
      );
    },
    // 展示图片
    showImage() {
      return this.type === 'image' && this.pos === 'message';
    },
    // 展示商品图片
    showGoodsCard() {
      return this.type === 'good' && this.pos === 'message';
    },
    // 展示订单信息
    showOrderInfo() {
      return this.type === 'order';
    },
    // 引用内容为纯文字的
    quoteContent() {
      if (this.type === 'text') return this.content;
      else if (this.type === 'image' && this.pos === 'input') return '[图片]';
      else if (this.type === 'good' && this.pos === 'input')
        return this.info?.title || '[商品标题]';
      else return '';
    },
  },
};
