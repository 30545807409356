/**
 * 中英文标签配置
 * DetailHeader.vue
 */
// hello world
// 顶部，商家评分标签配置
export const SCORE_TYPES = {
  'zh-cn': [
    {
      label: '商品',
      prop: 'commodityScore',
      value: '',
    },
    {
      label: '服务',
      prop: 'serviceScore',
      value: '',
    },
    {
      label: '物流',
      prop: 'logisticsScore',
      value: '',
    },
  ],
  en: [
    {
      label: 'Commodity',
      prop: 'commodityScore',
      value: '',
    },
    {
      label: 'Service',
      prop: 'serviceScore',
      value: '',
    },
    {
      label: 'Logistics',
      prop: 'logisticsScore',
      value: '',
    },
  ],
};

// 按钮的标签配置
export const BTN_LABELS = {
  'zh-cn': {
    'follow-store': '关注店铺',
    'enter-the-store': '进入店铺',
    sure: '确定',
    cancel: '取消',
    prompt: '提示',
    'prompt-tip': '是否确认切换成海外分销？',
    'change-mode': '切换至海外分销',
  },
  en: {
    'follow-store': 'Follow store',
    'enter-the-store': 'Enter the store',
    sure: 'Sure',
    cancel: 'Cancel',
    prompt: 'Prompt',
    'prompt-tip': 'Are you sure to switch to domestic distribution?',
    'change-mode': '切换至国内分销',
  },
};
