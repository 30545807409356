
import InputSearchBox from '../InputSearchBox';
import ForUserButton from '../ForUserButton.vue';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  name: 'SearchBox',
  components: {
    InputSearchBox,
    ForUserButton,
  },
  props: {
    // 版心大小,默认1400px
    wrapWidth: {
      type: String,
      default: '1400px',
    },
    // logo距离左右距离
    logoMargin: {
      type: String,
      default: '0 59px',
    },
    // 有几个按钮 eg:搜索、搜全站/搜本店
    hasBtnNum: {
      type: [Number, String],
      default: 1,
    },
    // 右边3个按钮的盒子距离左边距离
    forUserMarginLeft: {
      type: String,
      default: '30px',
    },
    // 供应商ID
    supplierID: {
      type: String,
      default: '',
    },
    // 是否有新品需求按钮
    hasNeedBox: {
      type: Boolean,
      default: false,
    },
    // 是否有我的收藏按钮
    hasCollectBox: {
      type: Boolean,
      default: true,
    },
    // 是否有我的订单按钮
    hasOrderBox: {
      type: Boolean,
      default: true,
    },
    // 是否显示推荐搜索栏
    hasRecommend: {
      type: Boolean,
      default: true,
    },
    // 热搜词
    searchRecommend: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      token: '',
    };
  },
  mounted() {
    if (process.client) {
      this.token = this.$cookies.get(CUR_TOKEN_NAME);
    }
  },
  methods: {
    // 去首页
    goIndex() {
      this.$router.push('/');
    },
  },
};
