
import { language } from '../static/switchLanguage';
import {
  addSupAddr,
  editSupAddr,
  getPhoneCode,
} from '@/api/client-api/mine/mineapi.js';
// 组件
import AutomaticIdentification from './AutomaticIdentification.vue';
export default {
  components: { AutomaticIdentification },
  data() {
    let checkAddressDetail = (rule, value, callback) => {
      let field = rule.field;
      let en =
        this.currLanguage == 'en' &&
        !(this.wareType == 2 && this.addressObj.crossType == 1);
      let reg = /[\u4E00-\u9FA5]/g;
      if (value == '') {
        if (en) {
          callback(new Error('Please enter detailed address information'));
        } else {
          switch (field) {
            case 'deatilAddress':
              callback(new Error('请输入详细地址信息'));
              break;
            case 'username':
              callback(new Error('请输入收货人姓名'));
              break;
          }
        }
      } else if (en && reg.test(value)) {
        callback(new Error('Please fill in the English information'));
      } else {
        callback();
      }
    };
    let checkAddress = (rule, value, callback) => {
      let en =
        this.currLanguage == 'en' &&
        !(this.wareType == 2 && this.addressObj.crossType == 1);

      if (en) {
        if (this.addressInfo.country_id == '') {
          callback(new Error('Please select a country/province/city/district'));
        } else {
          callback();
        }
      } else {
        if (this.addressInfo.province_id == '') {
          callback(new Error('请选择完整地址'));
        } else {
          callback();
        }
      }
    };
    let checkPhone = (rule, value, callback) => {
      let en =
        this.currLanguage == 'en' &&
        !(this.wareType == 2 && this.addressObj.crossType == 1);
      let reg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      let virtual =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}-\d{3,5}$/;
      let fixedLine = /^\d{2,4}-\d{6,8}$/;
      if (value == '') {
        // 判空
        if (en) {
          callback(new Error('Please enter your phone number'));
        } else {
          callback(new Error('请输入手机号'));
        }
      } else if (
        !en &&
        !(reg.test(value) || virtual.test(value) || fixedLine.test(value))
      ) {
        callback(new Error('请输入正确的号码'));
      } else if (
        en &&
        (value.length > 15 ||
          (this.limtCode && value.length != Number(this.limtCode)))
      ) {
        if (en) {
          callback(new Error('Please enter the correct phone number'));
        } else {
          callback(new Error('请输入正确的手机号码'));
        }
      } else {
        callback();
      }
    };
    let checkNote = (rule, value, callback) => {
      let en = this.currLanguage == 'en';
      let reg = /[\u4E00-\u9FA5]/g;
      if (en && value && reg.test(value)) {
        callback(new Error('Please fill in the English information'));
      } else {
        callback();
      }
    };

    let checkCode = (rule, value, callback) => {
      let en =
        this.currLanguage == 'en' &&
        !(this.wareType == 2 && this.addressObj.crossType == 1);
      if (this.addressObj.code_id == '') {
        if (en) {
          callback(new Error('Please select dialing code'));
        } else {
          callback(new Error('请选择区号'));
        }
      } else {
        callback();
      }
    };
    return {
      languageObj: language,
      currentLanguage: {},
      addressKey: 1,
      // 地址信息
      addressInfo: {
        country_id: this.countryId,
        country: '',
        province_id: '',
        province: '',
        city_id: '',
        city: '',
        area: '',
        area_id: '',
      },
      // 英文地址
      en_addressInfo_obj: {
        en_country: '',
        en_province: '',
        en_city: '',
        en_area: '',
      },
      addressObj: {
        addressInfo: {}, // 地址信息,校验使用
        deatilAddress: '', // 详细地址
        zip: '', // 邮政编码
        username: '', // 收货人姓名
        phone: '', // 电话
        code_id: '', // 区号
        // remarks: "", // 备注
        checked: false, // 设为默认收货地址
        crossType: '2', // 跨境仓地址类型
      },
      crossTypeList: [
        {
          label: 'China',
          value: '1',
        },
        {
          label: 'others',
          value: '2',
        },
      ],
      rules: {
        addressInfo: [
          { required: true, trigger: 'change', validator: checkAddress },
        ],
        deatilAddress: [
          { required: true, trigger: 'blur', validator: checkAddressDetail },
        ],
        zip: [
          {
            required: true,
            trigger: 'blur',
            message: 'Please fill in the postal code',
          },
        ],
        username: [
          { required: true, trigger: 'blur', validator: checkAddressDetail },
        ],
        phone: [{ required: true, trigger: 'blur', validator: checkPhone }],
        code_id: [{ required: true, trigger: 'change', validator: checkCode }],
        // remarks: [
        //   {
        //     trigger: "blur",
        //     validator: checkNote,
        //   },
        // ],
      },
      phonetypelist: [],
      dialogVisible: true,
      limtCode: null,
      ware_countryId: '', // 仓库所在地国家id
      disableSave: false, // 是否禁用保存按钮
    };
  },
  props: {
    addressType: {
      type: Number,
      default: 1,
    },
    currentOperaObj: {
      type: Object,
      default: () => {},
    },
    addressTitle: {
      type: String,
      default: '',
    },
    operaType: {
      // 1是新增收货地址，2是新增临时收货地址，3是编辑地址，4是编辑临时地址
      type: String,
      default: '1',
    },
    temporaryIndex: {
      // 临时地址修改序列
      type: Number,
      default: 0,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    // 仓库id
    wareId: {
      type: String,
      default: '',
    },
    // 仓库类型1是国内仓，2是跨境仓，3是海外仓
    wareType: {
      type: String,
      default: '',
    },
    // 是否是欧盟
    eu: {
      type: Number,
      default: 0,
    },
    // 仓库所在地国家id
    countryId: {
      type: String,
      default: '',
    },
  },

  computed: {
    currLanguage() {
      return this.$store.state.common.currLanguage;
    },
    placeholderKey() {
      if (this.currLanguage == 'zh-cn') {
        return '请选择省/市/区';
      } else {
        return 'Please select a country/province/city/district';
      }
    },
    // 判断邮政编码是否必填
    requestCode() {
      if (
        this.currLanguage == 'en' &&
        !(this.wareType == 2 && this.addressObj.crossType == 1)
      ) {
        return 'zip';
      } else {
        return '';
      }
    },
  },
  mounted() {
    if (Object.keys(this.currentOperaObj).length > 0) {
      // 编辑
      this.addressKey++;
      this.addressObj.deatilAddress = this.currentOperaObj.address;
      this.addressObj.zip = this.currentOperaObj.zip;
      this.addressObj.username = this.currentOperaObj.username;
      this.addressObj.phone = this.currentOperaObj.phone;
      this.addressObj.code_id = this.currentOperaObj.code_id;
      // this.addressObj.remarks = this.currentOperaObj.remarks;
      this.addressObj.checked = this.currentOperaObj.status == 1;
      this.addressInfo.country_id = this.currentOperaObj.country_id;
      this.addressInfo.province_id = this.currentOperaObj.province_id;
      this.addressInfo.city_id = this.currentOperaObj.city_id;
      this.addressInfo.area_id = this.currentOperaObj.region_id;
      this.addressInfo.country = this.currentOperaObj.countries_name;
      this.addressInfo.province = this.currentOperaObj.province_name;
      this.addressInfo.city = this.currentOperaObj.city_name;
      this.addressInfo.area = this.currentOperaObj.region_name;

      if (this.currentOperaObj.type) {
        this.addressObj.crossType = this.currentOperaObj.type.toString();
      }
      if (this.wareType == 2 && this.addressObj.crossType == 1) {
        this.langType = 'zh-cn';
      }
    }

    this.getPhoneCode();
  },
  watch: {
    currLanguage: {
      handler(val) {
        if (val == 'zh-cn') {
          // 中文
          this.currentLanguage = this.languageObj.chObj;
        } else if (val == 'en') {
          // 英文
          this.currentLanguage = this.languageObj.enObj;
        }
        // if (this.wareType == 2 && this.addressObj.crossType == 1) {
        //   this.langType = "zh-cn";
        // } else {
        //   this.langType = val;
        // }
        this.langType = val;
      },
      immediate: true,
    },
    countryId: {
      handler(value) {
        if (this.wareType == 3) {
          this.ware_countryId = value;
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 获取区号
    getPhoneCode() {
      let option = {
        API_LANG_TYPE: this.langType,
      };
      getPhoneCode(option)
        .then((res) => {
          if (res.errcode == 0) {
            this.phonetypelist = res.data;
          }
        })
        .catch((err) => {
          console.log(err, 'err');
        });
    },
    // 获取校验电话号码长度
    codechange(limt) {
      if (limt) {
        let arr = this.phonetypelist.filter((item) => {
          return item.id == limt;
        });
        this.limtCode = arr[0]?.limitation;
      } else {
        this.limtCode = null;
      }
    },
    handleClose() {
      this.$emit('closeAddress');
    },
    async getAddressDataFunc(zh_addresss, en_address) {
      this.addressInfo.country_id = zh_addresss.country_id; // 国家id
      this.addressInfo.province_id = zh_addresss.province_id; // 省份id
      this.addressInfo.city_id = zh_addresss.city_id; // 市id
      this.addressInfo.area_id = zh_addresss.area_id;
      // 中文
      if (this.currLanguage == 'zh-cn') {
        this.addressInfo.country = zh_addresss.country; // 国家
        this.addressInfo.province = zh_addresss.province; // 省份
        this.addressInfo.city = zh_addresss.city; // 市
        this.addressInfo.area = zh_addresss.area;
      } else {
        // 英文
        this.addressInfo.country = en_address.en_country; // 国家
        this.addressInfo.province = en_address.en_province; // 省份
        this.addressInfo.city = en_address.en_city; // 市
        this.addressInfo.area = zh_addresss.en_area;
      }
      // this.addressInfo.country_id = zh_addresss.country_id;
      // this.addressInfo.country_id = zh_addresss.country_id;
    },
    onaddress() {
      if (this.disableSave) return;

      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.disableSave = true;
          let option = {
            country_id: this.addressInfo.country_id,
            province_id: this.addressInfo.province_id,
            city_id: this.addressInfo.city_id,
            region_id: this.addressInfo.area_id,
            address: this.addressObj.deatilAddress,
            zip: this.addressObj.zip,
            username: this.addressObj.username,
            phone: this.addressObj.phone,
            invoice_status: '1',
            status: this.addressObj.checked ? '1' : '2',
            // remarks: this.addressObj.remarks,
            code_id: this.addressObj.code_id,
            API_LANG_TYPE: this.currLanguage,
          };
          if (this.wareType == 2) {
            option.type = this.addressObj.crossType;
          }
          if (this.operaType == 1) {
            // 新建地址
            addSupAddr(option)
              .then((res) => {
                if (res.errcode == 0) {
                  if (res?.msg) {
                    this.$message.success(res?.msg);
                  }

                  this.$emit('getAddress', 'add');
                }
                this.disableSave = false;
              })
              .catch((err) => {
                this.disableSave = false;
                console.error(err);
              });
          } else if (this.operaType == 3) {
            // 编辑地址
            option.id = this.currentOperaObj.id;
            editSupAddr(option)
              .then(async (res) => {
                if (res.errcode == 0) {
                  if (res?.msg) {
                    this.$message.success(res?.msg);
                  }
                  this.$emit('getAddress', 'editor', option.id);
                }
                this.disableSave = false;
              })
              .catch((err) => {
                this.disableSave = false;
                console.error(err);
              });
          } else if (this.operaType == 2 || this.operaType == 4) {
            // 新增/编辑临时收货地址
            if (this.currentOperaObj.id) {
              option.id = this.currentOperaObj.id;
            } else {
              option.id = this.temporaryIndex + Math.random();
            }
            option.countries_name = this.addressInfo.country;
            option.province_name = this.addressInfo.province;
            option.city_name = this.addressInfo.city;
            option.region_name = this.addressInfo.area;
            option.temporary = true;
            this.$emit(
              'getTemporary',
              option,
              this.operaType,
              this.temporaryIndex
            );
            let msg = this.operaType == 2 ? '新增成功' : ' 编辑成功';
            this.$message.success(msg);
            this.disableSave = false;
          }
        }
      });
    },
    // 跨境仓，地址类型改变
    crossChange(value) {
      if (value == 1) {
        // 国内
        this.ware_countryId = 44;
        this.langType = 'zh-cn';
      } else if (value == 2) {
        // 国外地址
        this.ware_countryId = this.countryId;
        this.langType = this.currLanguage;
      }

      this.addressKey++;
      this.$refs.XmAreaSelectAll.clearAddressFunc();
      this.getPhoneCode();
      this.addressObj.code_id = '';
    },
    // -------------------------------------------------------------------------------
    // 智能识别地址
    setAddress(data) {
      // 地址信息
      const options = ['country', 'province', 'state', 'city', 'area'];
      for (let i = 0; i < options?.length; i++) {
        if (data[options[i] + '_id'] && data[options[i] + '_name']) {
          this.addressInfo[options[i] + '_id'] = data[options[i] + '_id'];
          this.addressInfo[options[i]] = data[options[i] + '_name'];
          // 省
          if (options[i] == 'state' || options[i] == 'province') {
            this.addressInfo['province_id'] = data[options[i] + '_id'];
            this.addressInfo['province'] = data[options[i] + '_name'];
          }
        }
      }
      this.addressKey++;
      // 详细地址
      if (data?.detail) {
        this.addressObj.deatilAddress = data?.detail || '';
      }
      // 收货人姓名
      if (data?.receive_name) {
        this.addressObj.username = data?.receive_name || '';
      }
      // 收货人手机号
      if (data?.phone) {
        this.addressObj.phone = data?.phone || '';
      }
    },
  },
};
