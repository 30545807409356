
export default {
  props: {
    // 认证：认证
    authentic: {
      type: String,
      default: '',
    },
    // 赔：晚发必赔
    lateCompensate: {
      type: Boolean,
      default: false,
    },
    // 正：假一赔三
    fakeCompensate: {
      type: Boolean,
      default: false,
    },
    // 退：退货政策
    returnPolicy: {
      type: Object,
      default: () => {
        // supplier_problem: [],
        // user_problem: []
      },
    },
    // 批：批发
    wholesale: {
      type: String,
      default: '',
    },
    isClickJump: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // returnPolicyInfo:{}
    };
  },

  computed: {
    returnPolicyInfo() {
      let info = {};
      let supplierContent = '';
      let shopContent = '';
      if (this.returnPolicy?.supplier_problem?.length > 0) {
        supplierContent = this.returnPolicy.supplier_problem?.join('；');
      }
      if (this.returnPolicy?.user_problem?.length > 0) {
        shopContent = this.returnPolicy.user_problem?.join('；');
      }
      info = {
        supplierContent,
        shopContent,
      };
      return info;
    },
  },
  mounted() {},
  methods: {
    // 点击标识跳转  (目前只有点击 假一赔三、晚发必赔标识有点击跳转效果)
    handleClickMark(id) {
      if (!this.isClickJump) return;
      let url = 'https://home.ximu.cn/platrule/' + id;
      window.open(url, '_blank');
    },
  },
};
