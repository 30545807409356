/**
 * 中英文配置
 * 店铺列表配置
 */

export const PUBLIC_CONFIHURATION = {
  'zh-cn': {
    'all-product-categories': '全部商品分类',
    'selected-products': '精选商品',
    keyword: '搜索关键字',
    'all-goods-store': '本店所有商品',
    'comprehensive-sorting': '综合排序',
    'sales-volume': '销量',
    sales: '销量',
    comment: '评价',
    price: '价格',
    currency: '￥',
    'listing-start-date': '上架时间',
    'end-date-of-listing': '上架结束日期',
    'more-filtering-criteria': '更多筛选条件',
    'tax-rate': '税率',
    'committed-pass-rate': '承诺合格率',
    'actual-pass-rate': '实际合格率',
    'timely-delivery-rate': '发货及时率',
    'committed-delivery-days': '发货承诺天数',
    'commodity-score': '商品评分',
    'return-rate': '退货率',
    ok: '确定',
    clear: '清空',
    cancel: '取消',
    'brand-licensing': '品牌授权',
    'accept-return-of-samples': '接受样品退货',
    'price-control-restrictions': '控价限制',
    'accept-the-forecast': '接受预测',
    'accept-the-stock': '支持批发',
    'accept-the-lock-goods': '接受锁货',
    'sales-requirements': '销量要求',
    brand: '品牌',
    open: '展开',
    close: '收起',
    multi: '多选',
  },
  en: {
    'all-product-categories': 'All product categories',
    'selected-products': 'Selected products',
    keyword: 'Keyword',
    'all-goods-store': 'All Goods In Our Store',
    'comprehensive-sorting': 'Comprehensive sorting',
    'sales-volume': 'Sales volume',
    sales: 'Sales',
    comment: 'Comment',
    price: 'Price',
    currency: '$',
    'listing-start-date': 'Listing start date',
    'end-date-of-listing': 'End date of listing',
    'more-filtering-criteria': 'More filtering criteria',
    'tax-rate': 'Tax rate',
    'committed-pass-rate': 'Committed pass rate',
    'actual-pass-rate': 'Actual pass rate',
    'timely-delivery-rate': 'Timely delivery rate',
    'committed-delivery-days': 'Committed delivery days',
    'commodity-score': 'Commodity score',
    'return-rate': 'Return rate',
    ok: 'OK',
    cancel: 'Cancel',
    clear: 'Clear',
    'brand-licensing': 'Brand licensing',
    'accept-return-of-samples': 'Accept return of samples',
    'price-control-restrictions': 'Price control restrictions',
    'accept-the-forecast': 'Accept the forecast',
    'accept-the-stock': 'Accept the stock',
    'accept-the-lock-goods': 'Accept the lock goods',
    'sales-requirements': 'Sales requirements',
    brand: 'Brand',
    open: 'Open',
    close: 'Close',
    multi: 'Multi',
  },
};

export const GOODS_BOX = {
  'zh-cn': {
    sales: '销量',
    'pass-rate': '合格率',
    'return-rate': '退货率',
    'number-of-warehouses': '仓库数',
    inventory: '库存',
    'the-ratio-of-price-weight-and-volume': '价重积比',
    reviews: '评价',
    'tax-rate': '税率',
    compare: '对比',
    new: '新品',
    sample: '样品',
    prediction: '预测',
    'lock-inventory': '锁货',
    'get-the-goods-ready': '批发',
    currency: '￥',
  },
  en: {
    sales: 'Sales',
    'pass-rate': 'Pass rate',
    'return-rate': 'Return rate',
    'number-of-warehouses': 'Number of warehouses',
    'number-of-warehouses': 'Number of warehouses',
    inventory: 'Inventory',
    'the-ratio-of-price-weight-and-volume':
      'The ratio of price, weight, and volume',
    reviews: 'Reviews',
    'tax-rate': 'Tax rate',
    compare: 'Compare',
    new: 'New',
    new: 'New',
    sample: 'Sample',
    prediction: 'Prediction',
    'lock-inventory': 'Lock inventory',
    'get-the-goods-ready': 'Get the goods ready',
    currency: '$',
  },
};
