
export default {
  name: 'FilePreviewDialog',
  props: {
    // 文件预览弹窗的类型
    // 'header': 顶部有“预览”标题栏的弹窗
    // 'no-header': 顶部无“预览”标题栏且弹窗较宽的弹窗
    type: {
      type: String,
      default: 'header',
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
  },
};
