// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export const state = () => ({
  // 登录表单数据
  loginAllForm: {
    phone: '',
    password: '',
    codePhone: '',
    code: '',
  },
  // 登录之后存储的数据
  loginInfo: {
    token: '',
    userdetail: '',
    statusShow: '',
  },
  // 分销商注册表单数据
  registerShopForm: {
    shipArea: '',
    phone: '',
    code: '',
    password: '',
    password2: '',
    invitationCode: '',
  },
  // 供应商表单注册数据
  registerSupplierForm: {
    phone: '',
    code: '',
    password: '',
    password2: '',
    invitationCode: '',
  },
  // 忘记密码表单数据
  forgetPasswordForm: {
    phone: '',
    password: '',
    password2: '',
    type: '', // 分销商 or 供应商
  },
  // 修改手机号表单数据
  modifyPhoneForm: {
    oldPhone: '',
    name: '',
    idcard: '',
    newPhone: '',
    code: '',
  },
});
export const getters = {
  getRegisterShopPhone: (state) => {
    return state.registerShopForm.phone;
  },
  getRegisterSupplierPhone: (state) => {
    return state.registerSupplierForm.phone;
  },
  getForgetPasswordPhone: (state) => {
    return state.forgetPasswordForm.phone;
  },
};
export const mutations = {
  setUserInfo(state, data) {
    state.loginInfo[data.key] = data.value;
    this.$cookies.set(data.key, data.value, {
      maxAge: 60 * 60 * 24 * 30,
      path: '/',
    });
  },
  setToken(state, token) {
    state.loginInfo.token = token || '';
    this.$cookies.set(CUR_TOKEN_NAME, token || '', {
      maxAge: 60 * 60 * 24 * 30,
      path: '/',
    });
  },
  // 修改表单数据
  setLoginAllFormByKey(state, data) {
    state.loginAllForm[data.key] = data.value;
  },
  setRegisterShopByKey(state, data) {
    state.registerShopForm[data.key] = data.value;
  },
  setRegisterSupplierByKey(state, data) {
    state.registerSupplierForm[data.key] = data.value;
  },
  setForgetPasswordByKey(state, data) {
    state.forgetPasswordForm[data.key] = data.value;
  },
  setModifyPhoneByKey(state, data) {
    state.modifyPhoneForm[data.key] = data.value;
  },
  // 清空表单数据
  clearLoginAllForm(state) {
    for (const key of Object.keys(state.loginAllForm)) {
      state.loginAllForm[key] = '';
    }
  },
  clearRegisterShopForm(state) {
    for (const key of Object.keys(state.registerShopForm)) {
      state.registerShopForm[key] = '';
    }
  },
  clearRegisterSupplierForm(state) {
    for (const key of Object.keys(state.registerSupplierForm)) {
      state.registerSupplierForm[key] = '';
    }
  },
  clearForgetPasswordForm(state) {
    for (const key of Object.keys(state.forgetPasswordForm)) {
      state.forgetPasswordForm[key] = '';
    }
  },
  clearModifyPhoneForm(state) {
    for (const key of Object.keys(state.modifyPhoneForm)) {
      state.modifyPhoneForm[key] = '';
    }
  },
  clearLoginInfoForm(state) {
    for (const key of Object.keys(state.loginInfo)) {
      state.loginInfo[key] = '';
    }
  },
};
export const actions = {
  // 清空注册的缓存数据
  clearRegisterForm({ commit }) {
    commit('clearRegisterShopForm');
    commit('clearRegisterSupplierForm');
  },
};
