export default ({ $axios }, inject) => {

  // 获取供应商店铺详情
  inject("getSupplierInfo", (data) =>
    $axios({
      url: "/Supplier/getSupplierInfo",
      method: "get",
      params: data,
    })
  );

  // 获取供应商分类
  inject("getCategoryList", (data) =>
    $axios({
      url: "/Supplier/getCategoryList",
      method: "get",
      params: data,
    })
  );

  // 获取供应商商品销量排行
  inject("getSupplierSaleRankList", (data) =>
    $axios({
      url: "/item/getSupplierSaleRankList",
      method: "get",
      params: data,
    })
  );
  // 商品列表
  inject("search", (data) =>
    $axios({
      url: "/es/search",
      method: "get",
      params: data,
    })
  );
};



