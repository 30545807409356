
import {
  saveUserItemComparisonGroupList,
  getiteminforma,
  getuseritemcomparisongrouplist,
  getItemInformationByIds,
  deleteUserItemComparisonGroup,
  getSlaePlatformList,
} from '@/api/client-api/goods/goodsContrast.js';
import { shelfItem } from '@/api/client-api/goods/GoodsAttention.js';
export default {
  data() {
    return {
      activeName: '1',
      initObj: {
        shopInfo: '商品名称',
        price: '价格',
        salesVolume: '销量',
        opPrice: '操作费',
        evaluate: '评价',
        storeNum: '库存数量',
        salesPrice: '销售额',
        qualifiedRate: '产品合格率',
        deliveryDays: '承诺发货天数',
        ratio: '价重积比',
        storeInfo: '仓库信息',
      },
      initObjArr: [
        // {supply_price_tax:'1234',sale_num:'35345345',title:'阿萨的煎熬可是大家阿萨的卡拉圣诞节爱上了打卡的考生阿萨的卡拉圣诞节'},
        // {supply_price_tax:'1234',sale_num:'12312321312',title:'阿萨的煎熬可是拉圣诞节'},
      ], //对比商品
      tableData1: [{ name: '淘宝', value: 'taobao' }],
      // 商品信息tab侧边表头列表
      tableData: [
        { name: '商品名称', value: 'image_default_id' },
        // {name:'商品名称',value:"title"},
        { name: '供货价', value: 'supply_price_tax' },
        { name: '建议售价', value: 'suggest_price' },
        { name: '操作费', value: 'operation_fee' },
        { name: '销量', value: 'sale_num' },
        { name: '订单笔数', value: 'order_count' },
        { name: '评分', value: 'rate' },
        { name: '评价率', value: 'evaluate_ratio' },
        { name: '承诺合格率', value: 'product_qualification_rate' },
        { name: '实际合格率', value: 'real_pass_rate' },
        { name: '退货率', value: 'return_ratio' },
        { name: '发货及时率', value: 'intime_ratio' },
        { name: '税率', value: 'import_tariff' },
        { name: '库存数量', value: 'store' },
        { name: '操作', value: 'operation' },
      ],
      // 可售平台侧边表头
      salePlatformList: [],
      // 更多字段可选字段
      allTableData: [
        { name: '上架时间', value: 'shelf_time' },
        { name: '价重积比', value: 'price_weight_ratio' },
        { name: '仓库ID', value: 'default_war_id' },
        { name: '类目', value: 'cat' },
        // { name: '库存数量', value: 'store' },
        { name: '锁货', value: 'is_accept_lock' },
        { name: '批发', value: 'is_stockup' },
        { name: '库龄', value: 'ware_age' },
        { name: '预测', value: 'is_accept_predict' },
        { name: '发货承诺天数', value: 'delivery_day' },
      ],
      sameHide: false,
      optimal: false,
      //记录更多字段的选中状态
      allCheckedTableData: [],
      addListArr: [1, 2, 3, 4],
      total: 0,
      pages: 1,
      pageSize: 10,

      //保存分组
      saveGroupShow: false,
      groupVal: '',
      //添加商品
      addItemShow: false,
      itemVal: '',
      hihstorygroupinglist: [
        // {id:'1',name:'分组1adhksfh1'},
        // {id:'2',name:'分组2'},
        // {id:'3',name:'分组3'},
      ], //历史分组
      seachlist: [], //搜索结果列表
      shopInfo: {}, //存储点击后的商品信息
      shopid: [],
      shopskuid: [],
      id: '',
      hoverId: '-1', //悬浮选中
      chooseIndex: '-1',
      titleM: '保存当前商品对比产品',
      scrollTop: '',
      // 删除提示框
      delAccountOrRoleVisible: false,
      jsq: null, //防抖
    };
  },
  filters: {
    wrapBefore(val) {
      if (val) {
        if (val.length > '16') {
          return val.slice(0, 16);
        } else {
          return val;
        }
      }
    },
    wrapAfter(val) {
      if (val) {
        if (val.length > '16') {
          return val.slice(16, val.length);
        }
      }
    },
  },

  mounted() {
    if (this.$route.query.item_id) {
      // this.initObjArr = this.$route.query.item_id.split(',')
      this.btngroup(this.$route.query);
    }
    // 根据滚动条显示表头
    window.addEventListener('scroll', this.scrolling);
    // 获取分组列表
    this.getuseritemcomparisongrouplist1();
    // 可售平台
    this.getSalePlatformList();
  },
  methods: {
    // 导出表格
    exportExcel() {
      let list = [];
      for (let i = 0; i < this.shopid.length; i++) {
        let listObject = {
          item_id: '',
          sku_id: '',
        };
        listObject.item_id = this.shopid[i];
        listObject.sku_id = this.shopskuid[i];
        list.push(listObject);
      }
      if (list?.length == 0) {
        this.$message.warning('请先添加商品再导出');
        return;
      }
      list = JSON.stringify(list);
      this.downloadFile(
        '/item/exportCompareExcel?data=' + list,
        '商品对比列表.xlsx'
      );
    },
    // 上架
    operationBtn(item) {
      const parme = {
        item_id: item,
        MUTE_WARNING: 1,
      };
      shelfItem(parme)
        .then((res) => {
          if (res?.errcode == 0) {
            this.$message.success('操作成功');
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 滚动监听事件
    scrolling() {
      let current =
        document.documentElement.scrollTop || document.body.scrollTop;
      let vh = window.innerHeight;
      if (current == vh) {
        this.showTop = true;
      } else {
        this.showTop = false;
      }
      this.scrollTop = current;
    },
    isShow(item, index) {
      // console.log(item,index);

      return true;
    },
    //点击tab事件 1-商品信息，2-可售平台
    changeTab(val) {
      this.activeName = val;
      this.optimal = false;
      this.allCheckedTableData = [];
      if (val == '1') {
        this.tableData = [
          { name: '商品名称', value: 'image_default_id' },
          // {name:'商品名称',value:"title"},
          { name: '供货价', value: 'supply_price_tax' },
          { name: '建议售价', value: 'suggest_price' },
          { name: '操作费', value: 'operation_fee' },
          { name: '销量', value: 'sale_num' },
          { name: '订单笔数', value: 'order_count' },
          { name: '评分', value: 'rate' },
          { name: '评价率', value: 'evaluate_ratio' },
          { name: '承诺合格率', value: 'product_qualification_rate' },
          { name: '实际合格率', value: 'real_pass_rate' },
          { name: '退货率', value: 'return_ratio' },
          { name: '发货及时率', value: 'intime_ratio' },
          { name: '税率', value: 'import_tariff' },
          { name: '库存数量', value: 'sale_amount' },
          { name: '操作', value: 'operation' },
        ];
      }
    },
    // 商品对比功能
    highlightMax(value, num) {
      var max = this.initObjArr.reduce((prev, current) => {
        return parseFloat(prev) > parseFloat(current[value])
          ? prev
          : current[value];
      }, 0);
      if (max != undefined && this.optimal == true) {
        if (num === max) {
          return { color: '#FF7802', fontWeight: 'bold' };
        } else {
          return {};
        }
      } else {
        return {};
      }
    },
    // 显示可售
    highlightactive(a, b) {
      if (b != undefined && this.optimal == true) {
        if (b == '1') {
          return { color: '#FF7802', fontWeight: 'bold' };
        } else {
          return {};
        }
      }
    },

    // 获取可售平台列表
    getSalePlatformList() {
      const options = {
        MUTE_WARNING: 1,
      };
      getSlaePlatformList(options)
        .then((res) => {
          if (res?.errcode == 0) {
            this.salePlatformList = JSON.parse(JSON.stringify(res?.data));
            const arr = [{ name: '商品名称' }, { name: '汇总' }];
            if (this.salePlatformList.length > 0) {
              this.salePlatformList.unshift(...arr);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // 对比
    // 获取历史对比分组列表
    getuseritemcomparisongrouplist1() {
      // const options = {
      //     token:'8b7c163f68dd7209c7afaf227752f59c'
      // }
      getuseritemcomparisongrouplist({
        MUTE_WARNING: 1,
      })
        .then((res) => {
          // console.log(res);
          if (res?.errcode == 0) {
            this.hihstorygroupinglist = res?.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 删除某几条
    spliceList() {
      this.addListArr = [1, 2, 3, 4];
      this.addListArr.splice(0, this.initObjArr?.length);
    },
    // 点击分组
    btngroup(e) {
      if (this.id == this.hoverId) {
        this.addGroup();
        this.titleM = '保存当前商品对比产品';
        return;
      }
      if (e.id) {
        //点击分组
        this.shopid = e.item_id.split(',');
        this.shopskuid = e.sku_id.split(',');
        getItemInformationByIds({
          item_id: e.item_id,
          sku_id: e.sku_id,
          MUTE_WARNING: 1,
        })
          .then((res) => {
            if (res?.errcode == 0) {
              this.initObjArr = res?.data;
              this.id = e.id;
              this.groupVal = e.name;
              this.titleM = '修改当前商品对比分组名';
              this.spliceList();
              this.jsq = null;
            }
          })
          .catch((err) => {
            console.error(err);
          });
        return;
      }

      //否则从对比调过来
      if (e.item_id) {
        this.shopid = e.item_id.split(',');
        this.shopskuid = e.sku_id.split(',');
        getItemInformationByIds({
          item_id: e.item_id,
          sku_id: e.sku_id,
          MUTE_WARNING: 1,
        })
          .then((res) => {
            if (res?.errcode == 0) {
              this.initObjArr = res?.data;
              this.spliceList();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    addGroup() {
      if (this.id != '') {
        this.id = '';
        this.groupVal = '';
        this.initObjArr = [];
        this.shopid = [];
        this.shopskuid = [];
        this.hoverId = '-1';
        this.addListArr = [1, 2, 3, 4];
      }
    },
    // 保存分组弹框
    saveGroupBefore() {
      this.saveGroupShow = !this.saveGroupShow;
    },
    saveCancel() {
      this.saveGroupShow = !this.saveGroupShow;
      this.groupVal = '';
    },
    // 保存
    async btnSave() {
      try {
        const option = {
          item_id: this.shopid.toString(),
          sku_id: this.shopskuid.toString(),
          name: this.groupVal,
          id: this.id,
        };
        if (this.id == '') {
          if (this.hihstorygroupinglist?.length >= 3) {
            this.$message.warning('最多只能保存三个');
            return;
          }
        }
        const res = await saveUserItemComparisonGroupList(option);
        if (res?.errcode == 0) {
          if (this.id == '') {
            this.$message.success('保存成功');
            // 清除query值
            // this.$router.replace({query:{}})
            this.initObjArr = [];
            this.shopid = [];
            this.shopskuid = [];
            this.addListArr = [1, 2, 3, 4];
          } else {
            this.$message.success('修改成功');
          }
          this.groupVal = '';
          this.saveGroupShow = !this.saveGroupShow;
          this.getuseritemcomparisongrouplist1();
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 删除历史分组
    delhis() {
      this.delAccountOrRoleVisible = true;
    },
    closedel(n) {
      this.delAccountOrRoleVisible = n;
    },
    suredel() {
      const options = {
        id: this.id == '' ? this.hoverId : this.id,
      };
      deleteUserItemComparisonGroup(options)
        .then((res) => {
          if (res?.errcode == 0) {
            if (res?.msg) {
              this.$message.success(res?.msg);
            }
            this.getuseritemcomparisongrouplist1();
            this.initObjArr = [];
            this.shopid = [];
            this.shopskuid = [];
            this.addListArr = [1, 2, 3, 4];
            this.delAccountOrRoleVisible = !this.delAccountOrRoleVisible;
            this.id = '';
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 添加商品弹框
    addGoods() {
      this.addItemShow = !this.addItemShow;
    },
    addCel() {
      this.addItemShow = !this.addItemShow;
      this.itemVal = '';
      this.seachlist = [];
      this.shopInfo = {};
    },
    // 确定添加
    addBtn() {
      if (!this.shopInfo?.item_id || !this.shopInfo?.sku_id) {
        return;
      }
      getItemInformationByIds({
        item_id: this.shopInfo.item_id,
        sku_id: this.shopInfo.sku_id,
      })
        .then((res) => {
          if (res.errcode == 0) {
            if (this.initObjArr.length > 0) {
              let temporarilyInfo = this.initObjArr.filter(
                (item) => item.sku_id == res.data[0].sku_id
              );
              if (temporarilyInfo.length > 0) {
                this.$message.warning('不要添加相同商品');
                return;
              } else {
                this.initObjArr.push(res.data[0]);
                this.shopid.push(res.data[0].item_id);
                this.shopskuid.push(res.data[0].sku_id);
              }
            } else {
              this.initObjArr.push(res.data[0]);
              this.shopid.push(res.data[0].item_id);
              this.shopskuid.push(res.data[0].sku_id);
            }
            this.spliceList();

            this.addItemShow = !this.addItemShow;
            this.itemVal = '';
            this.seachlist = [];
            this.shopInfo = {};
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 搜索商品
    seachGood() {
      const options = {
        keyword: this.itemVal,
      };
      this.chooseIndex = '-1';
      this.seachlist = [];
      getiteminforma(options)
        .then((res) => {
          // console.log(res,1111);
          if (res.errcode == 0) {
            this.seachlist = res?.data || [];
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 选中的商品
    chooseGoods(a, b) {
      this.chooseIndex = b;
      this.shopInfo = a;
    },
    // 删除选中商品
    delGoods(a) {
      this.initObjArr.map((item, index) => {
        if (item.sku_id == a.sku_id) {
          this.initObjArr.splice(index, 1);
        }
      });
      this.shopskuid.map((item, index) => {
        if (item == a.sku_id) {
          this.shopid.splice(index, 1);
          this.shopskuid.splice(index, 1);
        }
      });
      this.addListArr.push(1);
    },
    // 将更多字段中选中的字段渲染到table中
    addFiled(isChecked, index) {
      if (isChecked) {
        // this.tableData.push(this.allTableData[index]);
        this.tableData.splice(
          this.tableData.length - 1,
          0,
          this.allTableData[index]
        );
      } else {
        this.tableData.some((item, indexTwo) => {
          if (this.allTableData[index].name === item.name) {
            this.tableData.splice(indexTwo, 1);
            return;
          }
        });
        // console.log(this.allCheckedTableData);
      }
    },
    // 跳转商品详情
    goGoodDetail(obj) {
      window.open('/' + obj.item_id + '.html');
    },
  },
};
