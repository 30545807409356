
export default {
  name: 'LookContent',
  props: {
    // 该账号的发票设置信息
    invoiceMeaasge: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  filters: {
    // 判断是否有值
    emptyVal(val) {
      if (val && val != '') {
        return val;
      } else {
        return '--';
      }
    },
  },
  computed: {
    // 展示的发票设置信息
    showMessage() {
      let form = [
        {
          name: '发票类型',
          value: '',
          field_name: 'invoice_type_content',
        },
        {
          name: '发票内容',
          value: '',
          field_name: 'invoice_content_content',
        },
        {
          name: '抬头类型',
          value: '',
          field_name: 'invoice_header_type_content',
        },
        { name: '发票抬头', value: '', field_name: 'invoice_header' },
        { name: '收票人姓名', value: '', field_name: 'consignee_name' },
        {
          name: '收票人手机号',
          value: '',
          field_name: 'phone',
        },
        {
          name: '收票人地址',
          value: '',
          field_name: 'address',
        },
      ];
      let firmForm = [
        {
          name: '纳税人识别号',
          value: '',
          field_name: 'tax_number',
        },
        { name: '注册地址', value: '', field_name: 'register_address' },
        { name: '注册电话', value: '', field_name: 'register_phone' },
        {
          name: '开户银行',
          value: '',
          field_name: 'register_bank',
        },
        { name: '银行账号', value: '', field_name: 'bank_card' },
      ];
      if (this.invoiceMeaasge.invoice_header_type == '2') {
        form.splice(4, 0, ...firmForm);
      }
      let showList = [];
      for (let item of form) {
        const obj = {
          name: item.name,
          value: this.invoiceMeaasge[item.field_name],
          field_name: item.field_name,
        };
        showList.push(obj);
      }
      return showList;
    },
  },
  mounted() {},
  methods: {
    // 点击升级按钮
    upgrade() {
      this.$emit('click-upgrade');
    },
    // 点击编辑按钮
    edit() {
      this.$emit('click-edit');
    },
    // 返回上一级
    goBack() {
      this.$router.go(-1);
    },
  },
};
