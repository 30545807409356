
import { cancelStorecancellationApply } from '@/api/client-api/account/logOutAccount.js';

export default {
  name: 'materialAudit',
  props: {
    // 账号审核状态 0未申请 1申请中，2审核中，3审核通过，4审核驳回，5取消退店申请，6退款中，7已退款
    applyStatus: {
      type: String,
      default: '',
    },
    // 账号相关信息
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    // 标题文字
    titleFont() {
      if (this.applyStatus == '4') {
        return '注销材料审核失败';
      } else return '账号注销申请审核中，请耐心等待。';
    },
    // 按钮文字
    buttonFont() {
      if (this.applyStatus == '4') {
        return '重新申请';
      } else return '撤销注销申请';
    },
  },
  watch: {},
  methods: {
    // 撤销退店申请
    withdrawn() {
      cancelStorecancellationApply()
        .then((res) => {
          if (res?.errcode == 0) {
            if (res?.msg) {
              this.$message.success(res?.msg);
            }
            this.$emit('go-next', 0);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
