
import {
  StorecancellationApply,
  getSlaePlatformList,
} from '@/api/client-api/account/logOutAccount.js';
import { getBasicInformation } from '@/api/client-api/account/accountmanage.js';
import { sendSmsCode } from '@/api/client-api/api.js';
import cancellationAgreement from '@/pages/protocol/cancellationAgreement.vue';
export default {
  name: 'authenTicate',
  props: {
    // 账号相关信息
    info: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    cancellationAgreement,
  },
  data() {
    return {
      tipVisible: false, // 注销提示状态
      exitVisible: false, // 注销协议弹框状态
      timer: null, // 定时器
      numscond: 60,
      logoutReason: [], // 注销原因列表选择（接口返回）
      checkboxGrouplist: [], // 注销后经营店铺
      phone: '', // 完整手机号
      phone_show: '', // 隐藏的手机号
      form: {
        isSuccess: false,
        business_address: [], // 注销后经营
        cancellation_reason: '1', // 注销原因
        otherReason: '', // 注销其他原因(文字)
        verificationCode: '', // 验证码
        active: 1,
      },
      reasonChecked: '',
      infoData: {}, // 从接口获取到的信息
    };
  },
  watch: {
    /* 'form.business_address':function(newVal,oldVal) {
        console.log(newVal,oldVal);
        this.reasonChecked = newVal.some((item,index) => {
          return item == 102
        })
        console.log(this.reasonChecked);
        if(this.reasonChecked == true) {
        }
      } */
    // 获取接口数据（注销原因）
    info: {
      immediate: true,
      deep: true,
      handler(val) {
        // 获取注销主要原因
        this.logoutReason = Object.keys(val?.reasonList || {})?.map((key) => ({
          value: parseInt(key),
          label: val?.reasonList[key],
        }));
        this.logoutReason = this.logoutReason?.filter(
          (item) => item?.label !== '其他'
        );
      },
    },
    // 改变单选状态清空其他原因input框
    'form.cancellation_reason': function (newVal) {
      if (newVal != '') {
        this.form.otherReason = '';
      }
    },
  },
  mounted() {
    this.getBasicInformation();
    // this.getSlaePlatformList();
  },
  beforeDestroy() {
    // 离开页面清除计时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    // 多选时对应操作
    // changeList(val) {
    //   this.checkboxGrouplist.forEach(item => {
    //     item.disabled = false;
    //   });
    //   if (val.indexOf(102) != -1) {
    //     this.form.business_address = [102];
    //     this.checkboxGrouplist.forEach(item => {
    //       if (item.id != 102) {
    //         item.disabled = true;
    //       }
    //     });
    //   }
    // },
    // 点击了其他原因的input框
    focusOtherInput() {
      this.form.cancellation_reason = '';
    },
    // 点击下一步
    next() {
      /* if(this.form.business_address.length == 0){
          this.$message.warning('多选至少选一项');
          return;
        }  */
      if (this.form.cancellation_reason == '' && this.form.otherReason == '') {
        this.$message.warning('请选择注销主要原因');
        return;
      }
      if (this.form.verificationCode == '') {
        this.$message.warning('验证码不能为空');
        return;
      }
      if (this.form.isSuccess == false) {
        this.$message.warning('是否同意注销协议');
        return;
      }
      this.tipVisible = true;
    },
    // 确认提交下一步
    async sureNext() {
      // this.form.business_address = this.form.business_address.join(',');
      // this.$emit('goNext',1)
      try {
        let options = {
          phone: this.phone,
          // business_address: this.form.business_address,
          // business_address: '',
          code: this.form.verificationCode,
        };
        if (this.form.cancellation_reason) {
          options.cancellation_reason = this.form.cancellation_reason;
        }
        if (this.form.otherReason) {
          options.other_reason = this.form.otherReason;
        }
        const res = await StorecancellationApply(options);
        if (res?.errcode == 0) {
          if (res?.msg) {
            this.$message.success(res?.msg);
          }
          this.$emit('go-next', 1);
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 获取注销后经营店铺
    // getSlaePlatformList() {
    //   getSlaePlatformList().then(res => {
    //     if (res.errcode == 0) {
    //       // console.log(res.data);
    //       this.checkboxGrouplist = res.data;
    //     }
    //   });
    // },
    // 获取用户基本信息
    getBasicInformation() {
      getBasicInformation()
        .then((res) => {
          if (res?.errcode == 0) {
            this.phone_show = res?.data?.phone_show;
            this.phone = res?.data?.phone;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 跳转修改手机号
    editPhone() {
      this.$router.push('/account/editphone');
    },
    // 获取验证码
    sendSmsCode() {
      if (this.numscond != 60) {
        return;
      }
      let option = {
        phone: this.phone,
        type: 'distributorCancellation',
      };
      // this.publicReciprocal(event.target);
      sendSmsCode(option)
        .then((res) => {
          if (res?.errcode == 0) {
            if (res?.msg) {
              this.$message.success(res?.msg);
            }
            if (this.timer) {
              clearInterval(this.timer);
            }
            this.timer = setInterval(() => {
              this.numscond = this.numscond - 1;
              if (this.numscond == 0) {
                clearInterval(this.timer);
                this.timer = null;
                this.numscond = 60;
              }
            }, 1000);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 打开注销协议
    exitLook() {
      this.exitVisible = true;
    },
    // 同意注销协议
    agreeExit() {
      this.form.isSuccess = true;
      this.exitVisible = false;
    },
    // 下一步弹框取消
    undo() {
      // this.form.business_address = [];
      this.form.verificationCode = '';
      this.tipVisible = false;
    },
    torule() {
      window.open('https://home.ximu.cn/platrule/93', '_blank');
    },
  },
};
