/**
 * 协议展示
 */
import protocolDetail from '@/pages/protocol/ProtocolDetail.vue'; // 协议页面

const protocolRoutes = [
  {
    path: '/protocol',
    meta: {
      isAuth: true,
    },
    component: protocolDetail,
  },
];

export default protocolRoutes;
