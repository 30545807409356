
const IDRE18 =
  /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/; // 居民身份证
const HMIDCARD = /^[HMhm]{1}([0-9]{10}|[0-9]{8})$/; // 港澳
const PASSPORT =
  /^1[45][0-9]{7}$|([P|p|S|s]\d{7}$)|([S|s|G|g]\d{8}$)|([Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\d{8}$)|([H|h|M|m]\d{8,10})$/; // 护照
const TAIWAN = /^(\d{8}|[a-zA-Z0-9]{10}|\d{18})$/; // 台湾
export default {
  name: 'XmIdentityCardVerify',
  props: {
    /**
     * 编辑页回显数据
     */
    // 身份证类型
    showCardType: {
      type: String,
      default: '',
    },
    // 身份证号
    showIdCardValue: {
      type: String,
      default: '',
    },
    // 是否开始检测判空
    judgeEmpty: {
      type: Boolean,
      default: false,
    },
    // 失焦校验还是点击提交校验
    blurJudge: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cardType: '', // 身份证类型
      idCardValue: '', // 身份证号
      // 身份证类型可选值
      cardTypeOptions: [
        { label: '居民身份证', value: '1' },
        { label: '台湾居民来往大陆通行证', value: '4' },
        { label: '护照', value: '2' },
        { label: '港澳居民来往内地通行证', value: '3' },
      ],
      cardTypeTip: false, // 类型选择提示
      cardNumTip: false, // 输入错误提示
      errTip: false, // 整体是否有错误
      tipFont: '', // 提示文字
    };
  },
  watch: {
    // 回显身份证类型
    showCardType: {
      deep: true,
      immediate: true,
      handler(val) {
        this.cardType = val;
      },
    },
    // 回显身份证号
    showIdCardValue: {
      deep: true,
      immediate: true,
      handler(val) {
        this.idCardValue = val;
      },
    },
    // 身份证类型
    cardType(val) {
      if (this.cardTypeTip && val !== null && val !== '') {
        this.cardTypeTip = false;
      }
      if (
        (val == '1' && IDRE18.test(this.idCardValue?.trim())) ||
        (val == '4' && TAIWAN.test(this.idCardValue?.trim())) ||
        (val == '2' && PASSPORT.test(this.idCardValue?.trim())) ||
        (val == '3' && HMIDCARD.test(this.idCardValue?.trim()))
      ) {
        this.cardTypeTip = false;
        this.cardNumTip = false;
      }
    },
    // 身份证号
    idCardValue(val) {
      if (this.cardNumTip && !this.cardTypeTip) {
        if (
          (this.cardType == '1' && IDRE18.test(val?.trim())) ||
          (this.cardType == '4' && TAIWAN.test(val?.trim())) ||
          (this.cardType == '2' && PASSPORT.test(val?.trim())) ||
          (this.cardType == '3' && HMIDCARD.test(val?.trim()))
        ) {
          this.cardTypeTip = false;
          this.cardNumTip = false;
        }
      }
    },
    // 是否开始判断 父组件传值
    judgeEmpty(val) {
      if (val == true) {
        // console.log('请判断');
        this.updateTip();
      }
    },
  },
  methods: {
    // 弹框隐藏input失焦
    typeVisible(e) {
      e == false ? this.$refs.typeSelect.blur() : '';
    },
    // 开始验证
    updateTip() {
      if (
        (this.cardType == null || this.cardType == '') &&
        this.idCardValue?.trim() == ''
      ) {
        this.cardTypeTip = true;
        this.cardNumTip = true;
        this.tipFont = '请完善身份信息';
      }
      if (
        (this.cardType == null || this.cardType == '') &&
        this.idCardValue?.trim() !== ''
      ) {
        this.cardTypeTip = true;
        this.tipFont = '请选择证件类型';
      }
      if (
        this.idCardValue?.trim() == '' &&
        this.cardType !== null &&
        this.cardType !== ''
      ) {
        this.cardNumTip = true;
        this.tipFont = '请输入合法的证件号';
      }

      if (
        (this.cardType == '1' && !IDRE18.test(this.idCardValue?.trim())) ||
        (this.cardType == '4' && !TAIWAN.test(this.idCardValue?.trim())) ||
        (this.cardType == '2' && !PASSPORT.test(this.idCardValue?.trim())) ||
        (this.cardType == '3' && !HMIDCARD.test(this.idCardValue?.trim()))
      ) {
        this.cardNumTip = true;
        this.tipFont = '请输入合法的证件号';
      }
      // 是否有错误，全为false才是false
      if (!this.cardTypeTip && !this.cardNumTip) {
        this.errTip = false;
      }
      if (this.cardTypeTip || this.cardNumTip) {
        this.errTip = true;
      }
      // 把判断条件重置为false
      this.$emit('judge-over', this.errTip, this.cardType, this.idCardValue);
    },
    // 失焦事件
    startJudge() {
      if (this.blurJudge) {
        this.updateTip();
      }
    },
    // 重置验证
    clearValidate() {
      this.cardTypeTip = false; // 类型选择提示
      this.cardNumTip = false; // 输入错误提示
      this.errTip = false; // 整体是否有错误
      this.tipFont = ''; // 提示文字
    },
  },
};
