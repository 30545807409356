
export default {
  props: {
    //设置图片的尺寸
    imageSize: {
      type: Number,
      default: 400,
    },
    //控制显示隐藏
    isShow: {
      type: Boolean,
      default: false,
    },
    //设置展示图片的url
    imageUlr: {
      type: String,
      default: require('@/assets/images/defaultEmpty.png'),
    },
  },
};
