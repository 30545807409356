import utf8 from 'crypto-js/enc-utf8.js';
import aes from 'crypto-js/aes.js';
import ecb from 'crypto-js/mode-ecb.js';
import pkcs7 from 'crypto-js/pad-pkcs7.js';

// const utf8 = require('crypto-js/enc-utf8.js');
// const aes = require('crypto-js/aes.js');
// const ecb = require('crypto-js/mode-ecb.js');
// const pkcs7 = require('crypto-js/pad-pkcs7.js');

const DEFAULT_KEY = 'ximuLoginPasswor';

/**
 * 自定义的加密解密工具
 *
 * 采用 AES-128-ECB 加密方式
 * 可设置密钥，密钥长度必须为4的倍数（n字节）
 *
 * 使用方法：
 *
 	import { XmAES128Encryptor } from './test.js';

	let tool = new XmAES128Encryptor('testtesttesttest');

	const code = tool.encrypt('hello world');
	console.log('after encrypt: ', code);
	const decode = tool.decrypt(code);
	console.log('after decrypt: ', decode);
 */
export class XmAES128Encryptor {
  // module.exports = class XmAES128Encryptor {
  constructor(key = DEFAULT_KEY) {
    this._key = utf8.parse(key);
  }
  encrypt(value) {
    let sValue = utf8.parse(value);
    let encode = aes.encrypt(sValue, this._key, {
      mode: ecb,
      padding: pkcs7,
    });
    return encode.toString();
  }
  decrypt(value) {
    if (!value) return JSON.stringify('');

    try {
      let decode = aes.decrypt(value, this._key, {
        mode: ecb,
        padding: pkcs7,
      });
      return utf8.stringify(decode).toString();
    } catch {
      return JSON.stringify('');
    }
  }
}

// 直接返回实例对象
export const encryptor = new XmAES128Encryptor();
