
import { platformInfoList } from "@/api/client-api/fund/fundCenter.js";
export default {
  data() {
    return {
      panelShow: false,
      recodeList: [],
    };
  },
  mounted() {
    this.getPlatform();
  },
  methods: {
    handPanel() {
      this.panelShow = !this.panelShow;
    },
    handClosePanel() {
      this.panelShow = false;
    },
    getPlatform() {
      platformInfoList()
        .then((res) => {
          if (res.errcode == 0) {
            this.recodeList = res.data;
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
    },
    // 选择历史账户
    choseAccount(obj) {
      this.$emit("choseAccount", obj);
    },
  },
};
