
// api
import { identifyAddress } from '@/api/client-api/mine/mineapi.js';
export default {
  data() {
    return {
      placeholder:
        '请粘贴或输入文本，点击“识别”自动识别姓名、电话和地址。例如：张三，132XXXX5252，广东省深圳市龙华区XX街道。', //默认文本
      textVal: '', //输入的文本
    };
  },
  methods: {
    /**
     * 页面触发
     */
    // 点击“清空”，清空文本
    handleClearVal() {
      this.textVal = '';
    },
    // 点击“识别”
    async handleIdentificated() {
      // 输入文本没值的情况
      if (!this.textVal) return;
      try {
        const options = {
          address: this.textVal,
        };
        let res = await identifyAddress(options);
        if (res?.errcode == 0) {
          this.$message.success('识别成功');
          let info = res?.data || {};
          this.$emit('click-identificated', info);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
