
import leftmenu from '@/components/comme/leftmenu.vue';
export default {
  name: 'ComplaintsLayout',
  components: {
    leftmenu,
  },
  data() {
    return {
      leftname: '投诉&举报',
      menulist: [
        '投诉&举报',
        '违规列表',
      ],
      routerlist: [
        '/complaints/complaintslist',
        '/complaints/illegallist',
      ],
      routerIndexHash: {
        '/complaints/complaintslist':0,
        '/complaints/illegallist':1,
        '/complaints/illegaldetail':1,
      },
    };
  },
  methods: {},
  //计算属性computed无法传递参数
  computed: {},
  mounted() {},
};
