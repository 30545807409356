
/**
 * 多语言配置
 */
import { PLATE_CORRELATION } from '../js/label_config_board';
// 变量
import {
  curDefaultLanguage,
  CUR_TOKEN_NAME,
} from '@/utils/cur_default_config.js'; //默认语言
export default {
  name: 'PlateTitle',
  props: {
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
    // 标题文本
    title: {
      type: String,
      default: '',
    },
    // 是否有查看更多按钮
    hasMore: {
      type: Boolean,
      default: true,
    },
    // 查看更多对应跳转标志
    moreId: {
      type: [Number, String],
      default: -1,
    },
    // 活动模块动态的标题
    dynamicName: {
      type: String,
      default: '',
    },
    // 是否展示春节活动相关内容
    backShow: {
      type: Boolean,
      default: false,
    },
    // 判断春节活动展示那个图片
    imgType: {
      type: Number,
      default: 1,
    },
    // 副标题
    subtitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      token: '',
    };
  },
  computed: {
    // 标签多语言配置
    langConfig() {
      return PLATE_CORRELATION[this.lang || curDefaultLanguage];
    },
  },
  mounted() {
    if (process.client) {
      this.token = this.$cookies.get(CUR_TOKEN_NAME);
    }
  },
  methods: {
    /**
     * 点击查看更多
     * id==1 跳转高利润商品
     * id==2 跳转热销商品
     */
    lookMore(id) {
      // this.$router.push({
      //   path: '/goodsClassificat',
      //   query: { type: id },
      // });
      //新页签打开
      if (id == '1') {
        return this.routeJump('highprofits.html');
      }
      if (id == '2') {
        return this.routeJump('activity/festival');
      }
    },
  },
};
