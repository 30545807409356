
/**
 * vue3 特性传送门的 vue2 版本实现
 * @author hukeyi
 */
export default {
  name: 'XmTeleport',
  props: {
    to: {
      type: String,
      required: true,
    },
  },
  mounted() {
    if (process.client) {
      const toEl = document.querySelector(this.to);
      if (toEl) {
        toEl.appendChild(this.$el);
      }
    }
  },
  destroyed() {
    if (process.client) {
      const toEl = document.querySelector(this.to);
      if (toEl) {
        toEl.removeChild(this.$el);
      }
    }
  },
};
