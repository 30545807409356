/**
 * 类目数据
 */
export const state =()=> ({
  categoryData: [],
  categoryEnData: [], // 英文类目
});

export const mutations = {
  SET_CATEGORY_DATA(state, data) {
    return state.categoryData = data;
  },
  SET_CATEGORY_ENDATA(state, data) {
    return state.categoryEnData = data;
  }
};

export const actions = {
  setCategoryData({ commit }, data) {
    if (data) {
      commit('SET_CATEGORY_DATA', data);
    }
  },
  setCategoryEnData({ commit }, data) {
    if (data) {
      commit('SET_CATEGORY_ENDATA', data);
    }
  },
};
