
import { rightArithShift } from 'mathjs';

export default {
  name: 'XmPreviewImageTable',
  props: {
    width: {
      // 小图的宽度
      type: String,
      default: '100px',
    },
    height: {
      // 小图的高度
      type: String,
      default: '100px',
    },
    imgSrc: {
      // 图片地址
      type: String,
      default: '',
    },
    // 如果是表格
    pClassName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      smallImgDom: null, // 小图的 Dom 元素
      bigImgDom: null,

      bigImgWidth: '230px', // 预览大图的宽度
      bigImgHeight: '230px', // 预览大图的高度
      margin: 5, // 预览大图与小图的间距

      transformOrigin: 'top left', // 动画效果的改变原点
      animation: null,

      showPreviewimg: false, //预览弹窗标识
      imgUrl: '', //预览图片
    };
  },
  methods: {
    //预览图片回调
    showPreviewimgFunc(url, isImg) {
      this.imgUrl = this.$options.filters.imgbaseurl(url);
      if (isImg) {
        this.showPreviewimg = true;
      } else {
        this.showPreviewdoc = true;
      }
    },
    //关闭预览回调
    previewCloseFunc() {
      this.showPreviewimg = this.showPreviewdoc = false;
    },

    // 获取图片尺寸
    getImgSize(url) {
      return new Promise((resolve, reject) => {
        let imgObj = new Image();
        imgObj.src = url;
        imgObj.onload = () => {
          resolve({
            width: imgObj.width,
            height: imgObj.height,
          });
        };
        imgObj.onerror = () => {
          reject(`${imgObj} loading failed. `);
        };
      });
    },
    // 根据当前屏幕顶部和底部的剩余上下高度，改变预览大图的位置
    handleUpdateBigImgPosition() {
      if (!this.smallImgDom) {
        return;
      }
      const { top, left, bottom } = this.smallImgDom.getBoundingClientRect();
      // 预览大图应当在下边还是上边
      const isAtBottom = window.innerHeight - bottom >= 230 || top < 230;

      this.transformOrigin = isAtBottom ? 'top left' : 'bottom left';
      if (this.bigImgDom) {
        /**
         * 动态改变预览大图的位置
         */
        // y 轴偏移量
        const offset = isAtBottom
          ? parseInt(this.width) + this.margin
          : -1 * (parseInt(this.bigImgWidth) + this.margin);
        const yPos = top + offset + 'px';
        const xPos = left + 'px';

        this.bigImgDom.style.top = yPos;
        this.bigImgDom.style.left = xPos;
        this.bigImgDom.style.transformOrigin = this.transformOrigin;
      }
    },
    /**
     * 鼠标悬浮小图，展示预览大图
     */
    handleHoverSmallImage() {
      if (this.bigImgDom) {
        this.animation.play();
        this.bigImgDom.style.display = 'block';
      }
    },
    /**
     * 鼠标离开小图，预览大图消失
     */
    handleMouseLeaveSmallImage() {
      if (this.bigImgDom) {
        this.bigImgDom.style.display = 'none';
      }
    },
    /**
     * 将预览大图元素插入vue根元素
     * 防止预览大图被其他元素遮住
     */
    handleAppendToRoot() {
      const body = document.querySelector('#app');

      if (this.bigImgDom) {
        /**
         * appendChild 后，所有的 style 样式都会失效，原因不明
         * 需要像下面这样手动设置
         */
        this.bigImgDom.style.width = this.bigImgWidth;
        this.bigImgDom.style.height = this.bigImgHeight;
        this.bigImgDom.children[0].style.objectFit = 'contain';
        this.bigImgDom.children[0].style.width = '230px';
        // this.bigImgDom.children[0].style.width = this.bigImgWidth;
        this.bigImgDom.children[0].style.height = this.bigImgHeight;
        this.bigImgDom.style.transformOrigin = this.transformOrigin;
        this.bigImgDom.style.backgroundColor = '#f7f7f7';
        this.bigImgDom.style.position = 'fixed';
        this.bigImgDom.style.zIndex = '2000';
        this.bigImgDom.style.top = '0';
        this.bigImgDom.style.left = '0';
        this.bigImgDom.style.display = 'none';

        /**
         * 添加动画
         */
        const keyframes = [{ scale: 0 }, { scale: 1 }];
        const options = {
          duration: 100,
        };
        this.animation = this.bigImgDom.animate(keyframes, options);

        body.appendChild(this.bigImgDom);
      }
    },
    /**
     * 节流函数
     * @param Function func 节流目标函数
     * @param String delay 延迟时间
     * @returns
     */
    throttle(func, delay) {
      let timer;
      return function () {
        let context = this;
        let args = arguments;
        if (!timer) {
          timer = setTimeout(() => {
            timer = null;
            func.apply(context, args);
          }, delay);
        }
      };
    },
  },
  async mounted() {
    // try {
    // let imgSize = await this.getImgSize(this.imgSrc);

    this.$nextTick(() => {
      this.smallImgDom = this.$refs['small-image'];
      this.bigImgDom = this.$refs['big-image'];

      // if (imgSize.width > imgSize.height) {
      //   this.bigImgWidth = 'auto';
      // } else {
      //   this.bigImgHeight = 'auto';
      // }

      this.handleAppendToRoot(); // 预览大图元素插入根元素
      this.handleUpdateBigImgPosition();
    });
    /**
     * 绑定监听函数，监听窗口滚动和缩放，实时检查屏幕剩余高度，调整预览大图位置
     * 优化：节流函数
     */
    // 绑定滚动监听
    window.addEventListener(
      'scroll',
      this.throttle(this.handleUpdateBigImgPosition, 500)
    );
    // 窗口缩放监听
    window.addEventListener(
      'resize',
      this.throttle(this.handleUpdateBigImgPosition, 500)
    );
    // } catch (err) {
    //   this.$message.error(err);
    // }
  },
  beforeDestroy() {
    // 离开销毁dom
    const body = document.querySelector('#app');
    if (this.bigImgDom) {
      body.removeChild(this.bigImgDom);
    }
  },
};
