import request from '@/utils/request.js';

// 获取商品详情页的优惠券列表
// export function getCoupons(data) {
//   return request({
//     url: '/item/getItemCouponsList',
//     method: 'get',
//     params: data,
//   });
// }

// 获取商品详情页中部的人气单品列表
export function getPopularItems(data) {
  return request({
    url: '/Item/popularItem',
    apiModule: 'goods',
    method: 'get',
    params: data,
  });
}

// 获取商品详情页中部的同款推荐列表
export function getSimilarRecommendedItems(data) {
  return request({
    url: '/Item/getRecommendItemInfoList',
    apiModule: 'goods',
    method: 'get',
    params: data,
  });
}

// 商品详情获取优惠券
export function getCoupons(data) {
  return request({
    url: '/coupon/itemCouponList',
    method: 'get',
    params: data,
  });
}

// 获取商品详情页领取优惠券
export function getCoupon(data) {
  return request({
    url: '/coupon/getCoupon',
    method: 'post',
    data: data,
  });
}
