// 变量
import { curDefaultLanguage } from '@/utils/cur_default_config.js'; //默认语言
export default (app) => {
  let lang = curDefaultLanguage;
  if (app.$cookies.get('lang') != curDefaultLanguage) {
    lang = curDefaultLanguage;
    // lang = app.$cookies.get('lang');
    app.store.dispatch('common/setCurrLang', lang);
  }
  // 如果cookies没有  默认中文
  if (!app.$cookies.get('lang')) {
    app.$cookies.set('lang', curDefaultLanguage, {
      path: '/',
    });
    app.store.dispatch('common/setCurrLang', lang);
  }
};
