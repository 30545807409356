
import ImagesPreviewDialog from './ImagesPreviewDialog.vue';
import SaleArea from './SaleArea.vue';
import { BLOCK_LABELS } from '../js/label_config_common';
// 变量
import { curDefaultLanguage } from '@/utils/cur_default_config.js'; //默认语言
export default {
  components: {
    SaleArea,
    ImagesPreviewDialog,
  },
  name: 'GridBlock',
  props: {
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
    // 列数
    columnNum: {
      type: Number,
      default: 1,
    },
    // 行间隔
    rowGap: {
      type: String,
      default: '',
    },
    // 列宽
    columnWidth: {
      type: String,
      default: 'auto',
    },
    // 固定的标签宽度
    labelWidth: {
      type: String,
      default: '',
    },
    // 标签文本对齐方式
    // 左对齐：'left'；两端对齐：'justify'
    // 如果要设置两端对齐，则务必同时设置 fixedLabelWidth，否则将不会生效
    align: {
      type: String,
      default: 'left',
    },
    // 标签文本后是否显示冒号
    showColon: {
      type: Boolean,
      default: true,
    },
    // 要展示的数据数组
    /**
     * type 四种：text, image, table，tag
     * text -> String, image: String, table: Array, tag
     */
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    // 标签多语言配置
    labelConfig() {
      return BLOCK_LABELS[this.lang || curDefaultLanguage];
    },
    // Grid 布局的布局样式
    gridStyle() {
      let colWidth = this.columnWidth;
      if (colWidth == 'auto' || colWidth == '100%' || !colWidth) {
        colWidth = '1fr';
      }
      let layoutStr = new Array(this.columnNum).fill(colWidth).join(' ');
      return {
        '--grid-layout': layoutStr,
        '--grid-row-gap': this.rowGap,
      };
    },
    // 要展示的数据数组
    theList() {
      const myList = this.list.filter((x) => !x.hide);
      return myList;
    },
  },
  data() {
    return {
      // 长文本是否溢出
      isLongTextOverflow: false,
      showImg: false,
      imgUrl: '',
      showArea: false,
    };
  },
  methods: {
    // 表格单元格的公共样式
    tableCommomCellStyle() {
      return {
        height: '30px',
        padding: '0px',
        fontWeight: '400',
        textAlign: 'center',
        borderColor: '#ccc',
      };
    },
    // 表格表头行的单元格样式
    tableHeaderCellStyle() {
      const commonStyle = this.tableCommomCellStyle();
      const myStyle = {
        background: '#F2F2F2',
        color: '#808080',
      };
      return Object.assign(commonStyle, myStyle);
    },
    // 表格非表头行单元格样式
    tableCellStyle() {
      const commonStyle = this.tableCommomCellStyle();
      const myStyle = {
        color: '#666',
      };
      return Object.assign(commonStyle, myStyle);
    },
    /**
     * 点击长文本溢出后显示的标签（比如“全部”）
     */
    handleClickMoreText() {
      this.$emit('click-more-text');
    },
    /**
     * 处理空值字段
     * 空值不显示
     */
    handleEmptyText(text, labelFlag) {
      if (labelFlag) {
        if (labelFlag == '发货及时率' && text == '0%') {
          return '100%';
        } else if (labelFlag == '评分' && text == 0) {
          return 5;
        }
      }
      if (text == undefined || text.length == 0) {
        return '--';
      }
      if (text === '0') {
        return '--';
      }
      return text;
    },
    //预览图片
    showPreviewimg(key) {
      this.showImg = true;
      this.imgUrl = this.$options.filters.imgbaseurl(key);
    },
    //关闭预览图片
    previewClose() {
      this.showImg = false;
    },
    handleClickAll() {
      this.showArea = true;
    },
  },
  mounted() {
    if (this.$refs.longText) {
      const longTextItem = this.$refs?.longText;
      const totalWidth = longTextItem?.scrollWidth;
      const displayWidth = longTextItem?.offsetWidth;
      this.isLongTextOverflow = totalWidth > displayWidth;
    }
  },
};
