
export default {
  name: 'XmPreviewImgVideo',
  props: {
    mediaData: {
      type: Array,
      default: () => [],
    },
    imgIndex: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      currMedia: {},
      currIndex: 0,
    };
  },
  methods: {
    handleClickPlayBtn() {
      this.$refs.myCarouselVideoPlayer?.handleClickPlayBtn();
    },
    handleClose() {
      this.$emit('close');
    },
    /**
     * 根据imgs数组索引更新当前展示的轮播图大图
     * @param index 将要展示的图片imgs[index]
     */
    handleChangeImg(index) {
      this.currIndex = index;
      if (index < 0) return;
      this.currMedia = this.mediaData?.[index];
    },

    handleMoveImg(dir) {
      let limit = this.mediaData?.length;
      let currIndex = this.currIndex;
      // 计算移动后的下一张图片的 imgs 索引
      let nextIndex =
        dir == 'prev'
          ? (currIndex - 1 + limit) % limit
          : (currIndex + 1) % limit;

      this.handleChangeImg(nextIndex);
    },
  },

  mounted() {
    const index = this.imgIndex;
    this.handleChangeImg(index);
  },
};
