
export default {
  props: {
    paytype: {
      type: String,
      default: '1', // 默认人民币
    },
    memuTitle: {
      type: String,
      default: '细目商城名称',
    },
  },
  data() {
    return {};
  },
  mounted() {},
  filters: {},
  methods: {
    // 切换支付方式
    checkpay() {
      // this.paytype = this.paytype == 1 ? 2 : 1;
      this.$emit('update:paytype', this.paytype == 1 ? 2 : 1);
      this.select({});
    },
  },
};
