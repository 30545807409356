
import { showCurrentTime } from '@/utils/time.js';

export default {
  props: {
    title: {
      type: String,
      default: '细目协议默认标题',
    },
    hasFooter: {
      // 弹窗底部是否有同意按钮
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentTime: '',
      // seconds: 3,
      closeIcon: require('@/assets/close_icon.png'),
    };
  },
  methods: {
    // 倒计时
    // countdown() {
    //   if (this.seconds <= 0) return;

    //   const timer = setInterval(() => {
    //     if (this.seconds >= 0) {
    //       --this.seconds;
    //     } else {
    //       clearInterval(timer);
    //     }
    //   }, 1000);
    // },
    handleClickBtn() {
      this.$emit('agree');
    },
    handleClickClose() {
      this.$emit('close');
    },
  },
  mounted() {
    this.currentTime = showCurrentTime(); // 获取当前时间

    // if (this.hasFooter) this.countdown(); // 开启倒计时
  },
};
