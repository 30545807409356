
import NoShadowBox from '../components/NoShadowBox.vue';
// 变量
import {
  curDefaultLanguage,
  CUR_TOKEN_NAME,
} from '@/utils/cur_default_config.js'; //默认语言
export default {
  name: 'BasicScrollThree',
  components: { NoShadowBox },
  props: {
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
    goodsList: {
      type: Array,
      default: () => [],
    },
    hasTopTip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      token: '',
      second: 0.2,
      // goodsList: [],
      goodsListShow: [], // 展示的数组
      // scrollList: [], // 滚动的数组
      currentIndex: 0, // 当前展示数据的起始索引
      scrollOffset: [-570, 0, 570],
      leftOffset: '0px',
      goodsListLeftScroll: [], // 滚动的左边的数组
      goodsListRightScroll: [], // 滚动的右边的数组
      isTransition: true, // 控制子组件中收藏动画效果
    };
  },
  watch: {
    goodsList: {
      immediate: true,
      deep: true,
      handler: function (val) {
        if (val && val?.length !== 0) {
          this.updateDisplayItems();
        }
      },
    },
  },
  mounted() {
    if (process.client) {
      this.token = this.$cookies.get(CUR_TOKEN_NAME);
      if (this.goodsList?.length !== 0) {
        this.updateDisplayItems();
      }
      this.scrollLeftBtn = this._debounce(this.scrollLeft, 200); // 防抖
      this.scrollRightBtn = this._debounce(this.scrollRight, 200); // 防抖
    }
  },
  methods: {
    // 点击收藏商品
    isCollectGoods(id) {
      this.$emit('click-collect', id);
    },
    // 点击商品跳转
    gotoGoodsDetail(item) {
      // 注释掉的原因：
      // 路由拦截器 beforeEach 会把无token的用户强制弹回 /login，不需要在这里进行判断
      // if (this.token) {
      // this.toCachedShopdetail(item.item_id, item.image_default_id, '_self');
      this.toCachedShopdetail(item.item_id, item.image_default_id); //新窗口打开
      // } else {
      //   this.$router.push('/login');
      // }
    },
    // 滚动逻辑
    updateDisplayItems() {
      // 设置当前的展示数据
      if (this.goodsList?.length > 0) {
        const len = this.goodsList?.length;
        const startIndex = this.currentIndex % len;
        const endIndex = (startIndex + 2) % len;

        this.goodsListLeftScroll = [];
        this.goodsListRightScroll = [];
        for (let i = 1; i < 4; i++) {
          const leftIndex = (this.currentIndex - i + len) % len;
          const rightIndex = (this.currentIndex + i + 2) % len;

          this.goodsListLeftScroll.unshift(this.goodsList[leftIndex]);
          this.goodsListRightScroll.push(this.goodsList[rightIndex]);
        }

        if (startIndex <= endIndex) {
          this.goodsListShow = this.goodsList.slice(startIndex, endIndex + 1);
        } else {
          this.goodsListShow = [
            ...this.goodsList.slice(startIndex),
            ...this.goodsList.slice(0, endIndex + 1),
          ];
        }
        // this.scrollList = [
        //   ...this.goodsListLeftScroll,
        //   ...this.goodsListShow,
        //   ...this.goodsListRightScroll,
        // ];
      }
    },
    // 向右滚动
    scrollRight() {
      if (this.goodsList?.length <= 3) {
        return;
      }
      this.isTransition = false;

      this.second = 0.2;
      this.scrollOffset = [-570, -570, 0];

      setTimeout(() => {
        this.currentIndex = (this.currentIndex + 3) % this.goodsList.length;
        this.second = 0;
        this.updateDisplayItems();
        this.scrollOffset = [-570, 0, 570];
      }, 200);

      setTimeout(() => {
        this.isTransition = true;
      }, 300);
    },
    // 向左滚动
    scrollLeft() {
      if (this.goodsList?.length <= 3) {
        return;
      }
      this.isTransition = false;

      this.second = 0.2;
      this.scrollOffset = [0, 570, 570];

      setTimeout(() => {
        this.currentIndex =
          (this.currentIndex - 3 + this.goodsList?.length) %
          this.goodsList?.length;
        this.second = 0;
        this.updateDisplayItems();
        this.scrollOffset = [-570, 0, 570];
      }, 200);

      setTimeout(() => {
        this.isTransition = true;
      }, 300);
    },
  },
};
