
export default {
  name: 'XmShowFileBox',
  props: {
    /**
     * 需要展示的文件
     * 字符串
     * 逗号分隔
     * 要拼好路径的
     */
    showFile: {
      type: String,
      default: '',
    },
    // 是否需要展示文件名
    showTitle: {
      type: Boolean,
      default: true,
    },
    width: {
      // 小图的宽度
      type: String,
      default: '22px',
    },
    height: {
      // 小图的高度
      type: String,
      default: '22px',
    },
    /**
     * 是否显示下载按钮
     */
    showDownloaded: {
      type: Boolean,
      default: false,
    },
    // 预览图片是否需要旋转
    isPicRote: {
      type: Boolean,
      default: false,
    },
    // 是否需要悬浮小图放大
    needHoverZoom: {
      type: Boolean,
      default: true,
    },
    // 是否需要视频上的播放icon
    needVideoIcon: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    // 父组件传过来要展示的文件们
    showFile: {
      immediate: true,
      handler(val) {
        if (val !== '') {
          this.handleFile(val);
        }
      },
    },
  },
  data() {
    return {
      fileList: [], // 传递过来的文件数组

      // 预览相关
      showPreviewimg: false, // 预览图片弹窗标识
      previewImgUrl: '', // 预览图片

      showPreviewdoc: false, // 预览文档弹窗标识
    };
  },
  computed: {
    // 当前是否有文件列表，否则展示 --
    showFileBox() {
      return this.fileList?.length > 0;
    },
  },
  filters: {
    uploadFileType(val) {
      if (val == 'excel') {
        return require('@/assets/material/excel.svg');
      }
      if (val == 'pdf') {
        return require('@/assets/material/pdf.svg');
      }
      if (val == 'word') {
        return require('@/assets/material/word.svg');
      }
      if (val == 'zip') {
        return require('@/assets/material/zip.svg');
      }
      if (val == 'ppt') {
        return require('@/assets/material/ppt.svg');
      }
    },
  },
  mounted() {},
  methods: {
    // 下载
    handleDown(item) {
      this.down(item.link, item.name);
    },
    //预览图片回调
    showPreviewimgFunc(key) {
      let fileType = key.split('.').pop();
      let isImage = this.matchType(fileType).toLowerCase() == 'image';
      let isVideo = this.matchType(fileType).toLowerCase() == 'video';
      if (isImage || isVideo) {
        this.showPreviewimg = true;
      } else {
        this.showPreviewdoc = true;
      }
      this.previewImgUrl = this.$options.filters.imgbaseurl(key);
    },
    //关闭预览回调
    previewCloseFunc() {
      this.showPreviewimg = this.showPreviewdoc = false;
    },
    // 处理传递的文件们
    handleFile(val) {
      // console.log("【父组件传过来要展示的文件们】", val);
      let arr = val.split(',').map((item) => {
        const fileName = item.trim();
        const type = this.matchType(fileName.split('.').pop());
        const preview = fileName;
        const name = fileName.split('/').pop();
        const link = fileName.split('/').pop();

        return {
          type: type,
          preview: preview,
          name: name,
          link: link,
        };
      });
      this.fileList = arr;
    },
  },
};
