// 所属类别（收到的选项）
export const commonClassOptions = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '订单问题',
    value: '1,9',
  },
  {
    label: '物流问题',
    value: '2,10',
  },
  {
    label: '客服问题',
    value: '3,11',
  },
  {
    label: '供应商问题',
    value: '4,12',
  },
  {
    label: '分销商问题',
    value: '5',
  },
  {
    label: '禁限售类',
    value: '6,13',
  },
  {
    label: '知识产权类',
    value: '7,14',
  },
  {
    label: '政治有害类',
    value: '8,15',
  },
  {
    label: '交互信息违规',
    value: '16,17',
  },
];
// 所属类别（发起的选项）
export const launchClassOptions = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '订单问题',
    value: '9',
  },
  {
    label: '物流问题',
    value: '10',
  },
  {
    label: '客服问题',
    value: '11',
  },
  {
    label: '供应商问题',
    value: '12',
  },
  {
    label: '禁限售类',
    value: '13',
  },
  {
    label: '知识产权类',
    value: '14',
  },
  {
    label: '政治有害类',
    value: '15',
  },
  {
    label: '交互信息违规',
    value: '16',
  },
];

// 处理状态（发起和收到的选项相同）
export const commonProcessStatusOptions = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '处理中',
    value: '1',
  },
  {
    label: '已处理',
    value: '2',
  },
];

// 【发起投诉/举报】关键词搜索
export const launchKeywordOptions = [
  {
    label: '投诉/举报标题',
    value: 'title',
  },
  {
    label: '投诉/举报ID',
    value: 'complaint_id',
  },
  {
    label: '被投诉/举报方',
    value: 'accused_id',
  },
];

// 【收到投诉/举报】关键词搜索
export const receiveKeywordOptions = [
  {
    label: '投诉/举报标题',
    value: 'title',
  },
  {
    label: '投诉/举报ID',
    value: 'complaint_id',
  },
];

// 【发起投诉/举报】处理结果
export const launchProcessResultOptions = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '投诉成立',
    value: '1',
  },
  {
    label: '投诉不成立',
    value: '2',
  },
  {
    label: '举报成立',
    value: '3',
  },
  {
    label: '举报不成立',
    value: '4',
  },
];

// 【收起投诉/举报】处理结果
export const receiveProcessResultOptions = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '违规',
    value: '1',
  },
  {
    label: '未违规',
    value: '2',
  },
];

// 申诉状态
export const commonAppealStatusOptions = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '未申诉',
    value: '1',
  },
  {
    label: '已申诉',
    value: '2',
  },
  {
    label: '超时未申诉',
    value: '3',
  },
];
