
import ViolationCategory from './ViolationCategory';
export default {
  name: 'ReportSearch',
  components: {
    ViolationCategory,
  },
  props: {},
  data() {
    return {
      // 筛选内容
      searchForm: {
        violation_category_id: '', //所选的大类目
        violation_subcategory_id: '', //所选的小类目
        appeal_status: '', //申诉状态
        penalty_status: '', //处罚状态
        violation_status: '', //违规状态
      },
      xmSelect: {}, //下拉加输入框

      // 下拉框相关配置
      appealStatusOptions: [
        { label: '全部' },
        { label: '待申诉', value: '1' },
        { label: '待审核', value: '2' },
        { label: '已通过', value: '3' },
        { label: '已驳回', value: '4' },
        { label: '超时未申诉', value: '5' },
      ], //申诉状态
      penaltyStatusOptions: [
        { label: '全部' },
        { label: '处罚中', value: '1' },
        { label: '处罚结束', value: '2' },
        { label: '处罚撤销', value: '3' },
      ], //处罚状态
      violationStatusOptions: [
        { label: '全部' },
        { label: '违规中', value: '1' },
        { label: '违规结束', value: '2' },
        { label: '违规撤销', value: '3' },
      ], //违规状态
      timeOption: [
        { label: '处罚生效时间', value: 'punishment_effective_time' },
        { label: '处罚失效时间', value: 'punishment_expiry_time' },
        { label: '违规失效时间', value: 'violation_expiry_time' },
        { label: '申诉时间', value: 'appeal_time' },
      ], //时间筛选
    };
  },
  computed: {},
  methods: {
    // 大小类目筛选
    selectCategory(item) {
      if (item) {
        // 大类目
        this.searchForm.violation_category_id = item?.cate;
        // 小类目
        this.searchForm.violation_subcategory_id = item?.subCate;
      }
      this.handleChangeSearchForm();
    },
    // 关键字搜索输入框的值
    handleChangeKeyword(key, value) {
      this.searchForm.key = key;
      this.searchForm.value = value;
      this.handleChangeSearchForm();
    },
    // 下拉框加时间范围搜索
    handleChangeTimeData(key) {
      this.searchForm.time_key = key;
      this.handleChangeSearchForm();
    },
    // 搜索表单
    handleChangeSearchForm() {
      // 更新时间之前，将所有的时间删除，避免出现多个时间
      const timeKey = new Set([
        'punishment_effective_time',
        'punishment_expiry_time',
        'violation_expiry_time',
        'appeal_time',
      ]);
      for (let i of timeKey) {
        delete this.searchForm?.[i + '_start'];
        delete this.searchForm?.[i + '_end'];
      }
      let formData = this.filterEmptyKey(Object.assign({}, this.searchForm));

      // 特殊处理
      // 时间范围
      if (this.searchForm?.timeData && this.searchForm?.timeData?.length > 0) {
        formData[this.searchForm.time_key + '_start'] =
          this.searchForm?.timeData?.[0];
        formData[this.searchForm.time_key + '_end'] =
          this.searchForm?.timeData?.[1];
      }
      // 关键字搜索
      if (this.searchForm?.value) {
        formData[this.searchForm?.key] = this.searchForm.value;
      } else {
        // 若this.searchForm.value没有值 则删除这一项 避免用了回显的值
        delete formData?.[this.searchForm?.key];
      }
      this.$emit('search', formData);
    },
    // 点击重置按钮
    handleClickReset() {
      this.reset();
      // 重回第一页，重新获取列表
    },
    // 重置
    reset() {
      this.searchForm = {
        violation_category_id: '', //所选的大类目
        violation_subcategory_id: '', //所选的小类目
        appeal_status: '', // 申诉状态
        penalty_status: '', //处罚状态
        violation_status: '', //违规状态
      };
      this.xmSelect = {};
      if (this.$refs?.XmSelectOption) {
        this.$refs.XmSelectOption?.reVal('id');
      }
      this.$refs.ViolationCategory.reData(); // 重置违规类目页面显示
      this.$refs.XmDatePicker.reVal(); // 重置时间筛选页面显示
      this.handleChangeSearchForm();
    },
    // ref 回显搜索条件
    echoSearchData() {
      let searchInfo = JSON.parse(JSON.stringify(this.getPageFilterData()));
      this.searchForm = searchInfo;
      // 违规编号
      if (this.$refs?.XmSelectOption) {
        this.$refs.XmSelectOption?.reVal(
          this.searchForm?.key,
          this.searchForm?.value
        );
      }
      // 下拉选择时间类型回显
      if (this.$refs.XmDatePicker && this.searchForm?.time_key) {
        this.$refs.XmDatePicker.reVal(this.searchForm?.time_key || '');
      }
    },
  },
  beforeDestroy() {
    this.reset();
  },
};
