
import {
  sendSmsCode,
  sendRechargeSmsCode,
} from '@/api/client-api/login/api.js';
import { validatePhoneNumberReg } from '@/utils/validator.js';
import { mapMutations } from 'vuex';
export default {
  props: {
    label: {
      type: String,
      default: '输入框标签',
    },
    // 输入框占位字符
    placeholder: {
      type: String,
      default: '请输入',
    },
    // 输入框后缀的图标
    type: {
      type: String,
      default: 'none',
    },
    inputValue: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '380px',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    // 不传默认99，针对手机号、验证码、密码邀请码长度99够用了
    maxlength: {
      type: Number,
      default: 99,
    },
    // 原手机号(未登录修改手机号需要，跟原手机号一致不发送验证码)
    oldPhone: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // ↓ 输入框右侧图标
      userIcon: require('@/assets/images/user.png'),
      hidePassIcon: require('@/assets/images/hide-pass.png'),
      showPassIcon: require('@/assets/images/show-pass.png'),
      hidePassword: true, // 是否隐藏密码
      countdown: 60, // 倒计时秒数
      timer: null,
    };
  },
  methods: {
    ...mapMutations('login', ['setForgetPasswordByKey']),
    handleInput(val) {
      this.$emit('update:inputValue', val);
      this.$emit('update-store', val);
    },
    handleError(err) {
      this.$emit('error', err); // err 为错误信息字符串
    },
    getCurrentType() {
      const path = this.$route.path;
      switch (path) {
        case '/login':
          return 'login';
        case '/register/shop':
          return 'registerShop';
        case '/register/supplier':
          return 'registerSupplier';
        case '/login/forgetPassword':
          return 'forgetPassword';
        case '/login/modifyPhone':
          return 'modifyPhone';
        default:
          return '';
      }
    },
    // 获取到指定的手机号
    getCurrentPhone() {
      const type = this.getCurrentType();
      switch (type) {
        case 'login':
          return this.$store.state.login.loginAllForm.codePhone;
        case 'registerShop':
          return this.$store.state.login.registerShopForm.phone;
        case 'registerSupplier':
          return this.$store.state.login.registerSupplierForm.phone;
        case 'forgetPassword':
          return this.$store.state.login.forgetPasswordForm.phone;
        case 'modifyPhone':
          return this.$store.state.login.modifyPhoneForm.newPhone;
        default:
          return '';
      }
    },
    // 返回传给后端接口的 type（获取验证码的类型）
    getParamsType() {
      const type = this.getCurrentType();

      switch (type) {
        case 'login':
          return 'log';
        case 'registerShop':
          return 'distributorReg';
        case 'registerSupplier':
          return 'supplierReg';
        case 'forgetPassword':
          return 'forgetPassword';
        case 'modifyPhone': // 供应商未登录修改手机号
          return 'supplierEditPhone';
        default:
          return '';
      }
    },
    // 获取验证码
    // 注意：“忘记密码”界面的发送验证码input的接口与其他界面不同，需要特殊处理
    async handleGetCode() {
      try {
        this.handleError();
        const type = this.getParamsType();
        const params = {
          phone: this.getCurrentPhone()?.trim(),
          MUTE_WARNING: 1,
        };
        // 忘记密码的请求参数与其他类型不同
        if (type != 'forgetPassword') {
          params.type = type;
        }

        // 手机号为空或者验证不通过时不发请求
        let phoneReg = new RegExp(validatePhoneNumberReg);
        let inputPhone = this.getCurrentPhone()?.trim();
        let isPhoneValid = inputPhone && phoneReg.test(inputPhone);
        if (!isPhoneValid) {
          this.$emit('error', '手机号码格式不正确，请重新输入');
          return;
        }
        // 未登录修改手机号 需要校验跟原手机号是否一致
        if (
          type == 'supplierEditPhone' &&
          inputPhone == this.oldPhone?.trim()
        ) {
          this.$emit('error', '新手机号与原手机号相同，请重新输入');
          return;
        }
        // 忘记密码的发送验证码接口与其他不同
        const res =
          type != 'forgetPassword'
            ? await sendSmsCode(params)
            : await sendRechargeSmsCode(params);

        if (res.errcode == 0) {
          if (type == 'forgetPassword') {
            this.setForgetPasswordByKey({
              key: 'type',
              value: res.data.type,
            });
          }
          this.timer = setInterval(() => {
            this.countdown--;
            if (this.countdown == 0) {
              clearInterval(this.timer);
              this.timer = null;
              this.countdown = 60;
            }
          }, 1000);
          if (res?.msg) {
            this.$message.success(res?.msg);
          }
        } else {
          const currPath = this.$route.path;
          if (
            currPath == '/register/shop' ||
            currPath == '/register/supplier'
          ) {
            this.$message.error(res.msg);
          }
          this.$emit('error', res.msg);
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
