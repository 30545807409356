
export default {
  data() {
    return {
      curPage: this.page,
    };
  },
  props: {
    // 数据列表
    dataList: {
      type: Array,
      default: [],
    },
    // 标签点击
    activeName: {
      type: String,
      default: '-1',
    },
    // 数据总数
    total: {
      type: [String, Number],
      default: 0,
    },
    // 页码
    page: {
      type: [String, Number],
      default: 1,
    },
  },
  computed: {
    // 记录拥有的总页数
    totalPage() {
      let total = parseInt(Number(this.total) / 4);
      if (Number(this.total) % 4 == 0) {
        return total;
      }
      if (Number(this.total) % 4 != 0) {
        return total + 1;
      }
    },
  },
  methods: {
    // 点击标签页
    changeTab(id) {
      this.$emit('change-tab', id);
    },

    // 切换标签页相关操作
    handlePage(n) {
      if (n === -1 && this.curPage == 1) {
        return;
      }
      if (n === 1 && this.curPage == this.totalPage) {
        return;
      }
      this.curPage += n;
      this.$emit('handle-page', this.curPage);
    },
  },
};
