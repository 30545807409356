/**
 * 欠费提示弹窗文字
 */

export const ARREARS_FONT = {
  'zh-cn': {
    title: '提示',
    button: '去支付',
    content: '您当前人民币账户存在欠费信息，请您支付欠费后再进行采购。',
  },
  en: {
    title: 'Prompt',
    button: 'Go pay',
    content:
      'Your current USD account has outstanding payment information. Please make the payment before proceeding with the purchase.',
  },
};
