
export default {
  name: 'XmSelect',
  data() {
    return {
      selectVaule: this.selectVal, // 选项的val
      inputValue: '', // input的val值
    };
  },
  props: {
    width: {
      // 选择器的宽度
      type: String,
      default: '112px',
    },
    inputWidth: {
      // input的宽度
      type: String,
      default: '157px',
    },
    optionData: {
      // 选项数据
      type: Array,
      default: () => [],
    },
    // placeholder: {
    //   // 下拉选择的placeholder
    //   type: String,
    //   default: '请选择',
    // },
    inputPlaceholder: {
      // input框的placehoder
      type: String,
      default: '关键词',
    },
    appendBody: {
      type: Boolean,
      default: false,
    },
    //
    /**
     * 回显需要
     * returnShow: {
     *  selectVal: '',
     *  inputVal: ''
     * }
     */
    returnShow: {
      type: Object,
      default: () => {},
    },
    /**
     * 这个的作用是控制是否有下拉框
     * 配合下面的变量和reVal一起使用，传值就能跟有下拉框用法一样
     */
    hasSelect: {
      type: Boolean,
      default: true,
    },
    /**
     * 没有下拉框时，指定的key
     * 配合hasSelect 变量 和 reVal 一起使用
     */
    selectVal: {
      type: String,
      default: '',
    },
    // 控制是否要触发 onSearch
    isSearch: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    returnShow: {
      deep: true,
      handler(val) {
        if (
          !val?.selectVal ||
          !val?.inputVal ||
          val?.selectVal == '' ||
          val?.inputVal == ''
        ) {
          return;
        }
        this.selectVaule = val.selectVal;
        this.inputValue = val.inputVal;
        this.onSearch();
      },
    },
  },
  computed: {
    placeholder() {
      return this.optionData.length > 0 ? this.optionData[0].label : '';
    },
  },
  methods: {
    // 放大镜点击事件
    onBtn() {
      if (this.hasSelect && this.selectVaule == '') {
        this.selectVaule = this.optionData[0].value;
      }
      this.onSearch();
    },
    // 传值出去的方法
    onSearch() {
      this.$emit('change', this.selectVaule, this.inputValue);
    },
    // 切换下拉框值的方法
    onChange() {
      this.inputValue = '';
    },
    // 点击清空按钮的方法
    clearVal() {
      this.inputValue = '';
      this.onSearch();
    },
    /**
     *
     * @param {String} selectVaule 下拉框对应的值
     * @param {String} inputValue 输入框对应的值
     * 这里是为了父组件能够重置子组件中的数据
     * 也可以用作数据的回显
     */
    reVal(selectVaule = '', inputValue = '') {
      this.selectVaule = selectVaule;
      this.inputValue = inputValue;
      if (this.isSearch) {
        this.onSearch();
      }
    },
  },
};
