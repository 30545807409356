
import orderAddress from './orderAddress.vue';
import { changeOrderAddress } from '@/api/client-api/trade/orderlist.js';
export default {
  data() {
    // 校验地址
    let checkAddress = (rule, value, callback) => {
      if (this.addressObj.addressInfo.city_id == '' || this.isInfoNoIntegrity) {
        if (this.lang == 'en') {
          callback(new Error('Please select a country/province/city/district'));
        } else {
          callback(new Error('请选择完整的地址'));
        }
      } else {
        callback();
      }
    };
    // 校验区号
    let checkcode = (rule, value, callback) => {
      if (!this.addressObj.dialing_code) {
        if (this.lang == 'en') {
          callback(new Error('Please select'));
        } else {
          callback(new Error('请选择'));
        }
      } else {
        callback();
      }
    };
    // 校验详细地址
    let checkAddressDetail = (rule, value, callback) => {
      let field = rule.field;
      let en = this.lang == 'en';
      let reg = /[\u4E00-\u9FA5]/g;
      if (value == '') {
        if (en) {
          callback(new Error('Please enter detailed address information'));
        } else {
          switch (field) {
            case 'deatilAddress':
              callback(new Error('请输入详细地址信息'));
              break;
            case 'username':
              callback(new Error('请输入收货人姓名'));
              break;
          }
        }
      } else if (en && reg.test(value)) {
        callback(new Error('Please fill in the English information'));
      } else {
        callback();
      }
    };
    let checkPhone = (rule, value, callback) => {
      let reg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      let fixedLine = /^\d{2,4}-\d{6,8}$/;

      let en = this.lang == 'en';
      if (value == '') {
        if (en) {
          callback(new Error('Please enter your phone number'));
        } else {
          callback(new Error('请输入手机号'));
        }
        callback(new Error('请输入手机号'));
      } else if (!reg.test(value) && !fixedLine.test(value)) {
        if (en) {
          callback(new Error('Please enter the correct phone number'));
        } else {
          callback(new Error('请输入正确的联系方式'));
        }
      } else {
        callback();
      }
    };
    return {
      dialogVisible: true,
      rules: {
        deatilAddress: [
          { required: true, trigger: 'blur', validator: checkAddressDetail },
        ],
        addressInfo: [
          { required: true, trigger: 'change', validator: checkAddress },
        ],
        zip: [
          {
            required: true,
            trigger: 'blur',
            message: 'Please fill in the postal code',
          },
        ],
        username: [
          {
            required: true,
            trigger: 'blur',
            validator: checkAddressDetail,
          },
        ],
        dialing_code: [
          { required: true, trigger: 'change', validator: checkcode },
        ],
        phone: [{ required: true, trigger: 'blur', validator: checkPhone }],
      },
      addressObj: {
        addressInfo: {}, // 地址信息,校验使用
        deatilAddress: '', // 详细地址
        zip: '', // 邮政编码
        username: '', // 收货人姓名
        phone: '', // 电话
        dialing_code: '', // 区号
        //  remarks: "", // 备注
      },
      phonetypelist: [
        {
          label: '中国大陆+86',
          value: '86',
        },
        {
          label: '中国香港+852',
          value: '852',
        },
        {
          label: '中国澳门+853',
          value: '853',
        },
        {
          label: '中国台湾+886',
          value: '886',
        },
        {
          label: '韩国+82',
          value: '82',
        },
        {
          label: '日本+81',
          value: '81',
        },
      ],
      orderType: 1, // 1是中文订单，2是英文订单
      chaType: '', // 物流模板
      freightTempType: '', // 运费模板类型，province：省到省，city：市到市
      countryHaveAreas: '1', // 收货地国家是否有区
      isInfoNoIntegrity: false, // 地址信息是否填写不完整
    };
  },
  props: {
    tid: {
      type: String,
      default: '',
    },
    orderAddress: {
      type: Object,
      default: () => {},
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    orderAddress: {
      handler(obj) {
        if (Object.keys(obj).length > 0) {
          // 修改地址的国家省市区信息
          this.addressObj.addressInfo.country_id = obj.country_id;
          // this.addressObj.addressInfo.country_id = "56";
          this.addressObj.addressInfo.country = obj.country;
          this.addressObj.addressInfo.province_id = obj.province_id;
          this.addressObj.addressInfo.province = obj.province;
          this.addressObj.addressInfo.city_id = obj.city_id;
          this.addressObj.addressInfo.city = obj.city;
          this.addressObj.addressInfo.area_id = obj.area_id;
          this.addressObj.addressInfo.area = obj.area;
          // 其他信息
          this.addressObj.deatilAddress = obj.deatilAddress; // 详细地址
          this.addressObj.zip = obj.zip; // 邮政编码
          this.addressObj.username = obj.username; // 收获人姓名
          this.addressObj.phone = obj.phone; // 电话
          this.addressObj.dialing_code = obj.dialing_code; // 区号
          //  this.addressObj.remarks = obj.remarks; // 备注
          this.chaType = obj.chaType;
          this.freightTempType = obj.freightTempType; // 运费模板类型
          this.countryHaveAreas = obj.countryHaveAreas; // 运费模板类型
          if (this.addressObj.addressInfo.country_id == '44') {
            this.orderType = 1;
          } else {
            this.orderType = 2;
          }
        }
      },
      immediate: true,
    },
  },
  components: {
    orderAddress,
  },
  computed: {
    lang() {
      let type = '';
      if (this.addressObj.addressInfo?.country_id == '44') {
        type = 'zh-cn';
      } else {
        type = 'en';
      }
      return type;
    },
  },
  methods: {
    // 确认修改地址
    editAddress() {
      this.$refs.addressObjform.validate((valid) => {
        if (valid) {
          let option = {
            tid: this.tid, // 订单id
            receive_country: this.addressObj.addressInfo.country_id, // 新地址，国家
            receive_state: this.addressObj.addressInfo.province_id, // 新地址，省，州
            receive_city: this.addressObj.addressInfo.city_id, // 新地址，市
            receive_areas: this.addressObj.addressInfo.area_id, // 新地址，区
            receive_address: this.addressObj.deatilAddress, // 详细地址
            receive_zip: this.addressObj.zip, // 邮政编码
            receive_user: this.addressObj.username, // 收获人姓名
            receive_phone: this.addressObj.phone, // 收获人电话
            dialing_code: this.addressObj.dialing_code, // 区号
            // remark: this.addressObj.remarks, // 买家备注
          };
          changeOrderAddress(option).then((res) => {
            if (res.errcode == 0) {
              this.$message.success(res.msg);
              this.handleClose('back');
            }
          });
        }
      });
    },
    // 关闭弹框
    handleClose(obj) {
      this.$emit('closeAddress', obj);
    },
    // 更新地址信息
    update(obj, en_obj) {
      this.addressObj.addressInfo.city_id = obj.city_id;
      this.addressObj.addressInfo.city = obj.city;
      this.addressObj.addressInfo.area_id = obj.area_id;
      this.addressObj.addressInfo.area = obj.area;
    },
    // 获取地址是否没选择完成
    checkAddressInfo(isNoIntegrity) {
      this.isInfoNoIntegrity = isNoIntegrity;
      // 单独校验地址信息
      this.$refs.addressObjform.validateField('addressInfo');
    },
  },
};
