
import SearchBox from '@/components/comme/searchBox.vue';
import MainContent from './components/MainContent.vue';
import IndexBottom from '@/components/indexbottom.vue';
import BackToTop from '@/components/BackToTop.vue';
import { getSubsidizedGoods } from '@/api/client-api/activity/activity';
import { mapGetters } from 'vuex';
export default {
  name: 'PlatformSubsidy',
  components: {
    SearchBox,
    MainContent,
    IndexBottom,
    BackToTop,
  },
  async asyncData(app) {
    let lang = app.store.state.common.currLanguage;
    // 活动商品列表
    let activityGoodsList = {
      preference: [],
      hotSale: [],
    };
    let options = {
      pageSize: 16, // 页大小
      pageNum: 1, // 页码
      API_LANG_TYPE: lang,
      MUTE_WARNING: 1,
    };
    try {
      const [resPreference, resHotSale] = await Promise.all([
        // 细目优选
        app.$getSubsidizedGoods({
          ...options,
          activity_plate: '1',
        }),
        // 当季热销
        app.$getSubsidizedGoods({
          ...options,
          activity_plate: '2',
        }),
      ]);
      // 细目优选
      if (resPreference?.errcode == 0 && resPreference?.data?.list) {
        activityGoodsList.preference = resPreference?.data?.list || [];
      }
      // 当季热销
      if (resHotSale?.errcode == 0 && resHotSale?.data?.list) {
        activityGoodsList.hotSale = resHotSale?.data?.list || [];
      }
      return { activityGoodsList };
    } catch (error) {
      console.error(error);
      if (error?.status) {
        // 请求报错
        console.log('/activity/platform', error?.status, error?.statusText);
      } else {
        // 代码报错
        console.log(error);
      }
      return { activityGoodsList };
    }
  },
  data() {
    return {
      lang: '', // 语言

      // 活动商品列表
      activityGoodsList: {
        preference: [], // 细目优选
        hotSale: [], // 当季热销
      },

      pageSize: 16, // 页大小
      pageNum: 1, // 页码

      busy: false, // 无限加载的变量
      noMore: false, // 当季热销没有下一页
      moreHotSaleList: [], // 更多的当季热销商品
    };
  },
  computed: {
    ...mapGetters({
      currLang: 'common/currLang',
    }),
  },
  mounted() {
    this.lang = this.currLang;
    this.getXmWellSelectList(); // 获取细目优选数据
    this.getHotSaleList(); // 获取当季热销数据
  },
  methods: {
    // 获取细目优选数据
    getXmWellSelectList() {
      let options = {
        activity_plate: '1',
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        API_LANG_TYPE: this.lang,
        MUTE_WARNING: 1,
      };
      getSubsidizedGoods(options).then((res) => {
        if (res?.errcode == 0) {
          this.activityGoodsList.preference = res?.data?.list || [];
        }
      });
    },
    // 获取当季热销数据
    getHotSaleList() {
      let options = {
        activity_plate: '2',
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        API_LANG_TYPE: this.lang,
        MUTE_WARNING: 1,
      };
      getSubsidizedGoods(options).then((res) => {
        if (res?.errcode == 0) {
          this.activityGoodsList.hotSale = res?.data?.list || [];
          if (res?.data?.list?.length < 16) {
            this.noMore = true;
            this.busy = true;
          }
        }
      });
    },
    /**
     * 获取更多的智能推荐数据
     * noMore：默认值false, 为true时智能推荐没有下一页
     * busy: 无限加载的变量，为true时加载函数不触发
     */
    getMoreHotSaleList() {
      if (this.noMore) return;
      this.pageNum++;
      let options = {
        activity_plate: '2',
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        MUTE_WARNING: 1,
      };
      getSubsidizedGoods(options)
        .then((res) => {
          if (res?.errcode == 0) {
            if (res?.data?.list?.length !== 0) {
              this.moreHotSaleList = res?.data?.list;
              this.activityGoodsList.hotSale = [
                ...this.activityGoodsList.hotSale,
                ...this.moreHotSaleList,
              ];
              this.busy = false;
            }
            if (res?.data?.list?.length == 0 || res?.data?.list?.length < 16) {
              this.noMore = true;
              this.busy = true;
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    loadMore() {
      if (!this.busy) {
        this.busy = true;
        this.getMoreHotSaleList();
      }
    },
  },
};
