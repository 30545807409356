// 获取用户使用的浏览器
// 目前能获取到的浏览器：Google、火狐Firefox、Edge（有些浏览器内核是一样的，区分不了）
// 后续需要扩展可以继续加
const browser =(()=> {
  let u = navigator.userAgent;
  console.log(navigator.userAgent, 'navigator', u.indexOf('Edg'));

  let bws = [
    {
      name: 'edge',
      it: u.indexOf('Edg') > -1,
    },
    {
      name: 'firefox',
      it: u.indexOf('Firefox') > -1,
    },
    {
      name: 'chrome',
      it: u.indexOf('Chrome') > -1 || u.indexOf('CriOS') > -1,
    },
  ];

  for (var i = 0; i < bws.length; i++) {
    if (bws[i].it) {
      return bws[i].name;
    }
  }
  // 不在bws中的情况
  return navigator.userAgent;
})();

// 获取操作系统
const getOS = (() => {
  var u = navigator.userAgent;
  if (!!u.match(/compatible/i) || u.match(/Windows/i)) {
    return 'windows';
  } else if (!!u.match(/Macintosh/i) || u.match(/MacIntel/i)) {
    return 'macOS';
  } else if (!!u.match(/iphone/i) || u.match(/Ipad/i)) {
    return 'ios';
  } else if (!!u.match(/android/i)) {
    return 'android';
  } else {
    return u;
  }
})();

// 获取设备类型
const getDevice = (() => {
  let agent = navigator.userAgent.toLowerCase();
  if (/windows/.test(agent)) {
    return 'windows pc';
  } else if (/iphone|ipod/.test(agent) && /mobile/.test(agent)) {
    return 'iphone';
  } else if (/ipad/.test(agent) && /mobile/.test(agent)) {
    return 'ipad';
  } else if (/android/.test(agent) && /mobile/.test(agent)) {
    return 'android';
  } else if (/linux/.test(agent)) {
    return 'linux pc';
  } else if (/mac/.test(agent)) {
    return 'mac';
  } else {
    return navigator.userAgent;
  }
})();

export { browser, getOS, getDevice };
