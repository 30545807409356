
import FormTitle from './component/FormTitleItem.vue';
import UpInput from './component/UpInputItem.vue';
import WideButton from './component/WideButtonItem.vue';
import ErrorTip from './component/ErrorTipItem.vue';
import {
  loginDiscernAccount,
  loginVerifySupplier,
  loginEditPhone,
} from '@/api/client-api/login/api.js';
import { mapMutations } from 'vuex';
import {
  validatePhoneNumberReg,
  validateIDcardNumberReg,
  validatePassportNumberReg,
  validateHRPcardNumberReg,
  validateTaiWancardNumberReg,
} from '@/utils/validator';

export default {
  name: 'ModifyPhone',
  components: { FormTitle, UpInput, WideButton, ErrorTip },
  data() {
    return {
      // step1
      oldPhone: '', // 原来的手机号
      // step2
      name: '', // 姓名
      idcardType: '1', // 身份证类型 1居民身份证 2护照 3港澳居民来往内地通行证 4台湾居民来往大陆通行证
      idcard: '', // 身份证号
      // step3
      newPhone: '', // 新手机号
      code: '', // 验证码
      supplier_id: '', // 供应商id

      showErrorTip: false,
      errorTipContent: '',

      currentStep: 1,
      labelMap: {
        1: {
          title: '手机号验证',
          subTitle: '请输入手机号',
          btnLabel: '下 一 步',
        },
        2: {
          title: '身份信息验证',
          subTitle: '请输入身份信息',
          btnLabel: '下 一 步',
        },
        3: {
          title: '修改手机号',
          subTitle: '请输入用户信息',
          btnLabel: '提 交',
        },
        4: {
          icon: 'icon-a-Group427320624',
          title: '提交成功',
          subTitle: '',
          btnLabel: '返 回',
        },
        5: {
          icon: 'icon-SIGN-BLUE',
          title: '该手机号修改正在审核中...',
          subTitle: '',
          btnLabel: '返 回',
        },
      },
      // 身份证类型匹配
      cardTypeOptions: [
        { label: '居民身份证', value: '1' },
        { label: '护照', value: '2' },
        { label: '港澳居民来往内地通行证', value: '3' },
        { label: '台湾居民来往大陆通行证', value: '4' },
      ],
    };
  },
  computed: {
    // 证件类型文字
    cardTypeFont() {
      const type = this.cardTypeOptions.find(
        (item) => item.value == this.idcardType
      );
      return type?.label;
    },
  },
  methods: {
    ...mapMutations('login', ['setModifyPhoneByKey']),
    async nextStep() {
      try {
        this.handleHideErrorTip();
        let res = true;
        if (this.currentStep == 1) {
          // 验证原手机号
          res = await this.handleValidateOldPhone();
        } else if (this.currentStep == 2) {
          // 验证姓名和证件号
          res = await this.handleValidateIdentity();
        } else if (this.currentStep == 3) {
          // 发送修改手机号请求(已发送验证码后)
          res = await this.handlePostNewPhone();
        } else if (this.currentStep > 3) {
          // 返回登录页面
          // this.currentStep = 0;
          this.$store.commit('login/clearModifyPhoneForm'); // 清空 vuex 的缓存
          this.$router.push('/login');
          return;
        }
        if (res) this.currentStep++;
      } catch (err) {
        console.error(err);
      }
    },
    // 验证原手机号
    async handleValidateOldPhone() {
      try {
        let phoneReg = this.verifyPhoneNumber(this.oldPhone);
        if (!phoneReg) return false;
        // 调接口获取验证原手机号信息
        let options = {
          phone: this.oldPhone?.trim(),
          MUTE_WARNING: 1,
        };
        const res = await loginDiscernAccount(options);
        if (res?.errcode == 0 && res?.data?.idcard_type) {
          this.idcardType = res?.data?.idcard_type;
          return true;
        } else if (res?.errcode == 12002) {
          this.labelMap[5].subTitle = res?.msg;
          this.currentStep = 4;
          return true;
        } else {
          this.handleShowErrorTip(res.msg);
          return false;
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 验证姓名和证件号
    async handleValidateIdentity() {
      try {
        let idcardReg;
        // 姓名和证件号都没输入
        if (!this.name?.trim() || !this.idcard?.trim()) {
          this.handleShowErrorTip('姓名和证件号不能为空');
          return false;
        }
        // 验证证件号格式
        switch (this.idcardType?.toString()) {
          case '1':
            idcardReg = new RegExp(validateIDcardNumberReg);
            break;
          case '2':
            idcardReg = new RegExp(validatePassportNumberReg);
            break;
          case '3':
            idcardReg = new RegExp(validateHRPcardNumberReg);
            break;
          case '4':
            idcardReg = new RegExp(validateTaiWancardNumberReg);
            break;
          default:
            break;
        }
        if (!idcardReg.test(this.idcard?.trim())) {
          this.handleShowErrorTip('请输入合法的证件号');
          return false;
        }
        // 调接口获取验证姓名和证件号
        let options = {
          phone: this.oldPhone?.trim(),
          name: this.name?.trim(),
          idcard: this.idcard?.trim(),
          idcard_type: this.idcardType,
          MUTE_WARNING: 1,
        };
        const res = await loginVerifySupplier(options);
        if (res?.errcode == 0) {
          this.supplier_id = res?.data?.supplier_id;
          return true;
        } else {
          this.handleShowErrorTip(res.msg);
          return false;
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 提交新手机号和验证码
    async handlePostNewPhone() {
      try {
        // 判空
        if (!this.newPhone?.trim() || !this.code?.trim()) {
          this.handleShowErrorTip('手机号码和短信验证码不能为空');
          return false;
        }
        // 判断是否跟原手机号一致
        if (this.newPhone?.trim() == this.oldPhone?.trim()) {
          this.handleShowErrorTip('新手机号与原手机号相同，请重新输入');
          return false;
        }
        let phoneReg = this.verifyPhoneNumber(this.newPhone);
        if (!phoneReg) return false;
        // 调接口获取验证
        let options = {
          phone: this.newPhone?.trim(),
          code: this.code?.trim(),
          supplier_id: this.supplier_id,
          MUTE_WARNING: 1,
        };
        const res = await loginEditPhone(options);
        if (res?.errcode == 0) {
          this.labelMap[4].subTitle =
            '平台会在3个工作日内审核，请联系客户经理（' +
            res?.data?.real_name +
            '，' +
            res?.data?.admin_user_phone +
            '）获取审核进度';
          return true;
        } else {
          this.handleShowErrorTip(res.msg);
          return false;
        }
      } catch (err) {
        console.error(err);
      }
    },
    /**
     * 验证手机号规则
     * @param {String} val 要验证的手机号
     */
    verifyPhoneNumber(val) {
      let phoneReg = new RegExp(validatePhoneNumberReg);
      if (!phoneReg.test(val?.trim())) {
        this.handleShowErrorTip('请输入正确的手机号码');
        return false;
      }
      return true;
    },
    // 展示错误提示
    handleShowErrorTip(content) {
      // 如果无内容，则隐藏错误提示
      if (!content) {
        this.showErrorTip = false;
        this.errorTipContent = '';
        return;
      }
      this.showErrorTip = true;
      this.errorTipContent = content;
    },
    // 隐藏错误提示
    handleHideErrorTip() {
      this.showErrorTip = false;
    },
    handleUpdateStore(val, key) {
      this.setModifyPhoneByKey({ key: key, value: val });
    },
    // 返回登录
    backLogin() {
      this.$router.push('/login');
    },
  },
};
