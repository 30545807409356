
import {
  getuserInfo,
  getuseOrderInfo,
  getTopList,
  getGuessGoodsList,
} from '@/api/client-api/my-platform/platform.js';
import PersonInfo from './components/PersonInfo.vue'; // 个人信息
import OrderInfo from './components/OrderInfo.vue'; // 订单信息
import TopBoard from './components/TopBoard.vue'; // TOP榜单信息
import GuessPreferences from './components/GuessPreferences.vue'; // 猜你喜欢
import { mapGetters } from 'vuex';
export default {
  name: 'Platform',
  components: {
    PersonInfo,
    OrderInfo,
    TopBoard,
    GuessPreferences,
  },
  data() {
    return {
      lang: '',
      userInfo: {
        userInfo: {},
        depos: {},
        my_information: {},
      }, // 用户信息
      orderList: {}, // 订单信息
      // TOP榜单信息
      topData: {
        trade_rank: [], // 分销商采购额
        goodsPriceRank: [], // 商品销售额
        goodsRank: [], // 商品销量
      },
      guessLikeList: [], // 猜你喜欢商品列表
      pageSize: 5, // 猜你喜欢每页展示数据
      pageNum: 1, // 猜你喜欢的页码
      topListOptions: {},
      topFlag: false, // 是否加载top榜组件
    };
  },
  computed: {
    ...mapGetters({
      currLang: 'common/currLang',
    }),
  },
  mounted() {
    if (process.client) {
      this.lang = this.currLang;
      this.getUserInfo(); // 获取用户信息
      this.getUserOrderMsg(); // 获取用户订单数据
      this.getTopInfo(); // 获取榜单信息
      this.getGuessList(); // 获取猜你喜欢榜单数据
    }
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      getuserInfo({ API_LANG_TYPE: this.lang, MUTE_WARNING: 1 })
        .then((res) => {
          if (res?.errcode == 0) {
            this.userInfo = res?.data;
          } else {
            this.userInfo = {
              userInfo: {},
              depos: {},
              my_information: {},
            };
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取用户订单信息
    getUserOrderMsg() {
      getuseOrderInfo({ API_LANG_TYPE: this.lang, MUTE_WARNING: 1 })
        .then((res) => {
          if (res?.errcode == 0) {
            this.orderList = res?.data;
          } else {
            this.orderList = {};
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取榜单信息
    getTopInfo() {
      let options = {
        ...this.topListOptions,
        API_LANG_TYPE: this.lang,
        MUTE_WARNING: 1,
      };
      getTopList(options)
        .then((res) => {
          if (res?.errcode == 0) {
            if (res?.data?.trade_rank) {
              this.topData.trade_rank = JSON.parse(
                JSON.stringify(res?.data?.trade_rank || [])
              );
              this.topData.trade_rank = this.topData.trade_rank?.map((item) => {
                return { ...item, numShow: item.total_money };
              });
            } else {
              this.topData.trade_rank = [];
            }

            if (res?.data?.goodsPriceRank) {
              this.topData.goodsPriceRank = JSON.parse(
                JSON.stringify(res?.data?.goodsPriceRank || [])
              );
              this.topData.goodsPriceRank = this.topData.goodsPriceRank?.map(
                (item) => {
                  return { ...item, numShow: item.total_money };
                }
              );
            } else {
              this.topData.goodsPriceRank = [];
            }

            if (res?.data?.goodsRank) {
              this.topData.goodsRank = JSON.parse(
                JSON.stringify(res?.data?.goodsRank || [])
              );
              this.topData.goodsRank = this.topData.goodsRank?.map((item) => {
                return { ...item, numShow: item.num };
              });
            } else {
              this.topData.goodsRank = [];
            }

            this.topFlag = true;
          } else {
            this.topData = {};
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取猜你喜欢数据
    getGuessList() {
      let options = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        API_LANG_TYPE: this.lang,
        MUTE_WARNING: 1,
      };
      getGuessGoodsList(options)
        .then((res) => {
          if (res?.errcode == 0) {
            this.guessLikeList = res?.data?.map((obj) => {
              return { ...obj, collectFont: false };
            });
          } else {
            this.guessLikeList = [];
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 搜索TOP榜数据
    searchTopList(searchOptions) {
      this.topListOptions = searchOptions;
      this.getTopInfo();
    },
    // 点击换一批猜你喜欢
    changeBatch() {
      this.pageNum++;
      this.getGuessList(); // 重新获取数据
    },
    // 收藏商品
    collectGoods(item) {
      const guessLikeGoods = this.guessLikeList.find(
        (i) => i.item_id === item.item_id
      );

      let options = {
        item_id: item.item_id,
      };
      // 商品为已收藏状态
      if (item.is_fav == 1) {
        this.$delFav(options)
          .then((res) => {
            if (res?.errcode == 0) {
              item.is_fav = 0;
              item.collectFont = true;
              if (guessLikeGoods) {
                guessLikeGoods.is_fav = 0;
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
      // 商品为未收藏状态
      else {
        this.$addFav(options)
          .then((res) => {
            if (res?.errcode == 0) {
              item.is_fav = 1;
              item.collectFont = true;
              if (guessLikeGoods) {
                guessLikeGoods.is_fav = 1;
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }

      setTimeout(() => {
        item.collectFont = false;
      }, 1000);
    },
  },
};
