/**
 * 我的平台模块
 * -
 */

// 后台管理架子
import shophome from '@/pages/back-stage/index.vue';
// 通知模块
import MyPlatform from '@/pages/back-stage/my-platform/Platform.vue';//我的平台








const myPlatformRoutes = [

  {
    path: '/',
    component: shophome,
    meta: {
      isAuth: true,
    },
    children: [
    // 通知模块的
      {
        path: 'myplatform',
        component: MyPlatform,
        meta: {
          isAuth: true,
        },
      },
    ],
  },
];

export default myPlatformRoutes;
