
import leftmenu from '@/components/comme/leftmenu.vue';
export default {
  name: 'shopHomeAccount',
  components: {
    leftmenu,
  },
  data() {
    return {
      leftname: '账号及认证',
      menulist: [
        '账户设置',
        // '账号管理',
        // '角色&权限',
        // '关注类目',
        // '通知设置',
        // '基本信息',
        '账号注销',
      ],
      routerlist: [
        '/account/accountset',
        // '/account/accountmanagement',
        // '/account/role',
        // '/account/focusCategory',
        // '/account/notification',
        // '/account/information',
        '/account/logoutaccount',
      ],
      routerIndexHash: {
        '/account/accountset': 0,
        // '/account/accountmanagement': 1,
        // '/account/information': 1,
        '/account/logoutaccount': 1,
      },
      status: '',
      jsq: null,
    };
  },
  methods: {
    getStatus() {
      this.jsq = setInterval(() => {
        this.status = this.$cookies.get('statusShow');
        // if (this.status != '2') {
        //   this.menulist.splice(0, 4);
        //   this.routerlist.splice(0, 4);
        // }
        // 平台通过注销申请
        if (this.status == '6') {
          this.menulist = ['账号注销'];
          this.routerlist = ['/account/logoutaccount'];
          this.routerIndexHash = { '/account/logoutaccount': 0 };
        }
        clearInterval(this.jsq);
      }, 100);
    },
  },
  //计算属性computed无法传递参数
  computed: {},
  mounted() {
    this.getStatus();
  },
};
