// 投诉&举报
import request from '@/utils/request.js';

//获取分类问题列表
export function getComplaintCategoryList(data) {
  return request({
    url: '/ComplainReport/getComplaintCategoryList',
    method: 'get',
    params: data,
    controller: {
      openLoading:true
    }
  });
}
// 发起投诉/举报
export function submitComplaint(data) {
  return request({
    url: '/ComplainReport/submitComplaint',
    method: 'post',
    data: data,
    controller: {
      openLoading:true
    }
  });
}

// 申诉
export function complaintsAppeals(data) {
  return request({
    url: '/ComplainReport/complaintsAppeals',
    method: 'post',
    data: data,
    controller: {
      openLoading:true
    }
  });
}

// 获取举报理由
export function getReportReasonList(data) {
  return request({
    url: '/ComplainReport/getReportReasonList',
    method: 'get',
    params: data,
    controller: {
      openLoading:true
    }
  });
}

// 获取投诉&举报列表
export function getComplainList(data) {
  return request({
    url: '/complainReport/getList',
    method: 'get',
    params: data,
    controller: {
      openLoading:true
    }
  });
}

// 获取投诉&举报详情
export function complaintDetails(data) {
  return request({
    url: '/ComplainReport/complaintDetails',
    method: 'get',
    params: data,
    controller: {
      openLoading:true
    }
  });
}

// 获取数据id信息列表
export function getDataIdInfo(data) {
  return request({
    url: '/ComplainReport/getDataIdInfo',
    method: 'get',
    params: data,
  });
}

// 评价
export function assess(data) {
  return request({
    url: '/complainReport/assess',
    method: 'post',
    data: data,
    controller: {
      openLoading:true
    }
  });
}

