/**
 * 接口业务码 errcode 统一处理函数
 * @date 2024-08-26
 */

import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
import tools from '@/utils/tool-function/tool.js';

/**
 * 判断当前是否为登录状态
 * @returns Boolean
 */
function isLoginStatus() {
  let token = localStorage.getItem(CUR_TOKEN_NAME)
    ? localStorage.getItem(CUR_TOKEN_NAME)
    : window.$nuxt.context.$cookies.get(CUR_TOKEN_NAME);
  return !!token;
}
/**
 * 执行登出操作
 */
function logout() {
  tools.logout();
}

export function errcodeHandler(errcode) {
  let isLogin = isLoginStatus(); // 当前是否为登录状态
  if (isLogin) {
    if (errcode == -100 || errcode == -102) {
      alert('登录状态失效，请重新登录');
      logout();
    } else if (errcode == -101) {
      alert('账号在其他设备登录，请重新登录');
      logout();
    }
  } else {
    // 判断登录限制次数是否用光
    // 如果用光，则跳转登录
    if (errcode == -100 || errcode == -102 || errcode == -103) {
      if (window.location.pathname !== '/login') {
        window.location.assign('/login');
      }
    }
  }

  if (errcode == 80204) {
    window.location.assign('/');
  } else if (errcode == -106 || errcode == -107) {
    // 账号关闭&账号限制，不需要上报
    logout();
  } else if (errcode == -108) {
    // 账号注销审核通过
    window.location.assign('/account/logoutaccount');
  }
}
