
// import { getNavbar, getLogo } from '@/utils/api/bottom.js';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  name: 'IndexBottom',
  props: {
    // 底部版心
    bottomWrapWidth: {
      type: String,
      default: '1200px',
    },
    // 背景颜色
    backgroundColor: {
      type: String,
      default: '#fff',
    },
    // 是否有网站特色板块
    hasService: {
      type: Boolean,
      default: true,
    },
    // 是否展示友情链接
    showLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      token: '',
      treeData: [], // 底部数据
      logoImg: '', // 底部logo
      // 关于我们
      // aboutUs: [
      //   { title: '平台介绍', url: 'https://home.ximu.cn/HomePage' },
      //   { title: '用户指南', url: '' },
      // ],
      // 交易规则
      // tradingRule: [
      //   { title: '放款与提现规则', url: 'https://home.ximu.cn/PlatRule/44' },
      //   { title: '交易纠纷处理规则', url: 'https://home.ximu.cn/PlatRule/46' },
      //   { title: '交易违规管理规则', url: 'https://home.ximu.cn/PlatRule/48' },
      // ],
      // 发票管理
      // invoiceManage: [
      //   { title: '发票开具规则', url: 'https://home.ximu.cn/PlatRule/64' },
      //   { title: '发票常见问题解答', url: 'https://home.ximu.cn/PlatRule/84' },
      // ],
      // 售后服务
      // afterSales: [
      //   { title: '售后保障规则', url: 'https://home.ximu.cn/PlatRule/35' },
      //   { title: '退货政策', url: 'https://home.ximu.cn/PlatRule/60' },
      //   {
      //     title: '售后服务相关问题解答',
      //     url: 'https://home.ximu.cn/PlatRule/90',
      //   },
      // ],
      // 合作招商
      // cooperative: [
      //   { title: '供应商入驻', url: 'https://home.ximu.cn/register/supplier' },
      //   { title: '分销商入驻', url: 'https://home.ximu.cn/register/shop' },
      //   { title: '联系我们', url: '' },
      // ],
      // 法规政策
      policy: [
        { title: '隐私政策', url: 'https://home.ximu.cn/PlatRule/85' },
        { title: '法律声明', url: 'https://home.ximu.cn/PlatRule/87' },
        // {title: '廉正举报', url: ''},
        { title: '商品发布政策', url: 'https://home.ximu.cn/PlatRule/16' },
        { title: '友情链接', url: '/friendlyLinks' },
      ],
      // 版权文字
      copyrightFont: [
        {
          title: '湘ICP备2023010376号-1',
          url: 'https://beian.miit.gov.cn/#/Integrated/index',
        },
        {
          title: '湘公网备案 43019002001936',
          url: 'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43019002001936',
        },
        { title: '增值电信业务经营许可证: 湘B2-20230578' },
        { title: 'Copyright © 2023-2033 ximu.cn 版权所有' },
        { title: '违法和不良信息举报电话：0755-27761552' },
        {
          title: '营业执照',
          url: 'https://img.ximu.cn/test_upload_ZH12304010001_169094288685503563658',
        },
      ],
      webPic: [
        // {name: 'credible-web', title: '可信网站', url: ''},
        // {name: 'integrity-web', title: '诚信网站', url: ''},
        {
          name: 'complaint-reporting',
          title: '全国12315平台',
          url: 'https://www.12315.cn/',
        },
        {
          name: 'internet-police',
          title: '网络警察',
          url: 'http://cyberpolice.mps.gov.cn/wfjb/',
        },
        {
          name: 'internet-harmful-msg',
          title: '网络有害信息',
          url: 'https://www.12377.cn/',
        },
        {
          name: 'ipp',
          title: '知识产权',
          url: 'https://ggfw.cnipa.gov.cn/PatentCMS_Center/',
        },
        {
          name: 'crackdown',
          title: '扫黄打非',
          url: 'https://www.shdf.gov.cn/',
        },
      ],
    };
  },
  mounted() {
    if (process.client) {
      this.token = this.$cookies.get(CUR_TOKEN_NAME);
      this.getNavbar();
      this.getLogo();
    }
  },
  watch: {
    showLink: {
      handler(val) {
        if (val) {
          this.policy = [
            { title: '隐私政策', url: 'https://home.ximu.cn/PlatRule/85' },
            { title: '法律声明', url: 'https://home.ximu.cn/PlatRule/87' },
            // {title: '廉正举报', url: ''},
            { title: '商品发布政策', url: 'https://home.ximu.cn/PlatRule/16' },
            { title: '友情链接', url: '/friendlyLinks' },
          ];
        } else {
          this.policy = [
            { title: '隐私政策', url: 'https://home.ximu.cn/PlatRule/85' },
            { title: '法律声明', url: 'https://home.ximu.cn/PlatRule/87' },
            // {title: '廉正举报', url: ''},
            { title: '商品发布政策', url: 'https://home.ximu.cn/PlatRule/16' },
          ];
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 跳转
    jump(url) {
      if (url === '') {
        this.$message.warning('暂未开放，敬请期待');
        return;
      }
      if (url) {
        window.open(url);
      }
    },
    getNavbar() {
      let option = { MUTE_WARNING: 1 };
      this.$getNavbar(option)
        ?.then((res) => {
          if (res?.errcode == 0) {
            this.treeData = res?.data?.list;
          }
        })
        ?.catch((err) => {
          console.error(err);
        });
    },
    getLogo() {
      let option = { MUTE_WARNING: 1 };
      this.$getLogo(option)
        ?.then((res) => {
          if (res?.errcode == 0) {
            this.logoImg = res?.data?.image;
          }
        })
        ?.catch((err) => {
          console.error(err);
        });
    },
  },
};
